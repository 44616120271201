<section class="banner-all">
    <div class="container div-titulo">
        <h2 class="titulo text-center">¿Cómo te podemos ayudar?</h2>
        <p class="mt-3 text-justify">Resuelve tus dudas sobre nuestros servicios. Escribe a continuación una palabra relacionada con lo que necesitas saber.</p>
    </div>
    <div class="container">
        <div class="row mb-5">
            <div class="col-xl-4 col-lg-4"></div>
            <div class="col-xl-4 col-lg-4">
                <div class="col input-group w-100">
                    <input class="form-control text-center" placeholder="¿cuál es tu duda?" type="text"
                        [formControl]="myControl">
                    <div class="input-group-append">
                        <span class="input-group-text"><i class="fa fa-keyboard"></i></span>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4"></div>
        </div>
        <div class="row mx-3">
            <div class="card w-100" *ngIf="(filteredFaqs$ | async)?.length > 0 && myControl.value.length > 0">
                <div class="card-body">
                    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" class="w-100" activeIds="ngb-panel-0">
                        <ngb-panel *ngFor="let option of filteredFaqs$ | async | sortBy: 'asc':'id'; let index = index">
                            <ng-template ngbPanelTitle>
                                <span [innerHTML]="option.pregunta">
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p [innerHtml]="option.respuesta"></p>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
        <div class="row mx-3" *ngIf="((filteredFaqs$ | async)?.length === 0 || myControl.value.length === 0) && primerFiltro">
            <ngb-alert [dismissible]="false" type="secondary" class="text-center w-100">
                <strong>¿No encuentras lo que buscabas?</strong><br>
                Comunícate con nosotros a través de nuestro chat de WhatsApp
            </ngb-alert>
        </div>
    </div>
</section>