<div class="row align-items-center">
    <div class="col-xl-12 col-lg-12 col-md-12 normal">
        <div class="site-header-one__logo mb-3 mt-5 mt-sm-1">
            <a [href]="_urlWeb">
                <img src="assets/logo_128.png" width="150" alt="">
            </a>
            <!-- <span class="side-menu__toggler"><i class="fa fa-bars" style="color: #ff0b64;"></i></span> -->
        </div>
        <div class="widgets-wrap float-right">
            <div class="widget-header menu">
                <a [href]="'/mi-experto/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-home gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">INICIO</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [routerLink]="['/tarifa', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-dollar-sign gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">TARIFA </small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [routerLink]="['/perfil-experto', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="far fa-user gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">PERFIL</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [href]="'/beneficio/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-wallet gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">BENEFICIO</small>
                </a>
            </div>
            <div class="widget-header menu" *ngIf="destacado === 'NO'">
                <a [routerLink]="['/ser-destacado', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-certificate gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">{{_destacado.toUpperCase()}}</small>
                </a>
            </div>
            <div class="widget-header menu" *ngIf="isprime === 'NO'">
                <a [routerLink]="['/ser-prime', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-user-tie gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">PRIME</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [href]="'/notificacion-experto/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-exclamation gris"></i>
                        <span class="badge bg-rojo ml-1" *ngIf="notificaciones > 0"
                            style="font-size: 0.75rem!important; color: #fff!important; position: absolute;">{{notificaciones}}</span>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">NOTIFICACIONES</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a (click)="auth.salirMensaje()" class="widget-view" style="cursor: pointer;">
                    <div class="icon-area">
                        <i class="fas fa-door-open gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">CERRAR SESION</small>
                </a>
            </div>
        </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 responsive">
        <div class="site-header-one__logo mb-3 mt-5 mt-sm-1">
            <a [href]="_urlWeb">
                <img src="assets/logo_128.png" width="150" alt="">
            </a>
            <!-- <span class="side-menu__toggler"><i class="fa fa-bars" style="color: #ff0b64;"></i></span> -->
        </div>
        <span class="side-menu__toggler widgets-wrap float-right" (click)="changeMenu()" *ngIf="!isMenu">
            <i class="fa fa-bars" style="color: #ff0b64;"></i>
        </span>
        <span class="side-menu__toggler widgets-wrap float-right" (click)="changeMenu()" *ngIf="isMenu">
            <i class="fas fa-times" style="color: #ff0b64;"></i>
        </span>
        <div class="widgets-wrap float-right" *ngIf="isMenu">
            <div class="widget-header menu">
                <a [href]="'/mi-experto/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-home gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">INICIO</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [routerLink]="['/tarifa', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-dollar-sign gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">TARIFA </small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [routerLink]="['/perfil-experto', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="far fa-user gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">PERFIL</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [href]="'/beneficio/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-wallet gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">BENEFICIO</small>
                </a>
            </div>
            <div class="widget-header menu" *ngIf="destacado === 'NO'">
                <a [routerLink]="['/ser-destacado', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-certificate gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">{{_destacado.toUpperCase()}}</small>
                </a>
            </div>
            <div class="widget-header menu" *ngIf="isprime === 'NO'">
                <a [routerLink]="['/ser-prime', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-user-tie gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">PRIME</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [href]="'/notificacion-experto/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-exclamation gris"></i>
                        <span class="badge bg-rojo ml-1" *ngIf="notificaciones > 0"
                            style="font-size: 0.75rem!important; color: #fff!important; position: absolute;">{{notificaciones}}</span>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">NOTIFICACIONES</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a (click)="auth.salirMensaje()" class="widget-view" style="cursor: pointer;">
                    <div class="icon-area">
                        <i class="fas fa-door-open gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">CERRAR SESION</small>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="row align-items-center">
    <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="float-left">
            <main>
                <article class="card mb-3" style="border: none!important;">
                    <div class="card-body">
                        <figure class="icontext">
                            <div class="icon mr-4">
                                <img class="rounded-circle img-sm border" [src]="url">
                            </div>
                            <div class="text">
                                <strong class="rojo">Bienvenido</strong> <br>
                                <strong class="gris">{{nickname}}</strong>
                                <span style="font-size: 1.5rem;" class="badge" *ngIf="destacado === 'SI'">
                                    <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                <br>
                                <b>{{nivel}}</b><br>
                                <div *ngIf="calificaciones > 0 && calificaciones <= 0.5">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 0.5 && calificaciones <= 1">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 1 && calificaciones <= 1.5">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 1.5 && calificaciones <= 2">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 2 && calificaciones <= 2.5">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 2.5 && calificaciones <= 3">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 3 && calificaciones <= 3.5">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 3.5 && calificaciones <= 4">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 4 && calificaciones <= 4.5">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star-half"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                                <div *ngIf="calificaciones > 4.5 && calificaciones <= 5">
                                    <ul class="rating-stars">
                                        <li style="width:100%" class="stars-active">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                        <li>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </li>
                                    </ul>
                                    <div class="label-rating">{{calificaciones}} / 5</div>
                                </div>
                            </div>
                        </figure>
                    </div>
                </article>
            </main>
        </div>
    </div>
</div>