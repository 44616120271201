<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-experto></app-menu-experto>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">¿ Deseas ser Prime ?</h2>
    </div> -->
    <div class="container mb-3">
            <h2 class="titulo text-center">EXPERT PRIME</h2>
            <p class="mt-3 text-center">Si estás en el negocio de la asesoría o consultoría, puedes solicitar tu
                <b>Membresía Expert Prime</b></p>
        <div class="row">
            <div class="col-md-4 px-1 pb-3 text-center">
                <img src="assets/QUE_ES.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-4 px-1 pb-3 text-center">
                <img src="assets/REQUISITOS.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-4 px-1 pb-3 text-center">
                <img src="assets/DURACION.png" alt="" class="img-fluid">
            </div>
        </div>
        <div class="row">
            <a [href]="_urlPay + '/?tipo=prime&id=' + this.fk" type="button" class="btn btn-outline-primary rounded-pill mt-3 mx-auto">Proceder al pago</a>
        </div>
    </div>
</section>