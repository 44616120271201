import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { Experto } from './../shared/interface/experto';
import { Area } from './../shared/interface/area';
import { AreaService } from './../shared/service/area.service';
import { Rama } from './../shared/interface/rama';
import { RamaService } from './../shared/service/rama.service';
import { PreguntaService } from './../shared/service/pregunta.service';

@Component({
  selector: 'app-pregunta',
  templateUrl: './pregunta.component.html',
  styleUrls: ['./pregunta.component.css']
})
export class PreguntaComponent implements OnInit {

  public jsonExpertosRelacionado$: Observable<any[]>;
  public totalRelacionado = 0;
  public totalCaracteres = 0;
  public otro = '';
  public jsonAreas: Area[];
  public jsonRamas: Rama[];
  public fkexperto: number;
  public fkarea: number;
  public fkrama: number;
  public fkespecialidad: number;
  public tipo: string;
  private valor: number;
  private valorcomision: number;
  private contenido: string;
  public isMailContenido = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;
  public isEstado = -1;
  public estado$: Observable<any>;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private areaService: AreaService,
    private ramaService: RamaService,
    private preguntaService: PreguntaService,
  ) {
    this.getAreas();
    this.getRamas();

    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      
      this.fkarea = parseInt(params.area);
      this.fkrama = parseInt(params.rama);
      this.fkespecialidad = parseInt(params.especialidad);
      this.tipo = params.tipof;
      

      if (params.istodos) {
        this.fkexperto = parseInt('0');        
      } else {
        this.fkexperto = parseInt(params.experto);
      }

      if (this.auth.validar()) {
        if (this.auth.obtener('fk')) {
          if (params.istodos) {
            window.location.href = '/nueva-pregunta/' + this.auth.obtener('fk') + '/' + this.fkexperto + '/' + this.fkarea + '/' + this.fkrama + '/' + this.fkespecialidad + '/todos'+'/'+this.tipo;
          } else {
            window.location.href = '/nueva-pregunta/' + this.auth.obtener('fk') + '/' + this.fkexperto + '/' + this.fkarea + '/' + this.fkrama + '/' + this.fkespecialidad;
          }
        }
      } else {
        // window.location.href = '/registro';
      }

      if (params.istodos) {
        this.isEstado = 1;
      } else {
        this.getEstadoExperto(this.fkexperto, this.fkarea, this.fkrama, this.fkespecialidad);
      }

      // this.getEstadoExperto(this.fkexperto, this.fkarea, this.fkrama, this.fkespecialidad);

      // if (this.isEstado !== 1) {
      this.getExpertoRelacionado(params.especialidad);
      // }

      this.getTarifasExperto(this.fkexperto);
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      /** Pregunta */
      fecha: [''],
      tipo: [null], //DIRECTA
      titulo: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      fkarea: [null], //this.fkarea
      fkrama: [null], //this.fkrama 
      fkespecialidad: [null], //this.fkespecialidad
      fkusuario: [null], //0
      estado1: [null], //NO_ASIGNA
      estado2: [null], //*PENDIENTE
      correoelectronicotemp: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      /** Hilo */
      // fecha: [''],
      fecharespuesta: [null],
      fkexperto: [null], //this.fkexperto
      valor: [null],
      valorcomision: [null],
      fkpregunta: [null],
      contenido: [null, Validators.required],
      respuesta: [null],
      estado3: [null], //NUEVA
      estado4: [null], //NO_ASIGNA
      estado5: [null], //PENDIENTE
      estado6: [null], //*PENDIENTE
      fechamaxotranotificacion: [null],
      fkrecibo: [null],
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.valid) {
      const object = this.form.value;
      /** Pregunta */
      // object.tipo = 'DIRECTA';
      object.fkarea = this.fkarea;
      object.fkrama = this.fkrama;
      object.fkespecialidad = this.fkespecialidad;
      object.fkusuario = this.getFkusuario();
      object.estado1 = 'NO_ASIGNA';
      object.estado2 = 'PENDIENTE';
      /** Hilo */
      object.fkexperto = this.fkexperto;
      object.contenido = this.contenido;
      object.valor = this.valor;
      object.valorcomision = this.valorcomision;
      object.estado3 = 'NUEVA';
      object.estado4 = 'NO_ASIGNA';
      object.estado5 = 'PENDIENTE';
      object.estado6 = 'PENDIENTE';
      this.isAccion = 0;


      /** Pregunta */
      if (parseInt(object.fkexperto) === 0) {
        object.tipo = 'ABIERTA';        
      } else {
        object.tipo = 'DIRECTA';
      }


      this.preguntaService.post(object).subscribe(
        (response: any) => {
          // response 200
          this.isAccion = 1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          // if (isLogin) {
          // if (true) {
          //   swal.fire({
          //     imageUrl: this._urlTest + '/logo_128.png',
          //     imageHeight: 64,
          //     icon: 'success',
          //     title: 'Hemos almacenado tu inquietud, vamos a proceder con el pago ...',
          //     showConfirmButton: false,
          //     timer: 5000
          //   });
          // } else {
          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos almacenado tu inquietud, vamos a proceder al registro de tu usuario ...',
            showConfirmButton: false,
            timer: 4000
          });
          // }


          // if (isLogin) {
          // if (true) {
          //   response.fkrecibo = 1; // respuesta del servidor
          //   setTimeout(function () {
          //     window.location.href = '/confirmacion-pago-hilo/' + response.fkrecibo;
          //   }, 4000);
          // } else {
          setTimeout(function () {
            window.location.href = '/registro';
          }, 4000);
          // }

        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

      // });
    }
  }

  public getFkusuario(): number {
    return 0;
  }

  public getTarifasExperto(id: any): any {
    id = id.toString().trim();
    this.queryService.query_19(id).subscribe(
      (response: any) => {
        try {
          this.valor = response[0].valor;
          this.valorcomision = response[0].valorcomision;
        } catch (error) {
          this.valor = null;
          this.valorcomision = null;
        }
      },
      error => {
        this.valor = null;
        this.valorcomision = null;
      }
    );
  }

  private getEstadoExperto(id: any, fkarea: any, fkrama: any, fkespecialidad: any): void {
    this.queryService.query_21(id, fkarea, fkrama, fkespecialidad).subscribe(
      (experto: any[]) => {
        try {
          this.estado$ = new Observable((observer) => {
            try {
              observer.next(experto[0].estado);
              observer.complete();
            } catch (error) {
              this.isEstado = 0;
            }
          });

          this.estado$.subscribe(response => {
            if (response === 'ACTIVO') {
              this.isEstado = 1;
            } else {
              this.isEstado = 0;
            }
          });
        } catch (error) {
          this.isEstado = 0;
        }

      }, error => {
        this.isEstado = 0;
      }
    );
  }

  public getAreas(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas = areas;
      }
    );
  }

  public getRamas(): void {
    this.ramaService.getAll().subscribe(
      (ramas: Rama[]) => {
        this.jsonRamas = ramas;
      }
    );
  }

  public getNombreArea(id: number): Area[] {
    return this.jsonAreas.filter(area => area.id === id);
  }

  public getNombreRama(id: number): Rama[] {
    return this.jsonRamas.filter(rama => rama.id === id);
  }

  public onChangue(event: any): void {
    var re = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/;
    if (re.test(event.editor.getText())) {
      this.isMailContenido = true;
    } else {
      this.isMailContenido = false;
    }

    this.totalCaracteres = event.editor.getLength();
    if (this.totalCaracteres > 499) {
      event.editor.deleteText(499, event.editor.getLength());
    }
    this.contenido = event.editor.getText();
  }

  public otraBusquedad(): void {
    window.location.href = '/consulta-experto/busquedad/' + this.otro + '/1';
  }

  private getExpertoRelacionado(especialidad: any): void {
    this.queryService.query_24(especialidad).subscribe(
      (expertos: Experto[]) => {
        this.jsonExpertosRelacionado$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.jsonExpertosRelacionado$.subscribe(response => {
          this.totalRelacionado = response.length;
        });

        if (this.totalRelacionado === 0) {
          this.queryService.query_65().subscribe(
            (expertos: Experto[]) => {
              this.jsonExpertosRelacionado$ = new Observable((observer) => {
                observer.next(expertos);
                observer.complete();
              });

              this.jsonExpertosRelacionado$.subscribe(response => {
                this.totalRelacionado = response.length;
              });

            },
            error => {
            },
            () => {
            }
          );
        }
      }, error => {
        this.queryService.query_65().subscribe(
          (expertos: Experto[]) => {
            this.jsonExpertosRelacionado$ = new Observable((observer) => {
              observer.next(expertos);
              observer.complete();
            });

            this.jsonExpertosRelacionado$.subscribe(response => {
              this.totalRelacionado = response.length;
            });

          },
          error => {
          },
          () => {
          }
        );
      }
    );
  }

}
