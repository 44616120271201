import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';

@Component({
  selector: 'app-activacion-referencia-experto',
  templateUrl: './activacion-referencia-experto.component.html',
  styleUrls: ['./activacion-referencia-experto.component.css']
})
export class ActivacionReferenciaExpertoComponent implements OnInit {

  public numero: string;
  public correoelectronico: string;
  public token: string;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.numero = params.numero;
      this.form.get('numero').setValue(this.numero);
      this.correoelectronico = params.correoelectronico;
      this.form.get('correoelectronico').setValue(this.correoelectronico);
      this.token = params.token;
      this.form.get('tokenconfirma').setValue(this.token);
      this.update();
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      correoelectronico: [null, [Validators.required, Validators.email]],
      tokenconfirma: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      numero: [null, Validators.required],
    });
  }

  /** OBLIGATORIO */
  public update(): void {
    if (this.form.valid) {
      const object = this.form.value;
      this.isAccion = 0;

      this.queryService.query_9(object.correoelectronico, object.tokenconfirma, object.numero).subscribe(
        (response: any) => {
          // response 200
          try {
            if (response[0].isconfirmareferencia === 'SI') {
              this.isAccion = 1;
              const swal = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
              });

              swal.fire({
                imageUrl: this._urlTest + '/logo_128.png',
                imageHeight: 64,
                icon: 'success',
                title: 'Gracias, tu ' + this._experto + ' te reconocera tu valoración',
                showConfirmButton: false,
                timer: 5000
              });
            } else {
              this.isAccion = -1;
              const swal = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-error'
                },
                buttonsStyling: false
              });

              swal.fire({
                // imageUrl: this._urlTest + '/logo_128.png',
                // imageHeight: 64,
                icon: 'error',
                title: 'No fue posible verificar tu referencia',
                showConfirmButton: false,
                timer: 5000
              });
            }
          } catch (error) {
            this.isAccion = -1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'No fue posible verificar tu referencia',
              showConfirmButton: false,
              timer: 5000
            });
          }
        },
        //   this.isAccion = 1;
        //   const swal = Swal.mixin({
        //     customClass: {
        //       confirmButton: 'btn btn-success'
        //     },
        //     buttonsStyling: false
        //   });

        //   swal.fire({
        //     imageUrl: this._urlTest + '/logo_128.png',
        //     imageHeight: 64,
        //     icon: 'success',
        //     title: 'Bienvenido, hemos verificado tu identidad, puedes continuar ingresando usuario y contraseña',
        //     showConfirmButton: false,
        //     timer: 4000
        //   });

        //   setTimeout(function () {
        //     window.location.href = '/login-experto/' + object.correoelectronico;
        //   }, 4000);
        // },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'No fue posible verificar tu referencia',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

      // });
    }
  }

}
