<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Recuperamos tu acceso de {{_experto}}</h2> -->
    </div>
    <div class="container">
        <!-- <div class="row">
            <div class="col-md-6 offset-md-3 px-1 pb-3">
                <img src="assets/template/images/600x300.jpg" alt="" class="img-fluid">
            </div>
        </div> -->
        <div class="row">
            <!-- *** -->
            <div class="card mx-auto" style="background-color: #ffffff60; max-width: 650px;">
                <!-- *** -->
                <span class="span-titulo-confirmacion">
                    <mark class="titulo-confirmacion">RECUPERAR CONTRASEÑA</mark>
                </span>
                <!-- *** -->
                <div class="card-body card-confirmacion text-center">
                    <!-- *** -->
                    <p style="color: #665C61 !important;">Revisa tu correo electrónico (<b>{{correoelectronico}}</b>) en
                        la bandeja de entrada y/o correo no deseado (Spam). En menos de 5 minutos te llegará un mensaje
                        con un enlace y debes dar clic en él.</p>

                </div>
                <!-- *** -->
                <div class="form-group text-center" style="margin-top: -1rem;">
                    <a class="btn btn-outline-primary rounded-pill"
                        [routerLink]="['/activacion-recuperar-experto', correoelectronico]">
                        Ingresar el código de recuperación <i class="fas fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>