import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { ExpertosService } from './../shared/service/expertos.service';
import { AuthService } from './../shared/service/-auth.service';
import { QueryService } from './../shared/service/-query.service';

@Component({
  selector: 'app-login-experto',
  templateUrl: './login-experto.component.html',
  styleUrls: ['./login-experto.component.css']
})
export class LoginExpertoComponent implements OnInit {

  public correoelectronico: string;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private expertosService: ExpertosService,
    private auth: AuthService,
    private queryService: QueryService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    if (this.auth.validar()) {
      window.location.href = '/mi-experto/' + this.auth.obtener('fk');
    }

    this.route.params.subscribe((params: Params) => {
      this.correoelectronico = params.correoelectronico;
      this.form.get('correoelectronico').setValue(this.correoelectronico);
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      correoelectronico: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      contrasena: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
    });
  }

  /** OBLIGATORIO */
  public get(event: Event): void {
    event.preventDefault();

    if (this.form.valid) {
      const object = this.form.value;
      this.isAccion = 0;

      this.expertosService.login(object.correoelectronico, object.contrasena).subscribe(
        (response: any) => {
          // response 200
          this.auth.crear(response, object.correoelectronico);
          this.isAccion = 1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'La verificación de tu identidad ha sido exitosa ¡Bienvenido!',
            showConfirmButton: false,
            timer: 4000
          });

          setTimeout(function () {
            window.location.href = '/mi-experto/' + response.message.fk;
          }, 4000);
        },
        error => {
          // response 500

          this.queryService.query_72(object.correoelectronico).subscribe(
            (response: any[]) => {

              this.isAccion = -1;
              try {
                if (response[0].estado === 'ACTIVO') {
                  const swal = Swal.mixin({
                    customClass: {
                      confirmButton: 'btn btn-error'
                    },
                    buttonsStyling: false
                  });

                  swal.fire({
                    // imageUrl: this._urlTest + '/logo_128.png',
                    // imageHeight: 64,
                    icon: 'error',
                    title: 'No fue posible verificar tu identidad',
                    showConfirmButton: false,
                    timer: 5000
                  });

                } else {
                  const swal = Swal.mixin({
                    customClass: {
                      confirmButton: 'btn btn-error'
                    },
                    buttonsStyling: false
                  });

                  swal.fire({
                    // imageUrl: this._urlTest + '/logo_128.png',
                    // imageHeight: 64,
                    icon: 'error',
                    title: 'En cuanto recibamos la validación de tu identidad, podrás acceder a tu cuenta',
                    showConfirmButton: false,
                    timer: 5000
                  });
                }
              } catch (error) {
                const swal = Swal.mixin({
                  customClass: {
                    confirmButton: 'btn btn-error'
                  },
                  buttonsStyling: false
                });

                swal.fire({
                  // imageUrl: this._urlTest + '/logo_128.png',
                  // imageHeight: 64,
                  icon: 'error',
                  title: 'No fue posible verificar tu identidad',
                  showConfirmButton: false,
                  timer: 5000
                });
              }
            }
          );

          // this.isAccion = -1;
          // const swal = Swal.mixin({
          //   customClass: {
          //     confirmButton: 'btn btn-error'
          //   },
          //   buttonsStyling: false
          // });

          // swal.fire({
          //   // imageUrl: this._urlTest + '/logo_128.png',
          //   // imageHeight: 64,
          //   icon: 'error',
          //   title: 'No fue posible verificar tu identidad',
          //   showConfirmButton: false,
          //   timer: 5000
          // });
        }
      );

      // });
    }
  }

}
