import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as jQuery from 'jquery';
import { NgwWowService } from 'ngx-wow';
import { QueryService } from './../shared/service/-query.service';
import { environment } from './../../environments/environment';
import { AreaService } from './../shared/service/area.service';
import { Area } from './../shared/interface/area';
import { RamaService } from './../shared/service/rama.service';
import { Rama } from './../shared/interface/rama';
import { EspecialidadService } from './../shared/service/especialidad.service';
import { Especialidad } from './../shared/interface/especialidad';
import { Tarifa } from './../shared/interface/tarifa';
import { AnosExperienciaService } from './../shared/service/anos-experiencia.service';
import { AnosExperiencia } from './../shared/interface/anos-experiencia';
import { EjemploPreguntaService } from './../shared/service/ejemplo-pregunta.service';
import { EjemploPregunta } from './../shared/interface/ejemplo-pregunta';
import { EjemploEncuestaService } from './../shared/service/ejemplo-encuesta.service';
import { EjemploEncuesta } from './../shared/interface/ejemplo-encuesta';
import { HomeService } from './../shared/service/home.service';
import { Home } from './../shared/interface/home';
import { Experto } from './../shared/interface/experto';
import { LocalStorageService } from '../shared/service/-local-storage.service';
// import { log } from 'console';
// import { AuthService } from './../shared/service/-auth.service';
// import Swal from 'sweetalert2';
// import { UrlsafePipe } from './../shared/pipe/urlsafe.pipe';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  /** OBLIGATORIO */
  public form1: FormGroup;
  public form2: FormGroup;

  // public areas: Area[] = [];
  // public ramas: Rama[] = [];
  // public especialidads: Especialidad[] = [];
  // public areas_Verify: Area[][] = [[]];
  // public ramas_Verify: Rama[][] = [[]];
  // public especialidads_Verify: Especialidad[][] = [[]];
  // public tarifas: Tarifa[] = [];
  // public anosExperiencias: AnosExperiencia[] = [];

  private ejemploPreguntasObservable = new BehaviorSubject<EjemploPregunta[]>(null);
  public ejemploPreguntas$ = this.ejemploPreguntasObservable.asObservable();
  public ejemploPreguntas: EjemploPregunta[] = [];
  private ejemploEncuestasObservable = new BehaviorSubject<EjemploEncuesta[]>(null);
  public ejemploEncuestas$ = this.ejemploEncuestasObservable.asObservable();
  private homeObservable = new BehaviorSubject<Home[]>(null);
  public home$ = this.homeObservable.asObservable();
  public home: Home[] = [];
  private expertosObservable = new BehaviorSubject<Experto[]>(null);
  public expertos2: Experto[] = [];
  public expertos4: Experto[] = [];
  public expertos$ = this.expertosObservable.asObservable();

  // public filteredAreas1$: Observable<Area[]>;
  // public filteredAreas2$: Observable<Area[]>;
  // public filteredRamas1$: Observable<Rama[]>;
  // public filteredRamas2$: Observable<Rama[]>;
  // public filteredEspecialidads1$: Observable<Especialidad[]>;
  // public filteredEspecialidads2$: Observable<Especialidad[]>;
  // public filteredTarifas1$: Observable<Tarifa[]>;
  // public filteredTarifas2$: Observable<Tarifa[]>;
  // public filteredAnosExperiencias1$: Observable<AnosExperiencia[]>;
  // public filteredAnosExperiencias2$: Observable<AnosExperiencia[]>;

  // public form1Visible = false;
  // public form2Visible = false;
  // public isRama = false;
  // public isEspecialidad = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  public otro = '';

  constructor(
    /** OBLIGATORIO */
    // private formBuilder1: FormBuilder,
    // private formBuilder2: FormBuilder,
    private wowService: NgwWowService,
    private queryService: QueryService,
    // private areaService: AreaService,
    // private ramaService: RamaService,
    // private especialidadService: EspecialidadService,
    // private ejemploPreguntaService: EjemploPreguntaService,
    // private ejemploEncuestaService: EjemploEncuestaService,
    private homeService: HomeService,
    private localStorageService: LocalStorageService
    // private anosExperienciaService: AnosExperienciaService,
  ) {
    // this._buildForm1();
    // this._buildForm2();
    this.wowService.init();
  }

  /** OBLIGATORIO */
  // private _buildForm1(): void {
  //   this.form1 = this.formBuilder1.group({
  //     area: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
  //     rama: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
  //     especialidad: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
  //   });
  // }

  // private _buildForm2(): void {
  //   this.form2 = this.formBuilder2.group({
  //     area: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
  //     rama: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
  //     especialidad: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
  //     tarifamin: [null, Validators.required],
  //     tarifamax: [null, Validators.required],
  //     anosmin: [null, Validators.required],
  //     anosmax: [null, Validators.required],
  //   });
  // }

  ngOnInit(): void {

    
    // this.areaService.getAll().subscribe(
    //   (areas: Area[]) => {
    //     this.areas = areas;
        
    //   },
    //   error => {
    //     this.areas = null;
    //   },
    //   () => {
    //   }
    // );
    // this.filteredAreas1$ = this.form1.get('area').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterArea(value))
    //   );
    // this.filteredAreas2$ = this.form2.get('area').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterArea(value))
    //   );


    // this.ramaService.getAll().subscribe(
    //   (ramas: Rama[]) => {
    //     this.ramas = ramas;
    //   },
    //   error => {
    //     this.ramas = null;
    //   },
    //   () => {
    //   }
    // );
    // this.filteredRamas1$ = this.form1.get('rama').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterRama(value))
    //   );
    // this.filteredRamas2$ = this.form2.get('rama').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterRama(value))
    //   );


    // this.especialidadService.getAll().subscribe(
    //   (especialidads: Especialidad[]) => {
    //     this.especialidads = especialidads;
    //   },
    //   error => {
    //     this.especialidads = null;
    //   },
    //   () => {
    //   }
    // );
    // this.filteredEspecialidads1$ = this.form1.get('especialidad').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterEspecialidad(value))
    //   );
    // this.filteredEspecialidads2$ = this.form2.get('especialidad').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterEspecialidad(value))
    //   );


    // this.queryService.query_53().subscribe(
    //   (tarifas: Tarifa[]) => {
    //     this.tarifas = tarifas;
    //     console.log(tarifas);
        
    //   },
    //   error => {
    //     this.tarifas = null;
    //   },
    //   () => {
    //   }
    // );
    // this.filteredTarifas1$ = this.form2.get('tarifamin').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterTarifa(value))
    //   );
    // this.filteredTarifas2$ = this.form2.get('tarifamax').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterTarifa(value))
    //   );


    // this.anosExperienciaService.getAll().subscribe(
    //   (anosExperiencias: AnosExperiencia[]) => {
    //     this.anosExperiencias = anosExperiencias;
    //   },
    //   error => {
    //     this.anosExperiencias = null;
    //   },
    //   () => {
    //   }
    // );
    // this.filteredAnosExperiencias1$ = this.form2.get('anosmin').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterAnosExperiencia(value))
    //   );
    // this.filteredAnosExperiencias2$ = this.form2.get('anosmax').valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filterAnosExperiencia(value))
    //   );

    this.queryService.query_65().subscribe(
      (expertos: Experto[]) => {
        // this.expertos = expertos;
        
        let k = 0;
        let expertoPara4 = [];
        const expertosEn4 = [];
        for (let i = 0; i <= expertos.length - 1; i++) {
          const j = i + Math.floor(Math.random() * (expertos.length - i));

          const temp = expertos[j];
          expertos[j] = expertos[i];
          expertos[i] = temp;

          if (k < 3) {
            expertoPara4.push(temp);
            k++;
          } else if (k === 3) {
            expertoPara4.push(temp);
            expertosEn4.push(expertoPara4);
            expertoPara4 = [];
            k = 0;
          }

        }

        this.expertos4 = expertosEn4;

        k = 0;
        let expertoPara2 = [];
        const expertosEn2 = [];
        for (let i = 0; i <= expertos.length - 1; i++) {
          const j = i + Math.floor(Math.random() * (expertos.length - i));

          const temp = expertos[j];
          expertos[j] = expertos[i];
          expertos[i] = temp;

          if (k < 1) {
            expertoPara2.push(temp);
            k++;
          } else if (k === 1) {
            expertoPara2.push(temp);
            expertosEn2.push(expertoPara2);
            expertoPara2 = [];
            k = 0;
          }

        }

        this.expertos2 = expertosEn2;

        this.expertosObservable.next(expertos);
        
        
      },
      error => {
        this.expertosObservable = null;          
      },
      () => {
      }
    );


    // this.ejemploPreguntaService.getAll().subscribe(
    //   (ejemploPreguntas: EjemploPregunta[]) => {
    //     this.ejemploPreguntas = ejemploPreguntas;
    //     this.ejemploPreguntasObservable.next(ejemploPreguntas);

    //     this.ejemploEncuestaService.getAll().subscribe(
    //       (ejemploEncuesta: EjemploEncuesta[]) => {
    //         this.ejemploEncuestasObservable.next(ejemploEncuesta);

    //         this.queryService.query_100().subscribe(
    //           (home: Home[]) => {
    //             this.home = home;
    //             this.homeObservable.next(home);

    //             jQuery(window).on('scroll', function () {
    //               if (jQuery('.stricked-menu').length) {
    //                 var headerScrollPos = 100;
    //                 var stricky = jQuery('.stricked-menu');
    //                 if (jQuery(window).scrollTop() > headerScrollPos) {
    //                   stricky.addClass('stricky-fixed');
    //                 } else if (jQuery(this).scrollTop() <= headerScrollPos) {
    //                   stricky.removeClass('stricky-fixed');
    //                 }
    //               }

    //               var windscroll = jQuery(window).scrollTop();
    //               if (windscroll >= 100) {
    //                 var menuAnchor = jQuery('.one-page-scroll-menu .scrollToLink').children('a');
    //               } else {
    //                 // jQuery('.one-page-scroll-menu li.current').removeClass('current');
    //                 // jQuery('.one-page-scroll-menu li:first').addClass('current');
    //               }
    //             });
    //           },
    //           error => {
    //             this.homeObservable = null;
    //           },
    //           () => {
    //           }
    //         );
    //       },
    //       error => {
    //         this.ejemploEncuestasObservable = null;
    //       },
    //       () => {
    //       }
    //     );
    //   },
    //   error => {
    //     this.ejemploPreguntasObservable = null;
    //   },
    //   () => {
    //   }
    // );
  }

  // public video(url: string): void {
  //   Swal.fire({
  //     imageUrl: this._urlTest + '/logo_128.png',
  //     imageHeight: 64,
  //     width: 640,
  //     padding: 0,
  //     html: '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + url + '?controls=0" ' +
  //       'frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; ' +
  //       'picture-in-picture" allowfullscreen></iframe>',
  //     showConfirmButton: false,
  //   })
  // }

  // public cancelarForm1(): void {
  //   this.form1Visible = false;
  //   this.form2Visible = false;
  //   this.isRama = false;
  //   this.isEspecialidad = false;
  //   // this.ramas = [];
  //   // this.especialidads = [];
  //   // this.filteredRamas1$ = new Observable<Rama[]>();
  //   // this.filteredRamas2$ = new Observable<Rama[]>();
  //   // this.filteredEspecialidads1$ = new Observable<Especialidad[]>();
  //   // this.filteredEspecialidads2$ = new Observable<Especialidad[]>();
  //   this.form1.get('area').setValue(null);
  //   this.form1.get('rama').setValue(null);
  //   this.form1.get('especialidad').setValue(null);
  // }

  // public cancelarForm2(): void {
  //   this.form1Visible = false;
  //   this.form2Visible = false;
  //   this.isRama = false;
  //   this.isEspecialidad = false;
  //   // this.ramas = [];
  //   // this.especialidads = [];
  //   // this.filteredRamas1$ = new Observable<Rama[]>();
  //   // this.filteredRamas2$ = new Observable<Rama[]>();
  //   // this.filteredEspecialidads1$ = new Observable<Especialidad[]>();
  //   // this.filteredEspecialidads2$ = new Observable<Especialidad[]>();
  //   this.form2.get('area').setValue(null);
  //   this.form2.get('rama').setValue(null);
  //   this.form2.get('especialidad').setValue(null);
  //   this.form2.get('tarifamin').setValue(null);
  //   this.form2.get('tarifamax').setValue(null);
  //   this.form2.get('anosmin').setValue(null);
  //   this.form2.get('anosmax').setValue(null);
  // }

 /** OBLIGATORIO */
 public accionConsul(): void {
  event.preventDefault();
    this.localStorageService.remove('selectedRa');
    this.localStorageService.remove('selectedEs');
    this.localStorageService.remove('selectedArea');
    window.location.href = '/consulta-experto/all/1';
} 

public accionPreg():void{
  window.location.href = '/pregunta/0/0/0/0/todos/all';
}

  /** OBLIGATORIO */
  // public accion1(event: Event): void {
  //   event.preventDefault();

  //   if (this.form1.valid || this.form1.get('rama').value === 'todos:todos') {
  //     const object = this.form1.value;
  //     if (object.rama === 'todos:todos') {
  //       window.location.href = '/consulta-experto/area/' + this.form1.get('area').value.split(':')[1] + '/1';
  //     } else {
  //       window.location.href = '/consulta-experto/especialidad/' + this.form1.get('especialidad').value.split(':')[0] + '/1';
  //     }
  //   }
  // }
  // /** OBLIGATORIO */
  // public accion2(event: Event): void {
  //   event.preventDefault();

  //   if (this.form2.valid) {
  //     const object = this.form2.value;
  //     if (object.rama === 'todos:todos') {
  //       window.location.href = '/encuesta/' + object.area.split(':')[0] + '/todos/' +
  //         object.tarifamin + '/' + object.tarifamax + '/' + object.anosmin + '/' + object.anosmax;
  //     } else {
  //       window.location.href = '/encuesta/' + object.area.split(':')[0] + '/' + object.rama.split(':')[0] + '/' + object.especialidad.split(':')[0] + '/' +
  //       object.tarifamin + '/' + object.tarifamax + '/' + object.anosmin + '/' + object.anosmax;
  //     }
  //   }
  // }

  // public activarRama(area: any, form: number) {
  //   this.isRama = false;
  //   const id = area.split(':')[0];
  //   this.queryService.query_1(id).subscribe(
  //     (ramas: Rama[]) => {
  //       this.ramas = ramas;
  //       this.isRama = true;

  //       // if (form === 1) {
  //       //   this.filteredRamas1$ = this.filteredRamas1$.pipe(map(value => ramas));
  //       // } else if (form === 2) {
  //       //   this.filteredRamas2$ = this.filteredRamas2$.pipe(map(value => ramas));
  //       // }
  //     },
  //     error => {
  //       this.ramas = null;
  //       this.isRama = false;
  //     },
  //     () => {
  //     }
  //   );

  //   if (form === 1) {
  //     // this.form1.get('rama').setValue(null);
  //     // this.form1.get('especialidad').setValue(null);
  //     // this.filteredRamas$ = this.form1.get('rama').valueChanges
  //     //   .pipe(
  //     //     startWith(''),
  //     //     map(value => this._filterRama(value))
  //     //  );
  //   } else if (form === 2) {
  //     // this.form2.get('rama').setValue(null);
  //     // this.form2.get('especialidad').setValue(null);
  //     // this.filteredRamas$ = this.form2.get('rama').valueChanges
  //     //   .pipe(
  //     //     startWith(''),
  //     //     map(value => this._filterRama(value))
  //     //   );
  //   }
  // }

  // public activarEspecialidad(rama: any, form: number) {
  //   this.isEspecialidad = false;
  //   const id = rama.split(':')[0];
  //   if (id !== 'todos') {
  //     this.queryService.query_2(id).subscribe(
  //       (especialidads: Especialidad[]) => {
  //         this.especialidads = especialidads;
  //         this.isEspecialidad = true;
  //         // this.filteredEspecialidads$ = this.filteredEspecialidads$.pipe(map(value => especialidads));

  //         // if (form === 1) {
  //         //   this.filteredEspecialidads1$ = this.filteredEspecialidads1$.pipe(map(value => especialidads));
  //         // } else if (form === 2) {
  //         //   this.filteredEspecialidads2$ = this.filteredEspecialidads2$.pipe(map(value => especialidads));
  //         // }
  //       },
  //       error => {
  //         this.especialidads = null;
  //         this.isEspecialidad = false;
  //       },
  //       () => {
  //       }
  //     );
  //   }

  //   if (form === 1 && id !== 'todos') {
  //     // this.form1.get('especialidad').setValue(null);
  //     // this.filteredEspecialidads$ = this.form1.get('especialidad').valueChanges
  //     //   .pipe(
  //     //     startWith(''),
  //     //     map(value => this._filterEspecialidad(value))
  //     //   );
  //   } else if (form === 2 && id !== 'todos') {
  //     // this.form2.get('especialidad').setValue(null);
  //     // this.filteredEspecialidads$ = this.form2.get('especialidad').valueChanges
  //     //   .pipe(
  //     //     startWith(''),
  //     //     map(value => this._filterEspecialidad(value))
  //     //   );
  //   } else if (form === 2 && id === 'todos') {
  //     this.isEspecialidad = true;
  //     this.form2.get('especialidad').clearValidators();
  //     this.form2.get('especialidad').updateValueAndValidity();
  //   }
  // }

  public otraBusquedad(): void {
    window.location.href = '/consulta-experto/busquedad/' + this.otro + '/1';
  }

  public getSplit(value: string): string {
    try {
      let id = value.split(':')[0];
      if (parseInt(id) > 0) {
        return value.split(':')[1];
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  // private _filterArea(value: string): Area[] {
  //   try {
  //     const filterValue = value;
  //     return this.areas.filter(option => option.nombre.toLowerCase().includes(filterValue.toLowerCase()));
  //   } catch (error) { }
  // }

  // private _filterRama(value: string): Rama[] {
  //   try {
  //     const filterValue = value;
  //     return this.ramas.filter(option => option.nombre.toLowerCase().includes(filterValue.toLowerCase()));
  //   } catch (error) { }
  // }

  // private _filterEspecialidad(value: string): Especialidad[] {
  //   try {
  //     const filterValue = value;
  //     return this.especialidads.filter(option => option.nombre.toLowerCase().includes(filterValue.toLowerCase()));
  //   } catch (error) { }
  // }

  // private _filterTarifa(value: string): Tarifa[] {
  //   try {
  //     const filterValue = value.toString();
  //     return this.tarifas.filter(option => option.valor.toString().toLowerCase().includes(filterValue.toLowerCase()));
  //   } catch (error) { }
  // }

  // private _filterAnosExperiencia(value: string): AnosExperiencia[] {
  //   try {
  //     const filterValue = value;
  //     return this.anosExperiencias.filter(option => option.anos.toLowerCase().includes(filterValue.toLowerCase()));
  //   } catch (error) { }
  // }

  public filterHomeHorizontal(): Home[] {
    try {
      return this.home.filter(option => option.tipo.toLowerCase().includes('videohorizontal'));
    } catch (error) { }
  }

  public filterHomeVertical(): Home[] {
    try {
      return this.home.filter(option => option.tipo.toLowerCase().includes('videovertical'));
    } catch (error) { }
  }

}
