import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { EjemploPregunta } from './../interface/ejemplo-pregunta';

@Injectable({
  providedIn: 'root'
})
export class EjemploPreguntaService {

  constructor(
    private http: HttpClient
  ) { }

  public getAll(): Observable<EjemploPregunta[]> {
    return this.http.get<EjemploPregunta[]>(environment.urlApi + '/EjemploPregunta');
  }
}
