<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Confirmamos tu registro de {{_experto}}</h2> -->
    </div>
    <div class="container">
        <div class="row">
            <div class="card mx-auto" style="background-color: #ffffff60; max-width: 650px;">
                <span class="span-titulo-confirmacion">
                    <mark class="titulo-confirmacion">Gracias por aceptar</mark>
                </span>
                <div class="card-body card-confirmacion text-center">
                    <p style="color: #665C61 !important;">Desde este momento estarás disponible por 4 horas para ayudar
                        a responder la inquietud de nuestro {{_usuario}}.</p>

                </div>
                <div class="form-group text-center" style="margin-top: -1rem;">
                    <a class="btn btn-outline-primary rounded-pill">
                        Responder <i class="fas fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>