import { Pipe, PipeTransform } from '@angular/core';

import { EjemploPregunta } from './../interface/ejemplo-pregunta';


@Pipe({
  name: 'filterEjemploPregunta'
})
export class FilterEjemploPreguntaPipe implements PipeTransform {

  transform(ejemploPreguntas: EjemploPregunta[], filter: any, value: any): any {
    if (!ejemploPreguntas || !filter) {
      return ejemploPreguntas;
    }

    if (filter === 'tipo') {
      return ejemploPreguntas.filter(item => item.pregunta === value);
    } else {
      return ejemploPreguntas;
    }
  }

}
