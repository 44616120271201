import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { QueryService } from './../shared/service/-query.service';
import { environment } from './../../environments/environment';
import { Experto } from './../shared/interface/experto';
import { Area } from './../shared/interface/area';
import { Rama } from './../shared/interface/rama';
import { Especialidad } from './../shared/interface/especialidad';
import { AreaService } from './../shared/service/area.service';
import { RamaService } from './../shared/service/rama.service';
import { EspecialidadService } from './../shared/service/especialidad.service';
import { FacebookService, UIResponse, UIParams } from 'ngx-facebook';
import { ClipboardService } from 'ngx-clipboard';
import Swal from 'sweetalert2';
import { AuthService } from './../shared/service/-auth.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-experto',
  templateUrl: './experto.component.html',
  styleUrls: ['./experto.component.css']
})
export class ExpertoComponent implements OnInit {

  public fk: number;
  public jsonExpertos$: Observable<any[]>;
  public jsonExpertosRelacionado$: Observable<any[]>;
  public jsonAreas: Area[];
  public jsonRamas: Rama[];
  public jsonEspecialidads: Especialidad[];
  public total = 0;
  public totalRelacionado = 0;
  public isBuscando = -1;
  public isEstado = -1;
  public isEspecialidad = -1;
  public especialidad = 0;
  public estado$: Observable<any>;
  private handleError: any;
  public otro = '';

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  constructor(
    private queryService: QueryService,
    private areaService: AreaService,
    private ramaService: RamaService,
    private especialidadService: EspecialidadService,
    private route: ActivatedRoute,
    private fb: FacebookService,
    private clipboardService: ClipboardService,
    private auth: AuthService,
    private meta: Meta,
  ) {
    this.getAreas();
    this.getRamas();
    this.getEspecialidads();

    fb.init({
      appId: '206187567646346',
      version: 'v9.0'
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.getExperto(params.id);

      if (params.area && params.rama && params.especialidad) {
        this.isEspecialidad = 1;
        this.especialidad = parseInt(params.especialidad);
        this.getEstadoExperto(params.id, params.area, params.rama, params.especialidad);
      } else {
        this.isEspecialidad = 0;
        this.isEstado = 1;
      }

      // if (this.isEspecialidad === 1 && this.isEstado !== 1) {
      this.getExpertoRelacionado(params.especialidad);
      // }
    });

    if (this.auth.validar()) {
      this.fk = parseInt(this.auth.obtener('fk'));
    }

    this.meta.addTags([
      // { name: 'og:locale', content: 'og:locale' },
      // { name: 'og:type', content: 'article' },
      // { name: 'og:title', content: 'Expertips - Resuelve las dudas de negocios de alguien' },
      // { name: 'og:description', content: 'Expertips es la plataforma online donde expertos como tú ponen todo su conocimiento y experiencia para ayudar a empleados, emprendedores y profesionales a resolver sus dudas e inquietudes de manera eficiente, rápida y certera.' },
      // { name: 'og:url', content: this._urlWeb + '/experto/' + this.fk },
      // { name: 'og:site_name', content: 'Expertips - Resuelve las dudas de negocios de alguien' },
      // { name: 'article:publisher', content: 'https://www.facebook.com/Expertips-103840814936677/' },
      // { name: 'og:image', content: 'https://test.expertips.co/imagen_og.png' },
      // { name: 'og:image:secure_url', content: 'https://test.expertips.co/imagen_og.png' },
      // { name: 'og:image:width', content: '800' },
      // { name: 'og:image:height', content: '477' },
      // { name: 'twitter:card', content: 'summary_large_image' }, 
      // { name: 'twitter:title', content: 'Expertips - Resuelve las dudas de negocios de alguien' },
      // { name: 'twitter:description', content: 'Expertips es la plataforma online donde expertos como tú ponen todo su conocimiento y experiencia para ayudar a empleados, emprendedores y profesionales a resolver sus dudas e inquietudes de manera eficiente, rápida y certera.' },
      // { name: 'twitter:image', content: 'https://test.expertips.co/imagen_og.png' },
    ]);
  }

  // *** OJO IMPORTANTE CAMBIAR ***
  public share(url: string): void {
    const options: UIParams = {
      method: 'share',
      href: this._urlWeb + '/' + url
      // href: 'https://expertips.co'
    };

    this.fb.ui(options)
      .then((res: UIResponse) => {
        // console.log('Got the users profile', res);
      })
      .catch(this.handleError);
  }

  public copy(url: string): void {
    this.clipboardService.copyFromContent(this._urlWeb + '/' + url);

    const swal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    });

    swal.fire({
      imageUrl: this._urlTest + '/logo_128.png',
      imageHeight: 64,
      icon: 'success',
      title: 'Copiado',
      showConfirmButton: false,
      timer: 5000
    });
  }

  public otraBusquedad(): void {
    window.location.href = '/consulta-experto/busquedad/' + this.otro + '/1';
  }

  private getExperto(id: any): void {
    this.queryService.query_10(id).subscribe(
      (expertos: Experto[]) => {
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.jsonExpertos$.subscribe(response => {
          this.total = response.length;
        });

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
      }
    );
  }

  private getExpertoRelacionado(especialidad: any): void {
    this.queryService.query_24(especialidad).subscribe(
      (expertos: Experto[]) => {
        this.jsonExpertosRelacionado$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.jsonExpertosRelacionado$.subscribe(response => {
          this.totalRelacionado = response.length;
        });

        if (this.totalRelacionado === 0) {
          this.queryService.query_65().subscribe(
            (expertos: Experto[]) => {
              this.jsonExpertosRelacionado$ = new Observable((observer) => {
                observer.next(expertos);
                observer.complete();
              });

              this.jsonExpertosRelacionado$.subscribe(response => {
                this.totalRelacionado = response.length;
              });
            },
            error => {
            },
            () => {
            }
          );
        }

      }, error => {
        this.queryService.query_65().subscribe(
          (expertos: Experto[]) => {
            this.jsonExpertosRelacionado$ = new Observable((observer) => {
              observer.next(expertos);
              observer.complete();
            });

            this.jsonExpertosRelacionado$.subscribe(response => {
              this.totalRelacionado = response.length;
            });
          },
          error => {
          },
          () => {
          }
        );
      }
    );
  }

  private getEstadoExperto(id: any, fkarea: any, fkrama: any, fkespecialidad: any): void {
    this.queryService.query_21(id, fkarea, fkrama, fkespecialidad).subscribe(
      (experto: any[]) => {
        try {
          this.estado$ = new Observable((observer) => {
            try {
              observer.next(experto[0].estado);
              observer.complete();
            } catch (error) {
              this.isEstado = 0;
            }
          });

          this.estado$.subscribe(response => {
            if (response === 'ACTIVO') {
              this.isEstado = 1;
            } else {
              this.isEstado = 0;
            }
          });
        } catch (error) {
          this.isEstado = 0;
        }

      }, error => {
        this.isEstado = 0;
      }
    );
  }

  public getAreas(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas = areas;
      }
    );
  }

  public getRamas(): void {
    this.ramaService.getAll().subscribe(
      (ramas: Rama[]) => {
        this.jsonRamas = ramas;
      }
    );
  }

  public getEspecialidads(): void {
    this.especialidadService.getAll().subscribe(
      (especialidads: Especialidad[]) => {
        this.jsonEspecialidads = especialidads;
      }
    );
  }

  public getNombreArea(id: number): Area[] {
    let aux =  this.jsonAreas.filter(area => area.id === id);

    try {
      if (aux[0].nombre) {
        return aux;
      }
    } catch (error) {
      return [{
        id: 0, fecha: new Date(), nombre: ''
      }];
    }
  }

  public getNombreRama(id: number): Rama[] {
    let aux =  this.jsonRamas.filter(rama => rama.id === id);

    try {
      if (aux[0].nombre) {
        return aux;
      }
    } catch (error) {
      return [{
        id: 0, fecha: new Date(), fkarea: 0, nombre: ''
      }];
    }
  }

  public getNombreEspecialidad(id: number): Especialidad[] {
    let aux = this.jsonEspecialidads.filter(especialidad => especialidad.id === id);

    try {
      if (aux[0].nombre) {
        return aux;
      }
    } catch (error) {
      return [{
        id: 0, fecha: new Date(), fkarea: 0, fkrama: 0, nombre: ''
      }];
    }
  }

  public convertToJSON(array: string): string[] {
    // return array.split(',').map(String);
    return JSON.parse(array);
  }

}
