<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <div class="container div-titulo">
        <h2 class="titulo">{{_pregunta.toUpperCase()}}</h2>
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isBuscando === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_hilo}}, intenta
                                nuevamente.</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                    </div>
                    <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1">
                        <div class="card-body">
                            <div
                                *ngFor="let item of jsonPreguntaHilo$ | async | sortBy: 'asc':'idHilo'; let index = index">

                                <div class="form-group row" *ngIf="index === 0">
                                    <label class="col-md-3 col-form-label">FECHA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" [value]="item.fechaHilo" disabled>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="index === 0">
                                    <label class="col-md-3 col-form-label">TÍTULO</label>
                                    <div class="col-md-9">
                                        <div class="p-3" [innerHtml]="'<b>' + item.titulo + '</b>'"
                                            style="border: 1px solid #ced4da; border-radius: .25rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <div class="card" style="background-color: #ffffff60;">
                                            <div class="card-header" *ngIf="index === 0">
                                                <b>{{_pregunta}}</b>
                                            </div>
                                            <div class="card-header" *ngIf="index > 0"><b>{{_hilo}}</b>
                                            </div>
                                            <div class="card-body">
                                                <div [innerHtml]="item.contenido" style="text-align: justify;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3" *ngIf="item.respuesta">
                                    <div class="offset-md-3 col-md-9">
                                        <div class="card" style="background-color: #ffffff60;">
                                            <div class="card-header" style="background-color: #b44ad2!important;">
                                                <b>[{{_experto}}] {{item.nickname}}</b> dice :
                                            </div>
                                            <div class="card-body">
                                                <div [innerHtml]="item.respuesta" style="text-align: justify;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" style="background-color: #ffffff60;"
                        *ngIf="isBuscando === 1 && (jsonPreguntaHilo$ | async)?.length > 0">
                        <div class="card-body">
                            <div class="text-center" *ngFor="let item of jsonEstados$ | async; let index = index">
                                <!-- ({{total}}) <b>{{item.tipo}}</b> - {{item.estado1}} -
                                <b>{{item.estado2}}</b> -
                                {{item.estado3}} - {{item.estado4}} - {{item.estado5}} -
                                <b>{{item.estado6}}</b> -
                                Experto {{item.fkexperto}} - {{item.horas}} horas -
                                {{item.horasRespuesta}} horas *Respuesta<br>
                                Recibo {{item.estadoRecibo}} - Pregunta {{item.fkpregunta}} - Hilo
                                {{item.id}}<br>{{parseInt(item.estrella)}}
                                estrellas<br> -->
                                <span class="badge bg-secondary m-1" *ngIf="parseInt(total) > 2"
                                    style="color: #fff!important;">Recuerda que solo tenemos hasta 5 hilos de conversación.</span><br>
                                <a [routerLink]="['nueva-pregunta', fk, item.fkexperto, item.fkarea, item.fkrama, item.fkespecialidad]"
                                    class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'RESUELTA' &&
                                item.estado4 === 'RESUELTA' &&
                                item.estado5 === 'ACEPTADA' &&
                                parseInt(item.fkexperto) > 0 &&
                                parseInt(total) < 4 &&
                                parseInt(item.estrella) > 0">
                                    <i class="fas fa-angle-double-right"></i> Nuevo {{_hilo}}
                                </a>
                                <span *ngIf="item.estado1 === 'RESUELTA' &&
                                item.estado4 === 'RESUELTA' &&
                                item.estado5 === 'ACEPTADA' &&
                                parseInt(item.fkexperto) > 0 &&
                                parseInt(item.estrella) === 0" class="badge bg-secondary m-1"
                                    style="color: #fff!important;">Valora la respuesta del {{_experto}} 
                                    y si deseas puede continuar con este {{_hilo}} 
                                    dando click en <b>Nuevo {{_hilo}}</b></span><br>
                                <a [routerLink]="['/valoracion-respuesta', fk, item.id, item.fkexperto]"
                                    class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'RESUELTA' &&
                                item.estado4 === 'RESUELTA' &&
                                item.estado5 === 'ACEPTADA' &&
                                parseInt(item.fkexperto) > 0 &&
                                parseInt(item.estrella) === 0 && 
                                parseInt(total) === 1">
                                    <i class="fas fa-angle-double-right"></i> Valora tu {{_pregunta}}
                                </a>
                                <a [routerLink]="['/valoracion-respuesta', fk, item.id, item.fkexperto]"
                                    class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'RESUELTA' &&
                                item.estado4 === 'RESUELTA' &&
                                item.estado5 === 'ACEPTADA' &&
                                parseInt(item.fkexperto) > 0 &&
                                parseInt(item.estrella) === 0 && 
                                parseInt(total) > 1">
                                    <i class="fas fa-angle-double-right"></i> Valora tu {{_hilo}}
                                </a>
                                <a class="btn btn-secondary rounded-pill m-1" *ngIf="item.estado1 === 'RESUELTA' &&
                                item.estado4 === 'RESUELTA' &&
                                item.estado5 === 'ACEPTADA' &&
                                parseInt(item.fkexperto) > 0 &&
                                parseInt(item.estrella) === 0">
                                    <i class="fas fa-angle-double-right"></i> Nuevo {{_hilo}}
                                </a>
                                <a [routerLink]="['/pregunta-cambio-experto', fk, item.id, item.fkexperto, item.fkespecialidad]"
                                    class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'RECHAZADA' &&
                                item.estado4 === 'RECHAZADA' &&
                                item.estado5 === 'RECHAZADA' && parseInt(item.fkexperto) > 0">
                                    <i class="fas fa-angle-double-right"></i> Buscar otro {{_experto}}
                                </a>
                                <a (click)="update(fk, item.id)" class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'RECHAZADA' &&
                                item.estado4 === 'RECHAZADA' &&
                                item.estado5 === 'RECHAZADA' && 
                                parseInt(item.fkexperto) > 0 && 
                                parseInt(total) === 1">
                                    <i class="fas fa-angle-double-right"></i> Finalizar servicio {{_pregunta}}
                                </a>
                                <a (click)="update(fk, item.id)" class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'RECHAZADA' &&
                                item.estado4 === 'RECHAZADA' &&
                                item.estado5 === 'RECHAZADA' && 
                                parseInt(item.fkexperto) > 0 && 
                                parseInt(total) > 1">
                                    <i class="fas fa-angle-double-right"></i> Finalizar servicio {{_hilo}}
                                </a>
                                <a [routerLink]="['/pregunta-cambio-experto', fk, item.id, item.fkexperto, item.fkespecialidad]"
                                    class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'PENDIENTE' &&
                                item.estado4 === 'PENDIENTE' &&
                                item.estado5 === 'PENDIENTE' && parseInt(item.fkexperto) > 0 &&
                                parseInt(item.horas) >= 24">
                                    <i class="fas fa-angle-double-right"></i> Buscar otro {{_experto}}
                                </a>
                                <a (click)="update(fk, item.id)" class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'PENDIENTE' &&
                                item.estado4 === 'PENDIENTE' &&
                                item.estado5 === 'PENDIENTE' && 
                                parseInt(total) === 1">
                                    <i class="fas fa-angle-double-right"></i> Finalizar servicio {{_pregunta}}
                                </a>
                                <a (click)="update(fk, item.id)" class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'PENDIENTE' &&
                                item.estado4 === 'PENDIENTE' &&
                                item.estado5 === 'PENDIENTE' && 
                                parseInt(total) > 1">
                                    <i class="fas fa-angle-double-right"></i> Finalizar servicio {{_hilo}}
                                </a>
                                <span *ngIf="item.estado1 === 'NO_PAGO' &&
                                item.estado4 === 'NO_PAGO' &&
                                item.estado5 === 'ACEPTADA' &&
                                (item.estadoRecibo === 'inicia' && parseInt(item.minutosestadoRecibo) < 10) &&
                                parseInt(item.fkexperto) > 0" 
                                class="badge bg-secondary m-1 gris"
                                style="color: #fff!important;">PROCESANDO EL PAGO</span>
                                <br *ngIf="item.estado1 === 'NO_PAGO' &&
                                item.estado4 === 'NO_PAGO' &&
                                item.estado5 === 'ACEPTADA' &&
                                (item.estadoRecibo === 'inicia' && parseInt(item.minutosestadoRecibo) < 10) &&
                                parseInt(item.fkexperto) > 0">
                                <a *ngIf="item.estado1 === 'NO_PAGO' &&
                                item.estado4 === 'NO_PAGO' &&
                                item.estado5 === 'ACEPTADA' &&
                                (item.estadoRecibo === 'inicia' && parseInt(item.minutosestadoRecibo) < 10) &&
                                parseInt(item.fkexperto) > 0" 
                                class="btn btn-outline-primary rounded-pill m-1" 
                                href="javascript:location.reload()"><i class="fas fa-angle-double-right"></i> Actualizar página</a>
                                <hr *ngIf="item.estado1 === 'NO_PAGO' &&
                                item.estado4 === 'NO_PAGO' &&
                                item.estado5 === 'ACEPTADA' &&
                                (item.estadoRecibo !== 'inicia' || parseInt(item.minutosestadoRecibo) >= 10) &&
                                parseInt(item.fkexperto) > 0">
                                <form [formGroup]="form" enctype="multipart/form-data" *ngIf="item.estado1 === 'NO_PAGO' &&
                                item.estado4 === 'NO_PAGO' &&
                                item.estado5 === 'ACEPTADA' &&
                                (item.estadoRecibo !== 'inicia' || parseInt(item.minutosestadoRecibo) >= 10) &&
                                parseInt(item.fkexperto) > 0">
                                    <div class="col-md-3 mx-auto col-12 mb-1">
                                        <input class="form-control text-center m-1" placeholder="Bono descuento"
                                            type="text" formControlName="bono">
                                    </div>
                                </form>
                                <a (click)="go(item.id)" class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'NO_PAGO' &&
                                item.estado4 === 'NO_PAGO' &&
                                item.estado5 === 'ACEPTADA' &&
                                (item.estadoRecibo !== 'inicia' || 
                                parseInt(item.minutosestadoRecibo) >= 10) &&
                                parseInt(item.fkexperto) > 0 && 
                                parseInt(total) === 1">
                                    <i class="fas fa-angle-double-right"></i> Pagar {{_pregunta}}
                                </a>
                                <a (click)="go(item.id)" class="btn btn-outline-primary rounded-pill m-1" *ngIf="item.estado1 === 'NO_PAGO' &&
                                item.estado4 === 'NO_PAGO' &&
                                item.estado5 === 'ACEPTADA' &&
                                (item.estadoRecibo !== 'inicia' || 
                                parseInt(item.minutosestadoRecibo) >= 10) &&
                                parseInt(item.fkexperto) > 0 && 
                                parseInt(total) > 1">
                                    <i class="fas fa-angle-double-right"></i> Pagar {{_hilo}}
                                </a>
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>