import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ExportService } from './../shared/service/-export.service';

@Component({
  selector: 'app-informe-encuesta-xls',
  templateUrl: './informe-encuesta-xls.component.html',
  styleUrls: ['./informe-encuesta-xls.component.css']
})
export class InformeEncuestaXlsComponent implements OnInit {

  public fk: number;
  public id: number;

  public jsonEncuesta$: Observable<any[]>;
  public jsonEncuesta: any[];
  public jsonCSV: any[];
  public jsonEstados$: Observable<any>;
  public total = 0;
  public isBuscando = -1;
  public isCSV = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public isAccion = -1;

  // options
  view: any[] = [1100, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = "Respuesta";
  showYAxisLabel = true;
  yAxisLabel = "Resultados";

  colorScheme = {
    domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"]
  };

  constructor(
    /** OBLIGATORIO */
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private exportService: ExportService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      // this.fk = parseInt(params.fk);
      this.fk = parseInt(this.auth.obtener('fk'));
      this.id = parseInt(params.id);//Encuesta.id
      this.getContenido();
    });
  }

  public getContenido(): void {
    this.queryService.query_62(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonEncuesta = response;

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
      }
    );
    
    this.queryService.query_63(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonEncuesta$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });

        this.jsonEncuesta$.subscribe(response => {
          this.total = response.length;
        });

        this.getEstados();
      }, error => {
      }
    );

    
    
    this.queryService.query_64(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonCSV = response;
      }, error => {
      }
    );
  }

  public getEstados(): void {
    this.queryService.query_58(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });
      }, error => {
      }
    );
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  public _filter(value: number): any[] {
    try {
      const filterValue = value;
      return this.jsonEncuesta.filter(option => option.fkpregunta === value);
    } catch (error) { }
  }

  exportToCsv(titulo: string): void {
    this.isCSV = true;
    this.exportService.exportToCsv(this.jsonCSV, titulo, ['experto', 'pregunta', 'respuesta']);
  }

}
