<section class="banner-all">
    <div class="container div-titulo">
        <h2 class="titulo" *ngIf="tipo === 'empresa'">Crea tu perfil de {{_usuario}} Empresa</h2>
        <h2 class="titulo" *ngIf="tipo === 'usuario'">Crea tu perfil de {{_usuario}}</h2>
        <p class="mt-3 text-justify">Regístrate y resuelve tus inquietudes con nuestros Expertos. Para más información,
            clic aquí
            <a href="https://file.expertips.co/modeloservicios.pdf" target="_blank">
                <span style="color: #390796!important;">
                    Modelo de Servicios Expertips <i class="fas fa-arrow-down"></i>
                </span>
            </a>, y clic aquí para el 
            <a href="https://www.youtube.com/watch?v=sdc1IJC8ryo" target="_blank">
                <span style="color: #390796!important;">
                    Modelo de Negocio Expertips <i class="fas fa-arrow-down"></i>
                </span>
            </a>.
        </p>
    </div>
    <!-- <p>myForm value: {{ form.value | json }}</p>
    <p>myForm status: {{ form.status | json }}</p>
    <p>myForm ¿valid?: {{ form.valid }}</p> -->
    <div class="container">
        <div class="animated fadeIn">
            <div class="row">
                <div class="col-md-12">
                    <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal">
                        <p>* Campos obligatorios</p>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body" *ngIf="isParte1">
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">FECHA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="fecha" disabled>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label">Por favor no colocar tildes, números, signos
                                        de puntuación, espacios, símbolos u otras restricciones</label>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'empresa'">
                                    <label class="col-md-3 col-form-label">RAZON SOCIAL<span
                                            *ngIf="validator('nombre1')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nombre1">
                                        <div *ngIf="form.get('nombre1').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('pattern')">
                                                + Ingresa un nombre valido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('nombre1').errors && form.get('nombre1').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'empresa'">
                                    <label class="col-md-3 col-form-label">NICKNAME</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nickname" disabled>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'empresa'">
                                    <label class="col-md-3 col-form-label">TIPO IDENTIFICACIÓN<span
                                            *ngIf="validator('tipoidentificacion')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="tipoidentificacion"
                                            disabled>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'usuario'">
                                    <label class="col-md-3 col-form-label">PRIMER NOMBRE<span
                                            *ngIf="validator('nombre1')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nombre1">
                                        <div *ngIf="form.get('nombre1').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('pattern')">
                                                + Ingresa un nombre valido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('nombre1').errors && form.get('nombre1').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'usuario'">
                                    <label class="col-md-3 col-form-label">SEGUNDO NOMBRE</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nombre2">
                                        <div *ngIf="form.get('nombre2').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre2'].hasError('pattern')">
                                                + Ingresa un nombre valido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('nombre2').errors && form.get('nombre2').dirty)">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre2'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre2'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'usuario'">
                                    <label class="col-md-3 col-form-label">PRIMER APELLIDO<span
                                            *ngIf="validator('apellido1')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="apellido1">
                                        <div *ngIf="form.get('apellido1').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido1'].hasError('pattern')">
                                                + Ingresa un apellido valido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('apellido1').errors && form.get('apellido1').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido1'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido1'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'usuario'">
                                    <label class="col-md-3 col-form-label">SEGUNDO APELLIDO</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="apellido2">
                                        <div *ngIf="form.get('apellido2').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido2'].hasError('pattern')">
                                                + Ingresa un apellido valido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('apellido2').errors && form.get('apellido2').dirty)">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido2'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido2'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'usuario'">
                                    <label class="col-md-3 col-form-label">NICKNAME</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nickname" disabled
                                            [value]="getNombre1 + getApellido1 + getApellido2">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'usuario'">
                                    <label class="col-md-3 col-form-label">TIPO IDENTIFICACIÓN<span
                                            *ngIf="validator('tipoidentificacion')">*</span></label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="tipoidentificacion">
                                            <option value="null" disabled>... Seleccione</option>
                                            <option value="CC">CÉDULA DE CIUDADANÍA</option>
                                            <option value="CE">CÉDULA DE EXTRANJERÍA</option>
                                            <option value="PPN">PASAPORTE</option>
                                            <option value="SSN">NÚMERO DE SEGURIDAD SOCIAL</option>
                                            <option value="LIC">LICENCIA DE CONDUCCIÓN</option>
                                            <!-- <option value="NIT">NÚMERO DE INDENTIFICACIÓN TRIBUTARIA</option> -->
                                            <option value="TI">TARJETA DE IDENTIDAD</option>
                                            <option value="DNI">DOCUMENTO NACIONAL DE IDENTIFICACIÓN</option>
                                        </select>
                                        <div
                                            *ngIf="(form.get('tipoidentificacion').errors && form.get('tipoidentificacion').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['tipoidentificacion'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label mb-1">IDENTIFICACIÓN<span
                                            *ngIf="validator('identificacion')">*</span></label>
                                    <div class="col-md-9 mb-1">
                                        <input type="text" class="form-control" formControlName="identificacion">
                                        <div *ngIf="form.get('identificacion').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['identificacion'].hasError('identificacion')">
                                                + Ingresa una identificación valido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="(form.get('identificacion').errors && form.get('identificacion').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['identificacion'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['identificacion'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['identificacion'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3"></div>
                                    <div class="col-md-9">
                                        <div class="alert alert-primary text-center" role="alert" *ngIf="tipo === 'empresa'">
                                            Ingresar código de verificación, Ej. 890123456-1</div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row" *ngIf="tipo === 'empresa'"> -->
                                    <!-- <label class="col-md-3 col-form-label">GÉNERO<span
                                            *ngIf="validator('genero')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="genero" disabled>
                                    </div> -->
                                <!-- </div> -->
                                <div class="form-group row" *ngIf="tipo === 'usuario'">
                                    <label class="col-md-3 col-form-label">GÉNERO<span
                                            *ngIf="validator('genero')">*</span></label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="genero">
                                            <option value="null" disabled>... Seleccione</option>
                                            <option value="FEMENINO">FEMENINO</option>
                                            <option value="MASCULINO">MASCULINO</option>
                                            <option value="OTRO">OTRO</option>
                                        </select>
                                        <div *ngIf="(form.get('genero').errors && form.get('genero').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['genero'].hasError('required')">
                                                + Campo requerido
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="tipo === 'usuario'">
                                    <label class="col-md-3 col-form-label mb-1">FECHA DE NACIMIENTO<span
                                            *ngIf="validator('anoFechanacimiento')">*</span></label>
                                    <div class="col-md-2 col-6 mb-1">
                                        <input type="number" class="form-control" formControlName="anoFechanacimiento"
                                            placeholder="AAAA" min="1900" [max]="ano">
                                        <div
                                            *ngIf="(form.get('anoFechanacimiento').errors && form.get('anoFechanacimiento').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['anoFechanacimiento'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['anoFechanacimiento'].hasError('min') || form.controls['anoFechanacimiento'].hasError('max')">
                                                + Error
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-3 mb-1">
                                        <input type="number" class="form-control" formControlName="mesFechanacimiento"
                                            placeholder="MM" min="1" max="12">
                                        <div
                                            *ngIf="(form.get('mesFechanacimiento').errors && form.get('mesFechanacimiento').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['mesFechanacimiento'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['mesFechanacimiento'].hasError('min') || form.controls['mesFechanacimiento'].hasError('max')">
                                                + Error
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-3 mb-1">
                                        <input type="number" class="form-control" formControlName="diaFechanacimiento"
                                            placeholder="DD" min="1" max="31">
                                        <div
                                            *ngIf="(form.get('diaFechanacimiento').errors && form.get('diaFechanacimiento').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['diaFechanacimiento'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['diaFechanacimiento'].hasError('min') || form.controls['diaFechanacimiento'].hasError('max')">
                                                + Error
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-control alert alert-primary text-center" role="alert">
                                            AAAA-MM-DD</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label mb-1" *ngIf="tipo === 'usuario'">FOTOGRAFÍA</label>
                                    <label class="col-md-3 col-form-label mb-1" *ngIf="tipo === 'empresa'">LOGO</label>
                                    <div class="col-md-9 text-center" *ngIf="loadingFileurlfotografia">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-7 mb-1" *ngIf="!loadingFileurlfotografia">
                                        <input type="text" class="form-control" formControlName="urlfotografia"
                                            *ngIf="isFileurlfotografia" disabled>
                                        <input style="display: none" type="file" accept="image/png, image/jpeg"
                                            (change)="onFileChanged('urlfotografia', $event)" #fileInputurlfotografia>
                                        <div class="row" *ngIf="!isFileurlfotografia">
                                            <!-- <div class="col-md-6"> -->
                                            <div class="col-md-12">
                                                <button type="button"
                                                    [attr.class]="this.fileurlfotografia ? 'btn btn-block btn-secondary mb-2' : 'btn btn-block btn-light mb-2'"
                                                    (click)="fileInputurlfotografia.click()"
                                                    [disabled]="this.fileurlfotografia"><i
                                                        class="far fa-folder-open"></i> Seleccione un
                                                    archivo</button>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <button type="button"
                                                    [attr.class]="!this.fileurlfotografia ? 'btn btn-block btn-secondary mb-2' : 'btn btn-block btn-light mb-2'"
                                                    (click)="onUpload('urlfotografia', 'usuario_fotografia')"
                                                    [disabled]="!this.fileurlfotografia"><i
                                                        class="fas fa-angle-double-up"></i>
                                                    Cargar al servidor</button>
                                            </div> -->
                                        </div>                                        
                                        
                                        <small *ngIf="tipo === 'usuario'"><b>Usa una foto donde se pueda apreciar tu rostro, este debe estar centrado.</b><br></small>
                                        <small *ngIf="tipo === 'usuario'" style="color: #390796!important;">Si deseas cambiar la fotografía, podrás realizarlo desde tu perfil una vez creada la cuenta.</small>
                                        <small *ngIf="tipo === 'empresa'" style="color: #390796!important;">Si deseas cambiar el logo, podrás realizarlo desde tu perfil una vez creada la cuenta.</small>
                                    
                                    </div>
                                    <div class="col-md-2" *ngIf="!loadingFileurlfotografia">
                                        <div class="form-control alert alert-primary text-center" role="alert">Máx.
                                            5MB</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" *ngIf="isParte2">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">PAÍS<span
                                            *ngIf="validator('pais')">*</span></label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonPaises$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonPaises$ | async)?.length > 0">
                                        <select class="form-control" formControlName="pais"
                                            (change)="getEstados($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let pais of jsonPaises$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="pais.name + ':' + pais.id">
                                                {{ pais.name }}</option>
                                        </select>
                                        <div *ngIf="(form.get('pais').errors && form.get('pais').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['pais'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">DEPARTAMENTO<span
                                            *ngIf="validator('departamento')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isEstado">
                                        <input type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="isEstado && (jsonEstados$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="isEstado && (jsonEstados$ | async)?.length > 0">
                                        <select class="form-control" formControlName="departamento"
                                            (change)="getCiudades($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let estado of jsonEstados$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="estado.name + ':' +estado.id">
                                                {{ estado.name }}</option>
                                        </select>
                                        <div *ngIf="(form.get('departamento').errors && form.get('departamento').dirty) || isVerificar">
                                            <span class="help-block text-danger" +
                                                *ngIf="form.controls['departamento'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CIUDAD / BARRIO (CAPITAL)<span
                                            *ngIf="validator('ciudad')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isCiudad">
                                        <input type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="isCiudad && (jsonCiudades$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="isCiudad && (jsonCiudades$ | async)?.length > 0">
                                        <select class="form-control" formControlName="ciudad">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let ciudad of jsonCiudades$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="ciudad.name">
                                                {{ ciudad.name }}</option>
                                        </select>
                                        <div *ngIf="(form.get('ciudad').errors && form.get('ciudad').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['ciudad'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">DIRECCIÓN<span
                                            *ngIf="validator('direccion')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="direccion">
                                        <div *ngIf="(form.get('direccion').errors && form.get('direccion').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CÓDIGO PAÍS + CELULAR<span
                                            *ngIf="validator('celular')">*</span></label>
                                    <div class="col-md-2 col-4">
                                        <input type="text" class="form-control" formControlName="codigopais">
                                        <div *ngIf="form.get('codigopais').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('pattern')">
                                                + Ingresa un número valido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('codigopais').errors && form.get('codigopais').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-8">
                                        <input type="text" class="form-control" formControlName="celular">
                                        <div *ngIf="form.get('celular').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('pattern')">
                                                + Ingresa un número valido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('celular').errors && form.get('celular').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CORREO ELECTRÓNICO<span
                                            *ngIf="validator('correoelectronico')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoelectronico"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form.get('correoelectronico').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('pattern')">
                                                + Ingresa un correo valido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="(form.get('correoelectronico').errors && form.get('correoelectronico').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CONTRASEÑA<span
                                            *ngIf="validator('Usuario_contrasena')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="password" class="form-control" formControlName="Usuario_contrasena"
                                            autocomplete="nope">
                                        <div *ngIf="form.get('Usuario_contrasena').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Usuario_contrasena'].hasError('pattern')">
                                                + Ingresa una contraseña con el Siguiente formato
                                                <ul>
                                                    <li>Mínimo 6 caracteres</li>
                                                    <li>Máximo 10</li>
                                                    <li>No espacios en blanco</li>
                                                    <li>Al menos una letra mayúscula</li>
                                                    <li>Al menos una letra minúscula</li>
                                                    <li>Al menos un dígito</li>
                                                    <li>Al menos 1 caracter especial</li>
                                                </ul>
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="(form.get('Usuario_contrasena').errors && form.get('Usuario_contrasena').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Usuario_contrasena'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Usuario_contrasena'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Usuario_contrasena'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CONFIRMA CONTRASEÑA<span
                                            *ngIf="validator('Usuario_confirmacontrasena')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="password" class="form-control"
                                            formControlName="Usuario_confirmacontrasena" autocomplete="nope">
                                        <div
                                            *ngIf="(form.get('Usuario_confirmacontrasena').errors && form.get('Usuario_confirmacontrasena').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Usuario_confirmacontrasena'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Usuario_confirmacontrasena'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Usuario_confirmacontrasena'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                        <div *ngIf="form.get('Usuario_confirmacontrasena').value">
                                            <div
                                                *ngIf="(!form.controls['Usuario_confirmacontrasena'].hasError('minlength') 
                                                && !form.controls['Usuario_confirmacontrasena'].hasError('maxlength')) 
                                                && (form.get('Usuario_contrasena').value !== form.get('Usuario_confirmacontrasena').value)">
                                                <span class="help-block text-danger">
                                                    + Las contraseñas no coinciden
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">ÁREA DE INTERÉS (1)<span
                                            *ngIf="validator('fkespecialidad1')">*</span></label>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonEspecialidads1$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonEspecialidads1$ | async)?.length > 0">
                                        <div class="input-group">
                                            <select class="form-control" formControlName="fkespecialidad1"
                                                (change)="setEspecialidad1($event)">
                                                <option [ngValue]="null" disabled>... Seleccione</option>
                                                <option
                                                    *ngFor="let especialidad of jsonEspecialidads1$ | async | sortBy: 'asc':'nombre'"
                                                    [ngValue]="especialidad.id">
                                                    <!-- [ngValue]="especialidad.id" [disabled]="isEspecialidad1"> -->
                                                    <!-- {{ especialidad.nombre }} ({{ especialidad.nombrearea }}) -->
                                                    {{ especialidad.nombre }}
                                                </option>
                                            </select>
                                            
                                        </div>
                                        <div
                                            *ngIf="(form.get('fkespecialidad1').errors && form.get('fkespecialidad1').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['fkespecialidad1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                    <small>Por favor, no escoja la misma especialidad dos veces</small>
                                </div>
                                <div class="form-group row" *ngIf="isEspecialidad1">
                                    <label class="col-md-3 col-form-label">ÁREA DE INTERÉS (2)</label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonEspecialidads2$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9 d-flex align-items-center" *ngIf="(jsonEspecialidads2$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fkespecialidad2" (change)="setEspecialidad2($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let especialidad of jsonEspecialidads2$ | async | sortBy: 'asc':'nombre'" [ngValue]="especialidad.id">
                                                {{ especialidad.nombre }}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-sm btn-primary" (click)="refreshEspecialidads1();">
                                                <span>Eliminar</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group row" *ngIf="isEspecialidad2">
                                    <label class="col-md-3 col-form-label">ÁREA DE INTERÉS (3)</label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonEspecialidads3$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                
                                    <div class="col-md-9 d-flex align-items-center" *ngIf="(jsonEspecialidads3$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fkespecialidad3" (change)="setEspecialidad3($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let especialidad of jsonEspecialidads3$ | async | sortBy: 'asc':'nombre'" [ngValue]="especialidad.id">
                                                {{ especialidad.nombre }}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-sm btn-primary" (click)="refreshEspecialidads2();">
                                                <span>Eliminar</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="form-group row" *ngIf="isEspecialidad3">
                                    <label class="col-md-3 col-form-label">ÁREA DE INTERÉS (4)</label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonEspecialidads4$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9 d-flex align-items-center" *ngIf="(jsonEspecialidads4$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fkespecialidad4" (change)="setEspecialidad4($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let especialidad of jsonEspecialidads4$ | async | sortBy: 'asc':'nombre'" [ngValue]="especialidad.id">
                                                {{ especialidad.nombre }}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-sm btn-primary" (click)="refreshEspecialidads3();">
                                                <span>Eliminar</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="form-group row" *ngIf="isEspecialidad4">
                                    <label class="col-md-3 col-form-label">ÁREA DE INTERÉS (5)</label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonEspecialidads5$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9 d-flex align-items-center" *ngIf="(jsonEspecialidads5$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fkespecialidad5" (change)="setEspecialidad5($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let especialidad of jsonEspecialidads5$ | async | sortBy: 'asc':'nombre'" [ngValue]="especialidad.id">
                                                {{ especialidad.nombre }}
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-sm btn-primary" (click)="refreshEspecialidads4();">
                                                <span>Eliminar</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="form-group row" *ngIf="isEspecialidad1">
                                    <label class="col-md-3 col-form-label"></label>
                                    <div class="col-md-9">
                                        <small>¿NO ENCUENTRAS TÚ ÁREA DE INTERÉS? ¡INCLÚYELAS!</small>
                                        <input type="text" class="form-control" formControlName="otraespecialidad"
                                            oninput="this.value = this.value.toUpperCase()">
                                        <small>Por favor, incluya el/los {{_especialidad}}(s) separadas por
                                            coma.</small>
                                    </div>
                                </div>

                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ISCONFIRMA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="isconfirma">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">TOKENCONFIRMA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="tokenconfirma">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ESTADOCONFIRMA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="estadoconfirma">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ISRECUPERA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="isrecupera">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">TOKENRECUPERA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="tokenrecupera">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ESTADORECUPERA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="estadorecupera">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ESTADO</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="estado">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <input type="checkbox" class="form-check-input" id="acepta"
                                            formControlName="acepta">
                                        <label class="form-check-label" for="acepta">Aceptas los
                                            <a href="https://file.expertips.co/terminosycondiciones.pdf"
                                                target="_blank">Términos & Condiciones</a>
                                            y
                                            <a href="https://file.expertips.co/contratomandato.pdf"
                                                target="_blank">Contrato de Mandato</a>
                                            <span *ngIf="validator('acepta')">*</span></label>
                                    </div>
                                    <div class="col-md-12"
                                    *ngIf="(form.get('acepta').errors && form.get('acepta').dirty) || isVerificar">
                                    <span class="help-block text-danger"
                                        *ngIf="form.controls['acepta'].hasError('required')">
                                        + Campo requerido
                                    </span>
                                </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="text-center d-flex justify-content-between">
                                    <!-- Botón Regresar -->
                                    <a (click)="regresar2()" *ngIf="isParte2" class="btn btn-outline-primary rounded-pill">
                                        <i class="fas fa-step-backward"></i>&nbsp;Regresar
                                    </a>
                        
                                    <!-- Contenedor para los botones Cancelar y Siguiente -->
                                    <div class="d-flex">
                                        <!-- Botón Cancelar -->
                                        <a [href]="_urlWeb" class="btn btn-secondary rounded-pill">Cancelar</a>
                                    </div>
                        
                                    <!-- Botones Siguiente y Guardar formulario -->
                                    <div class="d-flex ml-auto">
                                        <!-- Botón Siguiente con validación -->
                                        <button class="btn btn-secondary rounded-pill ml-2"
                                            (click)="isVerificar = true;"
                                            *ngIf="isParte1 && !validar1()">
                                            Siguiente&nbsp;<i class="fas fa-step-forward"></i>
                                        </button>
                        
                                        <!-- Botón Siguiente sin validación -->
                                        <button class="btn btn-outline-primary rounded-pill"
                                            (click)="isParte1 = false; isParte2 = true; isVerificar = false;"
                                            *ngIf="isParte1 && validar1()">
                                            Siguiente&nbsp;<i class="fas fa-step-forward"></i>
                                        </button>
                        
                                        <!-- Botón Guardar con validación -->
                                        <button type="button" class="btn btn-secondary rounded-pill ml-2"
                                            (click)="isVerificar = true;"
                                            *ngIf="form.invalid && isAccion === -1 && isParte2">
                                            <i class="fas fa-angle-double-right"></i> Guardar formulario
                                        </button>
                        
                                        <!-- Botón Guardar sin validación -->
                                        <button type="submit" class="btn btn-outline-primary rounded-pill"
                                            *ngIf="form.valid && isAccion === -1 && isParte2">
                                            <i class="fas fa-angle-double-right"></i> Guardar formulario
                                        </button>
                        
                                        <!-- Icono de carga -->
                                        <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                                                                                                                                            
                  </form>
                </div>
            </div>
        </div>
    </div>
</section>