import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Recibo } from '../shared/interface/recibo';
import { AuthService } from '../shared/service/-auth.service';
import { QueryService } from './../shared/service/-query.service';

@Component({
  selector: 'app-recibo',
  templateUrl: './recibo.component.html',
  styleUrls: ['./recibo.component.css']
})
export class ReciboComponent implements OnInit {

  public fk: number;
  public myControl0 = new FormControl();
  public myControl1 = new FormControl();
  public jsonRecibos$: Observable<Recibo[]>;
  public jsonRecibos: Recibo[] = [];
  public isBuscando = -1;
  public page = 1;
  public pageSize =10;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private queryService: QueryService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }
    });

    this.fk = parseInt(this.auth.obtener('fk'));

    this.queryService.query_43(this.fk).subscribe(
      (response: any[]) => {
        this.jsonRecibos = response;
        this.jsonRecibos$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
      }
    );

    this.myControl0.setValue('TITULO');
    this.jsonRecibos$ = this.myControl1.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): Recibo[] {
    const filterValue = value;
    if (this.myControl0.value === 'TITULO') {
      return this.jsonRecibos.filter(option => option.titulo.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      return this.jsonRecibos.filter(option => option.estado.toLowerCase().includes(filterValue.toLowerCase()));
    }
  }

}
