<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Política de datos</h2> -->
    </div>
    <div class="container">
        <div class="row">
            <!-- <img src="assets/template/images/1200x600.jpg" alt="" class="img-fluid"> -->
            <div class="col-md-12" style="font-size: 12pt; line-height: 110%; color: #333333;">
                <p class="MsoNormal" align="center" style="text-align: center; color: #390796 !important;"><b><u><span
                                >POLÍTICA
                                DE PRIVACIDAD Y
                                DE TRATAMIENTO DE DATOS PERSONALES <span></span></span></u></b></p>

                <p class="MsoNormal" align="center" style="text-align: center; color: #390796 !important;"><b><u><span
                                >DE <span zeum4c17="PR_1_0" data-ddnwab="PR_1_0"
                                    aria-invalid="spelling" class="LI ng">EXPERTIPS</span> COLOMBIA
                                S.A.S.</span></u></b><span ><span></span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            >EXPERTIPS
                            COLOMBIA S.A.S., </span></b><span >en adelante
                        “EXPERTIPS”, so</span><span >ciedad comercial
                        constituida el&nbsp;día&nbsp;24 del mes de Junio de 2020 mediante documento
                        privado contenido en el Acta 001, registrada en la Cámara de Comercio de
                        Santiago de Cali, con matrícula mercantil número&nbsp;1088590-16, registrada el
                        24 de Julio de 2020, titular del NIT&nbsp;901.397.896 - 5 y con domicilio en la
                        ciudad de Santiago de Cali (Colombia), </span><span >en cumplimiento
                        del artículo 15 de la Constitución
                        Política Colombiana, la ley Estatutaria 1581 de 2012, el Decreto 1377 de 2013,
                        el Decreto 886 de 2014 y la Circular No. 2 de la Superintendencia de Industria
                        y Comercio y demás normas que adicionen, complementen o modifiquen la materia
                        de protección de datos personales, en calidad de RESPONSABLE DEL TRATAMIENTO de
                        los datos personales que usted nos ha entregado a través de diferentes medios
                        como el diligenciamiento del formulario de contacto, aceptación de correos
                        electrónicos, ventanas de chat que se encuentren disponibles <a
                            href="http://www.expertips..co%20y/o%20la%20aplicaci%C3%B3n%20m%C3%B3vil,%20">www.expertips.co
                            y/o la aplicación móvil, </a>correos electrónicos, entrega de teléfonos de
                        contacto, mensajes de texto, llamadas telefónicas, videollamadas, contratos,
                        documentos escritos, publicación de información o fotografías en perfiles de
                        plataformas operadas o de propiedad de EXPERTIPS, entre otros y quien para los
                        efectos de la presente POLITICA DE PRIVACIDAD Y DE TRATAMIENTO DE DATOS PERSONALES
                        (en adelante la “POLÍTICA”) se denominará EXPERTIPS, informa a sus grupos de
                        interés a saber: accionistas, trabajadores, ex trabajadores, clientes de
                        nuestros servicios, prospectos de clientes, usuarios de plataformas digitales,
                        prospectos de usuarios de plataformas digitales,&nbsp; aliados estratégicos y/o comerciales,
                        proveedores de bienes o servicios nacionales e internacionales, contratistas,
                        candidatos a una vacante, aprendices, practicantes SENA y en general a todas
                        las personas naturales (en adelante el “TITULAR”), que suministren o hayan
                        suministrado sus datos personales; que el contenido de la presente POLÍTICA,
                        tiene por objeto comunicar a los titulares de datos personales qué tipo de
                        datos utilizaremos y las condiciones en que EXPERTIPS tratará todo dato
                        personal que se le proporcione de la forma previamente señalada.<span></span></span></p>

                <p class="MsoNormal"><span><span>&nbsp;</span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >1.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >DEFINICIONES<span></span></span></b>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 18pt; text-align: justify;">
                    <b><span ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >a)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Autorización</span></b><span
                        >: Consentimiento
                        previo, expreso e informado del TITULAR para llevar a
                        cabo el Tratamiento de Datos personales. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >b)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Aviso de
                            Privacidad</span></b><span >: Comunicación
                        verbal o escrita generada por el RESPONSABLE, dirigida al
                        TITULAR para el Tratamiento de sus datos personales, mediante la cual se le
                        informa acerca de la existencia de la Políticas de Tratamiento de datos
                        personales y de información que le serán aplicables, la forma de acceder a las
                        mismas y las finalidades del Tratamiento que se pretende dar a los datos
                        personales. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >c)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Base de
                            Datos</span></b><span >: Conjunto
                        organizado de datos personales que será objeto de
                        Tratamiento.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >d)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Consentimiento
                            del Titular</span></b><span >: Es una
                        manifestación de la voluntad la cual es
                        informada y se entenderá libre e inequívoca, a través de la cual el TITULAR
                        acepta que un tercero utilice su información. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >e)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Consultas</span></b><span
                        >: el TITULAR o sus
                        causahabientes podrán consultar la información
                        personal del TITULAR que repose en cualquier base de datos, sea esta del sector
                        público o privado. El responsable o encargado del Tratamiento deberán
                        suministrar a éstos toda la información contenida en el registro individual o
                        que esté vinculada con la identificación del TITULAR.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >f)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Dato
                            Personal</span></b><span >: Cualquier
                        información vinculada o que pueda asociarse a una o varias
                        personas naturales y/o jurídicas, determinadas o determinables, relativa a su
                        identidad, existencia y/u ocupación.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >g)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Dato Personal
                            Privado</span></b><span >: Es el dato
                        personal que por su naturaleza íntima o
                        reservada sólo es relevante para el TITULAR.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >h)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Dato Personal
                            Semiprivado</span></b><span >: Es el dato
                        personal que no tiene naturaleza íntima,
                        reservada, ni pública, y cuyo conocimiento o divulgación puede interesar no
                        sólo a su TITULAR, sino a cierto sector o grupo de personas o a la sociedad en
                        general, como la actividad comercial o dato crediticio entre otros. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >i)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Dato Personal
                            Sensible</span></b><span >: Se entiende por
                        dato sensible todo aquel que afecte
                        la intimidad del TITULAR o cuyo uso indebido puede generar su discriminación,
                        como aquellos que revelen la orientación política, el origen racial o étnico,
                        la pertenencia a sindicatos, las convicciones religiosas o filosóficas,
                        organizaciones sociales, de derechos humanos o que promueva intereses de
                        cualquier partido político o que garanticen los derechos y garantías de
                        partidos políticos de oposición, así como los datos relativos a la salud, vida
                        sexual y datos biométricos.&nbsp; <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >j)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Dato <span zeum4c17="PR_2_0"
                                data-ddnwab="PR_2_0" aria-invalid="grammar" class="Lm ng">Público</span></span></b><span
                        >: Es el dato que
                        no sea privado, <span zeum4c17="PR_3_0" data-ddnwab="PR_3_0" aria-invalid="spelling"
                            class="LI ng">semiprivado</span> o sensible. Son
                        considerados datos públicos, entre otros, los datos relativos al estado civil
                        de las personas, a su profesión u oficio y a su calidad de comerciante o de
                        servidor público. Por su naturaleza, pueden estar contenidos, entre otros, en
                        registros públicos, documentos públicos, gacetas y boletines oficiales y
                        sentencias judiciales debidamente ejecutoriadas, que no estén sometidas a reserva.
                        <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >k)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Encargado del
                            Tratamiento</span></b><span >: Persona Natural
                        o Jurídica, que por sí mismo o en
                        asocio con otros manipula los datos de carácter personal y realiza el
                        Tratamiento de Datos personales por cuenta del RESPONSABLE del Tratamiento. No
                        tiene poder de decisión sobre cómo se hace el Tratamiento ni con qué fin y su
                        trabajo es meramente operativo, al seguir los lineamientos de la POLÍTICA y las
                        instrucciones del RESPONSABLE.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >l)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span ><span zeum4c17="PR_4_0"
                                data-ddnwab="PR_4_0" aria-invalid="grammar" class="Lm ng">Habeas</span> Data:
                        </span></b><span >Es el derecho que
                        todo titular de información tiene de conocer,
                        actualizar, rectificar <span zeum4c17="PR_5_0" data-ddnwab="PR_5_0" aria-invalid="grammar"
                            class="Lm ng">u oponerse</span> a la información concerniente o al
                        Tratamiento de sus datos personales.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >m)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Protección de
                            Datos de Carácter Personal</span></b><span >: Es un derecho
                        fundamental que tienen todas las personas naturales. Busca la protección de su
                        intimidad y privacidad frente a una posible vulneración por el Tratamiento
                        indebido de datos personales capturados por un tercero.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >n)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span ><span zeum4c17="PR_6_0"
                                data-ddnwab="PR_6_0" aria-invalid="grammar" class="Lm ng">Reclamo</span></span></b><span
                        >: El Titular o sus
                        causahabientes que consideren que la información
                        contenida en una base de datos debe ser objeto de corrección, actualización o
                        supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los
                        deberes contenidos en esta ley, podrán presentar un reclamo ante el RESPONSABLE
                        del Tratamiento o el ENCARGADO del Tratamiento.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >o)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Responsable
                            del Tratamiento</span></b><span >: Persona natural
                        o jurídica, que por sí misma o en
                        asocio con otros <span zeum4c17="PR_7_0" data-ddnwab="PR_7_0" aria-invalid="grammar"
                            class="Lm ng">decide</span> sobre la base de datos y/o el Tratamiento de los datos.
                        <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >p)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Titular</span></b><span
                        >: Persona natural
                        cuyos datos personales sean objeto de Tratamiento. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >q)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Titular de los
                            Datos Personales</span></b><span >: Es la persona
                        natural cuyos datos personales son
                        objeto de Tratamiento por parte de un tercero.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >r)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Tratamiento</span></b><span
                        >: Cualquier
                        operación o conjunto de operaciones o procedimientos físicos
                        o automatizados que permita captar, registrar, reproducir, usar, conservar,
                        organizar, modificar y/o transmitir datos personales. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >s)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Transferencia</span></b><span
                        >: La transferencia
                        de datos tiene lugar cuando el RESPONSABLE y/o
                        ENCARGADO del Tratamiento de Datos Personales, envía la información o los datos
                        personales a un receptor, que a su vez es RESPONSABLE del Tratamiento y se encuentra
                        dentro o fuera del país, considerando la Autorización dada por el TITULAR.<span></span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify;"><b><span
                            >t)<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >Transmisión</span></b><span
                        >: Tratamiento de
                        Datos Personales que implica la comunicación de los
                        mismos dentro o fuera del territorio de la República de Colombia, cuando tenga
                        por objeto la realización de un Tratamiento por el ENCARGADO por cuenta del
                        RESPONSABLE. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >2.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span ><span zeum4c17="PR_8_0"
                                data-ddnwab="PR_8_0" aria-invalid="spelling" class="LI ng">AMBITO</span> DE
                            APLICACIÓN<span></span></span></b></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 18pt; text-align: justify;">
                    <b><span ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span >En
                        cumplimiento de la ley colombiana y los decretos aquí mencionados, <span zeum4c17="PR_9_0"
                            data-ddnwab="PR_9_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>,
                        asumirá plena responsabilidad sobre el Tratamiento de los datos a ella suministrados,
                        que no sean datos que por su generalidad llegaren a convertirse en anónimos al
                        no permitir identificar plenamente a un Titular específico o de dominio
                        público.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >&nbsp;<span></span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span >El
                        TITULAR autoriza expresamente a <span zeum4c17="PR_10_0" data-ddnwab="PR_10_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> para que los datos puedan ser
                        compilados, tratados, almacenados, consultados, administrados, compartidos,
                        divulgados y transmitidos, de conformidad con la presente POLÍTICA DE
                        PRIVACIDAD Y DE TRATAMIENTO DE DATOS PERSONALES. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_11_0" data-ddnwab="PR_11_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>,
                        se compromete a: <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Realizar el
                        ejercicio del derecho de <span zeum4c17="PR_12_0" data-ddnwab="PR_12_0" aria-invalid="grammar"
                            class="Lm ng">Habeas</span> Data del TITULAR que se
                        encuentre en sus bases de datos y archivos;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Cumplir la
                        normatividad vigente en materia de protección de datos
                        personales y aplicar la presente <span zeum4c17="PR_13_0" data-ddnwab="PR_13_0"
                            aria-invalid="spelling" class="LI ng">POLITICA</span> para el Tratamiento de los mismos.
                        <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Garantizar el
                        adecuado control de los datos personales otorgados por el
                        TITULAR, con el fin de evitar pérdida, manipulación por parte de terceros no
                        autorizados o uso fraudulento de los mismos. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >3.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >TRATAMIENTO DE
                            DATOS PERSONALES.<span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >Con
                        la autorización a la cual se hizo referencia en el <span zeum4c17="PR_14_0"
                            data-ddnwab="PR_14_0" aria-invalid="spelling" class="LI ng">AMBITO</span> DE APLICACIÓN, el
                        TITULAR persona natural siendo accionista, trabajador, ex trabajador, cliente,
                        prospecto de cliente, usuario, prospecto de usuario, aliado estratégico y/o
                        comerciales, proveedores de bienes y servicios nacionales e internacionales,
                        contratistas, candidatos a una vacante, aprendices,&nbsp; practicantes SENA y en general a todas
                        las
                        personas naturales, autorizan a <span zeum4c17="PR_15_0" data-ddnwab="PR_15_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> para:<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >&nbsp;<span></span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Suministrar la
                        información que reposa en sus bases de datos a las
                        siguientes personas: a los Titulares de los datos personales, a las personas
                        debidamente autorizadas por éstos; a los usuarios de la información dentro de
                        los parámetros de la ley, de los contratos o convenios suscritos por <span zeum4c17="PR_16_0"
                            data-ddnwab="PR_16_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>;
                        previa orden, a cualquier autoridad judicial o administrativa; a las entidades
                        públicas del poder ejecutivo, cuando el conocimiento de dicha información
                        corresponda directamente al cumplimiento de alguna de sus funciones; a los
                        órganos de control y demás dependencias de investigación disciplinaria, fiscal,
                        o administrativa, cuando la información sea necesaria para el desarrollo de una
                        investigación en curso y en general a las demás personas autorizadas por la
                        ley. <span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Recolectar,
                        obtener, compilar, modificar, emplear, almacenar, procesar
                        y, en general, administrar la información. Lo anterior, <span zeum4c17="PR_17_0"
                            data-ddnwab="PR_17_0" aria-invalid="grammar" class="Lm ng">sujeto las</span> obligaciones
                        de sigilo profesional previstas en las normas que lo regulen, modifiquen o
                        complementen. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_18_0" data-ddnwab="PR_18_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>,
                        informa que, mediante la autorización libre, previa, expresa e informada, el
                        TITULAR de datos personales, autoriza a que los mismos sean captados,
                        almacenados, transmitidos, usados, actualizados, circulados y en general
                        tratados conforme a las finalidades que se relacionan en la presente
                        POLÍTICA.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span >El
                        TITULAR podrá en cualquier momento revocar el consentimiento que haya otorgado
                        para el Tratamiento de sus datos personales mediante el envío de una
                        comunicación y/o solicitud escrita a través del correo <a></a><a>electrónico </a><a
                            href="mailto:servicioalcliente@expertips.co">servicioalcliente@expertips.co</a></span>
                            <span> aportando copia
                        de su documento de identificación.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_20_0" data-ddnwab="PR_20_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>
                        sólo podrá recolectar, almacenar, usar o circular los datos personales durante
                        el tiempo que sea razonable y necesario, de acuerdo con las finalidades que
                        justificaron el Tratamiento, atendiendo a las disposiciones aplicables de que
                        se trate la información. Una vez cumplida la finalidad del Tratamiento y sin
                        perjuicio de normas legales que dispongan lo contrario, procederá a la
                        supresión de los datos personales en su posesión. No obstante, lo anterior, los
                        datos personales deberán ser conservados cuando así se requiera para el
                        cumplimiento de una obligación legal o contractual.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><b><span
                            >4.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >FINALIDAD DEL
                            TRATAMIENTO DE DATOS</span></b><b><span ><span></span></span></b>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_21_0" data-ddnwab="PR_21_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, en su
                        condición de RESPONSABLE DEL TRATAMIENTO de sus datos personales, a través de
                        la presente POLÍTICA, le informa que sus datos personales serán tratados con
                        total confidencialidad y que la captación, uso, circulación, transmisión,
                        transferencia y, en general, cualquier forma de Tratamiento sobre los mismos,
                        se hará de acuerdo a las siguientes finalidades, que corresponden al desarrollo
                        de su objeto social y al giro ordinario de sus actividades.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal"
                    style="margin-bottom: 18pt; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >Las
                        siguientes finalidades se aplicarán
                        siempre que se haya otorgado autorización previa, expresa e informada del
                        TITULAR la cual se entiende dada a través del envío de la información
                        solicitada en el formulario de contacto, aceptación de recibo de correos
                        electrónicos, ventanas de chat que se encuentran disponibles en <a
                            href="http://www.expertips.co/">www.expertips.co</a>&nbsp; y/o la aplicación móvil,
                        teléfonos de
                        contacto y correos electrónicos ahí indicados, mensajes de texto, llamadas
                        telefónicas, videollamadas, contratos, documentos escritos, publicación de
                        fotografías o información con datos personales en perfiles o cuentas de
                        plataformas de propiedad o manejadas por <span zeum4c17="PR_22_0" data-ddnwab="PR_22_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, entre otros o cualquier
                        dato entregado o transferido a través de cualquier medio.</span><span
                        ><span></span></span>
                </p>

                <p class="MsoNormal"
                    style="margin: 0cm 0cm 18pt; text-align: justify; text-indent: 0cm; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <b><span >4.1.
                        </span></b><b><span >FINALIDADES
                            GENERALES DEL TRATAMIENTO APLICABLE A TODOS LOS TITULARES.</span></b><span
                        ><span></span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Establecer y
                        gestionar la relación <span zeum4c17="PR_23_0" data-ddnwab="PR_23_0" aria-invalid="grammar"
                            class="Lm ng">pre contractual</span>, contractual y post
                        contractual ya sea comercial, laboral, civil y cualquier otra que surja en
                        virtud del cumplimiento de una obligación legal o contractual a cargo de
                        <span zeum4c17="PR_24_0" data-ddnwab="PR_24_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Desarrollar una
                        correcta gestión de la relación contractual, civil,
                        laboral o comercial que lo vincula con <span zeum4c17="PR_25_0" data-ddnwab="PR_25_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> y dar cumplimiento a
                        disposiciones y trámites legales que tengan que ver con dicha relación. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Recolectar,
                        registrar y actualizar sus datos personales con la finalidad
                        de informar, comunicar, organizar, controlar, atender, acreditar las
                        actividades en relación a su condición de tercero relacionado con <span zeum4c17="PR_26_0"
                            data-ddnwab="PR_26_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Enviar
                        comunicaciones referentes a la relación comercial, civil, laboral
                        u otra existente entre <span zeum4c17="PR_27_0" data-ddnwab="PR_27_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> y el TITULAR.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Responder a las
                        peticiones, consultas, reclamos y/o quejas que realicen
                        los titulares de información de tipo personal a través de cualquiera de los
                        canales habilitados para tal efecto.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >f)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Gestionar los
                        datos personales para que <span zeum4c17="PR_28_0" data-ddnwab="PR_28_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>, en condición de
                        RESPONSABLE DEL TRATAMIENTO, cumpla correctamente con sus obligaciones legales
                        respecto de la relación comercial, laboral o civil que une a <span zeum4c17="PR_29_0"
                            data-ddnwab="PR_29_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span> con el
                        TITULAR. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >g)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Enviar
                        comunicaciones sobre nuevos servicios o productos de <span zeum4c17="PR_30_0"
                            data-ddnwab="PR_30_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>,
                        contactarlo y/o comunicarse para temas relacionados con la gestión
                        encomendada.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >h)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Enviar noticias
                        que <span zeum4c17="PR_31_0" data-ddnwab="PR_31_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> considere, pueden ser de su interés.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >i)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Enviar ofertas de
                        servicios.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >j)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Transferir o
                        transmitir sus datos personales a entidades y/o autoridades
                        judiciales y/o administrativas, cuando estos sean requeridos en relación a su objeto
                        y necesarios para el cumplimiento de sus funciones.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >k)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Realizar las
                        gestiones necesarias para dar cumplimiento a las
                        obligaciones inherentes a los servicios y productos contratados con <span zeum4c17="PR_32_0"
                            data-ddnwab="PR_32_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>.
                        <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >l)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >El Tratamiento de
                        Datos personales podrá realizarse de forma directa por
                        el RESPONSABLE del Tratamiento de Datos y/o por quien esta determine, siempre y
                        cuando se efectúe para los fines aquí dispuestos, para lo cual no requerirá
                        autorización adicional del TITULAR, lo cual el TITULAR acepta expresamente al
                        suministrar sus datos personales. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >m)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Informar cambios
                        sustanciales en la Política de Privacidad y de
                        Tratamiento de Datos Personales de <span zeum4c17="PR_33_0" data-ddnwab="PR_33_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >n)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Efectuar los
                        diferentes procesos de pagos, facturas, cuentas de cobro,
                        comisiones, dividendos y/o salarios y gestión de cobranzas que se encuentren a
                        cargo de <span zeum4c17="PR_34_0" data-ddnwab="PR_34_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >o)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Permitir el
                        ejercicio de los derechos y deberes derivados de su
                        condición de tercero vinculado a <span zeum4c17="PR_35_0" data-ddnwab="PR_35_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >p)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Recolectar y
                        actualizar sus datos personales con el fin de acreditar
                        cualquier actividad que tenga relación con <span zeum4c17="PR_36_0" data-ddnwab="PR_36_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >q)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Ejecutar la
                        gestión comercial y todo lo referente al manejo, uso y
                        publicación de información de las plataformas digitales de propiedad o que sean
                        operadas por <span zeum4c17="PR_37_0" data-ddnwab="PR_37_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>, incluyendo para tal efecto finalidades comerciales y de
                        mercadeo ya sea dentro de dichas plataformas o fuera de ellas. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >r)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Además de la
                        recopilación, almacenamiento, copia, entrega,
                        actualización, ordenamiento, clasificación, transferencia, corrección, verificación,
                        uso para fines estadísticos y en general empleo y utilización de todos los
                        datos suministrados, con el propósito de gestionar correctamente la relación
                        contractual, comercial o civil que se genere con <span zeum4c17="PR_38_0" data-ddnwab="PR_38_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 0cm; text-align: justify; text-indent: 0cm;"><a
                        name="_Hlk45086890"><b><span>4.2.
                            </span></b><b><span>FINALIDADES
                                DEL TRATAMIENTO RESPECTO DE LOS
                                TRABAJADORES, EX TRABAJADORES Y ASPIRANTES A UNA VACANTE DE <span zeum4c17="PR_39_0"
                                    data-ddnwab="PR_39_0" aria-invalid="spelling"
                                    class="LI ng">EXPERTIPS</span>.<span></span></span></b></a></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Administrar y
                        operar, directamente o por conducto de terceros, los
                        procesos de selección y vinculación de trabajadores, incluyendo la evaluación y
                        calificación de los aspirantes o de los propios trabajadores, posibilitando la
                        verificación de referencias laborales y/o personales, y la realización de
                        estudios de seguridad. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Desarrollar
                        actividades propias de recursos y gestión humana, tales como
                        la nómina, afiliaciones a entidades del sistema general de seguridad social del
                        TITULAR y sus beneficiarios, actividades de bienestar y salud ocupacional,
                        ejercicio de la potestad <span zeum4c17="PR_40_0" data-ddnwab="PR_40_0" aria-invalid="grammar"
                            class="Lm ng">sancionatoria</span> del empleador, entre otras.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Reportar
                        accidentes de trabajo, prevención de riesgos laborales,
                        exámenes médicos y de seguridad.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Control de
                        asistencia y registro para el pago de la nómina. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Además de la
                        recopilación, almacenamiento, copia, entrega,
                        actualización, ordenamiento, clasificación, transferencia, corrección,
                        verificación, uso para fines estadísticos y en general empleo y utilización de
                        todos los datos suministrados, con el propósito de administrar correctamente la
                        relación laboral por parte de <span zeum4c17="PR_41_0" data-ddnwab="PR_41_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> con sus trabajadores.
                        <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >f)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span ><span zeum4c17="PR_42_0" data-ddnwab="PR_42_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> podrá compartir los datos de sus
                        trabajadores con sus
                        clientes
                        actuales o potenciales, en desarrollo de su relación comercial y con el fin de
                        cumplir con sus obligaciones comerciales y/o contractuales. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >g)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Incluir datos
                        personales en los diferentes contratos con <span zeum4c17="PR_43_0" data-ddnwab="PR_43_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>,
                        tales como contrato laboral, de aprendiz, de practicante, etc., así como en sus
                        modificaciones y demás documentos necesarios para gestionar la relación laboral
                        y obligaciones derivadas de la misma. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >h)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Ejecutar pruebas
                        psicotécnicas, visitas domiciliarias, pruebas de
                        desempeño y demás que se consideren convenientes para la relación laboral o
                        contratación pertinente con <span zeum4c17="PR_44_0" data-ddnwab="PR_44_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >i)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Desarrollar una
                        correcta gestión de la relación contractual que une al
                        Titular del dato personal con <span zeum4c17="PR_45_0" data-ddnwab="PR_45_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >j)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Los datos
                        personales de candidatos a una vacante serán tratados durante
                        su proceso de selección por parte de <span zeum4c17="PR_46_0" data-ddnwab="PR_46_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. Una vez terminado el proceso de
                        selección y si el candidato no fue escogido, sus datos serán eliminados de la
                        base de datos de <span zeum4c17="PR_47_0" data-ddnwab="PR_47_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >&nbsp;<span></span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm;"><b><span>4.3.
                        </span></b><b><span>FINALIDADES DEL TRATAMIENTO
                            RESPECTO DE
                            PRACTICANTES, PRACTICANTES DEL SENA Y PASANTES EN GENERAL DE <span zeum4c17="PR_48_0"
                                data-ddnwab="PR_48_0" aria-invalid="spelling"
                                class="LI ng">EXPERTIPS</span>.<span></span></span></b></p>

                <p class="MsoNormal"><b><span><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Administrar y
                        operar, directamente o por conducto de terceros, los
                        procesos de selección y vinculación de practicantes o pasantes, incluyendo la
                        evaluación y calificación de los aspirantes o de los practicantes o pasantes,
                        posibilitando la verificación de referencias académicas, laborales y/o
                        personales, y la realización de estudios de seguridad. <span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;"><span>b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span>Incorporar sus
                        datos
                        personales en el contrato de aprendizaje, convenio de pasante o de cualquier documento
                        que resulte necesario para gestionar la relación de aprendizaje y derechos y
                        obligaciones que resulten derivados de la misma a cargo de <span zeum4c17="PR_49_0"
                            data-ddnwab="PR_49_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span><span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Desarrollar
                        actividades propias de recursos y gestión humana, tales como
                        la nómina, afiliaciones a entidades del sistema general de seguridad social del
                        TITULAR y sus beneficiarios, actividades de bienestar y salud ocupacional,
                        ejercicio de la potestad sancionatoria de <span zeum4c17="PR_50_0" data-ddnwab="PR_50_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, entre otras.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Adelantar
                        evaluaciones pertinentes con el fin de identificar la
                        conveniencia de la contratación del practicante o pasante.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Gestionar los
                        datos personales para que el RESPONSABLE del Tratamiento
                        cumpla con todas sus obligaciones legales.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >k)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Ejecutar pruebas
                        psicotécnicas, visitas domiciliarias, pruebas de
                        desempeño y demás que se consideren convenientes para la relación laboral o
                        contratación pertinente con <span zeum4c17="PR_51_0" data-ddnwab="PR_51_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >l)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Desarrollar una
                        correcta gestión de la relación contractual que une al
                        Titular del dato personal con <span zeum4c17="PR_52_0" data-ddnwab="PR_52_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >m)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Además de la
                        recopilación, almacenamiento, copia, entrega,
                        actualización, ordenamiento, clasificación, transferencia, corrección,
                        verificación, uso para fines estadísticos y en general empleo y utilización de
                        todos los datos suministrados, con el propósito de gestionar correctamente el
                        convenio de pasantía o relación que se genere por parte de <span zeum4c17="PR_53_0"
                            data-ddnwab="PR_53_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span> con los
                        practicantes o pasantes.<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="text-align: justify;"><span><span>&nbsp;</span></span></p>

                <p class="MsoNormal" style="margin-left: 0cm; text-align: justify; text-indent: 0cm;"><b><span>4.4.
                        </span></b><b><span>FINALIDADES DEL TRATAMIENTO
                            RESPECTO DE
                            ALIADOS ESTRATÉGICOS Y/O COMERCIALES, PROVEEDORES DE BIENES Y SERVICIOS Y
                            CONTRATISTAS O CONTRATANTES DE <span zeum4c17="PR_54_0" data-ddnwab="PR_54_0"
                                aria-invalid="spelling"
                                class="LI ng">EXPERTIPS</span>.</span></b><span><span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span><span>&nbsp;</span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Desarrollar
                        procesos de gestión contractual de bienes y servicios. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Invitar a
                        participar en procesos de ofertas o licitaciones para ser
                        seleccionados. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Registro en las
                        bases de datos de <span zeum4c17="PR_55_0" data-ddnwab="PR_55_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>, de información del TITULAR
                        con fines comerciales, tributarios y fiscales. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Registro de pagos
                        y estados de cuenta. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Evaluación y
                        calificación de cumplimiento. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >f)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Además de la
                        recopilación, almacenamiento, copia, entrega,
                        actualización, ordenamiento, clasificación, transferencia, corrección,
                        verificación, uso para fines estadísticos y en general empleo y utilización de
                        todos los datos suministrados con el propósito de desarrollar el objeto social
                        de <span zeum4c17="PR_56_0" data-ddnwab="PR_56_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> y lo estipulado en los respectivos contratos y/o documentos
                        comerciales suscritos entre las partes, así como para administrar correctamente
                        la relación comercial por parte de <span zeum4c17="PR_57_0" data-ddnwab="PR_57_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> con sus proveedores y
                        contratistas.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >g)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Para ejecutar
                        todas las gestiones comerciales referentes a plataformas
                        digitales operadas y/o de propiedad de <span zeum4c17="PR_58_0" data-ddnwab="PR_58_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, incluyendo actividades de
                        mercadeo dentro y fuera de la Plataforma. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 0cm; text-align: justify; text-indent: 0cm;"><b><span>4.5.
                        </span></b><b><span>FINALIDADES DEL TRATAMIENTO
                            RESPECTO DE
                            CLIENTES Y PROSPECTOS DE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CLIENTES DE
                            <span zeum4c17="PR_59_0" data-ddnwab="PR_59_0" aria-invalid="spelling"
                                class="LI ng">EXPERTIPS</span>. </span></b><span><span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span><span>&nbsp;</span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Cumplir las
                        obligaciones contraídas al momento de la contratación de los
                        servicios de <span zeum4c17="PR_60_0" data-ddnwab="PR_60_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Enviar información
                        de ofrecimientos y cotizaciones, de servicios. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Fortalecer las
                        relaciones comerciales mediante el envío de información
                        relevante, la contratación de nuevos servicios, cotizaciones, facturación y
                        recaudo de cartera.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Enviar información
                        sobre cambios en condiciones generales o particulares
                        de los servicios ofrecidos por <span zeum4c17="PR_61_0" data-ddnwab="PR_61_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Además de la
                        recopilación, almacenamiento, copia, entrega,
                        actualización, utilización, ordenamiento, clasificación, transferencia,
                        corrección, verificación y uso para fines estadísticos de datos de propiedad de
                        clientes corporativos de <span zeum4c17="PR_62_0" data-ddnwab="PR_62_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>, los cuales estarán en todo momento sujetos
                        a las políticas e instrucciones que se acuerden entre las Partes. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span><span>&nbsp;</span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span >El
                        TITULAR tendrá
                        derecho, en forma gratuita, a conocer, actualizar, rectificar y/o solicitar la
                        supresión de sus datos personales, mediante comunicación que se recibirá a
                        través de los canales disponibles para tal fin: Correo electrónico: </span><a
                        href="mailto:servicioalcliente@expertips.co"><span
                            >servicioalcliente@expertips.co</span></a><span
                        > </span><span ><span></span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 0cm; text-align: justify; text-indent: 0cm;"><b><span>4.6.
                        </span></b><b><span>FINALIDADES DEL TRATAMIENTO
                            RESPECTO DE
                            USUARIOS DE PLATAFORMAS DIGITALES Y PROSPECTOS DE USUARIOS DE PLATAFORMAS
                            DIGITALES DE <span zeum4c17="PR_63_0" data-ddnwab="PR_63_0" aria-invalid="spelling"
                                class="LI ng">EXPERTIPS</span>. <span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span><span>&nbsp;</span></span></b>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;"><span>a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span>Acceso a la
                        información
                        necesaria de los Usuarios o prospectos de Usuarios de plataformas digitales.
                        <span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;"><span>b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span>Envío de
                        notificaciones e
                        información relacionada con sus consultas o encuestas vía SMS o correo
                        electrónico<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;"><span>c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span>Gestión del
                        sistema de
                        PQR y evaluación de Usuario. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Evaluación de la
                        idoneidad, verificación de la profesión,
                        calificaciones, experiencia y antecedentes. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Recaudo y pago de
                        los servicios ofrecidos por Usuarios en plataformas digitales
                        de <span zeum4c17="PR_64_0" data-ddnwab="PR_64_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >f)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Solución de
                        peticiones, quejas y reclamos a través de los canales
                        dispuestos para tal efecto en las plataformas digitales de <span zeum4c17="PR_65_0"
                            data-ddnwab="PR_65_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>.
                        <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >g)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Envío de
                        notificaciones e información relacionada con la disponibilidad
                        de consultas o encuestas pendientes por resolver y sobre aquellas que haya
                        accedido a responder, vía SMS, correo electrónico o través de plataformas
                        operadas o de propiedad de <span zeum4c17="PR_66_0" data-ddnwab="PR_66_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >h)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Envío de campañas
                        publicitarias que adelante <span zeum4c17="PR_67_0" data-ddnwab="PR_67_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> y que, bajo su
                        juicio y perfil considere que pueden ser de su interés.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >i)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Gestión de Cuentas
                        de Usuario de las plataformas digitales operadas o de
                        propiedad de <span zeum4c17="PR_68_0" data-ddnwab="PR_68_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >j)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Reporte a
                        entidades fiscales y parafiscales.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal"
                    style="margin-left: 0cm; text-align: justify; text-indent: 0cm;"><b><span
                            >4.7.
                        </span></b><b><span >FINALIDADES
                            DEL TRATAMIENTO RESPECTO DE ACCIONISTAS DE
                            <span zeum4c17="PR_69_0" data-ddnwab="PR_69_0" aria-invalid="spelling"
                                class="LI ng">EXPERTIPS</span>.</span></b><span
                        ><span></span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >Los datos
                        personales de los accionistas de <span zeum4c17="PR_70_0" data-ddnwab="PR_70_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, se considerarán
                        como información confidencial y reservada, pues la misma está registrada en los
                        libros de comercio y tienen el carácter de reserva por disposición legal. En
                        consecuencia, el acceso a tal información personal se realizará conforme las
                        normas contenidas en el Código de Comercio y las demás normas que apliquen
                        sobre la materia. <span zeum4c17="PR_71_0" data-ddnwab="PR_71_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> sólo usará los datos personales de los accionistas
                        para las finalidades derivadas de la relación estatutaria existente.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><b><span
                            >5.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >PRINCIPIOS
                            APLICABLES AL TRATAMIENTO DE DATOS PERSONALES<span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_72_0" data-ddnwab="PR_72_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, en el
                        desarrollo de sus actividades comerciales <span zeum4c17="PR_73_0" data-ddnwab="PR_73_0"
                            aria-invalid="grammar" class="Lm ng">recolectará</span>, utilizará, almacenará,
                        transmitirá y realizará diversas operaciones sobre los datos personales de LOS
                        TITULARES. Todo Tratamiento realizado por <span zeum4c17="PR_74_0" data-ddnwab="PR_74_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, el responsable, encargado
                        y/o tercero a quienes se les transfiera Datos Personales deberán dar
                        cumplimiento a los principios y reglas establecidas en la Ley y en esta POLÍTICA,
                        con el fin de garantizar el derecho al Habeas Data de LOS TITULARES, los cuales
                        son:<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Legalidad</span></b><span >: El Tratamiento
                        de datos personales es una actividad
                        regulada y debe obedecer a una finalidad <span zeum4c17="PR_75_0" data-ddnwab="PR_75_0"
                            aria-invalid="grammar" class="Lm ng">legitima</span>, por lo cual <span zeum4c17="PR_76_0"
                            data-ddnwab="PR_76_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>,
                        cumplirá lo establecido en la de Ley 1581 de 2012 y en las demás disposiciones
                        que la desarrollen.&nbsp; <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Exactitud</span></b><span >: Este principio
                        hace alusión a la exactitud y
                        pertinencia de los datos personales, los cuales serán los adecuados en atención
                        al fin del Tratamiento, garantizando que la información sea la más completa
                        posible. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Finalidad</span></b><span >: El Tratamiento
                        debe obedecer a una finalidad
                        legítima de acuerdo con la Constitución y la Ley. Esta finalidad legal será
                        previamente informada al TITULAR. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Libertad</span></b><span >: <span zeum4c17="PR_77_0"
                            data-ddnwab="PR_77_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, sólo tratará
                        los datos personales cuando
                        cuente con el consentimiento previo, expreso e informado del titular. Los datos
                        personales no podrán ser obtenidos o divulgados sin previa autorización, o en
                        ausencia de mandato legal o judicial que releve el consentimiento. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Veracidad o Calidad</span></b><span >: Los datos
                        personales sujetos a Tratamiento deben ser
                        veraces, completos, exactos, actualizados, comprobables y comprensibles. Se
                        prohíbe el Tratamiento de Datos parciales, incompletos, fraccionados o que
                        induzcan al error.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >f)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Transparencia</span></b><span >: En el
                        Tratamiento, EXPERTIPS garantiza al Titular de
                        los datos personales, a obtener del RESPONSABLE o del ENCARGADO del
                        Tratamiento, en cualquier momento y sin restricciones, información acerca de la
                        existencia de datos que a le <span zeum4c17="PR_78_0" data-ddnwab="PR_78_0"
                            aria-invalid="grammar" class="Lm ng">conciernan</span>. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >g)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Acceso y Circulación Restringida</span></b><span >: El Tratamiento
                        se
                        sujeta a los límites que se derivan de la naturaleza de los datos personales,
                        de las disposiciones de la presente Política y la Constitución. Por lo tanto,
                        el Tratamiento sólo podrá hacerse por personas autorizadas por el TITULAR y/o
                        por las personas previstas en la presente Política. Los datos personales salvo
                        información pública, no podrán estar disponibles en Internet u otros medios de
                        divulgación o comunicación masiva, salvo que el acceso sea técnicamente
                        controlable para brindar un conocimiento restringido <span zeum4c17="PR_79_0"
                            data-ddnwab="PR_79_0" aria-invalid="grammar" class="Lm ng">solo</span> a los Titulares o
                        terceros autorizados conforme la presente POLÍTICA. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >h)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Seguridad</span></b><span >: La información
                        que se encuentra sujeta a Tratamiento
                        por parte de <span zeum4c17="PR_80_0" data-ddnwab="PR_80_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> ya sea en calidad de RESPONSABLE o ENCARGADO del
                        Tratamiento, a que se refiere la presente POLÍTICA, se <span zeum4c17="PR_81_0"
                            data-ddnwab="PR_81_0" aria-invalid="grammar" class="Lm ng">manejará</span> con las medidas
                        técnicas, humanas y administrativas que sean necesarias para otorgar seguridad
                        a los registros, evitando de esta forma su adulteración, pérdida, consulta, uso
                        o acceso no autorizado o fraudulento. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >i)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Confidencialidad</span></b><span >: <span zeum4c17="PR_82_0"
                            data-ddnwab="PR_82_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, se compromete
                        a que las personas que
                        intervengan en el Tratamiento de datos personales que no tengan la naturaleza
                        de públicos se encontrarán obligadas a garantizar la reserva de la información,
                        incluso después de finalizada su relación con alguna de las labores que
                        comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de
                        datos personales cuando ello <span zeum4c17="PR_83_0" data-ddnwab="PR_83_0"
                            aria-invalid="grammar" class="Lm ng">corresponde</span> al desarrollo de las actividades
                        autorizadas en la presente POLÍTICA y en los términos de la misma. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >j)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Principio de
                            Responsabilidad Demostrada </span></b><span >(Accountability):
                        Este
                        principio se basa en el enfoque del compromiso y reconocimiento de las
                        entidades con el fin de incrementar los estándares de protección para procurar
                        y garantizar al TITULAR un Tratamiento adecuado de sus datos personales. Este
                        principio comporta para <span zeum4c17="PR_84_0" data-ddnwab="PR_84_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> una obligación de rendir cuentas sobre sus
                        actividades en materia de protección de datos personales, aceptar responsabilidad
                        sobre ellas y divulgar los resultados de manera transparente.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_85_0" data-ddnwab="PR_85_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>
                        informa a todos los Titulares cuyos datos sean tratados por la misma que, en el
                        evento de efectuar alguna modificación a la presente POLÍTICA, esta será
                        comunicada oportunamente a través de los canales dispuestos para dicho efecto.
                        Lo anterior con el fin de mantener a todos los titulares informados y
                        actualizados sobre las condiciones de uso de sus datos personales.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><b><span
                            >6.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >DERECHOS DE
                            LOS TITULARES DE INFORMACIÓN PERSONAL.<span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >Como
                        TITULAR de información personal que reposa en la Base de Datos de propiedad de
                        <span zeum4c17="PR_86_0" data-ddnwab="PR_86_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>, los titulares de información personal podrán ejercer los
                        siguientes
                        derechos:<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 21.25pt; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Derecho
                            de Conocimiento y
                            Acceso</span></b><span >:
                        Toda persona natural tendrá el derecho de
                        conocer si sus datos personales han sido sometidos a cualquier forma de
                        Tratamiento por parte de <span zeum4c17="PR_87_0" data-ddnwab="PR_87_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> en los términos expresados en la norma,
                        además de ejercer el derecho de conocer el origen de sus datos y si los mismos
                        han sido transmitidos o transferidos o no a terceros y, por ende, la
                        identificación de esos terceros.</span><span ><span></span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 21.25pt; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Derecho
                            de Actualización</span></b><span >:
                        Toda persona natural tendrá el derecho de actualizar la información
                        conservada por <span zeum4c17="PR_88_0" data-ddnwab="PR_88_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> como datos personales en los términos expresados en la
                        norma.</span><span ><span></span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 21.25pt; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Derecho
                            de Rectificación</span></b><span >:
                        Toda persona natural tiene el derecho de comprobar frente al
                        responsable del Tratamiento de la información la exactitud y veracidad y
                        solicitar la rectificación de sus datos personales recolectados cuando los
                        mismos resulten inexactos, estén incompletos o conlleven a error. Los titulares
                        de la información deberán indicar los datos que solicitan corregir y además
                        acompañar la documentación que justifique lo solicitado.</span><span
                        ><span></span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 21.25pt; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Derecho
                            a solicitar prueba de
                            la autorización otorgada </span></b><span >al
                        responsable para el
                        Tratamiento de sus datos personales y a ser informados por <span zeum4c17="PR_89_0"
                            data-ddnwab="PR_89_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, previa
                        solicitud, respecto del uso que les ha dado a sus datos personales.</span><span
                        ><span></span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 21.25pt; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Derecho
                            a presentar Quejas</span></b><span >:
                        ante la Superintendencia de Industria y Comercio por infracciones a lo
                        dispuesto en la ley y las demás normas que la modifiquen, adicionen o
                        complementen.</span><span ><span></span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 21.25pt; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >f)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span >Solicitud
                            de supresión o
                            cancelación</span></b><span >: EL
                        TITULAR de la
                        información personal deberá indicar los datos que se deben cancelar o rectificar,
                        aportando si es del caso la documentación o prueba que lo justifique. La
                        cancelación dará lugar al bloqueo de sus datos, <span zeum4c17="PR_91_0" data-ddnwab="PR_91_0"
                            aria-invalid="spelling" class="LI ng">conservándolos</span> por parte del
                        responsable, con el único fin de que estos sean accesibles a autoridades
                        administrativas o judiciales, siempre obedeciendo el plazo de prescripción que
                        exista sobre los mismos. Cumplido este plazo debe procederse por parte del
                        responsable a la cancelación definitiva de la información personal del
                        interesado o afectado, que repose en nuestras bases de datos o archivos.
                        Igualmente, EL TITULAR podrá solicitar la supresión o cancelación de sus datos
                        personales cuando el Tratamiento de los mismos por parte del responsable o
                        encargado resulte excesivo e inclusive inadecuado. Los datos personales de los
                        titulares de la información serán conservados durante los tiempos previstos en
                        las normas aplicables y/o, dependiendo del caso, de las relaciones
                        contractuales entre el titular de la información personal y el responsable del
                        Tratamiento.</span><span ><span></span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 0cm; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 0cm; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >En
                        todo caso, la
                        solicitud de supresión de la información y la revocatoria de la autorización no
                        procederán cuando el titular tenga el deber legal o contractual de permanecer
                        en la base de datos.</span><span ><span></span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 0cm; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal"
                    style="text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span >Los
                        derechos podrán ser ejercidos
                        directamente por EL TITULAR de los datos personales, su apoderado o
                        causahabientes, según sea el caso. Si EL TITULAR desea ejercer su derecho de
                        <span zeum4c17="PR_92_0" data-ddnwab="PR_92_0" aria-invalid="grammar"
                            class="Lm ng">habeas</span> data a través de representante legal, deberá presentar un poder
                        general
                        o especial debidamente autenticado.</span><span ><span></span></span>
                </p>

                <p class="MsoNormal"
                    style="text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <span ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph"
                    style="margin-left: 21.25pt; text-align: justify; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">
                    <b><span>7.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span>TRATAMIENTO DE
                            DATOS SENSIBLES.</span></b><b><span><span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >EXPERTIPS
                        podrá eventualmente hacer Tratamiento de Datos sensibles como RESPONSABLE o
                        ENCARGADO de datos personales, más no hará el Tratamiento de Datos Personales
                        de menores de edad. No obstante, lo anterior, se compromete a cumplir
                        cabalmente con el Título III de la Ley 1581 de 2012, los cuales establecen los
                        datos sensibles y los datos de los niños, niñas y adolescentes. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >EXPERTIPS
                    </span><span >identificará los
                        datos
                        sensibles que eventualmente recolecte o almacene para cumplir con los
                        siguientes objetivos:<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Implementar
                        especial
                        atención y reforzar su responsabilidad frente al Tratamiento de este tipo de
                        datos, lo cual se traduce en una exigencia mayor en términos de cumplimiento de
                        los principios y los deberes establecidos por la normatividad vigente en
                        materia de protección de datos.<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Establecer los
                        niveles de
                        seguridad técnica, legal y administrativa para tratar esa información de forma
                        adecuada.<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Aumentar
                        las restricciones de acceso y uso por parte del personal de <span zeum4c17="PR_93_0"
                            data-ddnwab="PR_93_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span> en su
                        calidad de empleadora y de terceros contratistas o proveedores de ésta.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >8.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >PROCEDIMIENTO
                            PARA EL EJERCICIO DE LOS DERECHOS DE HABEAS DATA, CONSULTA
                            Y RECLAMO POR LOS TITULARES DE LA INFORMACIÓN. <span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span >EL
                        TITULAR de la
                        información de datos personales podrá ejercer su derecho de <span zeum4c17="PR_94_0"
                            data-ddnwab="PR_94_0" aria-invalid="grammar" class="Lm ng">Habeas</span> Data en todo
                        momento y de forma efectiva para garantizar su derecho de </span><span
                        >conocer,
                        actualizar,
                        rectificar y suprimir información, revocar la autorización inicialmente
                        otorgada, consultar información, presentar reclamos y en general los demás
                        derechos establecidos en el artículo 8 y demás concordantes de la Ley 1581 de
                        2012, a través de los siguientes medios: correo electrónico <a
                            href="mailto:servicioalcliente@expertips.co">servicioalcliente@expertips.co</a>
                        dirigido a &nbsp;Mario Fernando Copete Sierra quién
                        será el Oficial de Cumplimiento de la Política de Privacidad y de Tratamiento
                        de Datos Personales de <span zeum4c17="PR_95_0" data-ddnwab="PR_95_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span >El
                        derecho de
                        <span zeum4c17="PR_96_0" data-ddnwab="PR_96_0" aria-invalid="grammar"
                            class="Lm ng">Habeas</span> Data se podrá ejercer de las siguientes
                        formas:<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >A
                        nombre propio: EL TITULAR de los datos personales que se encuentren almacenados
                        en las bases de datos y/o archivos de <span zeum4c17="PR_97_0" data-ddnwab="PR_97_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>, tendrá derecho a conocer,
                        actualizar </span><span >rectificar
                        y suprimir información, revocar la autorización inicialmente otorgada,
                        consultar información, presentar reclamos, solicitar prueba de autorización
                        otorgada, ser informado respecto del uso de sus datos.</span><span
                        >&nbsp;
                        <span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >A
                        través de apoderado: este derecho se puede ejercer por el interesado
                        debidamente identificado o por el apoderado DEL TITULAR de la información
                        personal. Si se <span zeum4c17="PR_98_0" data-ddnwab="PR_98_0" aria-invalid="grammar"
                            class="Lm ng">llegare</span> a presentar la solicitud por parte de una persona que
                        no fuese EL TITULAR de la información personal, sin cumplir con la presentación
                        del documento idóneo que sustente la representación, ésta se tendrá como no
                        presentada y no se dará respuesta a dicho requerimiento.<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Ejercicio
                        del derecho de menores de edad: en caso de presentarse, los menores de edad
                        ejercerán su derecho de <span zeum4c17="PR_99_0" data-ddnwab="PR_99_0" aria-invalid="grammar"
                            class="Lm ng">Habeas</span> Data a través de quien acredite su
                        representación legal.<span></span></span></p>

                <p class="gmail-MsoListParagraph" ><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span >El
                        procedimiento
                        de
                        Consulta y Reclamo, será el siguiente: <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Procedimiento
                        de Consulta: La consulta se formulará a través de los canales habilitados por
                        <span zeum4c17="PR_100_0" data-ddnwab="PR_100_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> y será atendida en un máximo de quince (15) días hábiles
                        contados a
                        partir de la fecha de recibo de la solicitud. Este plazo puede prorrogarse
                        cuando no fuere posible atender la petición dentro de dicho término, debiendo <span
                            zeum4c17="PR_101_0" data-ddnwab="PR_101_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span>
                        informar al peticionario los motivos del aplazamiento, antes de su
                        vencimiento.<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <span >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Procedimiento
                        de Reclamo: EL TITULAR que considere que la información contenida en una base
                        de datos de <span zeum4c17="PR_102_0" data-ddnwab="PR_102_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> debe ser objeto de corrección, actualización o supresión,
                        o cuando <span zeum4c17="PR_103_0" data-ddnwab="PR_103_0" aria-invalid="grammar"
                            class="Lm ng">advierta</span> el presunto incumplimiento de cualquiera de los deberes
                        contenidos en Ley 1581 de 2012, podrá presentar un reclamo ante el RESPONSABLE
                        o el ENCARGADO del Tratamiento el cual será tramitado bajo las siguientes
                        reglas: <span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 0cm; text-align: justify;">
                    <span ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 40pt; text-align: justify;">
                    <span ><span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>i.<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;
                        </span></span><span >El reclamo se
                        formulará
                        mediante solicitud dirigida al RESPONSABLE o al ENCARGADO del Tratamiento, con
                        la identificación del titular, la descripción de los hechos que dan lugar al
                        reclamo y la dirección, acompañando los documentos que se quiera hacer vale.<span></span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 40pt; text-align: justify;">
                    <span ><span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>ii.<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;
                        </span></span><span >Si el reclamo
                        resulta
                        incompleto, se requerirá al interesado dentro de los cinco (5) días hábiles
                        siguientes a la recepción del reclamo para que subsane las fallas.
                        Transcurridos dos (2) meses desde la fecha del requerimiento sin que el
                        solicitante presente la información requerida, se entenderá que ha desistido
                        del reclamo. <span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 40pt; text-align: justify;">
                    <span ><span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>iii.<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;
                        </span></span><span >En caso de que
                        quien reciba
                        el reclamo no sea competente para resolverlo, dará traslado a quién corresponda
                        en un término máximo de dos (2) días hábiles e informará la situación al
                        interesado.<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 40pt; text-align: justify;">
                    <span ><span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>iv.<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;
                        </span></span><span >Una vez recibido
                        el reclamo
                        completo, en un término no mayor a dos (2) días hábiles se incluirá en la base
                        de datos una leyenda que diga “reclamo en trámite” y el motivo del mismo. Dicha
                        leyenda deberá mantenerse hasta que el reclamo sea decidido.<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 40pt; text-align: justify;">
                    <span ><span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>v.<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;
                        </span></span><span >El término máximo
                        para
                        atender el reclamo será de quince (15) días hábiles contados a partir del día
                        siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo
                        dentro de dicho término, se informará al interesado los motivos de la demora y
                        la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar
                        los ocho (8) días hábiles siguientes al vencimiento del primer término.<span></span></span></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 18pt; text-align: justify;">
                    <span ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >9.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >TRANSFERENCIA,
                            TRANSMISIÓN Y CONSERVACIÓN DE DATOS PERSONALES. <span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_104_0" data-ddnwab="PR_104_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>
                        se obliga a conservar los datos personales de los titulares de la información,
                        cumpliendo con la finalidad autorizada por el titular de los datos, realizando
                        sus mayores esfuerzos para mantener la información de manera segura,
                        salvaguardando su integridad, veracidad y confidencialidad. Igualmente
                        realizará la transmisión de los datos personales del TITULAR de los cuales es
                        RESPONSABLE, única y exclusivamente para los efectos y finalidad indicados en
                        la presente POLÍTICA previo consentimiento expreso e informado por parte del
                        TITULAR.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >10.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >IDENTIFICACIÓN
                            DEL RESPONSABLE<span></span></span></b></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 0cm; text-align: justify;">
                    <b><span ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >SALOMÓN
                        ÁLVAREZ VALENZUELA, mayor de edad, identificado con cédula de ciudadanía número
                        6.135.407 de Cali, actuando como representante legal de <span zeum4c17="PR_105_0"
                            data-ddnwab="PR_105_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span> COLOMBIA
                        S.A.S., so</span><span >ciedad comercial
                        constituida el&nbsp;día&nbsp;XXX
                        del mes XXX de 2020 mediante documento privado contenido en el Acta 001,
                        registrada en la Cámara de Comercio de Santiago de Cali, con matrícula
                        mercantil número&nbsp;XXX, titular del NIT&nbsp;XXX y con domicilio en la
                        ciudad de Santiago de Cali (Colombia), </span><span >será la persona
                        natural RESPONSABLE del Tratamiento de
                        datos personales que se entreguen a través del diligenciamiento del formulario
                        de contacto, aceptación de correos electrónicos, ventanas de chat que se
                        encuentren disponibles en <a href="http://www.expertips.co./">www.expertips.co</a>
                        y/o la aplicación móvil, teléfonos de contacto y correos electrónicos ahí
                        indicados, mensajes de texto, llamadas telefónicas, videollamadas, anuncios en
                        sitios web, diferentes redes sociales, cookies, documentos escritos,
                        publicación de información o fotografías en perfiles de plataformas operadas o
                        de propiedad de <span zeum4c17="PR_106_0" data-ddnwab="PR_106_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> o contratos suscritos con EL RESPONSABLE del
                        Tratamiento de datos. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span >El
                        RESPONSABLE se identificará de la siguiente manera: Mario Fernando Copete Sierra,
                        correo electrónico: <a
                            href="mailto:servicioalcliente@expertips.co">servicioalcliente@expertips.co</a>
                        teléfono: (+57) 2 5921525<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >11.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >DEBERES DE
                            <span zeum4c17="PR_107_0" data-ddnwab="PR_107_0" aria-invalid="spelling"
                                class="LI ng">EXPERTIPS</span> COMO RESPONSABLE DEL TRATAMIENTO DE DATOS.
                            <span></span></span></b></p>

                <p class="gmail-MsoListParagraph" style="margin-left: 0cm; text-align: justify;">
                    <b><span ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_108_0" data-ddnwab="PR_108_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>
                        hará el Tratamiento de los datos personales en los términos y alcances de la
                        autorización entregada por el TITULAR de la información, de los siguientes
                        datos sin perjuicio de las demás disposiciones previstas en la ley: <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >a)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Garantizar al
                        TITULAR, en todo tiempo, el pleno y efectivo ejercicio del
                        derecho de <span zeum4c17="PR_109_0" data-ddnwab="PR_109_0" aria-invalid="grammar"
                            class="Lm ng">habeas</span> data;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >b)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Solicitar y
                        conservar, en las condiciones previstas en la ley, copia de
                        la respectiva autorización otorgada por el TITULAR;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >c)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Informar al
                        TITULAR la finalidad de la recolección y los derechos que le
                        asisten por virtud de la autorización otorgada;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >d)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Custodiar la
                        información recolectada con el fin de impedir su pérdida,
                        adulteración, consulta, uso o acceso no autorizado o fraudulento;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >e)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Garantizar que la
                        información que se suministre al ENCARGADO del
                        Tratamiento sea veraz, completa, exacta, actualizada, comprobable y
                        comprensible;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >f)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Actualizar la
                        información y mantener actualizado al ENCARGADO acerca de
                        cualquier modificación o cambio en la misma y adoptar todas las medidas
                        necesarias para que la información suministrada a este se mantenga
                        actualizada;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >g)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Rectificar la
                        información cuando sea incorrecta y comunicar lo
                        pertinente al ENCARGADO del Tratamiento;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >h)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Suministrar al
                        ENCARGADO del Tratamiento, según el caso, únicamente
                        datos cuyo Tratamiento esté previamente autorizado de conformidad con lo
                        previsto en la ley;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >i)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Exigir al
                        ENCARGADO del Tratamiento en todo momento, el respeto a las
                        condiciones de seguridad y privacidad de la información del TITULAR;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >j)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Tramitar las
                        consultas y reclamos formulados en los términos señalados
                        en la presente <span zeum4c17="PR_110_0" data-ddnwab="PR_110_0" aria-invalid="spelling"
                            class="LI ng">POLITICA</span> y en la ley 1581 de 2012;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >k)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Adoptar un manual
                        interno de políticas y procedimientos para garantizar
                        el adecuado cumplimiento de la ley y en especial, para la atención de consultas
                        y reclamos;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >l)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Informar al
                        ENCARGADO del Tratamiento cuando determinada información se
                        encuentre en discusión por parte del TITULAR, una vez se haya presentado la
                        reclamación y no haya finalizado el trámite respectivo;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >m)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Informar a
                        solicitud del
                        TITULAR sobre el uso dado a sus datos;<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >n)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Informar a la
                        autoridad de protección de datos cuando se presenten
                        violaciones a los códigos de seguridad y existan riesgos en la administración
                        de la información de los TITULARES.<span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21.25pt; text-align: justify;"><span
                        >o)<span
                            style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span >Cumplir las
                        instrucciones y requerimientos que imparta la
                        Superintendencia de Industria y Comercio.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><a name="_Hlk45103206"><b><span
                                ><span>&nbsp;</span></span></b></a>
                </p>



                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >12.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >SEGURIDAD DE
                            LA INFORMACIÓN. <span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_112_0" data-ddnwab="PR_112_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>
                        se compromete a garantizar la seguridad adecuada con el fin de proteger los
                        datos personales del TITULAR y manifiesta que cuenta con herramientas
                        tecnológicas seguras para garantizar que los datos personales se mantendrán
                        confidenciales y que serán almacenados de manera que se impida posibles
                        pérdidas accidentales y accesos indeseados por personas no autorizadas. Lo
                        anterior, de acuerdo al estado de tecnología, el tipo y naturaleza de los datos
                        que se encuentren en nuestras bases de datos y los riesgos a que están
                        expuestos. <span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >Los
                        datos personales serán conservados mientras exista una relación comercial entre
                        <span zeum4c17="PR_113_0" data-ddnwab="PR_113_0" aria-invalid="spelling"
                            class="LI ng">EXPERTIPS</span> y los TITULARES de los datos personales, y en caso dado hasta
                        por
                        cinco (5) años después de la terminación de la mencionada relación.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >13.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span
                            >CONFIDENCIALIDAD<span></span></span></b>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >Los
                        datos personales que obtenga <span zeum4c17="PR_114_0" data-ddnwab="PR_114_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> a través del diligenciamiento del
                        formulario de contacto, aceptación de correos electrónicos, ventanas de chat
                        que se encuentra disponible en <a href="http://www.expertips.co/">www.expertips.co</a>
                        y/o la aplicación móvil, los teléfonos de contacto y correos electrónicos ahí
                        indicados, mensajes de texto, llamadas telefónicas, videollamadas, contratos,
                        publicación de fotografías o información personal en perfiles o en cuentas de
                        plataformas operadas o de propiedad de <span zeum4c17="PR_115_0" data-ddnwab="PR_115_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span> y/o documentos escritos entre
                        otros, serán tratados con total reserva y confidencialidad. <span zeum4c17="PR_116_0"
                            data-ddnwab="PR_116_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span> se
                        compromete a guardar el debido secreto respecto de los mismos y garantiza el
                        deber de almacenarlos adoptando las medidas necesarias que eviten su alteración,
                        pérdida y tratamiento o acceso no autorizado, de acuerdo con lo establecido en
                        la legislación aplicable.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >14.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >MODIFICACIONES
                            A LA <span zeum4c17="PR_117_0" data-ddnwab="PR_117_0" aria-invalid="spelling"
                                class="LI ng">POLITICA</span> DE PRIVACIDAD Y TRATAMIENTO DE DATOS
                            PERSONALES.<span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span zeum4c17="PR_118_0" data-ddnwab="PR_118_0"
                            aria-invalid="spelling" class="LI ng">EXPERTIPS</span>
                        se reserva el derecho a realizar modificaciones a la <span zeum4c17="PR_119_0"
                            data-ddnwab="PR_119_0" aria-invalid="spelling" class="LI ng">POLITICA</span> en cualquier
                        momento
                        y sin notificación cuando los mismos sean el resultado de un mandato legal.
                        Cualquier cambio sustancial será notificado inmediatamente al TITULAR y entrará
                        en vigencia en el momento de su publicación.<span></span></span></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >Las
                        modificaciones posteriores a la fecha de publicación inicial de esta <span zeum4c17="PR_120_0"
                            data-ddnwab="PR_120_0" aria-invalid="spelling" class="LI ng">POLITICA</span>
                        DE PRIVACIDAD Y TRATAMIENTO DE DATOS PERSONALES, serán publicadas en nuestra
                        página web <a href="http://www.expertips.co/">www.expertips.co</a> y la
                        aplicación móvil. <span></span></span></p>

                <p class="MsoNormal" style="margin-left: 21pt;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >15.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >PREVALENCIA DE
                            LAS NORMAS SUSTANTIVAS SOBRE LA MATERIA.<span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><b><span
                            ><span>&nbsp;</span></span></b>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        >Teniendo
                        en cuenta que el presente documento busca dar cumplimiento a las normas que
                        regulan la protección del derecho de Habeas Data consagrado en la Constitución,
                        las leyes estatutarias sobre la materia y los reglamentos que expida el Gobierno
                        Nacional para tal propósito, la interpretación de la POLÍTICA de <span zeum4c17="PR_121_0"
                            data-ddnwab="PR_121_0" aria-invalid="spelling" class="LI ng">EXPERTIPS</span>
                        estará en todo momento subordinada al contenido de tales disposiciones
                        superiores, por lo que en caso de incompatibilidad o contradicción entre las
                        presentes políticas y la normatividad superior será aplicable esta última. <span></span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="gmail-MsoListParagraph" style="margin-left: 21.25pt; text-align: justify;">
                    <b><span >16.<span
                                style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; line-height: normal;">&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span></b><b><span >FECHA DE
                            ENTRADA EN VIGENCIA DE LA PRESENTE <span zeum4c17="PR_122_0" data-ddnwab="PR_122_0"
                                aria-invalid="spelling" class="LI ng">POLITICA</span> DE PRIVACIDAD Y
                            TRATAMIENTO DE DATOS PERSONALES<span></span></span></b></p>

                <p class="MsoNormal" style="text-align: justify;"><span
                        ><span>&nbsp;</span></span>
                </p>

                <p class="MsoNormal" style="text-align: justify;"><span >La
                        presente POLÍTICA entra en vigencia a partir del día <b>01 de diciembre de 2020</b>.
                        <span></span></span></p>

                <p class="MsoNormal" ><span
                        ><span>&nbsp;</span></span>
                </p>

                <div>

                    <hr class="gmail-msocomoff" align="left" size="1" width="33%">



                    <div>

                        <div id="gmail-_com_1" class="gmail-msocomtxt"><a name="_msocom_1"></a>

                            <p class="gmail-MsoCommentText">Debemos definir
                                una Lista de Correos <span zeum4c17="PR_123_0" data-ddnwab="PR_123_0"
                                    aria-invalid="spelling" class="LI ng">electronicos</span><span></span></p>

                        </div>

                    </div>

                    <div>

                        <div id="gmail-_com_2" class="gmail-msocomtxt"><a name="_msocom_2"></a>

                            <p class="gmail-MsoCommentText">Si, puede ser
                                info, admin, contacto, Esa decisión depende completamente de ustedes, una vez
                                tengan definidos los correos, proceden a hacer las modificaciones
                                pertinentes.<span></span></p>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>