import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Experto } from './../interface/experto';

@Injectable({
  providedIn: 'root'
})
export class ExpertoService {

  constructor(
    private http: HttpClient
  ) { }

  public getPais(): Observable<object> {
    return this.http.get('assets/countries.json');
  }

  public getEstado(): Observable<object> {
    return this.http.get('assets/states.json');
  }

  public getCiudad(): Observable<object> {
    return this.http.get('assets/cities.json');
  }

  public post(object: Experto): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/Experto', object);
  }

  public put(object: Experto): Observable<object> {/**Update */
    return this.http.put(environment.urlApi + '/Experto/' + object.id, object);
  }

  public getOne(value: any): Observable<object> {/**GetOne */
    return this.http.get(environment.urlApi + '/Experto/' + value);
  }

  /** ADICIONAL */
  public uploadImage(form: FormData): Observable<any> {
    return this.http.post(environment.urlImage, form);
  }

}
