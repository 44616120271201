<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-experto></app-menu-experto>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">{{_usuario}}</h2>
    </div> -->
    <div class="container">
        <div class="animated fadeIn">
            <h2 class="titulo">MI PERFIL</h2>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isBuscando === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_usuario}}, intenta
                                nuevamente.</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: s
                        auto; height: 8rem;">
                    </div>
                    <!-- <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal"> -->
                    <form [formGroup]="form" enctype="multipart/form-data" class="form-horizontal"
                        (ngSubmit)="update($event)">
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">NICKNAME</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nickname" disabled>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">FOTOGRAFÍA</label>
                                    <div class="col-md-9 text-center" *ngIf="loadingFileurlfotografia">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-7" *ngIf="!loadingFileurlfotografia">
                                        <input type="text" class="form-control" formControlName="urlfotografia"
                                            *ngIf="isFileurlfotografia" disabled>
                                        <input style="display: none" type="file" accept="image/png, image/jpeg"
                                            (change)="onFileChanged('urlfotografia', $event)" #fileInputurlfotografia>
                                        <div class="row" *ngIf="!isFileurlfotografia">
                                            <!-- <div class="col-md-6"> -->
                                            <div class="col-md-12">
                                                <button type="button"
                                                    [attr.class]="this.fileurlfotografia ? 'btn btn-block btn-secondary mb-2' : 'btn btn-block btn-light mb-2'"
                                                    (click)="fileInputurlfotografia.click()"
                                                    [disabled]="this.fileurlfotografia"><i
                                                        class="far fa-folder-open"></i> Seleccione un
                                                    archivo</button>
                                            </div>
                                        </div>
                                        <!-- <div class="row" *ngIf="imagenAux">
                                            <div class="col-md-12">
                                                <img [src]="imagenAux" alt="" class="img-thumbnail">
                                            </div>
                                        </div> -->
                                        <small><b>Te recomendamos usar una foto donde se pueda apreciar tu rostro, este
                                                debe estar centrado.</b>
                                            <br>Tu foto estará disponible cuando inicies sesión nuevamente.</small>
                                    </div>
                                    <div class="col-md-2" *ngIf="!loadingFileurlfotografia">
                                        <div class="form-control alert alert-primary text-center" role="alert">Max.
                                            5MB</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">PAÍS<span
                                            *ngIf="validator('pais')">*</span></label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonPaises$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="isPais">
                                        <div class="input-group">
                                            <input type="text" class="form-control" formControlName="pais" disabled>
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-sm btn-primary"
                                                    (click)="refresh();"><span>Cancelar</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonPaises$ | async)?.length > 0 && !isPais">
                                        <select class="form-control" formControlName="pais"
                                            (change)="getEstados($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let pais of jsonPaises$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="pais.name + ':' + pais.id">
                                                {{ pais.name }}</option>
                                        </select>
                                        <div *ngIf="form.get('pais').errors && form.get('pais').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['pais'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">DEPARTAMENTO<span
                                            *ngIf="validator('departamento')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isEstado && !isPais">
                                        <input type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-md-9" *ngIf="isPais">
                                        <input type="text" class="form-control" formControlName="departamento" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="isEstado && (jsonEstados$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="isEstado && (jsonEstados$ | async)?.length > 0">
                                        <select class="form-control" formControlName="departamento"
                                            (change)="getCiudades($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let estado of jsonEstados$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="estado.name + ':' + estado.id">
                                                {{ estado.name }}</option>
                                        </select>
                                        <div *ngIf="form.get('departamento').errors && form.get('departamento').dirty">
                                            <span class="help-block text-danger" +
                                                *ngIf="form.controls['departamento'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CIUDAD / BARRIO (CAPITAL)<span
                                            *ngIf="validator('ciudad')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isCiudad && !isPais">
                                        <input type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-md-9" *ngIf="isPais">
                                        <input type="text" class="form-control" formControlName="ciudad" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="isCiudad && (jsonCiudades$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="isCiudad && (jsonCiudades$ | async)?.length > 0">
                                        <select class="form-control" formControlName="ciudad">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let ciudad of jsonCiudades$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="ciudad.name">
                                                {{ ciudad.name }}</option>
                                        </select>
                                        <div *ngIf="form.get('ciudad').errors && form.get('ciudad').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['ciudad'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">DIRECCIÓN<span
                                            *ngIf="validator('direccion')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="direccion">
                                        <div *ngIf="form.get('direccion').errors && form.get('direccion').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CÓDIGO PAÍS + CELULAR<span
                                            *ngIf="validator('celular')">*</span></label>
                                    <div class="col-md-2">
                                        <input type="text" class="form-control" formControlName="codigopais">
                                        <div *ngIf="form.get('codigopais').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('pattern')">
                                                + Ingresa un número valido
                                            </span>
                                        </div>
                                        <div *ngIf="form.get('codigopais').errors && form.get('codigopais').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <input type="text" class="form-control" formControlName="celular">
                                        <div *ngIf="form.get('celular').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('pattern')">
                                                + Ingresa un número valido
                                            </span>
                                        </div>
                                        <div *ngIf="form.get('celular').errors && form.get('celular').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CORREO ELECTRÓNICO<span
                                            *ngIf="validator('correoelectronico')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoelectronico"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form.get('correoelectronico').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('pattern')">
                                                + Ingresa un correo valido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="form.get('correoelectronico').errors && form.get('correoelectronico').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">PERFIL PROFESIONAL<span
                                            *ngIf="validator('curriculum')">*</span></label>
                                    <div class="col-md-9">
                                        <quill-editor [styles]="{height: '100px'}"
                                            (onContentChanged)="onChangue($event)" formControlName="curriculum">
                                        </quill-editor>
                                        <small>Contador de caracteres, {{ totalCaracteres }} <span
                                                class="text-danger">Maximo 500</span></small><br>
                                        <small>Por favor especifica en este espacio tu Perfil Profesional. Recuerda que
                                            por politicas del sitio no debes incluir nombres de instituciones,
                                            compañias,
                                            personas, enlaces, direcciones web o numeros de contacto</small>
                                        <div *ngIf="(form.get('curriculum').errors && form.get('curriculum').dirty)">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['curriculum'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isValoresGrupo1">
                                    <label class="col-md-3 col-form-label">{{_area}} (1)</label>
                                    <div class="col-md-9">
                                        <div class="input-group">
                                            <input type="text" class="form-control" value="{{fkarea1Aux}}" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isValoresGrupo1">
                                    <label class="col-md-3 col-form-label">{{_rama}} (1)</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" value="{{fkrama1Aux}}" disabled>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isValoresGrupo1">
                                    <label class="col-md-3 col-form-label">{{_especialidad}}(s) (1)</label>
                                    <div class="col-md-9">
                                        <ul>
                                            <li *ngIf="fkespecialidad11Aux">{{fkespecialidad11Aux}}</li>
                                            <li *ngIf="fkespecialidad12Aux">{{fkespecialidad12Aux}}</li>
                                            <li *ngIf="fkespecialidad13Aux">{{fkespecialidad13Aux}}</li>
                                            <li *ngIf="fkespecialidad14Aux">{{fkespecialidad14Aux}}</li>
                                            <li *ngIf="fkespecialidad15Aux">{{fkespecialidad15Aux}}</li>
                                            <li *ngIf="otraespecialidad1Aux">{{otraespecialidad1Aux}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isValoresGrupo1">
                                    <button type="button" class="btn btn-sm btn-primary mx-auto"
                                        (click)="cancelarAreas1();"><span>Modificar</span></button>
                                </div>
                                <div class="form-group row" *ngIf="!isValoresGrupo1">
                                    <label class="col-md-3 col-form-label">{{_area}} (1) <span
                                            *ngIf="validator('fkarea1')">*</span></label>
                                    <div class="col-md-9" *ngIf="isRama1">
                                        <div class="input-group">
                                            <input type="text" class="form-control" value="{{fkarea1}}" disabled>
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-sm btn-primary"
                                                    (click)="cancelarAreas1();"><span>Cancelar</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="!isRama1 && (jsonAreas1$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="!isRama1 && (jsonAreas1$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fkarea1"
                                            (change)="getRamas1($event, false)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let area of jsonAreas1$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="area.id + ':' + area.nombre">
                                                {{ area.nombre }}</option>
                                        </select>
                                        <div *ngIf="form.get('fkarea1').errors && form.get('fkarea1').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['fkarea1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isValoresGrupo1">
                                    <label class="col-md-3 col-form-label">{{_rama}} (1) <span
                                            *ngIf="validator('fkrama1')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isRama1 || isEspecialidad1">
                                        <input type="text" class="form-control" value="{{fkrama1}}" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonRamas1$ | async)?.length <= 0 && isRama1">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonRamas1$ | async)?.length > 0 && !isEspecialidad1">
                                        <select class="form-control" formControlName="fkrama1"
                                            (change)="getEspecialidads1($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let rama of jsonRamas1$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="rama.id + ':' + rama.nombre">
                                                {{ rama.nombre }}</option>
                                        </select>
                                        <div *ngIf="form.get('fkrama1').errors && form.get('fkrama1').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['fkrama1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isValoresGrupo1">
                                    <label class="col-md-3 col-form-label">{{_especialidad}}(s) (1) <span
                                            *ngIf="validator('fkespecialidad11')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isEspecialidad1">
                                        <input type="text" class="form-control" disabled>
                                    </div>
                                    <!-- <div class="col-md-9"
                                                    *ngIf="((jsonEspecialidads1$ | async)?.length > 0) && !isOtro1"> -->
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonEspecialidads1$ | async)?.length <= 0 && isEspecialidad1">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonEspecialidads1$ | async)?.length > 0">
                                        <label class="custom-control custom-checkbox"
                                            *ngFor="let especialidad of jsonEspecialidads1$ | async | sortBy: 'asc':'nombre'">
                                            <input type="checkbox" class="custom-control-input"
                                                [value]="especialidad.id" (change)="changedEspecialidad1($event)" />
                                            <div class="custom-control-label">{{ especialidad.nombre }}
                                            </div>
                                        </label>
                                        <label class="custom-control custom-checkbox"
                                            *ngIf="contEspecilidad1 > 0 && contEspecilidad1 < 5">
                                            <input type="checkbox" class="custom-control-input" value="otro"
                                                (change)="changedEspecialidad1($event)" />
                                            <div class="custom-control-label">No encuentras tus
                                                {{_especialidad}}(s),
                                                ¡inclúyelas!</div>
                                        </label>
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad11">
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad12">
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad13">
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad14">
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad15">
                                    </div>
                                    <div
                                        *ngIf="form.get('fkespecialidad11').errors && form.get('fkespecialidad11').dirty">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['fkespecialidad11'].hasError('required')">
                                            + Campo requerido
                                        </span>
                                    </div>
                                    <div class="col-md-12" *ngIf="isOtro1 && contEspecilidad1 < 5">
                                        <input type="text" class="form-control" formControlName="otraespecialidad1"
                                            oninput="this.value = this.value.toUpperCase()">
                                        <small>Establece tus {{_especialidad}}(s) separadas por comas, no
                                            usar
                                            tildes o símbolos y continua registrándote</small>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isValoresGrupo2">
                                    <label class="col-md-3 col-form-label">{{_area}} (2)</label>
                                    <div class="col-md-9">
                                        <div class="input-group">
                                            <input type="text" class="form-control" value="{{fkarea2Aux}}" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isValoresGrupo2">
                                    <label class="col-md-3 col-form-label">{{_rama}} (2)</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" value="{{fkrama2Aux}}" disabled>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isValoresGrupo2">
                                    <label class="col-md-3 col-form-label">{{_especialidad}}(s) (2)</label>
                                    <div class="col-md-9">
                                        <ul>
                                            <li *ngIf="fkespecialidad21Aux">{{fkespecialidad21Aux}}</li>
                                            <li *ngIf="fkespecialidad22Aux">{{fkespecialidad22Aux}}</li>
                                            <li *ngIf="fkespecialidad23Aux">{{fkespecialidad23Aux}}</li>
                                            <li *ngIf="fkespecialidad24Aux">{{fkespecialidad24Aux}}</li>
                                            <li *ngIf="fkespecialidad25Aux">{{fkespecialidad25Aux}}</li>
                                            <li *ngIf="otraespecialidad2Aux">{{otraespecialidad2Aux}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="isValoresGrupo2">
                                    <button type="button" class="btn btn-sm btn-primary mx-auto"
                                        (click)="cancelarAreas2();"><span>Modificar</span></button>
                                </div>
                                <div class="form-group row" *ngIf="!isValoresGrupo2">
                                    <label class="col-md-3 col-form-label">{{_area}} (2)</label>
                                    <div class="col-md-9" *ngIf="isRama2">
                                        <div class="input-group">
                                            <input type="text" class="form-control" value="{{fkarea2}}" disabled>
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-sm btn-primary"
                                                    (click)="cancelarAreas2();"><span>Cancelar</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="!isRama2 && (jsonAreas2$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="!isRama2 && (jsonAreas2$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fkarea2"
                                            (change)="getRamas2($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let area of jsonAreas2$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="area.id + ':' + area.nombre">
                                                {{ area.nombre }}</option>
                                        </select>
                                        <div *ngIf="form.get('fkarea2').errors && form.get('fkarea2').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['fkarea2'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isValoresGrupo2">
                                    <label class="col-md-3 col-form-label">{{_rama}} (2)</label>
                                    <div class="col-md-9" *ngIf="!isRama2 || isEspecialidad2">
                                        <input type="text" class="form-control" value="{{fkrama2}}" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonRamas2$ | async)?.length <= 0 && isRama2">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonRamas2$ | async)?.length > 0 && !isEspecialidad2">
                                        <select class="form-control" formControlName="fkrama2"
                                            (change)="getEspecialidads2($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let rama of jsonRamas2$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="rama.id + ':' + rama.nombre">
                                                {{ rama.nombre }}</option>
                                        </select>
                                        <div *ngIf="form.get('fkrama2').errors && form.get('fkrama2').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['fkrama2'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isValoresGrupo2">
                                    <label class="col-md-3 col-form-label"> {{_especialidad}}(s) (2)</label>
                                    <div class="col-md-9" *ngIf="!isEspecialidad2">
                                        <input type="text" class="form-control" disabled>
                                    </div>
                                    <!-- <div class="col-md-9"
                                            *ngIf="((jsonEspecialidads2$ | async)?.length > 0) && !isOtro2"> -->
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonEspecialidads2$ | async)?.length <= 0 && isEspecialidad2">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonEspecialidads2$ | async)?.length > 0">
                                        <label class="custom-control custom-checkbox"
                                            *ngFor="let especialidad of jsonEspecialidads2$ | async | sortBy: 'asc':'nombre'">
                                            <input type="checkbox" class="custom-control-input"
                                                [value]="especialidad.id" (change)="changedEspecialidad2($event)" />
                                            <div class="custom-control-label">{{ especialidad.nombre }}</div>
                                        </label>
                                        <label class="custom-control custom-checkbox"
                                            *ngIf="contEspecilidad2 > 0 && contEspecilidad2 < 5">
                                            <input type="checkbox" class="custom-control-input" value="otro"
                                                (change)="changedEspecialidad2($event)" />
                                            <div class="custom-control-label">No encuentras tus
                                                {{_especialidad}}(s),
                                                ¡inclúyelas!</div>
                                        </label>
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad21">
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad22">
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad23">
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad24">
                                        <input type="hidden" class="form-control" formControlName="fkespecialidad25">
                                    </div>
                                    <div
                                        *ngIf="form.get('fkespecialidad21').errors && form.get('fkespecialidad21').dirty">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['fkespecialidad21'].hasError('required')">
                                            + Campo requerido
                                        </span>
                                    </div>
                                    <div class="col-md-12" *ngIf="isOtro2 && contEspecilidad2 < 5">
                                        <input type="text" class="form-control" formControlName="otraespecialidad2"
                                            oninput="this.value = this.value.toUpperCase()">
                                        <small>Establece tus {{_especialidad}}(s) separadas por comas, no usar
                                            tildes o símbolos y continua registrándote</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="text-center">
                                    <button type="button" class="btn btn-secondary rounded-pill"
                                        *ngIf="form.invalid && isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Actualizar información
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill"
                                        *ngIf="form.valid && isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Actualizar información
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>