<section class="banner-all">
    <div class="container div-titulo">
        <h2 class="titulo">¿ Cómo funciona ?</h2>
    </div>
    <div class="container">
        <div class="row">
            <img src="assets/template/images/1200x600.jpg" alt="" class="img-fluid">
            <button type="button" class="btn btn-outline-primary rounded-pill mt-3 mx-auto" [routerLink]="['/faq']">¿
                tienes más dudas ?</button>
        </div>
    </div>
</section>