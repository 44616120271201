// import { Injectable } from '@angular/core';
// import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
// import { Observable, throwError, ReplaySubject } from 'rxjs';
// import { catchError, finalize, map } from 'rxjs/operators';
// import { Router, NavigationStart, NavigationError, NavigationEnd, NavigationCancel } from '@angular/router';
import { LocalStorageService } from './-local-storage.service';
import { AuthService } from './-auth.service';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  // export class InterceptorService  {



  /*private pendingRequests = 0;
  private filteredUrlPatterns: RegExp[] = [];

  private pendingRequestsStatus: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private router: Router,
    private localstorageService: LocalStorageService,
  ) {
    debugger;
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.pendingRequestsStatus.next(true);
      }

      if ((event instanceof NavigationError || event instanceof NavigationEnd || event instanceof NavigationCancel)) {
        this.pendingRequestsStatus.next(false);
      }
    });
  }

  private shouldBypass(url: string): boolean {
    return this.filteredUrlPatterns.some(e => {
      return e.test(url);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    debugger;
    const shouldBypass = this.shouldBypass(req.url);

    const HTTP_JWT: string = this.localstorageService.get('QYfRuxeadphBcSuOAczF').toString();
    const tipo: string = this.localstorageService.get('qFUNseVbOFIMuWyQSdxC').toString();
    const fk: string = this.localstorageService.get('eVBEvatmKiVlokbfjfwJ').toString();

    if (HTTP_JWT) {
      req = req.clone({ headers: req.headers.set('HTTP_JWT', HTTP_JWT) })
    }

    if (tipo) {
      req = req.clone({ headers: req.headers.set('tipo', tipo) })
    }

    if (fk) {
      req = req.clone({ headers: req.headers.set('fk', fk) })
    }

    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }

    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    if (!shouldBypass) {
      this.pendingRequests++;

      if (1 === this.pendingRequests) {
        this.pendingRequestsStatus.next(true);
      }
    }

    return next.handle(req).pipe(
      map(event => {
        return event;
      }),
      catchError(error => {
        debugger;
        return throwError(error);
      }),
      finalize(() => {
        debugger;
        if (!shouldBypass) {
          this.pendingRequests--;

          if (0 === this.pendingRequests) {
            this.pendingRequestsStatus.next(false);
          }
        }
      })
    );
  }*/


  constructor(
    private localstorageService: LocalStorageService,
    private auth: AuthService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    try {
      const HTTP_JWT: string = this.localstorageService.get('QYfRuxeadphBcSuOAczF').toString();
      const tipo: string = this.localstorageService.get('qFUNseVbOFIMuWyQSdxC').toString();
      const fk: string = this.localstorageService.get('eVBEvatmKiVlokbfjfwJ').toString();
      const correoelectronico: string = this.localstorageService.get('CBIfXgisPiSqolrJrNFd').toString();

      if (HTTP_JWT) {
        req = req.clone({ headers: req.headers.set('JWT', HTTP_JWT) })
      }

      if (tipo) {
        req = req.clone({ headers: req.headers.set('tipo', tipo) })
      }

      if (fk) {
        req = req.clone({ headers: req.headers.set('fk', fk) })
      }

      if (correoelectronico) {
        req = req.clone({ headers: req.headers.set('correoelectronico', correoelectronico) })
      }
    } catch (error) {
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        // console.log('error--->>>', data);
        if (this.auth.validarExperto() || this.auth.validarUsuario()) {
          if (!this.auth.validar()) {
            this.auth.salirMensaje();
          }
        }
        return throwError(error);
      })
    );
  }



}
