import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { RecomendarExpertoService } from './../shared/service/recomendar-experto.service';
import { RecomendarExperto } from './../shared/interface/recomendar-experto';
import { Experto } from './../shared/interface/experto';

@Component({
  selector: 'app-recomendar-experto',
  templateUrl: './recomendar-experto.component.html',
  styleUrls: ['./recomendar-experto.component.css']
})
export class RecomendarExpertoComponent implements OnInit {

  public fk: number;
  // public fkexperto: number;
  public fkexperto: string;
  public totalCaracteres = 0;
  private mensaje: string;
  public isExperto = false;
  public isBuscandoExperto = false;

  public jsonExpertos$: Observable<Experto[]>;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private recomendarExpertoService: RecomendarExpertoService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      this.fk = parseInt(this.auth.obtener('fk'));
      if (params.experto) {
        this.fkexperto = params.experto;
        this.form.get('fkexperto').setValue(this.fkexperto);
      } else {
        this.fkexperto = '';
        this.form.get('fkexperto').setValidators(Validators.required);
        this.getExpertos();
      }
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      fecha: [''],
      nombre: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      correoelectronico: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      fkexperto: [null],
      mensaje: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      estado: [null],
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.valid) {
      const object = this.form.value;
      object.mensaje = this.mensaje;
      object.fkusuario = this.fk;
      if (this.isExperto) {
        object.fkexperto = this.form.get('fkexperto').value.split(':')[0];
      }
      object.estado = 'PENDIENTE';
      this.isAccion = 0;

      this.recomendarExpertoService.post(object).subscribe(
        (response: RecomendarExperto) => {
          // response 200
          this.isAccion = 1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos enviado tu recomendación...',
            showConfirmButton: false,
            timer: 4000
          });
          
          setTimeout(function () {
            window.location.href = '/mi-usuario/' + object.fkusuario;
          }, 4000);
        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

    }
  }

  public getExpertos(): void {
    this.isBuscandoExperto = true;
    this.isExperto = true;

    this.queryService.query_42(this.fk).subscribe(
      (expertos: Experto[]) => {
        this.isBuscandoExperto = false;
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });
      }
    );
  }

  public getFkusuario(): number {
    return this.fk;
  }

  public onChangue(event: any): void {
    this.totalCaracteres = event.editor.getLength();
    if (this.totalCaracteres > 199) {
      event.editor.deleteText(199, event.editor.getLength());
    }
    this.mensaje = event.editor.getText();
  }

  public reload(): void {
    window.location.href = '/recomendar-experto/' + this.fk;
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

}
