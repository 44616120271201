import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';

@Component({
  selector: 'app-respuesta-pendiente',
  templateUrl: './respuesta-pendiente.component.html',
  styleUrls: ['./respuesta-pendiente.component.css']
})
export class RespuestaPendienteComponent implements OnInit {

  public fk: number;
  public id: number;

  public jsonPreguntaHilo$: Observable<any[]>;
  public jsonEstados$: Observable<any>;
  public total = 0;
  public isBuscando = -1;
  public isMailContenido = false;
  public isContenidoIncorrecto = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
  ) { 
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      // this.fk = parseInt(params.fk);
      this.fk = parseInt(this.auth.obtener('fk'));

      console.log("fk experto autenticado", this.fk);
      
      this.id = parseInt(params.id);//Pregunta.id
      this.getContenido();
    });
  }

  public getContenido(): void {
    this.queryService.query_37(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonPreguntaHilo$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });

        this.jsonPreguntaHilo$.subscribe(response => {
          this.total = response.length;
        });

        

        this.isBuscando = 1;

        console.log(this.total,"total");
        console.log(response, "response");
        
        this.getEstados();
      }, error => {
        this.isBuscando = 0;        
      }
    );
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      /** Pregunta */
      id: [null], //this.id
      fkusuario: [null], //this.fkusuario
      /** Hilo */
      // fecha: [''],
      fecharespuesta: [null],
      fkexperto: [null], //this.fkexperto
      fkpregunta: [null], //this.id
      respuesta: [null, Validators.required],
      estado4: [null], //PENDIENTE
      estado5: [null], //PENDIENTE
      estado6: [null], //*PENDIENTE
      fechamaxotranotificacion: [null],
      fkrecibo: [null],
    });
  }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.valid) {
      
      const object = this.form.value;
      if(this.total == 1){
        object.estado1 = 'NO_PAGO';
        object.estado4 = 'NO_PAGO';
      }
      else{
        object.estado1 = 'RESUELTA';
        object.estado4 = 'RESUELTA';
      }
      // object.estado1 = 'NO_PAGO';
      object.estado2 = 'PENDIENTE';
      /** Hilo */
      if(object.estado3 == 'HILO'){
        /** Pregunta */
      object.estado1 = 'RESUELTA';
      object.estado2 = 'ENVIADO';
      
      
      object.estado4 = 'RESUELTA';
      

      }
      object.fkexperto = this.getFkexperto();
      object.fkpregunta = this.id;      
      object.estado5 = 'ACEPTADA';
      object.estado6 = 'PENDIENTE';
      this.isAccion = 0;

      if(object.estado3 == 'HILO'){
        /** Pregunta */
      object.estado1 = 'RESUELTA';
      object.estado2 = 'ENVIADO';
      
      object.estado4 = 'RESUELTA';
      

      }

      console.log(this.fk,"fk obtenido");
      
      this.queryService.query_38(object).subscribe(
        (response: any) => {
          // response 200
          this.isAccion = 1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos modificado tu ' + this._pregunta + ' ...',
            showConfirmButton: false,
            timer: 4000
          });

          setTimeout(function () {
            window.location.href = '/mi-experto/' + object.fkexperto;
          }, 4000);
        }, error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );
    }
  }

  public update(idHilo: number): void {
    const swal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary m-2',
        cancelButton: 'btn btn-secondary m-2'
      },
      buttonsStyling: false
    });

    swal.fire({
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, estoy seguro de rechazar',
      cancelButtonText: 'No, continuar con mi ' + this._hilo,
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
      // tslint:disable-next-line: no-shadowed-variable
    }).then((result) => {
      if (result.value) {
        const fk = this.fk;
        this.queryService.query_39(this.fk, idHilo, this.id).subscribe(
          (response: any) => {
            // response 200
            this.isAccion = 1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              title: 'Hemos modificado tu ' + this._pregunta + ' ...',
              showConfirmButton: false,
              timer: 4000
            });
            
            setTimeout(function () {
              window.location.href = '/mi-experto/' + fk;
            }, 4000);
          }, error => {
            // response 500
            this.isAccion = -1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
              showConfirmButton: false,
              timer: 5000
            });
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

  public getFkexperto(): number {
    return this.fk;
  }

  public getEstados(): void {
    console.log(this.fk, "fk estados");
    console.log(this.id, "id estados");
    
    this.queryService.query_40(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
          console.log(this.jsonEstados$ , "getEstados");
          
        });
      }, error => {
        console.log(error);
        
      }
    );
  }

  public onChangue(event: any): void {
    // console.log(event.editor.getText());
    var re = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/;
    if (re.test(event.editor.getText())) {
      this.isMailContenido = true;
    } else {
      this.isMailContenido = false;
    }

    if (event.editor.getText().toLowerCase().includes('llámame') ||
      event.editor.getText().toLowerCase().includes('llamame') ||
      event.editor.getText().toLowerCase().includes('contáctame') ||
      event.editor.getText().toLowerCase().includes('contacteme') ||
      event.editor.getText().toLowerCase().includes('mi número de contacto es') ||
      event.editor.getText().toLowerCase().includes('mi numero de contacto es') ||
      event.editor.getText().toLowerCase().includes('mi correo es') ||
      event.editor.getText().toLowerCase().includes('escríbeme al correo') ||
      event.editor.getText().toLowerCase().includes('escribeme al correo') ||
      event.editor.getText().toLowerCase().includes('mándeme un correo') ||
      event.editor.getText().toLowerCase().includes('mandeme un correo') ||
      event.editor.getText().toLowerCase().includes('escríbame al correo') ||
      event.editor.getText().toLowerCase().includes('escribame al correo') ||
      event.editor.getText().toLowerCase().includes('escríbame a') ||
      event.editor.getText().toLowerCase().includes('escribame a') ||
      event.editor.getText().toLowerCase().includes('mándeme a') ||
      event.editor.getText().toLowerCase().includes('mandame a') ||
      event.editor.getText().toLowerCase().includes('mándeme un correo') ||
      event.editor.getText().toLowerCase().includes('mandame un correo') ||
      event.editor.getText().toLowerCase().includes('mi número de teléfono') ||
      event.editor.getText().toLowerCase().includes('mi numero de teléfono') ||
      event.editor.getText().toLowerCase().includes('mi número de celular') ||
      event.editor.getText().toLowerCase().includes('mi numero de celular') ||
      event.editor.getText().toLowerCase().includes('mi celular es')) {
      this.isContenidoIncorrecto = true;
    } else {
      this.isContenidoIncorrecto = false;
    }
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

}