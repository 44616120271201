<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-experto></app-menu-experto>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">Notificación de {{_experto}}</h2>
    </div> -->
    <div class="container">
        <div class="animated fadeIn">
            <h2 class="titulo">NOTIFICACIÓN DE {{_experto.toUpperCase()}}</h2>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isBuscando === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="isBuscando === 1 && total > 0">
                            <strong class="mr-md-auto">Tienes {{total}} notificaciones.</strong>
                        </div>
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No tienes notificaciones</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                    </div>
                    <div *ngIf="total > 0">
                        <div role="alert" *ngFor="let notificacion of jsonNotificacion$ | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"
                            [attr.class]="notificacion.horas > 24 ? 'alert alert-secondary' : 'alert alert-primary'">
                            <a class="btn btn-sm btn-primary ml-3 float-right" style="width: 2rem;"
                                *ngIf="notificacion.horas <= 24 && notificacion.url"
                                [routerLink]="[notificacion.url]"><i class="fas fa-eye"></i></a>

                            <b><i class="fas fa-clock"></i> {{notificacion.fecha}}</b>
                            {{notificacion.texto}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3" *ngIf="(jsonNotificacion$ | async)?.length > 0 && isBuscando === 1">
                <div class="col-12 d-flex justify-content-center">
                    <ngb-pagination [collectionSize]="(jsonNotificacion$ | async)?.length" [(page)]="page"
                        [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</section>