<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <div class="container div-titulo">
        <h2 class="titulo">{{_hilo.toUpperCase()}}</h2>
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row mb-3">
                <div class="col-md-12">
                    <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal">
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CONTENIDO<span
                                            *ngIf="validator('contenido')">*</span></label>
                                    <div class="col-md-9">
                                        <quill-editor [styles]="{height: '250px'}"
                                            (onContentChanged)="onChangue($event)" formControlName="contenido">
                                        </quill-editor>
                                        <small>Contador de caracteres, {{ totalCaracteres }} <span
                                                class="text-danger">Maximo 500</span></small>
                                        <span class="help-block text-danger" *ngIf="isMailContenido">
                                            + No puedes incluir correos electronicos en el texto
                                        </span>
                                        <span class="help-block text-danger" *ngIf="isContenidoIncorrecto">
                                            + No puedes incluir esta palabra en el texto
                                        </span>
                                        <div *ngIf="form.get('contenido').errors && form.get('contenido').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="text-center" *ngFor="let item of jsonEstados$ | async; let index = index">
                                    <!-- <b>{{item.tipo}}</b> - {{item.estado1}} -
                                    <b>{{item.estado2}}</b> -
                                    {{item.estado3}} - {{item.estado4}} - {{item.estado5}} -
                                    <b>{{item.estado6}}</b> -
                                    Experto {{item.fkexperto}} - {{item.horas}} horas -
                                    {{item.horasRespuesta}} horas *Respuesta<br>
                                    Recibo {{item.estadoRecibo}} - Pregunta {{item.fkpregunta}} - Hilo {{item.id}}<br>{{parseInt(item.estrella)}}
                                    estrellas<br> -->
                                    <span *ngIf="isPendientePago === 0 && item.estado3 != 'HILO'" class="badge bg-secondary m-1"
                                        style="color: #fff!important;">Tienes pendiente un pago, debes resolverlo para
                                        continuar</span><br>
                                        
                                    <button type="submit" class="btn btn-outline-primary rounded-pill" *ngIf="
                                        isAccion === -1 &&
                                        item.estado1 === 'RESUELTA' &&
                                        item.estado4 === 'RESUELTA' &&
                                        item.estado5 === 'ACEPTADA' &&
                                        parseInt(item.fkexperto) > 0">
                                        <i class="fas fa-angle-double-right"></i> Enviar {{_hilo}}
                                    </button>
                                    
                                    
                                    
                                    
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>