import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { Experto } from './../shared/interface/experto';
import { Area } from './../shared/interface/area';
import { AreaService } from './../shared/service/area.service';
import { Rama } from './../shared/interface/rama';
import { Especialidad } from './../shared/interface/especialidad';
import { EncuestaService } from './../shared/service/encuesta.service';
import { Tarifa } from './../shared/interface/tarifa';
import { AnosExperienciaService } from './../shared/service/anos-experiencia.service';
import { AnosExperiencia } from './../shared/interface/anos-experiencia';
import { PreguntasService } from './../shared/service/preguntas.service';
import { Preguntas } from './../shared/interface/preguntas';

interface Opcion {
  posicion: number;
  texto: string;
}

@Component({
  selector: 'app-nueva-encuesta',
  templateUrl: './nueva-encuesta.component.html',
  styleUrls: ['./nueva-encuesta.component.css']
})
export class NuevaEncuestaComponent implements OnInit {

  public fk: number;
  // public fkexperto: number;
  public fkarea: number;
  public fkrama: number;
  public fkespecialidad: number;
  public tarifamin: number;
  public tarifamax: number;
  public anosmin: number;
  public anosmax: number;
  public totalCaracteres = 0;
  public totalExpertos: number;
  public isExpertos = false;
  private observacion: string;
  public isPendientePago: number;
  public isRamaTodos = false;

  public opciones: Opcion[] = [];

  public jsonAreas$: Observable<Area[]>;
  public jsonRamas$: Observable<Rama[]>;
  public jsonEspecialidads$: Observable<Especialidad[]>;
  public jsonTarifas$: Observable<Tarifa[]>;
  public jsonTarifas: Tarifa[];
  public jsonAnosExperiencias$: Observable<AnosExperiencia[]>;
  public jsonAnosExperiencias: AnosExperiencia[];
  public jsonExpertos$: Observable<Experto[]>;

  public jsonPreguntas$: Observable<Preguntas[]>;
  public isRama = false;
  public isEspecialidad = false;
  public isExperto = false;

  public isBuscandoExperto = false;
  public isIngresarPreguntas = false;
  public idEncuesta: any;

  public opcionBINARIA: Opcion[] = [{ posicion: 1, texto: 'SI' }, { posicion: 2, texto: 'NO' }];
  public opcionLINEAL: Opcion[] = [{ posicion: 1, texto: '1' },
  { posicion: 2, texto: '2' },
  { posicion: 3, texto: '3' },
  { posicion: 4, texto: '4' },
  { posicion: 5, texto: '5' }];

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form1: FormGroup;
  public form2: FormGroup;
  public isAccion1 = -1;
  public isAccion2 = -1;
  public isEstado = -1;
  public estado$: Observable<any>;

  public isSeleccionar = false;
  public isTodos = false;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private areaService: AreaService,
    private encuestaService: EncuestaService,
    private anosExperienciaService: AnosExperienciaService,
    private preguntasService: PreguntasService,
  ) {
    this.getAreas();
    this.getTarifas();
    this.getAnosExperiencias();

    /** OBLIGATORIO */
    this.buildForm1();
    this.buildForm2();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/mi-experto/' + params.fk + '/mensaje1';
          } else if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      if (params.fk) {
        // this.fk = parseInt(params.fk);
        this.fk = parseInt(this.auth.obtener('fk'));
      }

      if (params.rama === 'todos') {
        this.isSeleccionar = true;
        this.isTodos = true;

        // this.fkexperto = parseInt(params.experto);
        this.fkarea = parseInt(params.area);
        this.fkrama = 0;
        this.tarifamin = parseInt(params.tarifamin);
        this.tarifamax = parseInt(params.tarifamax);
        this.anosmin = parseInt(params.anosmin);
        this.anosmax = parseInt(params.anosmax);

        this.getExpertosTodos();

        this.form1.get('fkarea').setValue(params.area);
        this.form1.get('fkrama').setValue(0);
        this.form1.get('fkespecialidad').setValue(0);
        this.form1.get('tarifamin').setValue(params.tarifamin);
        this.form1.get('tarifamax').setValue(params.tarifamax);
        this.form1.get('anosmin').setValue(params.anosmin);
        this.form1.get('anosmax').setValue(params.anosmax);
      } else if (params.especialidad) {
        this.isSeleccionar = true;

        // this.fkexperto = parseInt(params.experto);
        this.fkarea = parseInt(params.area);
        this.fkrama = parseInt(params.rama);
        this.fkespecialidad = parseInt(params.especialidad);
        this.tarifamin = parseInt(params.tarifamin);
        this.tarifamax = parseInt(params.tarifamax);
        this.anosmin = parseInt(params.anosmin);
        this.anosmax = parseInt(params.anosmax);

        this.getExpertos();

        this.form1.get('fkarea').setValue(params.area);
        this.form1.get('fkrama').setValue(params.rama);
        this.form1.get('fkespecialidad').setValue(params.especialidad);
        this.form1.get('tarifamin').setValue(params.tarifamin);
        this.form1.get('tarifamax').setValue(params.tarifamax);
        this.form1.get('anosmin').setValue(params.anosmin);
        this.form1.get('anosmax').setValue(params.anosmax);
      }

      this.isEstado = 1;
    });

    this.queryService.query_69(this.fk).subscribe(
      (response: any) => {
        try {
          this.isPendientePago = response[0].id;
        } catch (error) {
          this.isPendientePago = 0;
        }
      },
      error => {
        this.isPendientePago = 0;
      }
    );
  }

  /** OBLIGATORIO */
  private buildForm1(): void {
    this.form1 = this.formBuilder.group({
      fecha: [''],
      tipo: [null],
      titulo: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      observacion: [null],
      respuestas: [null, Validators.required],
      fkarea: [null, Validators.required],
      fkrama: [null, Validators.required],
      fkespecialidad: [null, Validators.required],
      tarifamin: [null, Validators.required],
      tarifamax: [null, Validators.required],
      anosmin: [null, Validators.required],
      anosmax: [null, Validators.required],
      fkusuario: [null], //this.fk
      estado1: [null], //PENDIENTE
      estado2: [null], //*PENDIENTE
      fkrecibo: [null],
      correoelectronicotemp: [this.auth.obtener('correoelectronico'), [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }

  /** OBLIGATORIO */
  private buildForm2(): void {
    this.form2 = this.formBuilder.group({
      fecha: [''],
      fkencuesta: [this.idEncuesta],
      titulo: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      tipo: [null, Validators.required],
      Opciones_posicion: [1],
      Opciones_texto: ['', [Validators.minLength(1), Validators.maxLength(100)]],
      Opciones_cantidad: [null, [Validators.required, Validators.min(1), Validators.max(10)]],
    });
  }

  public validator1(control: string) {
    const validator = this.form1.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  public validator2(control: string) {
    const validator = this.form2.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  /** ADICIONALES */
  public addItemArray1(): void {
    const opcion: Opcion = { posicion: this.form2.get('Opciones_posicion').value, texto: this.form2.get('Opciones_texto').value };
    this.opciones.push({ ...opcion });
    this.form2.get('Opciones_posicion').setValue(this.opciones.length + 1);
    this.form2.get('Opciones_texto').setValue('');
    this.form2.get('Opciones_cantidad').setValue(this.opciones.length);
  }

  /** ADICIONALES */
  public removeItemArray1(item: any): void {
    const index = this.opciones.findIndex(({ posicion, texto }) => posicion === item.posicion && texto === item.texto);
    const aux = this.opciones.filter((_, i) => i !== index);
    this.opciones = aux;
    this.form2.get('Opciones_cantidad').setValue(this.opciones.length);
  }

  public getContenido(): void {
    this.queryService.query_54(this.fk, this.idEncuesta).subscribe(
      (response: any[]) => {
        this.jsonPreguntas$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });
      }, error => {
      }
    );
  }

  public refresh(): void {
    this.form1.get('fkarea').setValue(null);
    this.form1.get('fkrama').setValue(null);
    this.form1.get('fkespecialidad').setValue(null);

    this.fkarea = null;
    this.fkrama = null;
    this.fkespecialidad = null;
    this.isRama = false;
    this.isRamaTodos = false;
    this.isEspecialidad = false;
    this.isExperto = false;
    this.isBuscandoExperto = false;
    this.isIngresarPreguntas = false;

    this.jsonRamas$ = null;
    this.jsonEspecialidads$ = null;
    this.totalExpertos = null;
    this.isExpertos = false;
    this.jsonExpertos$ = null;
  }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form1.valid) {
      const object = this.form1.value;
      /** Encuesta */
      object.observacion = this.observacion;
      object.fkarea = this.form1.get('fkarea').value.split(':')[0];
      if (this.isTodos) {
        object.fkrama = 0;
        object.fkespecialidad = 0;
      } else {
        object.fkrama = this.form1.get('fkrama').value.split(':')[0];
        object.fkespecialidad = this.form1.get('fkespecialidad').value.split(':')[0];
      }
      object.fkusuario = this.getFkusuario();
      object.estado1 = 'PENDIENTE';
      object.estado2 = '_';
      object.tipo = 'ABIERTA';
      this.isAccion1 = 0;

      this.encuestaService.postAuth(object).subscribe(
        (response: any) => {
          // response 200
          this.isAccion1 = 1;
          /*** IMPORTANTE */
          this.idEncuesta = response.id;
          this.isIngresarPreguntas = true;

          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Creamos tu ' + this._encuesta + ', ahora debes ingresar tus preguntas...',
            showConfirmButton: false,
            timer: 4000
          });
          // setTimeout(function () {
          //   window.location.href = '/mi-usuario/' + object.fkusuario;
          // }, 4000);
        },
        error => {
          // response 500
          this.isAccion1 = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

    }
  }

  public savePreguntas(event: Event): void {
    event.preventDefault();
    // this.isAccion2 = 0;

    if (this.form2.valid) {
      const object = this.form2.value;
      object.opciones = this.opciones; /** ESPECIAL */
      object.fkusuario = this.fk; /** ESPECIAL */
      object.fkencuesta = this.idEncuesta;
      // console.log(object)
      // this.isAccion2 = 0;

      this.preguntasService.postAuth(object).subscribe(
        (response: any) => {
          // response 200
          // this.isAccion2 = 1;
          this.isAccion2 = -1;
          /*** IMPORTANTE */
          this.getContenido();

          this.opciones = [];
          this.form2.get('titulo').setValue('');
          this.form2.get('tipo').setValue(null);
          this.form2.get('Opciones_posicion').setValue(this.opciones.length + 1);
          this.form2.get('Opciones_texto').setValue('');
          this.form2.get('Opciones_cantidad').setValue(this.opciones.length);

          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Agregamos tu Pregunta, puedes continuar...',
            showConfirmButton: false,
            timer: 5000
          });
        },
        error => {
          // response 500
          this.isAccion2 = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );
      // } else {
      //   this.isAccion2 = -1;
    }
  }

  public deletePreguntas(id: number): void {
    const swal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary m-2',
        cancelButton: 'btn btn-secondary m-2'
      },
      buttonsStyling: false
    });

    swal.fire({
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, estoy seguro de eliminar',
      cancelButtonText: 'No, continuar con mi pregunta',
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
      // tslint:disable-next-line: no-shadowed-variable
    }).then((result) => {
      if (result.value) {
        this.isAccion2 = 0;

        this.queryService.query_55(this.fk, this.idEncuesta, id).subscribe(
          (response: any) => {
            // response 200
            // this.isAccion2 = 1;
            this.isAccion2 = -1;
            /*** IMPORTANTE */
            this.getContenido();

            this.opciones = [];
            this.form2.get('titulo').setValue('');
            this.form2.get('tipo').setValue(null);
            this.form2.get('Opciones_posicion').setValue(this.opciones.length + 1);
            this.form2.get('Opciones_texto').setValue('');
            this.form2.get('Opciones_cantidad').setValue(this.opciones.length);

            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              title: 'Eliminamos tu Pregunta, puedes continuar...',
              showConfirmButton: false,
              timer: 5000
            });
          },
          error => {
            // response 500
            this.isAccion2 = -1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
              showConfirmButton: false,
              timer: 5000
            });
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    });

  }

  public update(): void {
    this.isAccion2 = 0;
    this.queryService.query_56(this.fk, this.idEncuesta).subscribe(
      (response: any) => {
        // response 200
        const fk = this.fk;
        this.isAccion2 = 0;
        /*** IMPORTANTE */

        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        });

        swal.fire({
          imageUrl: this._urlTest + '/logo_128.png',
          imageHeight: 64,
          icon: 'success',
          title: 'Hemos enviado tu ' + this._encuesta + '...',
          showConfirmButton: false,
          timer: 4000
        });

        setTimeout(function () {
          window.location.href = '/mi-usuario/' + fk;
        }, 4000);
      },
      error => {
        // response 500
        this.isAccion2 = -1;
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
          showConfirmButton: false,
          timer: 5000
        });
      }
    );

  }

  public setTarifamax(tarifa: any): void {
    const value = tarifa.target.value.split(':')[1];

    this.jsonTarifas$ = new Observable((observer) => {
      // tslint:disable-next-line: radix
      observer.next(this.jsonTarifas.filter(option => option.valor >= parseInt(value)));
      observer.complete();
    });
  }

  public setAnosmax(anos: any): void {
    const value = anos.target.value.split(':')[1];

    this.jsonAnosExperiencias$ = new Observable((observer) => {
      // tslint:disable-next-line: radix
      observer.next(this.jsonAnosExperiencias.filter(option => parseInt(option.anos) >= parseInt(value)));
      observer.complete();
    });
  }

  public getFkusuario(): number {
    return this.fk;
  }

  public setTipo(): void {
    const tipo = this.form2.get('tipo').value;
    this.opciones = [];

    if (tipo.toString().match('BINARIA')) {
      this.opciones.push({ ...{ posicion: 1, texto: 'SI' } });
      this.opciones.push({ ...{ posicion: 2, texto: 'NO' } });
    } else if (tipo.toString().match('LINEAL')) {
      this.opciones.push({ ...{ posicion: 1, texto: '1' } });
      this.opciones.push({ ...{ posicion: 2, texto: '2' } });
      this.opciones.push({ ...{ posicion: 3, texto: '3' } });
      this.opciones.push({ ...{ posicion: 4, texto: '4' } });
      this.opciones.push({ ...{ posicion: 5, texto: '5' } });
    }

    if (tipo.toString().match('BINARIA') || tipo.toString().match('LINEAL')) {
      this.form2.get('Opciones_posicion').setValue(this.opciones.length + 1);
      this.form2.get('Opciones_texto').setValue('');
      this.form2.get('Opciones_cantidad').setValue(this.opciones.length);
    }
  }

  public onChangue(event: any): void {
    this.totalCaracteres = event.editor.getLength();
    if (this.totalCaracteres > 499) {
      event.editor.deleteText(499, event.editor.getLength());
    }
    this.observacion = event.editor.getText();
  }

  private getAreas(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas$ = new Observable((observer) => {
          observer.next(areas);
          observer.complete();
        });
      }
    );
  }

  public getRamas(area: any): void {
    this.isRama = true;
    const id = area.target.value.split(':')[1];
    const nombre = area.target.value.split(':')[2];
    this.fkarea = nombre;

    this.queryService.query_1(id).subscribe(
      (ramas: Rama[]) => {
        this.jsonRamas$ = new Observable((observer) => {
          observer.next(ramas);
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads(rama: any): void {
    if (rama.target.value.toString().match('Todos')) {
      this.isRamaTodos = true;
      this.isEspecialidad = false;
      this.fkrama = 0;
      this.isTodos = true;
      this.form1.get('fkespecialidad').setValue(0);
    } else {
      this.isRamaTodos = false;
      this.isEspecialidad = true;
      const id = rama.target.value.split(':')[1];
      const nombre = rama.target.value.split(':')[2];
      this.fkrama = nombre;

      this.queryService.query_2(id).subscribe(
        (especialidads: Especialidad[]) => {
          this.jsonEspecialidads$ = new Observable((observer) => {
            observer.next(especialidads);
            observer.complete();
          });
        }
      );
    }

  }

  public changueEspecialidads(especialidad: any): void {
    this.isExperto = true;
    const id = especialidad.target.value.split(':')[1];
    const nombre = especialidad.target.value.split(':')[2];
    this.fkespecialidad = nombre;
  }

  public getExpertos(): void {
    this.isBuscandoExperto = true;

    let criterio;
    let tarifamin;
    let tarifamax;
    let anosmin;
    let anosmax;

    if (!this.isSeleccionar) {
      criterio = this.form1.get('fkespecialidad').value.split(':')[0];
      tarifamin = this.form1.get('tarifamin').value;
      tarifamax = this.form1.get('tarifamax').value;
      anosmin = parseInt(this.form1.get('anosmin').value);
      anosmax = parseInt(this.form1.get('anosmax').value);
    } else {
      criterio = this.fkespecialidad;
      tarifamin = this.tarifamin;
      tarifamax = this.tarifamax;
      anosmin = this.anosmin;
      anosmax = this.anosmax;
    }

    this.queryService.query_51(criterio, tarifamin, tarifamax, anosmin, anosmax).subscribe(
      (expertos: Experto[]) => {
        this.isBuscandoExperto = false;
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.jsonExpertos$.subscribe(response => {
          this.totalExpertos = response.length;
          this.isExpertos = true;
          this.form1.get('respuestas').setValidators([Validators.required, Validators.min(1), Validators.max(this.totalExpertos)]);
          this.form1.get('respuestas').setValue(this.totalExpertos);
        });
      }
    );
  }

  public getExpertosTodos(): void {
    this.isBuscandoExperto = true;

    let criterio;
    let tarifamin;
    let tarifamax;
    let anosmin;
    let anosmax;

    criterio = this.fkarea;
    tarifamin = this.tarifamin;
    tarifamax = this.tarifamax;
    anosmin = this.anosmin;
    anosmax = this.anosmax;

    this.queryService.query_511(criterio, tarifamin, tarifamax, anosmin, anosmax).subscribe(
      (expertos: Experto[]) => {
        this.isBuscandoExperto = false;
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.jsonExpertos$.subscribe(response => {
          this.totalExpertos = response.length;
          this.isExpertos = true;
          this.form1.get('respuestas').setValidators([Validators.required, Validators.min(1), Validators.max(this.totalExpertos)]);
          this.form1.get('respuestas').setValue(this.totalExpertos);
        });
      }
    );
  }


  public getExpertosTodosAdicional(): void {
    this.isBuscandoExperto = true;

    let criterio;
    let tarifamin;
    let tarifamax;
    let anosmin;
    let anosmax;

    criterio = this.form1.get('fkarea').value.split(':')[0];
    tarifamin = this.form1.get('tarifamin').value;
    tarifamax = this.form1.get('tarifamax').value;
    anosmin = parseInt(this.form1.get('anosmin').value);
    anosmax = parseInt(this.form1.get('anosmax').value);

    this.queryService.query_511(criterio, tarifamin, tarifamax, anosmin, anosmax).subscribe(
      (expertos: Experto[]) => {
        this.isBuscandoExperto = false;
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.jsonExpertos$.subscribe(response => {
          this.totalExpertos = response.length;
          this.isExpertos = true;
          this.form1.get('respuestas').setValidators([Validators.required, Validators.min(1), Validators.max(this.totalExpertos)]);
          this.form1.get('respuestas').setValue(this.totalExpertos);
        });
      }
    );
  }

  public getTarifas(): void {
    this.queryService.query_53().subscribe(
      (tarifas: Tarifa[]) => {
        this.jsonTarifas = tarifas;
        this.jsonTarifas$ = new Observable((observer) => {
          observer.next(tarifas);
          observer.complete();
        });
      }
    );
  }

  public getAnosExperiencias(): void {
    this.anosExperienciaService.getAll().subscribe(
      (anosExperiencias: AnosExperiencia[]) => {
        this.jsonAnosExperiencias = anosExperiencias;
        this.jsonAnosExperiencias$ = new Observable((observer) => {
          observer.next(anosExperiencias);
          observer.complete();
        });
      }
    );
  }

  public reload(): void {
    window.location.href = '/nueva-encuesta/' + this.fk;
  }

}
