import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { RecomendarExperto } from './../interface/recomendar-experto';

@Injectable({
  providedIn: 'root'
})
export class RecomendarExpertoService {

  constructor(
    private http: HttpClient
  ) { }

  public post(object: RecomendarExperto): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/RecomendarExperto', object);
  }
}
