import { Component, OnInit } from '@angular/core';
import { AuthService } from './../shared/service/-auth.service';
import { environment } from './../../environments/environment';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { QueryService } from './../shared/service/-query.service';
import { ExpertoService } from './../shared/service/experto.service';
import { Experto } from './../shared/interface/experto';
import { Area } from './../shared/interface/area';
import { AreaService } from './../shared/service/area.service';
import { Rama } from './../shared/interface/rama';
import { RamaService } from './../shared/service/rama.service';
import { Especialidad } from './../shared/interface/especialidad';
import { EspecialidadService } from './../shared/service/especialidad.service';

interface Country {
  id: string;
  name: string;
}

interface State {
  id: string;
  id_country: string;
  name: string;
}

interface City {
  id: string;
  id_state: string;
  name: string;
}

interface Country {
  id: string;
  name: string;
}

interface State {
  id: string;
  id_country: string;
  name: string;
}

interface City {
  id: string;
  id_state: string;
  name: string;
}

@Component({
  selector: 'app-perfil-experto',
  templateUrl: './perfil-experto.component.html',
  styleUrls: ['./perfil-experto.component.css']
})
export class PerfilExpertoComponent implements OnInit {

  // public tipo: string;
  public fk: number;
  public imagenAux: string;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** ADICIONALES */
  public fileurlfotografia: File;
  public isFileurlfotografia = false;
  public loadingFileurlfotografia = false;
  private urlfotografiaTemp: string;

  public jsonPaises$: Observable<Country[]>;
  public jsonEstados$: Observable<State[]>;
  public jsonCiudades$: Observable<City[]>;
  public isPais = true;
  public isEstado = false;
  public isCiudad = false;

  public jsonAreas: Area[];
  public jsonRamas: Rama[];
  public jsonEspecialidads: Especialidad[];

  public jsonAreas1$: Observable<Area[]>;
  public jsonRamas1$: Observable<Rama[]>;
  public jsonEspecialidads1$: Observable<Especialidad[]>;
  public jsonEspecialidads1: Especialidad[];
  public fkarea1 = '';
  public isRama1 = false;
  public fkrama1 = '';
  public isEspecialidad1 = false;
  public isOtro1 = false;
  public contEspecilidad1 = 0;
  public isValoresGrupo1 = false;

  public jsonAreas2$: Observable<Area[]>;
  public jsonRamas2$: Observable<Rama[]>;
  public jsonEspecialidads2$: Observable<Especialidad[]>;
  public jsonEspecialidads2: Especialidad[];
  public fkarea2 = '';
  public isRama2 = false;
  public fkrama2 = '';
  public isEspecialidad2 = false;
  public isOtro2 = false;
  public contEspecilidad2 = 0;
  public isValoresGrupo2 = false;


  public fkarea1Aux; fkrama1Aux: any;
  public fkespecialidad11Aux; fkespecialidad12Aux; fkespecialidad13Aux; fkespecialidad14Aux; fkespecialidad15Aux: any;
  public otraespecialidad1Aux: any;
  public fkarea2Aux; fkrama2Aux: any;
  public fkespecialidad21Aux; fkespecialidad22Aux; fkespecialidad23Aux; fkespecialidad24Aux; fkespecialidad25Aux: any;
  public otraespecialidad2Aux: any;

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public hoy = new Date();

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;
  public isBuscando = -1;
  public total = 0;
  public totalCaracteres = 0;

  constructor(
    private expertoService: ExpertoService,
    private queryService: QueryService,
    // private especialidadService: EspecialidadService,
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService,
    private areaService: AreaService,
    private ramaService: RamaService,
    private especialidadService: EspecialidadService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
    this.getPaises();
    // this.getEspecialidads();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      this.fk = parseInt(this.auth.obtener('fk'));
      this.getAreas1();
      this.getAreas2();
      this.getInfoCompleta();
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      fecha: [''],
      nickname: [null],
      urlfotografia: [null, Validators.required],
      pais: [null, Validators.required],
      departamento: [null, Validators.required],
      ciudad: [null, Validators.required],
      direccion: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      codigopais: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(3), Validators.pattern('^[0-9 ]*$')]],
      celular: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(15), Validators.pattern('^[0-9 ]*$')]],
      correoelectronico: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      curriculum: [null, Validators.required],
      fkarea1: [null, Validators.required],
      fkrama1: [null, Validators.required],
      fkespecialidad11: [null, Validators.required],
      fkespecialidad12: [null],
      fkespecialidad13: [null],
      fkespecialidad14: [null],
      fkespecialidad15: [null],
      otraespecialidad1: [null],
      fkarea2: [null],
      fkrama2: [null],
      fkespecialidad21: [null],
      fkespecialidad22: [null],
      fkespecialidad23: [null],
      fkespecialidad24: [null],
      fkespecialidad25: [null],
      otraespecialidad2: [null],
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  private getPaises(): void {
    this.expertoService.getPais().subscribe(
      (paises: Country[]) => {
        this.jsonPaises$ = new Observable((observer) => {
          observer.next(paises);
          observer.complete();
        });
      }
    );
  }

  public getEstados(pais: any): void {
    this.isEstado = true;
    const id_country = pais.target.value.split(':')[2];
    this.expertoService.getEstado().subscribe(
      (estados: State[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          // tslint:disable-next-line: radix
          observer.next(estados.filter(option => parseInt('' + option.id_country) === parseInt('' + id_country)));
          observer.complete();
        });
      }
    );
  }

  public getCiudades(estado: any): void {
    this.isCiudad = true;
    const id_state = estado.target.value.split(':')[2];
    this.expertoService.getCiudad().subscribe(
      (ciudades: City[]) => {
        this.jsonCiudades$ = new Observable((observer) => {
          // tslint:disable-next-line: radix
          observer.next(ciudades.filter(option => parseInt('' + option.id_state) === parseInt('' + id_state)));
          observer.complete();
        });
      }
    );
  }

  private getInfoCompleta(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas = areas;

        this.ramaService.getAll().subscribe(
          (ramas: Rama[]) => {
            this.jsonRamas = ramas;

            this.especialidadService.getAll().subscribe(
              (especialidad: Especialidad[]) => {
                this.jsonEspecialidads = especialidad;

                this.getContenido();
              }
            );
          }
        );

      }
    );
  }

  private getAreas1(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas1$ = new Observable((observer) => {
          observer.next(areas);
          observer.complete();
        });
      }
    );
  }

  public getRamas1(area: any, isPatch: boolean): void {
    this.isRama1 = true;
    const id = area.target.value.split(':')[1];
    const nombre = area.target.value.split(':')[2];
    this.fkarea1 = nombre;

    this.queryService.query_1(id).subscribe(
      (ramas: Rama[]) => {
        this.jsonRamas1$ = new Observable((observer) => {
          observer.next(ramas);
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads1(rama: any): void {
    this.isEspecialidad1 = true;
    const id = rama.target.value.split(':')[1];
    const nombre = rama.target.value.split(':')[2];
    this.fkrama1 = nombre;
    this.queryService.query_2(id).subscribe(
      (especialidads: Especialidad[]) => {
        this.jsonEspecialidads1 = especialidads;

        if (!this.isEspecialidad2) {
          this.jsonEspecialidads1$ = new Observable((observer) => {
            observer.next(especialidads);
            observer.complete();
          });
        } else {
          let value1 = this.form.get('fkespecialidad21').value;
          let value2 = this.form.get('fkespecialidad22').value;
          let value3 = this.form.get('fkespecialidad23').value;
          let value4 = this.form.get('fkespecialidad24').value;
          let value5 = this.form.get('fkespecialidad25').value;

          let aux = this.jsonEspecialidads1.filter(option => option.id !== parseInt(value1));
          aux = aux.filter(option => option.id !== parseInt(value2));
          aux = aux.filter(option => option.id !== parseInt(value3));
          aux = aux.filter(option => option.id !== parseInt(value4));
          aux = aux.filter(option => option.id !== parseInt(value5));

          this.jsonEspecialidads1$ = new Observable((observer) => {
            // tslint:disable-next-line: radix
            observer.next(aux);
            observer.complete();
          });
        }
      }
    );
  }

  public changedEspecialidad1(especialidad: any): void {
    if (especialidad.target.value === 'otro') {
      this.isOtro1 = true;
      return;
    }

    if (especialidad.target.checked === true) {
      if (this.contEspecilidad1 < 5) {
        this.contEspecilidad1++;

        if (!this.form.get('fkespecialidad11').value) {
          this.form.get('fkespecialidad11').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad12').value) {
          this.form.get('fkespecialidad12').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad13').value) {
          this.form.get('fkespecialidad13').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad14').value) {
          this.form.get('fkespecialidad14').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad15').value) {
          this.form.get('fkespecialidad15').setValue(especialidad.target.value);
        }
      } else {
        especialidad.target.checked = false;
      }
    } else if (this.contEspecilidad1 > 0) {
      this.contEspecilidad1--;

      if (this.form.get('fkespecialidad15').value === especialidad.target.value) {
        this.form.get('fkespecialidad15').setValue(null);
      } else if (this.form.get('fkespecialidad14').value === especialidad.target.value) {
        this.form.get('fkespecialidad14').setValue(null);
      } else if (this.form.get('fkespecialidad13').value === especialidad.target.value) {
        this.form.get('fkespecialidad13').setValue(null);
      } else if (this.form.get('fkespecialidad12').value === especialidad.target.value) {
        this.form.get('fkespecialidad12').setValue(null);
      } else if (this.form.get('fkespecialidad11').value === especialidad.target.value) {
        this.form.get('fkespecialidad11').setValue(null);
      }
    }
  }

  public cancelarAreas1(): void {
    this.fkarea1 = '';
    this.isRama1 = false;
    this.fkrama1 = '';
    this.isEspecialidad1 = false;
    this.isOtro1 = false;
    this.contEspecilidad1 = 0;
    this.jsonRamas1$ = new Observable<Rama[]>();
    this.jsonEspecialidads1$ = new Observable<Especialidad[]>();
    this.form.get('fkarea1').setValue(null);
    this.form.get('fkrama1').setValue(null);
    this.form.get('fkespecialidad11').setValue(null);
    this.form.get('fkespecialidad12').setValue(null);
    this.form.get('fkespecialidad13').setValue(null);
    this.form.get('fkespecialidad14').setValue(null);
    this.form.get('fkespecialidad15').setValue(null);
    this.form.get('otraespecialidad1').setValue(null);
    this.isValoresGrupo1 = false;
  }

  private getAreas2(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas2$ = new Observable((observer) => {
          observer.next(areas);
          observer.complete();
        });
      }
    );
  }

  public getRamas2(area: any): void {
    this.form.get('fkarea2').setValidators(Validators.required);
    this.form.get('fkrama2').setValidators(Validators.required);
    this.form.get('fkespecialidad21').setValidators(Validators.required);

    this.isRama2 = true;
    const id = area.target.value.split(':')[1];
    const nombre = area.target.value.split(':')[2];
    this.fkarea2 = nombre;

    this.queryService.query_1(id).subscribe(
      (ramas: Rama[]) => {
        this.jsonRamas2$ = new Observable((observer) => {
          observer.next(ramas);
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads2(rama: any): void {
    this.isEspecialidad2 = true;
    const id = rama.target.value.split(':')[1];
    const nombre = rama.target.value.split(':')[2];
    this.fkrama2 = nombre;
    this.queryService.query_2(id).subscribe(
      (especialidads: Especialidad[]) => {
        this.jsonEspecialidads2 = especialidads;

        if (!this.isEspecialidad1) {
          this.jsonEspecialidads2$ = new Observable((observer) => {
            observer.next(especialidads);
            observer.complete();
          });
        } else {
          let value1 = this.form.get('fkespecialidad11').value;
          let value2 = this.form.get('fkespecialidad12').value;
          let value3 = this.form.get('fkespecialidad13').value;
          let value4 = this.form.get('fkespecialidad14').value;
          let value5 = this.form.get('fkespecialidad15').value;

          let aux = this.jsonEspecialidads2.filter(option => option.id !== parseInt(value1));
          aux = aux.filter(option => option.id !== parseInt(value2));
          aux = aux.filter(option => option.id !== parseInt(value3));
          aux = aux.filter(option => option.id !== parseInt(value4));
          aux = aux.filter(option => option.id !== parseInt(value5));

          this.jsonEspecialidads2$ = new Observable((observer) => {
            // tslint:disable-next-line: radix
            observer.next(aux);
            observer.complete();
          });
        }
      }
    );
  }

  public changedEspecialidad2(especialidad: any): void {
    if (especialidad.target.value === 'otro') {
      this.isOtro2 = true;
      // this.form.get('fkespecialidad21').setValue('0');
      // this.form.get('fkespecialidad22').setValue(null);
      // this.form.get('fkespecialidad23').setValue(null);
      // this.form.get('fkespecialidad24').setValue(null);
      // this.form.get('fkespecialidad25').setValue(null);
      return;
    }

    if (especialidad.target.checked === true) {
      if (this.contEspecilidad2 < 5) {
        this.contEspecilidad2++;

        if (!this.form.get('fkespecialidad21').value) {
          this.form.get('fkespecialidad21').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad22').value) {
          this.form.get('fkespecialidad22').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad23').value) {
          this.form.get('fkespecialidad23').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad24').value) {
          this.form.get('fkespecialidad24').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad25').value) {
          this.form.get('fkespecialidad25').setValue(especialidad.target.value);
        }
      } else {
        especialidad.target.checked = false;
      }
    } else if (this.contEspecilidad2 > 0) {
      this.contEspecilidad2--;

      if (this.form.get('fkespecialidad25').value === especialidad.target.value) {
        this.form.get('fkespecialidad25').setValue(null);
      } else if (this.form.get('fkespecialidad24').value === especialidad.target.value) {
        this.form.get('fkespecialidad24').setValue(null);
      } else if (this.form.get('fkespecialidad23').value === especialidad.target.value) {
        this.form.get('fkespecialidad23').setValue(null);
      } else if (this.form.get('fkespecialidad22').value === especialidad.target.value) {
        this.form.get('fkespecialidad22').setValue(null);
      } else if (this.form.get('fkespecialidad21').value === especialidad.target.value) {
        this.form.get('fkespecialidad21').setValue(null);
      }
    }
  }

  public cancelarAreas2(): void {
    this.fkarea2 = '';
    this.isRama2 = false;
    this.fkrama2 = '';
    this.isEspecialidad2 = false;
    this.isOtro2 = false;
    this.contEspecilidad2 = 0;
    this.jsonRamas2$ = new Observable<Rama[]>();
    this.jsonEspecialidads2$ = new Observable<Especialidad[]>();
    this.form.get('fkarea2').setValue(null);
    this.form.get('fkrama2').setValue(null);
    this.form.get('fkespecialidad21').setValue(null);
    this.form.get('fkespecialidad22').setValue(null);
    this.form.get('fkespecialidad23').setValue(null);
    this.form.get('fkespecialidad24').setValue(null);
    this.form.get('fkespecialidad25').setValue(null);
    this.form.get('otraespecialidad2').setValue(null);
    this.form.get('fkarea2').clearValidators();
    this.form.get('fkarea2').updateValueAndValidity();
    this.form.get('fkrama2').clearValidators();
    this.form.get('fkrama2').updateValueAndValidity();
    this.form.get('fkespecialidad21').clearValidators();
    this.form.get('fkespecialidad21').updateValueAndValidity();
    this.isValoresGrupo2 = false;
  }

  public getContenido(): void {
    this.expertoService.getOne(this.fk).subscribe(
      (response: Experto) => {
        try {
          this.form.patchValue(response);
          /** ADICIONALES */
          this.isBuscando = 1;
          this.total = 1;
          this.imagenAux = this.form.get('urlfotografia').value;
          // this.tipo = this.form.get('tipo').value;
          this.totalCaracteres = this.form.get('curriculum').value.length;

          try {
            if (this.form.get('fkarea1').value) {
              this.isValoresGrupo1 = true;
              let aux = this.jsonAreas.filter(option => option.id === parseInt(this.form.get('fkarea1').value));
              this.fkarea1Aux = aux[0].nombre;
            }
            if (this.form.get('fkarea2').value) {
              this.isValoresGrupo2 = true;
              let aux = this.jsonAreas.filter(option => option.id === parseInt(this.form.get('fkarea2').value));
              this.fkarea2Aux = aux[0].nombre;
            }
            if (this.form.get('fkrama1').value) {
              let aux = this.jsonRamas.filter(option => option.id === parseInt(this.form.get('fkrama1').value));
              this.fkrama1Aux = aux[0].nombre;
            }
            if (this.form.get('fkrama2').value) {
              let aux = this.jsonRamas.filter(option => option.id === parseInt(this.form.get('fkrama2').value));
              this.fkrama2Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad11').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad11').value));
              this.fkespecialidad11Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad12').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad12').value));
              this.fkespecialidad12Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad13').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad13').value));
              this.fkespecialidad13Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad14').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad14').value));
              this.fkespecialidad14Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad15').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad15').value));
              this.fkespecialidad15Aux = aux[0].nombre;
            }
            if (this.form.get('otraespecialidad1').value) {
              this.otraespecialidad1Aux = this.form.get('otraespecialidad1').value;
            }
            if (this.form.get('fkespecialidad21').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad21').value));
              this.fkespecialidad21Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad22').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad22').value));
              this.fkespecialidad22Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad23').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad23').value));
              this.fkespecialidad23Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad24').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad24').value));
              this.fkespecialidad24Aux = aux[0].nombre;
            }
            if (this.form.get('fkespecialidad25').value) {
              let aux = this.jsonEspecialidads.filter(option => option.id === parseInt(this.form.get('fkespecialidad25').value));
              this.fkespecialidad25Aux = aux[0].nombre;
            }
            if (this.form.get('otraespecialidad2').value) {
              this.otraespecialidad2Aux = this.form.get('otraespecialidad2').value;
            }
          } catch (error) {
          }
        } catch (error) {
          // console.log(error);
          this.isBuscando = 0;
        }
      }, error => {
        // console.log(error);
        this.isBuscando = 0;
      }
    );
  }

  /** OBLIGATORIO */
  public update(event: Event): void {
    event.preventDefault();

    const object: Experto = this.form.value;
    object.urlfotografia = this.urlfotografiaTemp;
    object.pais = this.form.get('pais').value.split(':')[0].trim();
    object.departamento = this.form.get('departamento').value.split(':')[0];
    try {
      object.fkarea1 = this.form.get('fkarea1').value.split(':')[0];
    } catch (error) {
    }
    try {
      object.fkrama1 = this.form.get('fkrama1').value.split(':')[0];
    } catch (error) {
    }
    try {
      object.fkarea2 = this.form.get('fkarea2').value.split(':')[0];
    } catch (error) {
    }
    try {
      object.fkrama2 = this.form.get('fkrama2').value.split(':')[0];
    } catch (error) {
    }

    this.isAccion = 0;

    this.expertoService.put(object).subscribe(
      (response: Experto) => {
        // response 200
        this.isAccion = 1;
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        });

        swal.fire({
          imageUrl: this._urlTest + '/logo_128.png',
          imageHeight: 64,
          icon: 'success',
          title: 'Hemos actualizado tu información ...',
          showConfirmButton: false,
          timer: 4000
        });
      },
      error => {
        // response 500
        this.isAccion = -1;
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Encontramos que tienes una cuenta asociada, intenta iniciar tu sesión',
          showConfirmButton: false,
          timer: 5000
        });
      }
    );
  }

  /** ADICIONALES */
  public onFileChanged(input: string, event: any): void {
    this.fileurlfotografia = event.target.files[0];
    this.loadingFileurlfotografia = true;
    this.onUpload('urlfotografia', 'experto_fotografia');
  }

  /** ADICIONALES */
  public onUpload(input: string, dir: string): void {
    const form = new FormData();
    // console.log('this.fileurlfotografia: ' + this.fileurlfotografia);
    // console.log('this.fileurlfotografia.name: ' + this.fileurlfotografia.name);
    // console.log('dir: ' + dir);
    form.append('imagen', this.fileurlfotografia, this.fileurlfotografia.name);
    form.append('dir', dir);

    this.expertoService.uploadImage(form).subscribe(
      (response: any) => {
        this.loadingFileurlfotografia = false;
        try {
          if (response.url) {
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              confirmButtonText: 'Exitoso, puedes continuar',
              showConfirmButton: true,
              timer: 5000
            });

            this.isFileurlfotografia = true;
            this.form.get(input).setValue(response.url);
            // this.urlfotografiaTemp = this._urlImage + '/' + this.fileurlfotografia.name;
            this.urlfotografiaTemp = response.url;
          } else {
            this.fileurlfotografia = null;

            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'ERROR al intentar cargar el archivo',
              showConfirmButton: false,
              timer: 5000
            });
          }
        } catch (error) {
          this.fileurlfotografia = null;

          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'ERROR al intentar cargar el archivo',
            showConfirmButton: false,
            timer: 5000
          });
        }
      }
    );
  }

  public refresh(): void {
    this.isPais = false;
  }

  public onChangue(event: any): void {
    this.totalCaracteres = event.editor.getLength();
    if (this.totalCaracteres > 499) {
      event.editor.deleteText(499, event.editor.getLength());
    }
  }
}