import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
// import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subject, merge, timer } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { Experto } from './../shared/interface/experto';
import { ExpertoService } from './../shared/service/experto.service';
import { Expertos } from './../shared/interface/expertos';
import { ExpertosService } from './../shared/service/expertos.service';
import { Area } from './../shared/interface/area';
import { AreaService } from './../shared/service/area.service';
import { Rama } from './../shared/interface/rama';
import { Especialidad } from './../shared/interface/especialidad';
import { Maestro } from './../shared/interface/maestro';
// import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// import { AppDateAdapter, APP_DATE_FORMATS } from './../shared/service/-format-datepicker.service';

// const states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado',
//   'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia',
//   'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
//   'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
//   'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
//   'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island',
//   'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia',
//   'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

interface Country {
  id: string;
  name: string;
}

interface State {
  id: string;
  id_country: string;
  name: string;
}

interface City {
  id: string;
  id_state: string;
  name: string;
}

@Component({
  selector: 'app-registro-experto',
  templateUrl: './registro-experto.component.html',
  styleUrls: ['./registro-experto.component.css'],
  // providers: [
  //   { provide: DateAdapter, useClass: AppDateAdapter },
  //   { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  // ]
})
export class RegistroExpertoComponent implements OnInit {

  // @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  // focus$ = new Subject<string>();
  // click$ = new Subject<string>();

  // minDate = new Date(2017, 0, 1);
  // public maxDate = new Date();
  public anoFechanacimiento: string;
  public mesFechanacimiento: string;
  public diaFechanacimiento: string;
  public anoFechainiciolaboral: string;
  public mesFechainiciolaboral: string;
  public diaFechainiciolaboral: string;
  public isVerificar = false;

  public empresa: string = null;
  public anoinicio: string = null;
  public anofin1: string = null;
  public cargo: string = null;
  public contExperiencialaboral = 0;
  public isExperiencialaboral = true;
  public totalCaracteres = 0;
  public pais = '';
  public actualExperiencialaboral = false;

  public pregrado: string = null;
  public jsonPregrados$: Observable<Maestro[]>;
  public jsonPregrados: any[] = [];
  public posgrado: string = null;
  public jsonPosgrados$: Observable<Maestro[]>;
  public jsonPosgrados: any[] = [];
  public anofin2: string = null;
  public anofin3: string = null;
  public universidad1: string = null;
  public universidad2: string = null;
  public contExperienciaacademica = 0;
  public isExperienciaacademica = true;
  public actualExperienciaacademica = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  public _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** ADICIONALES */
  public fileurlrut: File;
  public isFileurlrut = false;
  public loadingFileurlrut = false;
  private urlrutTemp: string;

  public fileurlfotografia: File;
  public isFileurlfotografia = false;
  public loadingFileurlfotografia = false;
  private urlfotografiaTemp: string;

  public jsonPaises$: Observable<Country[]>;
  public jsonEstados$: Observable<State[]>;
  public jsonCiudades$: Observable<City[]>;
  public isEstado = false;
  public isCiudad = false;

  public jsonAreas1$: Observable<Area[]>;
  // public jsonAreas1: Area[];
  public jsonRamas1$: Observable<Rama[]>;
  public jsonEspecialidads1$: Observable<Especialidad[]>;
  public jsonEspecialidads1: Especialidad[];
  public fkarea1 = '';
  public isRama1 = false;
  public fkrama1 = '';
  public isEspecialidad1 = false;
  public isOtro1 = false;
  public contEspecilidad1 = 0;

  public jsonAreas2$: Observable<Area[]>;
  // public jsonAreas2: Area[];
  public jsonRamas2$: Observable<Rama[]>;
  public jsonEspecialidads2$: Observable<Especialidad[]>;
  public jsonEspecialidads2: Especialidad[];
  public fkarea2 = '';
  public isRama2 = false;
  public fkrama2 = '';
  public isEspecialidad2 = false;
  public isOtro2 = false;
  public contEspecilidad2 = 0;

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public hoy = new Date();
  public ano = this.hoy.getFullYear();
  public mes = this.hoy.getMonth() + 1;
  public dia = this.hoy.getDate();
  // private fechanacimiento: Date;
  // private fechainiciolaboral: Date;

  public isParte1 = true;
  public isParte2 = false;
  public isParte3 = false;
  public isParte4 = false;
  public isParte5 = false;

  public tarifanuevaInicial = 0;
  public tarifahiloInicial = 0;
  public tarifaencuestaInicial = 0;
  public nivelInicial = 0;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    private expertoService: ExpertoService,
    private expertosService: ExpertosService,
    private queryService: QueryService,
    private areaService: AreaService,
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    // private dateAdapter: DateAdapter<Date>,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
    this.getPaises();
    this.getPregrado();
    this.getPosgrado();
    this.getAreas1();
    this.getAreas2();

    // this.dateAdapter.setLocale('es-CO');

    this.form.get('mesFechanacimiento').valueChanges.subscribe(
      (value: number) => {
        if (value === 2) {
          this.form.get('diaFechanacimiento').setValidators([Validators.required, Validators.min(1), Validators.max(29)]);
        } else if (value === 4 || value === 6 || value === 9 || value === 11) {
          this.form.get('diaFechanacimiento').setValidators([Validators.required, Validators.min(1), Validators.max(30)]);
        } else {
          this.form.get('diaFechanacimiento').setValidators([Validators.required, Validators.min(1), Validators.max(31)]);
        }
      }
    );

    this.form.get('mesFechainiciolaboral').valueChanges.subscribe(
      (value: number) => {
        if (value === 2) {
          this.form.get('diaFechainiciolaboral').setValidators([Validators.required, Validators.min(1), Validators.max(29)]);
        } else if (value === 4 || value === 6 || value === 9 || value === 11) {
          this.form.get('diaFechainiciolaboral').setValidators([Validators.required, Validators.min(1), Validators.max(30)]);
        } else {
          this.form.get('diaFechainiciolaboral').setValidators([Validators.required, Validators.min(1), Validators.max(31)]);
        }
      }
    );
  }

  ngOnInit(): void {
    this.isVerificar = false;
    this.getTarifanuevaInicial();
    this.getTarifahiloInicial();
    this.getTarifaencuestaInicial();
    this.getNivelInicial();
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      fecha: [''],
      nickname: [null],
      nombre1: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$')]],
      nombre2: [null, [Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$')]],
      apellido1: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$')]],
      apellido2: [null, [Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$')]],
      identificacion: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9-]*$')]],
      tipoidentificacion: [null, Validators.required],
      genero: [null, Validators.required],
      anoFechanacimiento: [null, [Validators.required, Validators.min(1900), Validators.max(this.ano)]],
      mesFechanacimiento: [null, [Validators.required, Validators.min(1), Validators.max(12)]],
      diaFechanacimiento: [null, [Validators.required, Validators.min(1), Validators.max(31)]],
      fechanacimiento: [null],
      anoFechainiciolaboral: [null, [Validators.required, Validators.min(1900), Validators.max(this.ano)]],
      mesFechainiciolaboral: [null, [Validators.required, Validators.min(1), Validators.max(12)]],
      diaFechainiciolaboral: [null, [Validators.required, Validators.min(1), Validators.max(31)]],
      fechainiciolaboral: [null],
      experiencialaboral1: [null, Validators.required],
      experiencialaboral2: [null],
      experiencialaboral3: [null],
      fktarifanueva: [null],
      fktarifahilo: [null],
      fktarifaencuesta: [null],
      fknivel: [null],
      destacado: [null],
      calificaciones: [null],
      urlrut: [null],
      urlfotografia: [null, Validators.required],
      banco: [null, Validators.required],
      numerocuenta: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      pais: [null, Validators.required],
      departamento: [null, Validators.required],
      ciudad: [null, Validators.required],
      direccion: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      codigopais: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(3), Validators.pattern('^[0-9]*$')]],
      celular: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(15), Validators.pattern('^[0-9]*$')]],
      correoelectronico: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      // Experto_contrasena: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10), Validators.pattern('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,10}$')]],
      Experto_contrasena: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!#%*?&•|°()=‘?¿¡´¨+_:.,;<>¬~`])([A-Za-z\d$@$!%*#?&+•|°()=‘?¿¡´¨_:.,;<>¬~`]|[^ ]){6,10}$/)]],
      Experto_confirmacontrasena: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      referencia1: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      correoreferencia1: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      isconfirmareferencia1: [null],
      referencia2: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      correoreferencia2: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      isconfirmareferencia2: [null],
      referencia3: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      correoreferencia3: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      isconfirmareferencia3: [null],
      experienciaacademica1: [null, Validators.required],
      experienciaacademica2: [null],
      experienciaacademica3: [null],
      experienciaacademica4: [null],
      experienciaacademica5: [null],
      curriculum: [null, Validators.required],
      fkarea1: [null, Validators.required],
      fkrama1: [null, Validators.required],
      fkespecialidad11: [null, Validators.required],
      fkespecialidad12: [null],
      fkespecialidad13: [null],
      fkespecialidad14: [null],
      fkespecialidad15: [null],
      otraespecialidad1: [null],
      fkarea2: [null],
      fkrama2: [null],
      fkespecialidad21: [null],
      fkespecialidad22: [null],
      fkespecialidad23: [null],
      fkespecialidad24: [null],
      fkespecialidad25: [null],
      otraespecialidad2: [null],
      isconfirma: [null],
      tokenconfirma: [null],
      tokenreferencia: [null],
      estadoconfirma: [null],
      isrecupera: [null],
      tokenrecupera: [null],
      estadorecupera: [null],
      estado: [null],
      urllinkedin: [null],
      acepta: [null, Validators.requiredTrue], //---------------------------------------------------
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  public validar1() {
    if (this.form.get('nombre1').valid &&
      this.form.get('apellido1').valid &&
      this.form.get('identificacion').valid &&
      this.form.get('tipoidentificacion').valid &&
      this.form.get('genero').valid &&
      this.form.get('anoFechanacimiento').valid &&
      this.form.get('mesFechanacimiento').valid &&
      this.form.get('diaFechanacimiento').valid &&
      // this.form.get('fechanacimiento').valid &&
      this.form.get('anoFechainiciolaboral').valid &&
      this.form.get('mesFechainiciolaboral').valid &&
      this.form.get('diaFechainiciolaboral').valid &&
      // this.form.get('fechainiciolaboral').valid &&
      this.form.get('urlfotografia').valid) {
      return true;
    } else {
      return false;
    }
  }

  public validar2() {
    if (this.form.get('banco').valid &&
      this.form.get('numerocuenta').valid &&
      this.form.get('pais').valid &&
      this.form.get('departamento').valid &&
      this.form.get('ciudad').valid &&
      this.form.get('direccion').valid &&
      this.form.get('codigopais').valid &&
      this.form.get('celular').valid &&
      this.form.get('correoelectronico').valid &&
      this.form.get('Experto_contrasena').valid &&
      this.form.get('Experto_confirmacontrasena').valid) {
      return true;
    } else {
      return false;
    }
  }

  public validar3() {
    if (this.form.get('referencia1').valid &&
      this.form.get('correoreferencia1').valid &&
      this.form.get('referencia2').valid &&
      this.form.get('correoreferencia2').valid &&
      this.form.get('referencia3').valid &&
      this.form.get('correoreferencia3').valid &&
      (this.form.get('correoreferencia1').value !== this.form.get('correoelectronico').value) &&
      (this.form.get('correoreferencia2').value !== this.form.get('correoelectronico').value) &&
      (this.form.get('correoreferencia3').value !== this.form.get('correoelectronico').value) &&
      (this.form.get('correoreferencia1').value !== this.form.get('correoreferencia2').value) &&
      (this.form.get('correoreferencia1').value !== this.form.get('correoreferencia3').value) &&
      (this.form.get('correoreferencia2').value !== this.form.get('correoreferencia1').value) &&
      (this.form.get('correoreferencia2').value !== this.form.get('correoreferencia3').value)) {
      return true;
    } else {
      return false;
    }
  }

  public validar4() {
    if (this.form.get('experiencialaboral1').valid &&
      this.form.get('experienciaacademica1').valid &&
      this.form.get('curriculum').valid) {
      return true;
    } else {
      return false;
    }
  }


  get getNombre1(): string {
    if (this.form.get('nombre1').value) {
      if (this.form.get('nombre1').value.includes(' ')) {
        return this.form.get('nombre1').value.toUpperCase().trim().split(' ')[0];
      } else {
        return this.form.get('nombre1').value.toUpperCase().trim();
      }
    } else {
      return '';
    }
  }

  get getApellido1(): string {
    if (this.form.get('apellido1').value) {
      return ' ' + this.form.get('apellido1').value.toUpperCase().trim().substring(0, 1) + '.';
    } else {
      return '';
    }
  }
  get getApellido2(): string {
    if (this.form.get('apellido2').value) {
      return ' ' + this.form.get('apellido2').value.toUpperCase().trim().substring(0, 1) + '.';
    } else {
      return '';
    }
  }

  private getPaises(): void {
    this.expertoService.getPais().subscribe(
      (paises: Country[]) => {
        this.jsonPaises$ = new Observable((observer) => {
          observer.next(paises);
          observer.complete();
        });
      }
    );
  }

  public getEstados(pais: any): void {
    this.isEstado = true;
    const id_country = pais.target.value.split(':')[2];
    this.pais = pais.target.value.split(':')[1];

    if (pais.target.value.split(':')[1].trim() === 'Colombia') {
      this.form.get('banco').setValue('DaviPlata');
    } else {
      this.form.get('banco').setValue('PayPal');
    }

    this.expertoService.getEstado().subscribe(
      (estados: State[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          // tslint:disable-next-line: radix
          observer.next(estados.filter(option => parseInt('' + option.id_country) === parseInt('' + id_country)));
          observer.complete();
        });
      }
    );
  }

  public getCiudades(estado: any): void {
    this.isCiudad = true;
    const id_state = estado.target.value.split(':')[2];
    this.expertoService.getCiudad().subscribe(
      (ciudades: City[]) => {
        this.jsonCiudades$ = new Observable((observer) => {
          // tslint:disable-next-line: radix
          observer.next(ciudades.filter(option => parseInt('' + option.id_state) === parseInt('' + id_state)));
          observer.complete();
        });
      }
    );
  }

  public addExperiencialaboral(): void {
    try {
      if (parseInt(this.anoinicio) < 1000 || parseInt(this.anoinicio) > this.ano ||
        parseInt(this.anofin1) < 1000 || parseInt(this.anofin1) > this.ano ||
        parseInt(this.anoinicio) > parseInt(this.anofin1)) {
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Encontramos un error en la información ingresada',
          showConfirmButton: false,
          timer: 5000
        });
      } else if (this.contExperiencialaboral < 3 && this.empresa != null
        && this.anoinicio != null && this.anofin1 != null && this.cargo != null) {
        this.contExperiencialaboral++;

        if (this.actualExperiencialaboral) {
          if (!this.form.get('experiencialaboral1').value) {
            this.form.get('experiencialaboral1').setValue(this.empresa + ':' + this.anoinicio + ':ACTUALMENTE:' + this.cargo);
          } else if (!this.form.get('experiencialaboral2').value) {
            this.form.get('experiencialaboral2').setValue(this.empresa + ':' + this.anoinicio + ':ACTUALMENTE:' + this.cargo);
          } else if (!this.form.get('experiencialaboral3').value) {
            this.form.get('experiencialaboral3').setValue(this.empresa + ':' + this.anoinicio + ':ACTUALMENTE:' + this.cargo);
          }
        } else {
          if (!this.form.get('experiencialaboral1').value) {
            this.form.get('experiencialaboral1').setValue(this.empresa + ':' + this.anoinicio + ':' + this.anofin1 + ':' + this.cargo);
          } else if (!this.form.get('experiencialaboral2').value) {
            this.form.get('experiencialaboral2').setValue(this.empresa + ':' + this.anoinicio + ':' + this.anofin1 + ':' + this.cargo);
          } else if (!this.form.get('experiencialaboral3').value) {
            this.form.get('experiencialaboral3').setValue(this.empresa + ':' + this.anoinicio + ':' + this.anofin1 + ':' + this.cargo);
          }
        }

        this.empresa = null;
        this.anoinicio = null;
        this.anofin1 = null;
        this.cargo = null;
        this.actualExperiencialaboral = false;
      } else {
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Falta información por completar',
          showConfirmButton: false,
          timer: 5000
        });
      }

      if (this.contExperiencialaboral === 3) {
        this.isExperiencialaboral = false;
      }
    } catch (error) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error'
        },
        buttonsStyling: false
      });

      swal.fire({
        // imageUrl: this._urlTest + '/logo_128.png',
        // imageHeight: 64,
        icon: 'error',
        title: 'Falta información por completar',
        showConfirmButton: false,
        timer: 5000
      });
    }
  }

  public removeExperiencialaboral1(): void {
    this.form.get('experiencialaboral1').setValue(null);
    this.contExperiencialaboral--;
    this.isExperiencialaboral = true;
  }

  public removeExperiencialaboral2(): void {
    this.form.get('experiencialaboral2').setValue(null);
    this.contExperiencialaboral--;
    this.isExperiencialaboral = true;
  }

  public removeExperiencialaboral3(): void {
    this.form.get('experiencialaboral3').setValue(null);
    this.contExperiencialaboral--;
    this.isExperiencialaboral = true;
  }

  private getPregrado(): void {
    this.queryService.query_121('pregrado').subscribe(
      (maestros: Maestro[]) => {
        for (let item of maestros) {
          this.jsonPregrados.push(item.texto);
        }
        this.jsonPregrados$ = new Observable((observer) => {
          observer.next(maestros);
          observer.complete();
        });
      }
    );
  }

  private getPosgrado(): void {
    this.queryService.query_121('posgrado').subscribe(
      (maestros: Maestro[]) => {
        for (let item of maestros) {
          this.jsonPosgrados.push(item.texto);
        }
        this.jsonPosgrados$ = new Observable((observer) => {
          observer.next(maestros);
          observer.complete();
        });
      }
    );
  }

  public addExperienciaacademicaPregrado(): void {
    try {
      if (parseInt(this.anofin2) < 1000 || parseInt(this.anofin2) > this.ano) {
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Encontramos un error en la información ingresada',
          showConfirmButton: false,
          timer: 5000
        });
      } else if (this.contExperienciaacademica < 5 && this.pregrado != null && this.anofin2 != null && this.universidad1 != null) {
        this.contExperienciaacademica++;

        if (this.actualExperienciaacademica) {
          if (!this.form.get('experienciaacademica1').value) {
            this.form.get('experienciaacademica1').setValue(this.pregrado + ':ACTUALMENTE:' + this.universidad1);
          } else if (!this.form.get('experienciaacademica2').value) {
            this.form.get('experienciaacademica2').setValue(this.pregrado + ':ACTUALMENTE:' + this.universidad1);
          } else if (!this.form.get('experienciaacademica3').value) {
            this.form.get('experienciaacademica3').setValue(this.pregrado + ':ACTUALMENTE:' + this.universidad1);
          } else if (!this.form.get('experienciaacademica4').value) {
            this.form.get('experienciaacademica4').setValue(this.pregrado + ':ACTUALMENTE:' + this.universidad1);
          } else if (!this.form.get('experienciaacademica5').value) {
            this.form.get('experienciaacademica5').setValue(this.pregrado + ':ACTUALMENTE:' + this.universidad1);
          }
        } else {

          if (!this.form.get('experienciaacademica1').value) {
            this.form.get('experienciaacademica1').setValue(this.pregrado + ':' + this.anofin2 + ':' + this.universidad1);
          } else if (!this.form.get('experienciaacademica2').value) {
            this.form.get('experienciaacademica2').setValue(this.pregrado + ':' + this.anofin2 + ':' + this.universidad1);
          } else if (!this.form.get('experienciaacademica3').value) {
            this.form.get('experienciaacademica3').setValue(this.pregrado + ':' + this.anofin2 + ':' + this.universidad1);
          } else if (!this.form.get('experienciaacademica4').value) {
            this.form.get('experienciaacademica4').setValue(this.pregrado + ':' + this.anofin2 + ':' + this.universidad1);
          } else if (!this.form.get('experienciaacademica5').value) {
            this.form.get('experienciaacademica5').setValue(this.pregrado + ':' + this.anofin2 + ':' + this.universidad1);
          }
        }

        this.pregrado = null;
        this.anofin2 = null;
        this.universidad1 = null;
        this.actualExperienciaacademica = false;
      } else {
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Falta información por completar',
          showConfirmButton: false,
          timer: 5000
        });
      }

      if (this.contExperienciaacademica === 5) {
        this.isExperienciaacademica = false;
      }
    } catch (error) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error'
        },
        buttonsStyling: false
      });

      swal.fire({
        // imageUrl: this._urlTest + '/logo_128.png',
        // imageHeight: 64,
        icon: 'error',
        title: 'Falta información por completar',
        showConfirmButton: false,
        timer: 5000
      });
    }
  }

  public addExperienciaacademicaPosgrado(): void {
    try {
      if (parseInt(this.anofin3) < 1000 || parseInt(this.anofin3) > this.ano) {
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Encontramos un error en la información ingresada',
          showConfirmButton: false,
          timer: 5000
        });
      } else if (this.contExperienciaacademica < 5 && this.posgrado != null && this.anofin3 != null && this.universidad2 != null) {
        this.contExperienciaacademica++;

        if (this.actualExperienciaacademica) {
          if (!this.form.get('experienciaacademica1').value) {
            this.form.get('experienciaacademica1').setValue(this.posgrado + ':ACTUALMENTE:' + this.universidad2);
          } else if (!this.form.get('experienciaacademica2').value) {
            this.form.get('experienciaacademica2').setValue(this.posgrado + ':ACTUALMENTE:' + this.universidad2);
          } else if (!this.form.get('experienciaacademica3').value) {
            this.form.get('experienciaacademica3').setValue(this.posgrado + ':ACTUALMENTE:' + this.universidad2);
          } else if (!this.form.get('experienciaacademica4').value) {
            this.form.get('experienciaacademica4').setValue(this.posgrado + ':ACTUALMENTE:' + this.universidad2);
          } else if (!this.form.get('experienciaacademica5').value) {
            this.form.get('experienciaacademica5').setValue(this.posgrado + ':ACTUALMENTE:' + this.universidad2);
          }
        } else {
          if (!this.form.get('experienciaacademica1').value) {
            this.form.get('experienciaacademica1').setValue(this.posgrado + ':' + this.anofin3 + ':' + this.universidad2);
          } else if (!this.form.get('experienciaacademica2').value) {
            this.form.get('experienciaacademica2').setValue(this.posgrado + ':' + this.anofin3 + ':' + this.universidad2);
          } else if (!this.form.get('experienciaacademica3').value) {
            this.form.get('experienciaacademica3').setValue(this.posgrado + ':' + this.anofin3 + ':' + this.universidad2);
          } else if (!this.form.get('experienciaacademica4').value) {
            this.form.get('experienciaacademica4').setValue(this.posgrado + ':' + this.anofin3 + ':' + this.universidad2);
          } else if (!this.form.get('experienciaacademica5').value) {
            this.form.get('experienciaacademica5').setValue(this.posgrado + ':' + this.anofin3 + ':' + this.universidad2);
          }
        }

        this.posgrado = null;
        this.anofin3 = null;
        this.universidad2 = null;
        this.actualExperienciaacademica = false;
      } else {
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Falta información por completar',
          showConfirmButton: false,
          timer: 5000
        });
      }

      if (this.contExperienciaacademica === 5) {
        this.isExperienciaacademica = false;
      }
    } catch (error) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error'
        },
        buttonsStyling: false
      });

      swal.fire({
        // imageUrl: this._urlTest + '/logo_128.png',
        // imageHeight: 64,
        icon: 'error',
        title: 'Falta información por completar',
        showConfirmButton: false,
        timer: 5000
      });
    }
  }

  public removeExperienciaacademica1(): void {
    this.form.get('experienciaacademica1').setValue(null);
    this.contExperienciaacademica--;
    this.isExperienciaacademica = true;
  }

  public removeExperienciaacademica2(): void {
    this.form.get('experienciaacademica2').setValue(null);
    this.contExperienciaacademica--;
    this.isExperienciaacademica = true;
  }

  public removeExperienciaacademica3(): void {
    this.form.get('experienciaacademica3').setValue(null);
    this.contExperienciaacademica--;
    this.isExperienciaacademica = true;
  }

  public removeExperienciaacademica4(): void {
    this.form.get('experienciaacademica4').setValue(null);
    this.contExperienciaacademica--;
    this.isExperienciaacademica = true;
  }

  public removeExperienciaacademica5(): void {
    this.form.get('experienciaacademica5').setValue(null);
    this.contExperienciaacademica--;
    this.isExperienciaacademica = true;
  }

  private getAreas1(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas1$ = new Observable((observer) => {
          observer.next(areas);
          observer.complete();
        });

        // this.jsonAreas1 = areas;
      }
    );
  }

  public getRamas1(area: any): void {
    this.isRama1 = true;
    const id = area.target.value.split(':')[1];
    const nombre = area.target.value.split(':')[2];
    this.fkarea1 = nombre;

    // if (!this.isRama2) {
    //   this.jsonAreas2$ = new Observable((observer) => {
    //     // tslint:disable-next-line: radix
    //     observer.next(this.jsonAreas2.filter(option => option.nombre !== nombre));
    //     observer.complete();
    //   });
    // }

    this.queryService.query_1(id).subscribe(
      (ramas: Rama[]) => {
        this.jsonRamas1$ = new Observable((observer) => {
          observer.next(ramas);
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads1(rama: any): void {
    this.isEspecialidad1 = true;
    const id = rama.target.value.split(':')[1];
    const nombre = rama.target.value.split(':')[2];
    this.fkrama1 = nombre;
    this.queryService.query_2(id).subscribe(
      (especialidads: Especialidad[]) => {
        this.jsonEspecialidads1 = especialidads;

        if (!this.isEspecialidad2) {
          this.jsonEspecialidads1$ = new Observable((observer) => {
            observer.next(especialidads);
            observer.complete();
          });
        } else {
          let value1 = this.form.get('fkespecialidad21').value;
          let value2 = this.form.get('fkespecialidad22').value;
          let value3 = this.form.get('fkespecialidad23').value;
          let value4 = this.form.get('fkespecialidad24').value;
          let value5 = this.form.get('fkespecialidad25').value;

          let aux = this.jsonEspecialidads1.filter(option => option.id !== parseInt(value1));
          aux = aux.filter(option => option.id !== parseInt(value2));
          aux = aux.filter(option => option.id !== parseInt(value3));
          aux = aux.filter(option => option.id !== parseInt(value4));
          aux = aux.filter(option => option.id !== parseInt(value5));

          this.jsonEspecialidads1$ = new Observable((observer) => {
            // tslint:disable-next-line: radix
            observer.next(aux);
            observer.complete();
          });
        }
      }
    );
  }

  public changedEspecialidad1(especialidad: any): void {
    if (especialidad.target.value === 'otro') {
      this.isOtro1 = true;
      // this.form.get('fkespecialidad11').setValue('0');
      // this.form.get('fkespecialidad12').setValue(null);
      // this.form.get('fkespecialidad13').setValue(null);
      // this.form.get('fkespecialidad14').setValue(null);
      // this.form.get('fkespecialidad15').setValue(null);
      return;
    }

    if (especialidad.target.checked === true) {
      if (this.contEspecilidad1 < 5) {
        this.contEspecilidad1++;

        if (!this.form.get('fkespecialidad11').value) {
          this.form.get('fkespecialidad11').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad12').value) {
          this.form.get('fkespecialidad12').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad13').value) {
          this.form.get('fkespecialidad13').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad14').value) {
          this.form.get('fkespecialidad14').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad15').value) {
          this.form.get('fkespecialidad15').setValue(especialidad.target.value);
        }
      } else {
        especialidad.target.checked = false;
      }
    } else if (this.contEspecilidad1 > 0) {
      this.contEspecilidad1--;

      if (this.form.get('fkespecialidad15').value === especialidad.target.value) {
        this.form.get('fkespecialidad15').setValue(null);
      } else if (this.form.get('fkespecialidad14').value === especialidad.target.value) {
        this.form.get('fkespecialidad14').setValue(null);
      } else if (this.form.get('fkespecialidad13').value === especialidad.target.value) {
        this.form.get('fkespecialidad13').setValue(null);
      } else if (this.form.get('fkespecialidad12').value === especialidad.target.value) {
        this.form.get('fkespecialidad12').setValue(null);
      } else if (this.form.get('fkespecialidad11').value === especialidad.target.value) {
        this.form.get('fkespecialidad11').setValue(null);
      }
    }

    // if (this.isEspecialidad2) {
    //   let value1 = this.form.get('fkespecialidad21').value;
    //   let value2 = this.form.get('fkespecialidad22').value;
    //   let value3 = this.form.get('fkespecialidad23').value;
    //   let value4 = this.form.get('fkespecialidad24').value;
    //   let value5 = this.form.get('fkespecialidad25').value;

    //   let aux = this.jsonEspecialidads1.filter(option => option.id !== value1);
    //   aux = aux.filter(option => option.id !== value2);
    //   aux = aux.filter(option => option.id !== value3);
    //   aux = aux.filter(option => option.id !== value4);
    //   aux = aux.filter(option => option.id !== value5);

    //   console.log(aux);

    //   this.jsonEspecialidads1$ = new Observable((observer) => {
    //     // tslint:disable-next-line: radix
    //     observer.next(aux);
    //     observer.complete();
    //   });
    // }
  }

  public cancelarAreas1(): void {
    this.fkarea1 = '';
    this.isRama1 = false;
    this.fkrama1 = '';
    this.isEspecialidad1 = false;
    this.isOtro1 = false;
    this.contEspecilidad1 = 0;
    this.jsonRamas1$ = new Observable<Rama[]>();
    this.jsonEspecialidads1$ = new Observable<Especialidad[]>();
    this.form.get('fkarea1').setValue(null);
    this.form.get('fkrama1').setValue(null);
    this.form.get('fkespecialidad11').setValue(null);
    this.form.get('fkespecialidad12').setValue(null);
    this.form.get('fkespecialidad13').setValue(null);
    this.form.get('fkespecialidad14').setValue(null);
    this.form.get('fkespecialidad15').setValue(null);
    this.form.get('otraespecialidad1').setValue(null);
  }

  private getAreas2(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas2$ = new Observable((observer) => {
          observer.next(areas);
          observer.complete();
        });

        // this.jsonAreas2 = areas;
      }
    );
  }

  public getRamas2(area: any): void {

    this.form.get('fkarea2').setValidators(Validators.required);
    this.form.get('fkrama2').setValidators(Validators.required);
    this.form.get('fkespecialidad21').setValidators(Validators.required);

    this.isRama2 = true;
    const id = area.target.value.split(':')[1];
    const nombre = area.target.value.split(':')[2];
    this.fkarea2 = nombre;

    // if (!this.isRama1) {
    //   this.jsonAreas1$ = new Observable((observer) => {
    //     // tslint:disable-next-line: radix
    //     observer.next(this.jsonAreas1.filter(option => option.nombre !== nombre));
    //     observer.complete();
    //   });
    // }

    this.queryService.query_1(id).subscribe(
      (ramas: Rama[]) => {
        this.jsonRamas2$ = new Observable((observer) => {
          observer.next(ramas);
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads2(rama: any): void {
    this.isEspecialidad2 = true;
    const id = rama.target.value.split(':')[1];
    const nombre = rama.target.value.split(':')[2];
    this.fkrama2 = nombre;
    this.queryService.query_2(id).subscribe(
      (especialidads: Especialidad[]) => {
        this.jsonEspecialidads2 = especialidads;

        if (!this.isEspecialidad1) {
          this.jsonEspecialidads2$ = new Observable((observer) => {
            observer.next(especialidads);
            observer.complete();
          });
        } else {
          let value1 = this.form.get('fkespecialidad11').value;
          let value2 = this.form.get('fkespecialidad12').value;
          let value3 = this.form.get('fkespecialidad13').value;
          let value4 = this.form.get('fkespecialidad14').value;
          let value5 = this.form.get('fkespecialidad15').value;
          // console.log("value1: " + value1);

          let aux = this.jsonEspecialidads2.filter(option => option.id !== parseInt(value1));
          aux = aux.filter(option => option.id !== parseInt(value2));
          aux = aux.filter(option => option.id !== parseInt(value3));
          aux = aux.filter(option => option.id !== parseInt(value4));
          aux = aux.filter(option => option.id !== parseInt(value5));

          // console.log(aux);

          this.jsonEspecialidads2$ = new Observable((observer) => {
            // tslint:disable-next-line: radix
            observer.next(aux);
            observer.complete();
          });
        }
      }
    );
  }

  public changedEspecialidad2(especialidad: any): void {
    if (especialidad.target.value === 'otro') {
      this.isOtro2 = true;
      // this.form.get('fkespecialidad21').setValue('0');
      // this.form.get('fkespecialidad22').setValue(null);
      // this.form.get('fkespecialidad23').setValue(null);
      // this.form.get('fkespecialidad24').setValue(null);
      // this.form.get('fkespecialidad25').setValue(null);
      return;
    }

    if (especialidad.target.checked === true) {
      if (this.contEspecilidad2 < 5) {
        this.contEspecilidad2++;

        if (!this.form.get('fkespecialidad21').value) {
          this.form.get('fkespecialidad21').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad22').value) {
          this.form.get('fkespecialidad22').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad23').value) {
          this.form.get('fkespecialidad23').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad24').value) {
          this.form.get('fkespecialidad24').setValue(especialidad.target.value);
        } else if (!this.form.get('fkespecialidad25').value) {
          this.form.get('fkespecialidad25').setValue(especialidad.target.value);
        }
      } else {
        especialidad.target.checked = false;
      }
    } else if (this.contEspecilidad2 > 0) {
      this.contEspecilidad2--;

      if (this.form.get('fkespecialidad25').value === especialidad.target.value) {
        this.form.get('fkespecialidad25').setValue(null);
      } else if (this.form.get('fkespecialidad24').value === especialidad.target.value) {
        this.form.get('fkespecialidad24').setValue(null);
      } else if (this.form.get('fkespecialidad23').value === especialidad.target.value) {
        this.form.get('fkespecialidad23').setValue(null);
      } else if (this.form.get('fkespecialidad22').value === especialidad.target.value) {
        this.form.get('fkespecialidad22').setValue(null);
      } else if (this.form.get('fkespecialidad21').value === especialidad.target.value) {
        this.form.get('fkespecialidad21').setValue(null);
      }
    }

    // if (this.isEspecialidad1) {
    //   let value1 = this.form.get('fkespecialidad11').value;
    //   let value2 = this.form.get('fkespecialidad12').value;
    //   let value3 = this.form.get('fkespecialidad13').value;
    //   let value4 = this.form.get('fkespecialidad14').value;
    //   let value5 = this.form.get('fkespecialidad15').value;

    //   let aux = this.jsonEspecialidads1.filter(option => option.id !== value1);
    //   aux = aux.filter(option => option.id !== value2);
    //   aux = aux.filter(option => option.id !== value3);
    //   aux = aux.filter(option => option.id !== value4);
    //   aux = aux.filter(option => option.id !== value5);

    //   console.log(aux);

    //   this.jsonEspecialidads2$ = new Observable((observer) => {
    //     // tslint:disable-next-line: radix
    //     observer.next(aux);
    //     observer.complete();
    //   });
    // }
  }

  public cancelarAreas2(): void {
    this.fkarea2 = '';
    this.isRama2 = false;
    this.fkrama2 = '';
    this.isEspecialidad2 = false;
    this.isOtro2 = false;
    this.contEspecilidad2 = 0;
    this.jsonRamas2$ = new Observable<Rama[]>();
    this.jsonEspecialidads2$ = new Observable<Especialidad[]>();
    this.form.get('fkarea2').setValue(null);
    this.form.get('fkrama2').setValue(null);
    this.form.get('fkespecialidad21').setValue(null);
    this.form.get('fkespecialidad22').setValue(null);
    this.form.get('fkespecialidad23').setValue(null);
    this.form.get('fkespecialidad24').setValue(null);
    this.form.get('fkespecialidad25').setValue(null);
    this.form.get('otraespecialidad2').setValue(null);
    this.form.get('fkarea2').clearValidators();
    this.form.get('fkarea2').updateValueAndValidity();
    this.form.get('fkrama2').clearValidators();
    this.form.get('fkrama2').updateValueAndValidity();
    this.form.get('fkespecialidad21').clearValidators();
    this.form.get('fkespecialidad21').updateValueAndValidity();
  }

  public changueExperiencialaboral(event: any): void {
    if (event.target.checked) {
      this.actualExperiencialaboral = true;
      this.anofin1 = this.ano.toString();
    } else {
      this.actualExperiencialaboral = false;
      this.anofin1 = null;
    }
  }

  public changueExperienciaacademica(event: any): void {
    if (event.target.checked) {
      this.actualExperienciaacademica = true;
      if (this.pregrado) {
        this.anofin2 = this.ano.toString();
      }
      if (this.posgrado) {
        this.anofin3 = this.ano.toString();
      }
    } else {
      this.actualExperienciaacademica = false;
      if (this.pregrado) {
        this.anofin2 = null;
      }
      if (this.posgrado) {
        this.anofin3 = null;
      }
    }
  }

  public onChangue(event: any): void {
    this.totalCaracteres = event.editor.getLength();
    if (this.totalCaracteres > 499) {
      event.editor.deleteText(499, event.editor.getLength());
    }
  }

  // public setFechanacimiento(fecha: any) {
  //   this.fechanacimiento = fecha;
  // }

  // public setFechainiciolaboral(fecha: any) {
  //   this.fechainiciolaboral = fecha;
  // }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.get('Experto_contrasena').value !== this.form.get('Experto_confirmacontrasena').value) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error'
        },
        buttonsStyling: false
      });

      swal.fire({
        // imageUrl: this._urlTest + '/logo_128.png',
        // imageHeight: 64,
        icon: 'error',
        title: 'Las contraseñas no coinciden, favor verificar!',
        showConfirmButton: false,
        timer: 5000
      });
    } else if (this.form.valid) {
      const object: any = this.form.value;
      object.nickname = this.getNombre1 + this.getApellido1 + this.getApellido2;
      object.fechanacimiento = object.anoFechanacimiento + '-' + object.mesFechanacimiento + '-' + object.diaFechanacimiento;
      object.fechainiciolaboral = object.anoFechainiciolaboral + '-' + object.mesFechainiciolaboral + '-' + object.diaFechainiciolaboral;
      object.fktarifanueva = this.tarifanuevaInicial;
      object.fktarifahilo = this.tarifahiloInicial;
      object.fktarifaencuesta = this.tarifaencuestaInicial;
      object.fknivel = this.nivelInicial;
      object.destacado = this.getDestacadoInicial();
      object.calificaciones = this.getCalificacionesInicial();
      object.pais = this.form.get('pais').value.split(':')[0].trim();
      object.departamento = this.form.get('departamento').value.split(':')[0];
      object.urlrut = this.urlrutTemp;
      object.urlfotografia = this.urlfotografiaTemp;
      object.fkarea1 = this.form.get('fkarea1').value.split(':')[0];
      object.fkrama1 = this.form.get('fkrama1').value.split(':')[0];
      object.fkarea2 = this.form.get('fkarea2').value.split(':')[0];
      object.fkrama2 = this.form.get('fkrama2').value.split(':')[0];
      object.isconfirma = 'NO';
      object.isconfirmareferencia1 = 'NO';
      object.isconfirmareferencia2 = 'NO';
      object.isconfirmareferencia3 = 'NO';
      object.aceptaterminos = 'SI';
      /** TEMPORAL */
      // object.tokenconfirma = generar en PHP
      // object.tokenreferencia = generar en PHP
      object.estadoconfirma = 'PENDIENTE';
      /** TEMPORAL */
      // añadir CRON para que envie correo con 'estadoconfirma' con 'PENDIENTE' y 'isconfirma' con 'NO',
      // envia correo y luego coloca 'estadoconfirma' con 'ENVIADO'
      object.estado = this.getEstadoInicial();
      this.isAccion = 0;

      this.expertoService.post(object).subscribe(
        (response: Experto) => {
          const object: Expertos = {
            id: 0, fecha: new Date(), 
            fkexperto: response.id, 
            correoelectronico: response.correoelectronico, 
            contrasena: this.form.get('Experto_contrasena').value, 
            ultimoacceso: new Date()
          };

          this.expertosService.post(object).subscribe(
            (response: Expertos) => {
              // response 200
              this.isAccion = 1;
              const swal = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
              });

              swal.fire({
                imageUrl: this._urlTest + '/logo_128.png',
                imageHeight: 64,
                icon: 'success',
                title: 'Hemos registrado tu información, espera unos segundos ...',
                showConfirmButton: false,
                timer: 4000
              });

              setTimeout(function () {
                window.location.href = '/confirmacion-registro-experto/' + object.correoelectronico;
              }, 4000);
            },
            error => {
              // response 500
              this.isAccion = -1;
              const swal = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-error'
                },
                buttonsStyling: false
              });

              swal.fire({
                // imageUrl: this._urlTest + '/logo_128.png',
                // imageHeight: 64,
                icon: 'error',
                title: 'Encontramos un ERROR al crear tu cuenta, intenta nuevamente',
                showConfirmButton: false,
                timer: 5000
              });
            }
          );

        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos que tienes una cuenta asociada, intenta iniciar tu sesión',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

      // });
    }
  }

  /** ADICIONALES */
  public onFileChanged(input: string, event: any): void {
    if (input === 'urlrut') {
      this.loadingFileurlrut = true;
      this.fileurlrut = event.target.files[0];
      this.onUpload('urlrut', 'experto_rut');
    } else if (input === 'urlfotografia') {
      this.loadingFileurlfotografia = true;
      this.fileurlfotografia = event.target.files[0];
      this.onUpload('urlfotografia', 'experto_fotografia');
    }
  }

  /** ADICIONALES */
  public onUpload(input: string, dir: string): void {
    const form = new FormData();
    if (input === 'urlrut') {
      form.append('imagen', this.fileurlrut, this.fileurlrut.name);
    } else if (input === 'urlfotografia') {
      form.append('imagen', this.fileurlfotografia, this.fileurlfotografia.name);
    }
    form.append('dir', dir);

    this.expertoService.uploadImage(form).subscribe(
      (response: any) => {
        this.loadingFileurlrut = false;
        this.loadingFileurlfotografia = false;
        try {
          if (response.url) {
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              confirmButtonText: 'Exitoso, puedes continuar',
              showConfirmButton: true,
              timer: 5000
            });

            if (input === 'urlrut') {
              this.isFileurlrut = true;
            } else if (input === 'urlfotografia') {
              this.isFileurlfotografia = true;
            }

            if (input === 'urlrut') {
              // this.form.get(input).setValue(this.fileurlrut.name);
              this.form.get(input).setValue(response.url);
              this.urlrutTemp = response.url;
            } else if (input === 'urlfotografia') {
              // this.form.get(input).setValue(this.fileurlfotografia.name);
              this.form.get(input).setValue(response.url);
              this.urlfotografiaTemp = response.url;
            }
          } else {
            if (input === 'urlrut') {
              this.fileurlrut = null;
            } else if (input === 'urlfotografia') {
              this.fileurlfotografia = null;
            }

            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'ERROR al intentar cargar el archivo',
              showConfirmButton: false,
              timer: 5000
            });
          }
        } catch (error) {
          if (input === 'urlrut') {
            this.fileurlrut = null;
          } else if (input === 'urlfotografia') {
            this.fileurlfotografia = null;
          }

          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'ERROR al intentar cargar el archivo',
            showConfirmButton: false,
            timer: 5000
          });
        }
      }
    );
  }

  public getTarifanuevaInicial(): void {
    this.queryService.query_25().subscribe(
      (response: any[]) => {
        this.tarifanuevaInicial = parseInt(response[0].id);
      }, error => {
        this.tarifanuevaInicial = 0;
      }
    );
  }

  public getTarifahiloInicial(): void {
    this.queryService.query_26().subscribe(
      (response: any[]) => {
        this.tarifahiloInicial = parseInt(response[0].id);
      }, error => {
        this.tarifahiloInicial = 0;
      }
    );
  }

  public getTarifaencuestaInicial(): void {
    this.queryService.query_27().subscribe(
      (response: any[]) => {
        this.tarifaencuestaInicial = parseInt(response[0].id);
      }, error => {
        this.tarifaencuestaInicial = 0;
      }
    );
  }

  public getNivelInicial(): void {
    this.queryService.query_28().subscribe(
      (response: any[]) => {
        this.nivelInicial = parseInt(response[0].id);
      }, error => {
        this.nivelInicial = 0;
      }
    );
  }

  public getDestacadoInicial(): string {
    return 'NO';
  }

  public getCalificacionesInicial(): number {
    return 0.0;
  }

  public getEstadoInicial(): string {
    return 'INACTIVO';
  }

  public regresar2(): void {
    this.isParte1 = true;
    this.isParte2 = false;
  }

  public regresar3(): void {
    this.isParte2 = true;
    this.isParte3 = false;
  }

  public regresar4(): void {
    this.isParte3 = true;
    this.isParte4 = false;
  }

  public regresar5(): void {
    this.isParte4 = true;
    this.isParte5 = false;
  }

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  // search1 = (text$: Observable<string>) => {
  //   const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  //   const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
  //   const inputFocus$ = this.focus$;

  //   return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
  //     map(term => (term === '' ? states : states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
  //   );
  // }

  search1 = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounce(t => timer(t.length > 0 ? 200 : 0)),
      distinctUntilChanged(),
      map(term => term === '' ? this.jsonPregrados
        : this.jsonPregrados.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 20))
    )
  };

  search2 = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounce(t => timer(t.length > 0 ? 200 : 0)),
      distinctUntilChanged(),
      map(term => term === '' ? this.jsonPosgrados
        : this.jsonPosgrados.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 20))
    )
  };

}
