import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../../environments/environment';
import { AuthService } from './../../../service/-auth.service';
import { QueryService } from './../../../service/-query.service';

@Component({
  selector: 'app-menu-usuario',
  templateUrl: './menu-usuario.component.html',
  styleUrls: ['./menu-usuario.component.css']
})
export class MenuUsuarioComponent implements OnInit {

  public fk: number;
  public nickname: string;
  public url: string;
  public notificaciones;
  public isMenu = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  //public _urlWeb: string = environment.urlWeb;
  public _urlWeb: string = '/inicio';
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  constructor(
    public auth: AuthService,
    public queryService: QueryService,
  ) { }

  ngOnInit(): void {
    this.fk = parseInt(this.auth.obtener('fk'));
    this.nickname = this.auth.obtener('nickname');
    this.url = this.auth.obtener('url');
    this.getNotificaciones();
  }

  public getNotificaciones(): void {
    this.queryService.query_50(this.fk).subscribe(
      (response: any) => {
        try {
          this.notificaciones = response[0].cantidad;
        } catch (error) {
          this.notificaciones = 0;
        }
      },
      error => {
        this.notificaciones = 0;
      }
    );
  }

  public changeMenu(): void {
    this.isMenu = !this.isMenu;
  }
}