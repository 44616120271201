import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Valoracion } from './../interface/valoracion';

@Injectable({
  providedIn: 'root'
})
export class ValoracionService {

  constructor(
    private http: HttpClient
  ) { }

  public post(object: Valoracion): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/Valoracion', object);
  }
}
