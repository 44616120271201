<!-- Inicio Modificación Lorena Munera henao 6/08/2023 -->
<section class="banner-one" id="home">
    <div class="container">
        <div class="row editRow">
            <div class="col-lg-5 d-flex texto_banner senorBanner">
                <img class="img-fluid m-auto d-block" src="assets/senorBanner.png" alt="">
                <!-- <div class="my-auto">
                    <div class="banner-one__content mt-3 normal">
                        <img class="img-fluid m-auto d-block" src="assets/texto_banner_2.png" alt="">
                        <div class="form-inline">
                            <div class="col-12 mt-3" style="padding: 0!important;">
                                <div class="col input-group w-100"
                                    style="padding-left: 0 !important; padding-right: 0!important;">
                                    <input class="form-control text-center"
                                        style="border-radius: unset; background-clip: unset;"
                                        placeholder="¡Encuentra tu experto!" type="text" [(ngModel)]="otro"
                                        (keyup.enter)="otraBusquedad()">
                                    <div class="input-group-append" *ngIf="otro.length > 0">
                                        <button type="button" (click)="otraBusquedad()"
                                            class="btn btn-block btn-primary">
                                            <i class="fas fa-search"></i> <span class="text">Buscar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="banner-one__content mt-3 responsive">
                        <img class="img-fluid m-auto d-block" src="assets/texto_banner_responsive_2.png" alt="">
                        <div class="form-inline">
                            <div class="col-12 mt-3" style="padding: 0!important;">
                                <div class="col input-group w-100"
                                    style="padding-left: 0 !important; padding-right: 0!important;">
                                    <input class="form-control text-center"
                                        style="border-radius: unset; background-clip: unset;"
                                        placeholder="¡Encuentra tu experto!" type="text" [(ngModel)]="otro"
                                        (keyup.enter)="otraBusquedad()">
                                    <div class="input-group-append" *ngIf="otro.length > 0">
                                        <button type="button" (click)="otraBusquedad()"
                                            class="btn btn-block btn-primary">
                                            <i class="fas fa-search"></i> <span class="text">Buscar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-lg-3 d-flex">
                <div class="banner-one__content mt-3 ">
                    <img class="img-fluid m-auto d-block titleS2" src="assets/logo_128_f_oscuro.png" alt="">
                    <img class="img-fluid m-auto d-block descS2" src="assets/texBannerNew.png" alt="">
                    <button class="experts-Btn" (click)="accionConsul();">
                        <!-- <img class="img-fluid m-auto d-block btnCon" src="assets/ExprtBtn.png" alt=""> -->
                        BUSCA UN EXPERTO
                    </button>
                    <span>ó</span>
                    <button class="experts-Btn" (click)="accionPreg();">
                        <!-- <img class="img-fluid m-auto d-block btnCon" src="assets/ExprtBtn.png" alt=""> -->
                        ¡CONSULTA AHORA!
                    </button>
                    <!-- <div class="form-inline">
                        <div class="col-12 mt-3" style="padding: 0!important;">
                            <div class="col input-group w-100"
                                style="padding-left: 0 !important; padding-right: 0!important;">
                                <input class="form-control text-center"
                                    style="border-radius: unset; background-clip: unset;"
                                    placeholder="¡Encuentra tu experto!" type="text" [(ngModel)]="otro"
                                    (keyup.enter)="otraBusquedad()">
                                <div class="input-group-append" *ngIf="otro.length > 0">
                                    <button type="button" (click)="otraBusquedad()"
                                        class="btn btn-block btn-primary">
                                        <i class="fas fa-search"></i> <span class="text">Buscar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <form class="contact-form-validated contact-one__form w-100" *ngIf="form2Visible" [formGroup]="form2"
                    (ngSubmit)="accion2($event)"> </form> -->

                    <!-- <button type="submit" class="thm-btn banner-one__btn text-center mr-5"
                                *ngIf="form2.valid"><span>{{_encuesta}}</span></button>
                            <button type="button" class="thm-btn banner-one__btn text-center"
                                (click)="cancelarForm2();"><span>CANCELAR</span></button> -->
                    

                </div>
            </div>
             <div class="col-lg-4 d-flex justify-content-end align-items-start wow fadeInUp row_formulario_banner descBanner">
                <!-- descBannerNew -->
                <img class="img-fluid m-auto d-block" src="assets/descBannerNew.png" alt="">
               <!-- <form class="contact-form-validated contact-one__form formulario_banner"
                    *ngIf="!form1Visible && !form2Visible">
                    <a (click)="form1Visible = true;"
                        class="thm-btn banner-one__btn text-center btn-block"><span>PREGUNTAR<br>A UN
                            {{_experto.toUpperCase()}}</span></a>
                    <a (click)="form2Visible = true;"
                        class="thm-btn banner-one__btn mt-5 text-center btn-block"><span>QUIERO LA
                            OPINIÓN<br>DE VARIOS {{_experto.toUpperCase()}}S</span></a>
                </form> -->

                <!-- <form class="contact-form-validated contact-one__form w-100" *ngIf="form1Visible" [formGroup]="form1"
                    (ngSubmit)="accion1($event)">
                    <div class="row">
                        <div class="col-lg-12" *ngIf="getSplit(form1.get('area').value) === null">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{_area}}</mat-label>
                                <input type="text" matInput formControlName="area" [matAutocomplete]="autoArea">
                                <mat-autocomplete #autoArea="matAutocomplete"
                                    (optionSelected)="activarRama($event.option.value, 1);">
                                    <mat-option *ngFor="let option of filteredAreas1$ | async | sortBy :'asc':'nombre'"
                                        [value]="option.id + ':' + option.nombre">
                                        {{option.nombre}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form1.get('area').errors && form1.get('area').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['area'].hasError('required')">
                                    + Campo requerido
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['area'].hasError('minlength')">
                                    + Debes ingresar más caracteres
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['area'].hasError('maxlength')">
                                    + Excediste el valor de caracteres
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12" *ngIf="getSplit(form1.get('area').value) !== null">
                            <mat-form-field class="example-full-width">
                                <!-- <mat-label>{{_area}}</mat-label> ////////
                                <mat-label></mat-label>
                                <input matInput [value]="getSplit(form1.get('area').value)" disabled
                                    style="padding-left: 10px; padding-right: 10px;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12" style="min-height: 20px; text-align: center; background-color: #fff;"
                            *ngIf="!isRama && getSplit(form1.get('area').value) !== null">
                            <img src="assets/template/images/loading_16.gif" alt="">
                        </div>
                        <!-- isRama {{isRama}} <br>
                        getSplit(form1.get('rama').value) === null {{getSplit(form1.get('rama').value) === null}}<br>
                        getSplit(form1.get('rama').value) !== null {{getSplit(form1.get('rama').value) !== null}}<br>
                        form1.get('rama').value !== 'todos:todos' {{form1.get('rama').value !== 'todos:todos'}}<br>
                        form1.get('rama').value === 'todos:todos' {{form1.get('rama').value === 'todos:todos'}} ////////
                        <div class="col-lg-12"
                            *ngIf="isRama && getSplit(form1.get('rama').value) === null && form1.get('rama').value !== 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{_rama}}</mat-label>
                                <input type="text" matInput formControlName="rama" [matAutocomplete]="autoRama">
                                <mat-autocomplete #autoRama="matAutocomplete"
                                    (optionSelected)="activarEspecialidad($event.option.value, 1);">
                                    <mat-option *ngFor="let option of filteredRamas1$ | async | sortBy :'asc':'nombre'"
                                        [value]="option.id + ':' + option.nombre">
                                        {{option.nombre}}
                                    </mat-option>
                                    <mat-option value="todos:todos">Todos</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form1.get('rama').errors && form1.get('rama').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['rama'].hasError('required')">
                                    + Campo requerido
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['rama'].hasError('minlength')">
                                    + Debes ingresar más caracteres
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['rama'].hasError('maxlength')">
                                    + Excediste el valor de caracteres
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12"
                            *ngIf="getSplit(form1.get('rama').value) !== null && form1.get('rama').value !== 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <!-- <mat-label>{{_rama}}</mat-label> ////
                                <mat-label></mat-label>
                                <input matInput [value]="getSplit(form1.get('rama').value)" disabled
                                    style="padding-left: 10px; padding-right: 10px;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12"
                            *ngIf="getSplit(form1.get('rama').value) === null && form1.get('rama').value === 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <!-- <mat-label>{{_rama}}</mat-label> /////
                                <mat-label></mat-label>
                                <input matInput [value]="'Todos'" disabled
                                    style="padding-left: 10px; padding-right: 10px;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12" style="min-height: 20px; text-align: center; background-color: #fff;"
                            *ngIf="!isEspecialidad && getSplit(form1.get('rama').value) !== null && form1.get('rama').value !== 'todos:todos'">
                            <img src="assets/template/images/loading_16.gif" alt="">
                        </div>
                        <div class="col-lg-12"
                            *ngIf="isEspecialidad && getSplit(form1.get('especialidad').value) === null && form1.get('rama').value !== 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{_especialidad}}</mat-label>
                                <input type="text" matInput formControlName="especialidad"
                                    [matAutocomplete]="autoEspecialidad">
                                <mat-autocomplete #autoEspecialidad="matAutocomplete">
                                    <mat-option
                                        *ngFor="let option of filteredEspecialidads1$ | async | sortBy :'asc':'nombre'"
                                        [value]="option.id + ':' + option.nombre">
                                        {{option.nombre}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form1.get('especialidad').errors && form1.get('especialidad').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['especialidad'].hasError('required')">
                                    + Campo requerido
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['especialidad'].hasError('minlength')">
                                    + Debes ingresar más caracteres
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form1.controls['especialidad'].hasError('maxlength')">
                                    + Excediste el valor de caracteres
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12"
                            *ngIf="getSplit(form1.get('especialidad').value) !== null && form1.get('rama').value !== 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <!-- <mat-label>{{_especialidad}}</mat-label> -///
                                <mat-label></mat-label>
                                <input matInput [value]="getSplit(form1.get('especialidad').value)" disabled
                                    style="padding-left: 10px; padding-right: 10px;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12 text-left">
                            <button type="submit" class="thm-btn banner-one__btn text-center mr-5"
                                *ngIf="form1.valid || form1.get('rama').value === 'todos:todos'"><span>Buscar
                                    {{_pregunta}}</span></button>
                            <button type="button" class="thm-btn banner-one__btn text-center"
                                (click)="cancelarForm1();"><span>CANCELAR</span></button>
                        </div>
                    </div>
                </form> -->

                <!-- <form class="contact-form-validated contact-one__form w-100" *ngIf="form2Visible" [formGroup]="form2"
                    (ngSubmit)="accion2($event)">
                    <div class="row">
                        <div class="col-lg-12" *ngIf="getSplit(form2.get('area').value) === null">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{_area}}</mat-label>
                                <input type="text" matInput formControlName="area" [matAutocomplete]="autoArea">
                                <mat-autocomplete #autoArea="matAutocomplete"
                                    (optionSelected)="activarRama($event.option.value, 2);">
                                    <mat-option *ngFor="let option of filteredAreas2$ | async | sortBy :'asc':'nombre'"
                                        [value]="option.id + ':' + option.nombre">
                                        {{option.nombre}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form2.get('area').errors && form2.get('area').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['area'].hasError('required')">
                                    + Campo requerido
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['area'].hasError('minlength')">
                                    + Debes ingresar más caracteres
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['area'].hasError('maxlength')">
                                    + Excediste el valor de caracteres
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12" *ngIf="getSplit(form2.get('area').value) !== null">
                            <mat-form-field class="example-full-width">
                                <!-- <mat-label>{{_area}}</mat-label> ///////
                                <mat-label></mat-label>
                                <input matInput [value]="getSplit(form2.get('area').value)" disabled
                                    style="padding-left: 10px; padding-right: 10px;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12" style="min-height: 20px; text-align: center; background-color: #fff;"
                            *ngIf="!isRama && getSplit(form2.get('area').value) !== null">
                            <img src="assets/template/images/loading_16.gif" alt="">
                        </div>
                        <!-- *ngIf="isRama && getSplit(form2.get('rama').value) === null"> //////////////////////
                        <div class="col-lg-12"
                            *ngIf="isRama && getSplit(form2.get('rama').value) === null && form2.get('rama').value !== 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{_rama}}</mat-label>
                                <input type="text" matInput formControlName="rama" [matAutocomplete]="autoRama">
                                <mat-autocomplete #autoRama="matAutocomplete"
                                    (optionSelected)="activarEspecialidad($event.option.value, 2);">
                                    <mat-option *ngFor="let option of filteredRamas2$ | async | sortBy :'asc':'nombre'"
                                        [value]="option.id + ':' + option.nombre">
                                        {{option.nombre}}
                                    </mat-option>
                                    <mat-option value="todos:todos">Todos</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form2.get('rama').errors && form2.get('rama').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['rama'].hasError('required')">
                                    + Campo requerido
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['rama'].hasError('minlength')">
                                    + Debes ingresar más caracteres
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['rama'].hasError('maxlength')">
                                    + Excediste el valor de caracteres
                                </span>
                            </div>
                        </div>
                        <!-- *ngIf="getSplit(form2.get('rama').value) !== null"> ///////////
                        <div class="col-lg-12"
                            *ngIf="getSplit(form2.get('rama').value) !== null && form2.get('rama').value !== 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <!-- <mat-label>{{_rama}}</mat-label> ///////////
                                <mat-label></mat-label>
                                <input matInput [value]="getSplit(form2.get('rama').value)" disabled
                                    style="padding-left: 10px; padding-right: 10px;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12"
                            *ngIf="getSplit(form2.get('rama').value) === null && form2.get('rama').value === 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <!-- <mat-label>{{_rama}}</mat-label> ///////////////////
                                <mat-label></mat-label>
                                <input matInput [value]="'Todos'" disabled
                                    style="padding-left: 10px; padding-right: 10px;">
                            </mat-form-field>
                        </div>
                        <!-- *ngIf="!isEspecialidad && getSplit(form2.get('rama').value) !== null"> /////////////
                        <div class="col-lg-12" style="min-height: 20px; text-align: center; background-color: #fff;"
                            *ngIf="!isEspecialidad && getSplit(form2.get('rama').value) !== null && form2.get('rama').value !== 'todos:todos'">
                            <img src="assets/template/images/loading_16.gif" alt="">
                        </div>
                        <!-- *ngIf="isEspecialidad && getSplit(form2.get('especialidad').value) === null"> /////////////////////
                        <div class="col-lg-12"
                            *ngIf="isEspecialidad && getSplit(form2.get('especialidad').value) === null && form2.get('rama').value !== 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <mat-label>{{_especialidad}}</mat-label>
                                <input type="text" matInput formControlName="especialidad"
                                    [matAutocomplete]="autoEspecialidad">
                                <mat-autocomplete #autoEspecialidad="matAutocomplete">
                                    <mat-option
                                        *ngFor="let option of filteredEspecialidads2$ | async | sortBy :'asc':'nombre'"
                                        [value]="option.id + ':' + option.nombre">
                                        {{option.nombre}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form2.get('especialidad').errors && form2.get('especialidad').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['especialidad'].hasError('required')">
                                    + Campo requerido
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['especialidad'].hasError('minlength')">
                                    + Debes ingresar más caracteres
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['especialidad'].hasError('maxlength')">
                                    + Excediste el valor de caracteres
                                </span>
                            </div>
                        </div>
                        <!-- *ngIf="getSplit(form2.get('especialidad').value) !== null">///////////////
                        <div class="col-lg-12"
                            *ngIf="getSplit(form2.get('especialidad').value) !== null && form2.get('rama').value !== 'todos:todos'">
                            <mat-form-field class="example-full-width">
                                <!-- <mat-label>{{_especialidad}}</mat-label>///////////////////
                                <mat-label></mat-label>
                                <input matInput [value]="getSplit(form2.get('especialidad').value)" disabled
                                    style="padding-left: 10px; padding-right: 10px;">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6" *ngIf="isEspecialidad">
                            <mat-form-field class="example-full-width">
                                <mat-label *ngIf="form2.get('tarifamin').value === null">Tarifa</mat-label>
                                <input type="text" matInput formControlName="tarifamin"
                                    [matAutocomplete]="autoTarifamin">
                                <mat-autocomplete #autoTarifamin="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredTarifas1$ | async | sortBy :'asc':'valor'"
                                        [value]="option.valor">
                                        {{ option.valor | currency:'$':'symbol':'1.0' }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form2.get('tarifamin').errors && form2.get('tarifamin').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['tarifamin'].hasError('required')">
                                    + Campo requerido
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['tarifamin'].hasError('minlength')">
                                    + Debes ingresar más caracteres
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['tarifamin'].hasError('maxlength')">
                                    + Excediste el valor de caracteres
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="isEspecialidad">
                            <mat-form-field class="example-full-width">
                                <mat-label *ngIf="form2.get('tarifamax').value === null">... Hasta</mat-label>
                                <input type="text" matInput formControlName="tarifamax"
                                    [matAutocomplete]="autoTarifamax">
                                <mat-autocomplete #autoTarifamax="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredTarifas2$ | async | sortBy :'asc':'valor'"
                                        [value]="option.valor">
                                        {{ option.valor | currency:'$':'symbol':'1.0' }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form2.get('tarifamax').errors && form2.get('tarifamax').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['tarifamax'].hasError('required')">
                                    + Campo requerido
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['tarifamax'].hasError('minlength')">
                                    + Debes ingresar más caracteres
                                </span>
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['tarifamax'].hasError('maxlength')">
                                    + Excediste el valor de caracteres
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="isEspecialidad">
                            <mat-form-field class="example-full-width">
                                <mat-label *ngIf="form2.get('anosmin').value === null">Experiencia</mat-label>
                                <input type="text" matInput formControlName="anosmin" [matAutocomplete]="autoAnosmin">
                                <mat-autocomplete #autoAnosmin="matAutocomplete">
                                    <mat-option
                                        *ngFor="let option of filteredAnosExperiencias1$ | async | sortBy :'asc':'id'"
                                        [value]="option.anos">
                                        {{option.anos}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form2.get('anosmin').errors && form2.get('anosmin').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['anosmin'].hasError('required')">
                                    + Campo requerido
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="isEspecialidad">
                            <mat-form-field class="example-full-width">
                                <mat-label *ngIf="form2.get('anosmax').value === null">... Hasta</mat-label>
                                <input type="text" matInput formControlName="anosmax" [matAutocomplete]="autoAnosmax">
                                <mat-autocomplete #autoAnosmax="matAutocomplete">
                                    <mat-option
                                        *ngFor="let option of filteredAnosExperiencias2$ | async | sortBy :'asc':'id'"
                                        [value]="option.anos">
                                        {{option.anos}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div *ngIf="form2.get('anosmax').errors && form2.get('anosmax').dirty">
                                <span class="help-block text-danger"
                                    *ngIf="form2.controls['anosmax'].hasError('required')">
                                    + Campo requerido
                                </span>
                            </div>
                        </div>
                        <!-- <p>myForm value: {{ form2.value | json }}</p>
                        <p>myForm status: {{ form2.status | json }}</p>///////////////////////////////
                        <div class="col-lg-12 text-left">
                            <button type="submit" class="thm-btn banner-one__btn text-center mr-5"
                                *ngIf="form2.valid"><span>{{_encuesta}}</span></button>
                            <button type="button" class="thm-btn banner-one__btn text-center"
                                (click)="cancelarForm2();"><span>CANCELAR</span></button>
                        </div>
                    </div>
                </form> -->
            </div>
            
        </div>
    </div>
</section>
<!-- Fin Modificación Lorena Munera henao 6/08/2023 -->

<!-- Sección 2 del inicio -->
<section class="cta-four normal">
    <div class="container contenedor py-5">

        <section style="min-height: 100% !important; display:table !important; height:inherit !important;">
            <div class="row contenidos"
                style="width: 100% !important; height: 100% !important; display:table-row !important;">
                <div class="col-lg-3" id="columna1" style="vertical-align: top !important; width: 15% !important;">
                    <div class="mb-auto" style="position: relative;">
                        <iframe width="250" height="auto" src="https://www.youtube.com/embed/-zNlHoc-ZGc"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                </div>
                <div class="col-lg-3" id="columna2" style="vertical-align: bottom !important; width: 15% !important;">
                    <div class="mt-auto" style="position: relative;">
                        <iframe width="250" height="auto" src="https://www.youtube.com/embed/sdc1IJC8ryo"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                </div>
                <div class="col-lg-6" id="columna3" style="width: 50% !important;">
                    <div class="cta-four__content">
                        <div class="cta-four__text"
                            style="text-align: right; margin-bottom: 0!important; font-size: 14px;">
                            <p>Realiza preguntas y obtén<br>
                                rápidamente esas respuestas<br>
                                que necesitas para avanzar<br>
                                en tu negocio o si prefieres<br>
                                la opinión de varios expertos,<br>
                                tendras un equipo para<br>
                                orientar tu decisión.
                            </p>
                        </div>
                        <div class="block-title" style="margin-bottom: 0!important;">
                            <h3 style="top: 10px; padding-left: 5%; padding-right: 5%; margin-bottom: 0!important;">
                                DESCUBRE
                                <br>CÓMO
                                <br>FUNCIONA
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</section>

<section class="cta-four responsiveChulo">
    <div class="container contenedor">
        <div class="row contenidos py-5 d-flex align-items-center">
            <div class="col-12 mb-3">
                <div class="cta-four__content">
                    <div class="cta-four__text" style="text-align: right; margin-bottom: 0!important;">
                        <p style="font-size: 20px!important;">Realiza preguntas y obtén<br>
                            rápidamente esas respuestas<br>
                            que necesitas para avanzar<br>
                            en tu negocio o si prefieres<br>
                            la opinión de varios expertos,<br>
                            tendras un equipo para<br>
                            orientar tu decisión.
                        </p>
                    </div>
                    <div class="block-title" style="margin-bottom: 0!important;">
                        <h3
                            style="top: 10px; padding-left: 5%; padding-right: 5%; margin-bottom: 0!important; font-size: 24px!important;">
                            DESCUBRE
                            <br>CÓMO
                            <br>FUNCIONA
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center mb-2">
                <iframe width="auto" height="auto" src="https://www.youtube.com/embed/fyd6Nzv1wWg"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
            <div class="col-12 text-center">
                <iframe width="auto" height="auto" src="https://www.youtube.com/embed/sdc1IJC8ryo"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
        </div>
    </div>
</section>


<!-- Sección 3 del inicio -->
<section class="brand-one" style="background-color: #f3f3f3;">
    <div class="container">
        <div class="block-title text-center">
            <h3 style="font-size: 30px !important;">Algunos de los {{_experto}}s</h3>
        </div>
        <div class="row no-gutters items-wrap normal">
            <div class="col-md-12">
                <div id="carousel3_indicator"
                    class="carousel slide brand-one__carousel owl-carousel1 owl-theme text-center" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="item-carousel carousel-item"
                            *ngFor="let expertosEn4 of expertos4 | slice:0:expertos4.length | sortBy :'asc':'calificaciones'; let index1 = index"
                            [attr.class]="index1 === 0 ? 'item-carousel carousel-item active' : 'item-carousel carousel-item'">
                            <div class="row">
                                <div *ngFor="let item of expertosEn4; let index2 = index"
                                    [attr.class]="index2 % 2 ? 'col-xl-3 col-lg-3 col-md-3 col-6' : 'col-xl-3 col-lg-3 col-md-3 col-6'">
                                    <div class="blog-one__single">
                                        <div class="blog-one__image container-imagen">
                                            <a [routerLink]="['/experto', item.id]">
                                                <img alt="" class="crop rounded-circle"
                                                    style="object-fit: cover; width: 100%; height: 100%;"
                                                    [src]="item.urlfotografia">
                                            </a>
                                        </div>
                                        <div class="blog-one__content mt-1"
                                            style="padding-top: 0; padding-bottom: 5px;">
                                            <h3
                                                style="overflow: hidden !important; display: block !important; height: 25px !important;">
                                                {{item.nickname}}</h3>
                                            <h5 style="margin: 0; color: #969696;">{{item.nombrenivel}}</h5>

                                            <div class="rating-wrap mb-2" *ngIf="item.calificaciones === 0">
                                                <ul class="rating-stars text-left">
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 0 && item.calificaciones <= 0.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 0.5 && item.calificaciones <= 1">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 1 && item.calificaciones <= 1.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 1.5 && item.calificaciones <= 2">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 2 && item.calificaciones <= 2.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 2.5 && item.calificaciones <= 3">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 3 && item.calificaciones <= 3.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 3.5 && item.calificaciones <= 4">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 4 && item.calificaciones <= 4.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 4.5 && item.calificaciones <= 5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>

                                        </div>
                                        <img class="img-fluid" src="assets/linea_color.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carousel3_indicator" role="button" data-slide="prev"
                        style="width: unset !important; left: -50px !important;">
                        <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Anterior</span> -->
                        <img class="img-fluid" src="assets/flecha_izq.png" alt="">
                    </a>
                    <a class="carousel-control-next" href="#carousel3_indicator" role="button" data-slide="next"
                        style="width: unset !important; right: -50px !important;">
                        <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Siguiente</span> -->
                        <img class="img-fluid" src="assets/flecha_der.png" alt="">
                    </a>
                </div>
            </div>
        </div>
        <div class="row no-gutters items-wrap responsive">
            <div class="col-md-12">
                <div id="carousel4_indicator"
                    class="carousel slide brand-one__carousel owl-carousel1 owl-theme text-center" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="item-carousel carousel-item"
                            *ngFor="let expertosEn4 of expertos2 | slice:0:expertos2.length | sortBy :'asc':'calificaciones'; let index1 = index"
                            [attr.class]="index1 === 0 ? 'item-carousel carousel-item active' : 'item-carousel carousel-item'">
                            <div class="row">
                                <div *ngFor="let item of expertosEn4; let index2 = index"
                                    [attr.class]="index2 % 2 ? 'col-xl-3 col-lg-3 col-md-3 col-12' : 'col-xl-3 col-lg-3 col-md-3 col-12'">
                                    <div class="blog-one__single">
                                        <div class="blog-one__image container-imagen">
                                            <a [routerLink]="['/experto', item.id]">
                                                <img alt="" class="crop rounded-circle"
                                                    style="object-fit: cover; width: 100%; height: 100%;"
                                                    [src]="item.urlfotografia">
                                            </a>
                                        </div>
                                        <div class="blog-one__content mt-1"
                                            style="padding-top: 0; padding-bottom: 5px;">
                                            <h3
                                                style="overflow: hidden !important; display: block !important; height: 25px !important;">
                                                {{item.nickname}}</h3>
                                            <h5 style="margin: 0; color: #969696;">{{item.nombrenivel}}</h5>

                                            <div class="rating-wrap mb-2" *ngIf="item.calificaciones === 0">
                                                <ul class="rating-stars text-left">
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 0 && item.calificaciones <= 0.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 0.5 && item.calificaciones <= 1">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 1 && item.calificaciones <= 1.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 1.5 && item.calificaciones <= 2">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 2 && item.calificaciones <= 2.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 2.5 && item.calificaciones <= 3">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 3 && item.calificaciones <= 3.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 3.5 && item.calificaciones <= 4">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 4 && item.calificaciones <= 4.5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="item.calificaciones > 4.5 && item.calificaciones <= 5">
                                                <ul class="rating-stars text-left">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{item.calificaciones}} / 5</div>
                                            </div>
                                        </div>
                                        <img class="img-fluid" src="assets/linea_color.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Sección 4 del inicio -->
<section class="funfact-one">
    <div class="container">
        <div class="block-title text-center">
            <h3 style="font-size: 30px !important;">Esto opinan varios {{_experto}}s<span></span></h3>
        </div>
        <div class="row">
            <div class="normal col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms"
                *ngFor="let item of ejemploEncuestas$ | async | sortBy :'asc':'id'; let index = index">
                <div class="align-items-center normal" style="position: relative; z-index: 2;">
                    <span class="mx-auto porcentaje">{{item.porcentaje}}%</span>
                </div>
                <div class="align-items-center responsive mt-5" style="position: relative; z-index: 2;">
                    <span class="mx-auto porcentaje">{{item.porcentaje}}%</span>
                </div>
                <div style="position: relative; z-index: -1;">
                    <img class="img-fluid m-auto d-block" src="assets/circulo_porcentaje.png" alt=""
                        style="width: 70%;">
                </div>
                <p style="height: auto; overflow: hidden; margin-top: 20px; text-align: left;" [innerHTML]="item.texto">
                </p>
            </div>
            <div class="responsive col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
                data-wow-delay="0ms"
                *ngFor="let item of ejemploEncuestas$ | async | sortBy :'asc':'id'; let index = index">
                <div class="align-items-center normal" style="position: relative; z-index: 2;">
                    <span class="mx-auto porcentaje">{{item.porcentaje}}%</span>
                </div>
                <div class="align-items-center responsive mt-5" style="position: relative; z-index: 2;">
                    <span class="mx-auto porcentaje">{{item.porcentaje}}%</span>
                </div>
                <div style="position: relative; z-index: -1;">
                    <img class="img-fluid m-auto d-block" src="assets/circulo_porcentaje.png" alt=""
                        style="width: 70%;">
                </div>
                <p style="height: auto; overflow: hidden; margin-top: 20px; margin-left: 20px; margin-right: 20px; text-align: left; font-size: 14px !important;"
                    [innerHTML]="item.texto">
                </p>
            </div>
        </div>
    </div>
</section>


<!-- Sección 5 del inicio -->
<section class="brand-one" style="padding-top: 20!important;">
    <div class="container">
        <div class="block-title text-center">
            <h3 style="font-size: 30px !important;">{{_pregunta.toUpperCase()}}S FRECUENTES<span></span></h3>
        </div>
        <div class="row mx-3">
            <div class="card w-100">
                <div class="card-body">
                    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" class="w-100" activeIds="ngb-panel-0">
                        <ngb-panel
                            *ngFor="let option of ejemploPreguntas$ | async | sortBy: 'asc':'id'; let index = index">
                            <ng-template ngbPanelTitle>
                                <span [innerHTML]="option.pregunta">
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p [innerHtml]="option.respuesta"></p>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
    </div>
</section>

<style>
    .img_chat {
        width: 60px;
    }

    @media (max-width:640px) {
        .img_chat {
            width: 40px;
        }
    }

    #btnmov {
        transform: scale(1);
        animation: mymove 3s infinite;
    }

    @keyframes mymove {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.15);
        }

        100% {
            transform: scale(1);
        }
    }
</style>
<a [href]="_whatsApp" target="_blank">
    <img id="btnmov" class="el-image img_chat" alt="" src="assets/whatsapp.png" style="
        position: fixed;
        bottom: 12.5%;
        right: 7.5%;
        z-index: 99 !important;">
</a>