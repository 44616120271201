import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { HiloService } from './../shared/service/hilo.service';

@Component({
  selector: 'app-pregunta-hilo',
  templateUrl: './pregunta-hilo.component.html',
  styleUrls: ['./pregunta-hilo.component.css']
})
export class PreguntaHiloComponent implements OnInit {

  public id: number;
  public fk: number;
  public fkexperto: number;
  public totalCaracteres = 0;
  private valor: number;
  private valorcomision: number;
  private contenido: string;
  public isMailContenido = false;
  public isPendientePago: number;
  public isContenidoIncorrecto = false;

  public jsonEstados$: Observable<any>;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private hiloService: HiloService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      this.id = parseInt(params.id);//Pregunta.id
      // this.fk = parseInt(params.fk);
      this.fk = parseInt(this.auth.obtener('fk'));
      this.fkexperto = parseInt(params.experto);

      this.getTarifasExperto(this.fkexperto);
      this.getEstados();
    });

    this.queryService.query_69(this.fk).subscribe(
      (response: any) => {
        try {
          this.isPendientePago = response[0].id;
        } catch (error) {
          this.isPendientePago = 0;
        }
      },
      error => {
        this.isPendientePago = 0;
      }
    );
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      /** Pregunta */
      id: [null], //this.id
      tipo: [null], //ABIERTA ó DIRECTA
      fkusuario: [null], //this.fk
      /** Hilo */
      // fecha: [''],
      fecharespuesta: [null],
      fkexperto: [null], //this.fkexperto ó 0(cero)
      valor: [null],
      valorcomision: [null],
      fkpregunta: [null], //this.id
      contenido: [null, Validators.required],
      respuesta: [null],
      estado3: [null], //HILO
      estado4: [null], //PENDIENTE
      estado5: [null], //PENDIENTE
      estado6: [null], //*PENDIENTE
      fechamaxotranotificacion: [null],
      fkrecibo: [null],
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.valid) {
      const object = this.form.value;
      /** Pregunta */
      object.id = this.id;
      object.fkusuario = this.getFkusuario();
      object.estado1 = 'PENDIENTE';
      object.estado2 = 'PENDIENTE';
      /** Hilo */
      object.fkexperto = this.fkexperto;
      /** Pregunta */
      object.tipo = 'DIRECTA';
      /** Hilo */
      object.valor = this.valor;
      object.valorcomision = this.valorcomision;
      object.fkpregunta = this.id;
      object.contenido = this.contenido;
      object.estado3 = 'HILO';
      object.estado4 = 'PENDIENTE';
      object.estado5 = 'PENDIENTE';
      object.estado6 = 'PENDIENTE';
      this.isAccion = 0;

      this.hiloService.post(object).subscribe(
        (response: any) => {
          // response 200
          this.isAccion = 1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos enviado tu ' + this._hilo + '...',
            showConfirmButton: false,
            timer: 4000
          });
          
          setTimeout(function () {
            window.location.href = '/mi-usuario/' + object.fkusuario;
          }, 4000);
        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

    }
  }

  public getFkusuario(): number {
    return this.fk;
  }

  public getTarifasExperto(id: any): any {
    id = id.toString().trim();
    this.queryService.query_52(id).subscribe(
      (response: any) => {
        try {
          this.valor = response[0].valor;
          this.valorcomision = response[0].valorcomision;
        } catch (error) {
          this.valor = null;
          this.valorcomision = null;
        }
      },
      error => {
        this.valor = null;
        this.valorcomision = null;
      }
    );
  }

  public getEstados(): void {
    this.queryService.query_35(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });
      }, error => {
      }
    );
  }

  public onChangue(event: any): void {
    var re = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/;
    if (re.test(event.editor.getText())) {
      this.isMailContenido = true;
    } else {
      this.isMailContenido = false;
    }

    if (event.editor.getText().toLowerCase().includes('llámame') ||
      event.editor.getText().toLowerCase().includes('llamame') ||
      event.editor.getText().toLowerCase().includes('contáctame') ||
      event.editor.getText().toLowerCase().includes('contacteme') ||
      event.editor.getText().toLowerCase().includes('mi número de contacto es') ||
      event.editor.getText().toLowerCase().includes('mi numero de contacto es') ||
      event.editor.getText().toLowerCase().includes('mi correo es') ||
      event.editor.getText().toLowerCase().includes('escríbeme al correo') ||
      event.editor.getText().toLowerCase().includes('escribeme al correo') ||
      event.editor.getText().toLowerCase().includes('mándeme un correo') ||
      event.editor.getText().toLowerCase().includes('mandeme un correo') ||
      event.editor.getText().toLowerCase().includes('escríbame al correo') ||
      event.editor.getText().toLowerCase().includes('escribame al correo') ||
      event.editor.getText().toLowerCase().includes('escríbame a') ||
      event.editor.getText().toLowerCase().includes('escribame a') ||
      event.editor.getText().toLowerCase().includes('mándeme a') ||
      event.editor.getText().toLowerCase().includes('mandame a') ||
      event.editor.getText().toLowerCase().includes('mándeme un correo') ||
      event.editor.getText().toLowerCase().includes('mandame un correo') ||
      event.editor.getText().toLowerCase().includes('mi número de teléfono') ||
      event.editor.getText().toLowerCase().includes('mi numero de teléfono') ||
      event.editor.getText().toLowerCase().includes('mi número de celular') ||
      event.editor.getText().toLowerCase().includes('mi numero de celular') ||
      event.editor.getText().toLowerCase().includes('mi celular es')) {
      this.isContenidoIncorrecto = true;
    } else {
      this.isContenidoIncorrecto = false;
    }

    this.totalCaracteres = event.editor.getLength();
    if (this.totalCaracteres > 499) {
      event.editor.deleteText(499, event.editor.getLength());
    }
    this.contenido = event.editor.getText();
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

}
