<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <div class="container div-titulo">
        <h2 class="titulo">{{_encuesta.toUpperCase()}}</h2>
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isBuscando === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_encuesta}}, intenta
                                nuevamente.</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                    </div>
                    <!-- <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1">
                        <div class="card-body">
                            <div
                                *ngFor="let item of jsonEncuesta$ | async | sortBy: 'asc':'fkpregunta'; let index = index">
                                <ngx-charts-bar-vertical [view]="view" [scheme]="colorScheme"
                                    [results]="_filter(item.fkpregunta)" [gradient]="gradient" [xAxis]="showXAxis"
                                    [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                                    [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                    [yAxisLabel]="yAxisLabel" (select)="onSelect($event)">
                                </ngx-charts-bar-vertical>
                            </div>
                        </div>
                    </div> -->
                    <div class="card" style="background-color: #ffffff60;"
                        *ngIf="isBuscando === 1 && (jsonEncuesta$ | async)?.length > 0">
                        <div *ngIf="isBuscando === 1 && (jsonEstados$ | async)?.length > 0">
                            <div *ngFor="let item of jsonEstados$ | async; let index = index">
                                <!-- Preguntas ({{total}}) -->
                                <!-- <b>{{item.idEncuesta}}</b> - {{item.fecha}} - Respuestas <b>{{item.respuestas}}</b>
                                <= Total Respuestas <b>{{item.totalRespuestas}}</b><br>{{item.fkarea}} - {{item.fkrama}}
                                    - {{item.fkespecialidad}}<br>{{item.estado1}} - {{item.estado2}} - {{item.horas}}
                                    horas<br> -->

                                <div class="card-body card-confirmacion text-center" *ngIf="item.totalRespuestas === 0">
                                    <div class="form-group">
                                        <h2 class="titulo-mensaje">En este momento los {{_experto}}(s) cuentan con una alta
                                            demanda de {{_encuesta}}(s), lamentamos que no pudieras resolver tus
                                            dudas</h2>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div
                                        *ngFor="let item of jsonEncuesta$ | async | sortBy: 'asc':'fkpregunta'; let index = index">
                                        <ngx-charts-bar-vertical [view]="view" [scheme]="colorScheme"
                                            [results]="_filter(item.fkpregunta)" [gradient]="gradient"
                                            [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                            [legendTitle]="item.titulo" [animations]="false" [legendPosition]="'below'"
                                            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                            [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
                                        </ngx-charts-bar-vertical>
                                    </div>
                                    <div class="text-center mt-5">
                                        <a (click)="exportToCsv(item.tituloEncuesta)" class="btn btn-outline-primary rounded-pill">
                                            <i class="fas fa-angle-double-right"></i> Descargar CSV
                                        </a><br>
                                        <span class="badge bg-secondary" *ngIf="isCSV"
                                    style="color: #fff!important;">Generando archivo, espera unos segundos</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>