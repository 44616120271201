import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgwWowModule } from 'ngx-wow';
import { NgpSortModule } from 'ngp-sort-pipe';
import { RouterModule } from '@angular/router';

// Interceptors
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbootstrapModule } from './ngbootstrap/ngbootstrap.module';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { FacebookModule } from 'ngx-facebook';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxChartsModule } from '@swimlane/ngx-charts';



import { FilterEjemploPreguntaPipe } from './shared/pipe/filter-ejemplo-pregunta.pipe';
import { InicioComponent } from './inicio/inicio.component';
import { EncuestaComponent } from './encuesta/encuesta.component';
import { ConsultaExpertoComponent } from './consulta-experto/consulta-experto.component';
import { ExpertoComponent } from './experto/experto.component';
import { ComoFuncionaComponent } from './como-funciona/como-funciona.component';
import { FaqComponent } from './faq/faq.component';
import { PoliticaDatosComponent } from './politica-datos/politica-datos.component';
import { RegistroComponent } from './registro/registro.component';
import { RegistroExpertoComponent } from './registro-experto/registro-experto.component';
import { RegistroUsuarioComponent } from './registro-usuario/registro-usuario.component';
import { ConfirmacionRegistroExpertoComponent } from './confirmacion-registro-experto/confirmacion-registro-experto.component';
import { ConfirmacionRegistroUsuarioComponent } from './confirmacion-registro-usuario/confirmacion-registro-usuario.component';
import { RecuperarExpertoComponent } from './recuperar-experto/recuperar-experto.component';
import { RecuperarUsuarioComponent } from './recuperar-usuario/recuperar-usuario.component';
import { ConfirmacionRecuperarExpertoComponent } from './confirmacion-recuperar-experto/confirmacion-recuperar-experto.component';
import { ConfirmacionRecuperarUsuarioComponent } from './confirmacion-recuperar-usuario/confirmacion-recuperar-usuario.component';
import { ActivacionExpertoComponent } from './activacion-experto/activacion-experto.component';
import { ActivacionUsuarioComponent } from './activacion-usuario/activacion-usuario.component';
import { ActivacionRecuperarExpertoComponent } from './activacion-recuperar-experto/activacion-recuperar-experto.component';
import { ActivacionRecuperarUsuarioComponent } from './activacion-recuperar-usuario/activacion-recuperar-usuario.component';
import { LoginExpertoComponent } from './login-experto/login-experto.component';
import { LoginUsuarioComponent } from './login-usuario/login-usuario.component';
import { MiExpertoComponent } from './mi-experto/mi-experto.component';
import { MiUsuarioComponent } from './mi-usuario/mi-usuario.component';
import { ActivacionReferenciaExpertoComponent } from './activacion-referencia-experto/activacion-referencia-experto.component';
import { PreguntaComponent } from './pregunta/pregunta.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NuevaEncuestaComponent } from './nueva-encuesta/nueva-encuesta.component';
import { InformeEncuestaXlsComponent } from './informe-encuesta-xls/informe-encuesta-xls.component';
import { NuevaPreguntaComponent } from './nueva-pregunta/nueva-pregunta.component';
import { PreguntaPendienteComponent } from './pregunta-pendiente/pregunta-pendiente.component';
import { PreguntaCambioExpertoComponent } from './pregunta-cambio-experto/pregunta-cambio-experto.component';
import { PreguntaResueltaComponent } from './pregunta-resuelta/pregunta-resuelta.component';
import { ValoracionRespuestaComponent } from './valoracion-respuesta/valoracion-respuesta.component';
import { PreguntaHiloComponent } from './pregunta-hilo/pregunta-hilo.component';
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import { ReciboComponent } from './recibo/recibo.component';
import { RecomendarAmigoComponent } from './recomendar-amigo/recomendar-amigo.component';
import { RecomendarExpertoComponent } from './recomendar-experto/recomendar-experto.component';
import { RespuestaPendienteComponent } from './respuesta-pendiente/respuesta-pendiente.component';
import { ResponderComponent } from './responder/responder.component';
import { RespuestaResueltaComponent } from './respuesta-resuelta/respuesta-resuelta.component';
import { OpinarComponent } from './opinar/opinar.component';
import { PerfilExpertoComponent } from './perfil-experto/perfil-experto.component';
import { ComisionComponent } from './comision/comision.component';
import { SerDestacadoComponent } from './ser-destacado/ser-destacado.component';

import { MenuUsuarioComponent } from './shared/component/menu/menu-usuario/menu-usuario.component';
import { MenuExpertoComponent } from './shared/component/menu/menu-experto/menu-experto.component';
import { NotificacionExpertoComponent } from './notificacion-experto/notificacion-experto.component';
import { NotificacionUsuarioComponent } from './notificacion-usuario/notificacion-usuario.component';
import { RespuestaAbiertaComponent } from './respuesta-abierta/respuesta-abierta.component';
import { OpinionAbiertaComponent } from './opinion-abierta/opinion-abierta.component';

import { InterceptorService } from './shared/service/-interceptor.service';
import { SerPrimeComponent } from './ser-prime/ser-prime.component';
import { TarifaComponent } from './tarifa/tarifa.component';
import { UrlsafePipe } from './shared/pipe/urlsafe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    FilterEjemploPreguntaPipe,
    InicioComponent,
    EncuestaComponent,
    ConsultaExpertoComponent,
    ExpertoComponent,
    ComoFuncionaComponent,
    FaqComponent,
    PoliticaDatosComponent,
    RegistroComponent,
    RegistroExpertoComponent,
    RegistroUsuarioComponent,
    ConfirmacionRegistroExpertoComponent,
    ConfirmacionRegistroUsuarioComponent,
    RecuperarExpertoComponent,
    RecuperarUsuarioComponent,
    ConfirmacionRecuperarExpertoComponent,
    ConfirmacionRecuperarUsuarioComponent,
    ActivacionExpertoComponent,
    ActivacionUsuarioComponent,
    ActivacionRecuperarExpertoComponent,
    ActivacionRecuperarUsuarioComponent,
    LoginExpertoComponent,
    LoginUsuarioComponent,
    MiExpertoComponent,
    MiUsuarioComponent,
    ActivacionReferenciaExpertoComponent,
    PreguntaComponent,
    NotFoundComponent,
    NuevaEncuestaComponent,
    InformeEncuestaXlsComponent,
    NuevaPreguntaComponent,
    PreguntaPendienteComponent,
    PreguntaCambioExpertoComponent,
    PreguntaResueltaComponent,
    ValoracionRespuestaComponent,
    PreguntaHiloComponent,
    PerfilUsuarioComponent,
    ReciboComponent,
    RecomendarAmigoComponent,
    RecomendarExpertoComponent,
    RespuestaPendienteComponent,
    ResponderComponent,
    RespuestaResueltaComponent,
    OpinarComponent,
    PerfilExpertoComponent,
    ComisionComponent,
    SerDestacadoComponent,
    MenuUsuarioComponent,
    MenuExpertoComponent,
    NotificacionExpertoComponent,
    NotificacionUsuarioComponent,
    RespuestaAbiertaComponent,
    OpinionAbiertaComponent,
    SerPrimeComponent,
    TarifaComponent,
    UrlsafePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgwWowModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbootstrapModule,
    FormsModule,
    ReactiveFormsModule,
    NgpSortModule,
    RouterModule,
    QuillModule.forRoot(
      {
        placeholder: '',
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic', 'underline', 'strike']]
        }
      }
    ),
    FacebookModule.forRoot(),
    InfiniteScrollModule,
    NgxChartsModule,
  ],
  exports: [
    MaterialModule,
    NgbootstrapModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
