<div class="row align-items-center">
    <div class="col-xl-12 col-lg-12 col-md-12 normal">
        <div class="site-header-one__logo mb-3 mt-5 mt-sm-1">
            <a [href]="_urlWeb">
                <img src="assets/logo_128.png" width="150" alt="">
            </a>
            <!-- <span class="side-menu__toggler"><i class="fa fa-bars" style="color: #ff0b64;"></i></span> -->
        </div>
        <div class="widgets-wrap float-right">
            <div class="widget-header menu">
                <a [href]="'/mi-usuario/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-home gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">INICIO</small>
                </a>
            </div>
            <!-- Inicio cambio Lorena Munera Henao 8/06/2023 -->
            <div class="widget-header menu">
                <a [href]="'/consulta-experto/all/1'" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-home gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">NUEVA PREGUNTA</small>
                </a>
            </div>
            <!-- Fin cambio Lorena Munera Henao 8/06/2023 -->
            <!-- <div class="widget-header menu">
                <a [routerLink]="['/nueva-pregunta', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="far fa-plus gris"></i>
                    </div> -->
                    <!-- <small class="text"></small> -->
                    <!-- <small class="text boton">{{_pregunta}}</small>
                </a>
            </div> -->
            <!-- <div class="widget-header menu">
                <a [routerLink]="['/nueva-encuesta', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="far fa-plus gris"></i>
                    </div> -->
                    <!-- <small class="text"></small> -->
                    <!-- <small class="text boton">{{_encuesta}}</small>
                </a>
            </div> -->
            <div class="widget-header menu">
                <a [routerLink]="['/perfil-usuario', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="far fa-user gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">PERFIL</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [routerLink]="['/recomendar-amigo', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-share gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">RECOMENDAR AMIGO</small>
                </a>
            </div>
            <!-- <div class="widget-header menu"> * OJO *
                <a class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-user-tie gris"></i>
                    </div>
                    <small class="text boton">HACERSE EXPERTO</small>
                </a>
            </div> -->
            <div class="widget-header menu">
                <a [routerLink]="['/recomendar-experto', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-share gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">RECOMENDAR EXPERTO</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [href]="'/recibo/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-file-invoice gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">RECIBOS</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [href]="'/notificacion-usuario/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-exclamation gris"></i>
                        <span class="badge bg-rojo ml-1" *ngIf="notificaciones > 0"
                            style="font-size: 0.75rem!important; color: #fff!important; position: absolute;">{{notificaciones}}</span>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">NOTIFICACIONES</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a (click)="auth.salirMensaje()" class="widget-view" style="cursor: pointer;">
                    <div class="icon-area">
                        <i class="fas fa-door-open gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">CERRAR SESION</small>
                </a>
            </div>
        </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 responsive">
        <div class="site-header-one__logo mb-3 mt-5 mt-sm-1">
            <a [href]="_urlWeb">
                <img src="assets/logo_128.png" width="150" alt="">
            </a>
            <!-- <span class="side-menu__toggler"><i class="fa fa-bars" style="color: #ff0b64;"></i></span> -->
        </div>
        <span class="side-menu__toggler widgets-wrap float-right" (click)="changeMenu()" *ngIf="!isMenu">
            <i class="fa fa-bars" style="color: #ff0b64;"></i>
        </span>
        <span class="side-menu__toggler widgets-wrap float-right" (click)="changeMenu()" *ngIf="isMenu">
            <i class="fas fa-times" style="color: #ff0b64;"></i>
        </span>
        <div class="widgets-wrap float-right" *ngIf="isMenu">
            <div class="widget-header menu">
                <a [href]="'/mi-usuario/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-home gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">INICIO</small>
                </a>
            </div>
            <!-- <div class="widget-header menu">
                <a [routerLink]="['/nueva-pregunta', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="far fa-plus gris"></i>
                    </div>
                    <small class="text boton">{{_pregunta}}</small>
                </a>
            </div> -->
            <!-- <div class="widget-header menu">
                <a [routerLink]="['/nueva-encuesta', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="far fa-plus gris"></i>
                    </div>
                    <small class="text boton">{{_encuesta}}</small>
                </a>
            </div> -->
            <div class="widget-header menu">
                <a [routerLink]="['/perfil-usuario', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="far fa-user gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">PERFIL</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [routerLink]="['/recomendar-amigo', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-share gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">RECOMENDAR AMIGO</small>
                </a>
            </div>
            <!-- <div class="widget-header menu"> * OJO *
                <a class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-user-tie gris"></i>
                    </div>
                    <small class="text boton">HACERSE EXPERTO</small>
                </a>
            </div> -->
            <div class="widget-header menu">
                <a [routerLink]="['/recomendar-experto', fk]" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-share gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">RECOMENDAR EXPERTO</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [href]="'/recibo/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-file-invoice gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">RECIBOS</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a [href]="'/notificacion-usuario/' + fk" class="widget-view">
                    <div class="icon-area">
                        <i class="fas fa-exclamation gris"></i>
                        <span class="badge bg-rojo ml-1" *ngIf="notificaciones > 0"
                            style="font-size: 0.75rem!important; color: #fff!important; position: absolute;">{{notificaciones}}</span>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">NOTIFICACIONES</small>
                </a>
            </div>
            <div class="widget-header menu">
                <a (click)="auth.salirMensaje()" class="widget-view" style="cursor: pointer;">
                    <div class="icon-area">
                        <i class="fas fa-door-open gris"></i>
                    </div>
                    <!-- <small class="text"></small> -->
                    <small class="text boton">CERRAR SESION</small>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="row align-items-center">
    <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="float-left">
            <main>
                <article class="card mb-3" style="border: none!important;">
                    <div class="card-body">
                        <figure class="icontext">
                            <div class="icon mr-4" *ngIf="url">
                                <img class="rounded-circle img-sm border" [src]="url">
                            </div>
                            <div class="text">
                                <strong class="rojo">Bienvenido</strong> <br>
                                <strong class="gris">{{nickname}}</strong> <br>
                            </div>
                        </figure>
                    </div>
                </article>
            </main>
        </div>
    </div>
</div>