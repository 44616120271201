<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">{{_pregunta}}</h2>
    </div> -->
    <div class="container">
        <div class="animated fadeIn">
            <h2 class="titulo">REQUIERO UN {{_pregunta.toUpperCase()}}</h2>
            <p class="mt-3 text-justify">El Servicio Expert Tip pretende ayudarte con esas inquietudes sobre negocios
                que
                tienes en tu empresa, proyecto o emprendimiento. Recuerda ser muy específico en la pregunta,
                contextualiza
                la situación o problema puntual a resolver y cuales son las posibles causas de esa situación. Trata de
                no
                hacer preguntas generales como ¿Cómo aumentar mis ventas? ¿Cómo Cambiar mi Cultura Organizacional? ese
                tipo
                de preguntas requieren de una consultoría a profundidad.
            </p>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isEstado === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="isEstado === 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_experto}}, intenta
                                nuevamente.</strong>
                            <a [routerLink]="['/consulta-experto/busquedad/%/1']"
                                class="btn btn-block btn-primary ml-2">
                                <i class="fas fa-search"></i> <span class="text">Buscar</span>
                            </a>
                        </div>
                    </header>

                    <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal">
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isEstado === 1">
                            <div class="card-body">
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 col-form-label">{{_area}}<span
                                            *ngIf="validator('fkarea')">*</span></label>
                                    <div class="col-md-9" *ngIf="isRama">
                                        <div class="input-group">
                                            <input type="text" class="form-control" value="{{fkarea}}" disabled>
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-primary py-0 px-2"
                                                    (click)="refresh()"> <i class="fas fa-sync"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9 text-center" *ngIf="(jsonAreas$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonAreas$ | async)?.length > 0 && !isRama">
                                        <select class="form-control" formControlName="fkarea"
                                            (change)="getRamas($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let area of jsonAreas$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="area.id + ':' + area.nombre">
                                                {{ area.nombre }}</option>
                                        </select>
                                        <div *ngIf="form.get('fkarea').errors && form.get('fkarea').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['fkarea'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 col-form-label">{{_rama}}<span
                                            *ngIf="validator('fkrama')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isRama || isEspecialidad">
                                        <input type="text" class="form-control" value="{{fkrama}}" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonRamas$ | async)?.length <= 0 && isRama">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonRamas$ | async)?.length > 0 && !isEspecialidad">
                                        <select class="form-control" formControlName="fkrama"
                                            (change)="getEspecialidads($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let rama of jsonRamas$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="rama.id + ':' + rama.nombre">
                                                {{ rama.nombre }}</option>
                                        </select>
                                        <div *ngIf="form.get('fkrama').errors && form.get('fkrama').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['fkrama'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 col-form-label">{{_especialidad}}<span
                                            *ngIf="validator('fkespecialidad')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isEspecialidad || isExperto">
                                        <input type="text" class="form-control" value="{{fkespecialidad}}" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonEspecialidads$ | async)?.length <= 0 && isEspecialidad">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9"
                                        *ngIf="(jsonEspecialidads$ | async)?.length > 0 && !isExperto">
                                        <select class="form-control" formControlName="fkespecialidad"
                                            (change)="getExpertos($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option
                                                *ngFor="let especialidad of jsonEspecialidads$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="especialidad.id + ':' + especialidad.nombre">
                                                {{ especialidad.nombre }}</option>
                                        </select>
                                        <div
                                            *ngIf="form.get('fkespecialidad').errors && form.get('fkespecialidad').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['fkespecialidad'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 col-form-label">{{_experto}}</label>
                                    <div class="col-md-9" *ngIf="!isExperto">
                                        <input type="text" class="form-control" value="{{fkexperto}}" disabled>
                                    </div>
                                    <div class="col-md-9 text-center" *ngIf="isBuscandoExperto && isExperto">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonExpertos$ | async)?.length === 0 && isExperto">
                                        <span class="mr-md-auto mb-5">No hemos encontrado tu(s) {{_experto}}, intenta
                                            nuevamente.</span>
                                        <a (click)="reload()" class="btn btn-primary ml-2">
                                            <i class="fas fa-sync"></i>
                                        </a>
                                    </div>
                                    <div class="col-md-9"
                                        *ngIf="(jsonExpertos$ | async)?.length > 0 && (jsonExpertos$ | async)?.length === 1">
                                        <select class="form-control" formControlName="fkexperto"
                                            (change)="changedExperto($event)">
                                            <option
                                                *ngFor="let experto of jsonExpertos$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="experto.id + ':' + experto.nickname">
                                                {{ experto.nickname }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="(jsonExpertos$ | async)?.length > 0 && (jsonExpertos$ | async)?.length > 1">
                                        <select class="form-control" formControlName="fkexperto"
                                            (change)="changedExperto($event)">
                                            <option value="0:0">... {{_pregunta}} para todos</option>
                                            <option
                                                *ngFor="let experto of jsonExpertos$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="experto.id + ':' + experto.nickname">
                                                {{ experto.nickname }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3"
                                        *ngIf="(jsonExpertos$ | async)?.length > 0 && (jsonExpertos$ | async)?.length > 1">
                                        <button type="button" class="btn btn-primary btn-block" (click)="setExperto()">
                                            Enviar a todos
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngIf="(jsonExpertos$ | async)?.length > 0 || isSeleccionar">
                                    <label class="col-md-3 col-form-label">TÍTULO<span
                                            *ngIf="validator('titulo')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="titulo">
                                        <div *ngIf="form.get('titulo').errors && form.get('titulo').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['titulo'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['titulo'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['titulo'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngIf="(jsonExpertos$ | async)?.length > 0 || isSeleccionar">
                                    <label class="col-md-3 col-form-label">CONTENIDO<span
                                            *ngIf="validator('contenido')">*</span></label>
                                    <div class="col-md-9">
                                        <quill-editor [styles]="{height: '250px'}"
                                            (onContentChanged)="onChangue($event)" formControlName="contenido">
                                        </quill-editor>
                                        <small>Contador de caracteres, {{ totalCaracteres }} <span
                                                class="text-danger">Maximo 500</span></small>
                                        <span class="help-block text-danger" *ngIf="isMailContenido">
                                            + No puedes incluir correos electronicos en el texto
                                        </span>
                                        <span class="help-block text-danger" *ngIf="isContenidoIncorrecto">
                                            + No puedes incluir esta palabra en el texto
                                        </span>
                                        <div *ngIf="form.get('contenido').errors && form.get('contenido').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row"
                                    *ngIf="(jsonExpertos$ | async)?.length > 0 || isSeleccionar">
                                    <label class="col-md-3 col-form-label">CORREO ELECTRÓNICO<span
                                            *ngIf="validator('correoelectronicotemp')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoelectronicotemp"
                                            autocomplete="off" disabled>
                                        <div *ngIf="form.get('correoelectronicotemp').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronicotemp'].hasError('pattern')">
                                                + Ingresa un correo valido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="form.get('correoelectronicotemp').errors && form.get('correoelectronicotemp').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronicotemp'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isEstado === 1">
                            <div class="card-body">
                                <div class="text-center">
                                    <span *ngIf="isPendientePago > 0" class="badge bg-secondary m-1"
                                        style="color: #fff!important;">Tienes pendiente un pago, debes resolverlo para
                                        continuar</span><br>
                                    <button type="button" class="btn btn-secondary rounded-pill" *ngIf="(isMailContenido || isContenidoIncorrecto || form.invalid || 
                                        isPendientePago > 0) &&
                                        isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Enviar {{_pregunta}}
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill" *ngIf="form.valid && !isMailContenido && !isContenidoIncorrecto && 
                                        isPendientePago === 0 &&
                                        isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Enviar {{_pregunta}}
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>