<section class="banner-all">
    <!-- <div class="container div-titulo">
        <h2 class="titulo">{{_experto}}</h2>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <header class="mb-3">
                    <div class="form-inline" *ngIf="isBuscando === -1 || isEstado === -1">
                        <strong class="mr-md-auto">Buscando ...</strong>
                    </div>
                    <div class="form-inline"
                        *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0 || isEstado === 0">
                        <strong class="mr-md-auto mt-5 mb-5">No hemos encontrado resultados que coincidan con tu
                            búsqueda.</strong>
                        <!-- <a [routerLink]="['/consulta-experto/busquedad/%/1']" class="btn btn-block btn-primary">
                            <i class="fas fa-search"></i> <span class="text">Buscar</span>
                        </a> -->
                    </div>
                    <div class="form-inline"
                        *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0 || isEstado === 0">
                        <div class="col-xl-3 col-lg-3"></div>
                        <div class="col-xl-6 col-lg-6">
                            <div class="col input-group w-100"
                                style="padding-left: 0 !important; padding-right: 0!important;">
                                <input class="form-control text-center"
                                    placeholder="Prueba con otro criterio de búsquedad" type="text" [(ngModel)]="otro"
                                    (keyup.enter)="otraBusquedad()">
                                <div class="input-group-append" *ngIf="otro.length > 0">
                                    <button type="button" (click)="otraBusquedad()" class="btn btn-block btn-primary">
                                        <i class="fas fa-search"></i> <span class="text">Buscar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3"></div>
                    </div>
                </header>
                <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                    <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                </div>
                <div *ngIf="total > 0 && isEstado === 1" class="mt-5">
                    <article class="card card-product-list" style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertos$ | async">
                        <div class="row no-gutters">
                            <aside class="col-md-3">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1.5rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i>
                                    </span>
                                    <span
                                        style="font-size: 1.5rem; top: unset; right: 10px; bottom: 10px; left: unset; position: absolute;"
                                        class="badge badge-danger">
                                        {{ experto.nombrenivel }} </span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                                <div class="m-2">
                                    <div class="rating-wrap text-center mb-2" *ngIf="experto.calificaciones === 0">
                                        <ul class="rating-stars">
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                </div>

                                <div class="m-2">
                                    <button (click)="share('/experto/' + experto.id)" class="btn btn-sm m-1" style="background-color: #3b5998;
                                    border-color: #3b5998; color: #fff;">
                                        <i class="fab fa-facebook-square"></i> Compartir
                                    </button>
                                    <button (click)="copy('experto/' + experto.id)"
                                        class="btn btn-sm btn-secondary m-1">
                                        <i class="far fa-envelope"></i> Copiar URL
                                    </button>
                                    <a *ngIf="fk" [routerLink]="['/recomendar-experto', fk, experto.id]"
                                        class="btn btn-sm btn-secondary m-1">
                                        <i class="far fa-envelope"></i> Correo electrónico
                                    </a>
                                </div>
                            </aside>
                            <div class="col-md-9">
                                <div class="info-main">
                                    <span class="h5 title" style="line-height: 1.6rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </span>
                                    <h3 style="margin: 0;">Años de experiencia: {{experto.anosexperiencia}}
                                        año(s)</h3>
                                    <h3 style="margin: 0;">Tarifa: {{experto.tarifa | currency:'$':'symbol':'1.0' }}
                                    </h3>
                                    <div>
                                        <span class="badge badge-dark mr-1" *ngIf="experto.fkarea1 > 0">{{
                                            getNombreArea(experto.fkarea1)[0].nombre
                                            }}</span>
                                        <span class="badge badge-dark mr-1" *ngIf="experto.fkarea2 > 0">{{
                                            getNombreArea(experto.fkarea2)[0].nombre
                                            }}</span>
                                    </div>

                                    <div>
                                        <span class="badge badge-secondary mr-1" *ngIf="experto.fkrama1 > 0">{{
                                            getNombreRama(experto.fkrama1)[0].nombre
                                            }}</span>
                                        <span class="badge badge-secondary mr-1" *ngIf="experto.fkrama2 > 0">{{
                                            getNombreRama(experto.fkrama2)[0].nombre
                                            }}</span>
                                    </div>

                                    <div *ngIf="isEspecialidad === 0">
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad11">
                                            {{ getNombreEspecialidad(experto.fkespecialidad11)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad12">
                                            {{ getNombreEspecialidad(experto.fkespecialidad12)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad13">
                                            {{ getNombreEspecialidad(experto.fkespecialidad13)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad14">
                                            {{ getNombreEspecialidad(experto.fkespecialidad14)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad15">
                                            {{ getNombreEspecialidad(experto.fkespecialidad15)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad21">
                                            {{ getNombreEspecialidad(experto.fkespecialidad21)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad22">
                                            {{ getNombreEspecialidad(experto.fkespecialidad22)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad23">
                                            {{ getNombreEspecialidad(experto.fkespecialidad23)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad24">
                                            {{ getNombreEspecialidad(experto.fkespecialidad24)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad25">
                                            {{ getNombreEspecialidad(experto.fkespecialidad25)[0].nombre }}
                                        </span>
                                    </div>
                                    <h1 class="mt-3">Perfil Profesional</h1>
                                    <p [innerHtml]="experto.curriculum"></p>

                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row-reverse" style="min-height: 100%;">
                            <div class="p-2" *ngIf="isEspecialidad === 1">
                                <a *ngIf="experto.fkespecialidad11 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad12 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad13 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad14 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad15 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad21 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad21]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad22 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad22]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad23 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad23]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad24 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad24]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                                <a *ngIf="experto.fkespecialidad25 === especialidad"
                                    [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad25]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                                    border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                            </div>
                            <div class="p-2" *ngIf="isEspecialidad === 0">
                                <a [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                    class="btn btn-block btn-primary" style="background-color: #5f08bc !important;
                            border-color: #5f08bc !important;">
                                    <span class="text">¿Deseas hacer una Pregunta?</span>
                                </a>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="mt-5 mb-3" *ngIf="total <= 0 && totalRelacionado > 0">
                    <br><br>
                    <h4 class="titulo-confirmacion">También puede interesarte</h4>
                </div>
                <div *ngIf="total <= 0 && totalRelacionado > 0" class="row no-gutters p-1">
                    <article class="card card-product-list offset-md-1 col-md-5 col-12"
                        style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertosRelacionado$ | async">
                        <div class="row no-gutters">
                            <aside class="col-md-6">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                            </aside>
                            <div class="col-md-6">
                                <div class="info-main">
                                    <span style="font-size: 1.1rem;" class="badge badge-danger w-100 mb-2">
                                        {{ experto.nombrenivel }} </span>

                                    <span class="h5 title" style="line-height: 1rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </span>

                                    <div class="m-2">
                                        <div class="rating-wrap mb-2" *ngIf="experto.calificaciones === 0">
                                            <ul class="rating-stars">
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star-half"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-half"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-half"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-half"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star-half"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                        <div class="rating-wrap mb-2"
                                            *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                            <ul class="rating-stars">
                                                <li style="width:100%" class="stars-active">
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                            </ul>
                                            <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                        </div>
                                    </div>

                                    <p class="mb-1">
                                        <span class="badge badge-dark mr-1" *ngIf="experto.fkarea1 > 0">{{
                                            getNombreArea(experto.fkarea1)[0].nombre
                                            }}</span>
                                        <span class="badge badge-dark mr-1" *ngIf="experto.fkarea2 > 0">{{
                                            getNombreArea(experto.fkarea2)[0].nombre
                                            }}</span>
                                    </p>

                                    <p class="mb-1">
                                        <span class="badge badge-secondary mr-1" *ngIf="experto.fkrama1 > 0">{{
                                            getNombreRama(experto.fkrama1)[0].nombre
                                            }}</span>
                                        <span class="badge badge-secondary mr-1" *ngIf="experto.fkrama2 > 0">{{
                                            getNombreRama(experto.fkrama2)[0].nombre
                                            }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</section>