<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Inicie sesión de {{_experto}}</h2> -->
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mx-auto" style="background-color: #ffffff60; max-width: 650px;">
                        <span class="span-titulo-confirmacion">
                            <mark class="titulo-confirmacion">ACCEDE A TU CUENTA {{_experto.toUpperCase()}}</mark>
                        </span>
                        <form [formGroup]="form" (ngSubmit)="get($event)" enctype="multipart/form-data">
                            <div class="card-body card-confirmacion">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Correo electrónico"
                                        formControlName="correoelectronico" required autocomplete="off" 
                                        oninput="this.value = this.value.toLowerCase()">
                                    <div *ngIf="form.get('correoelectronico').errors?.pattern">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['correoelectronico'].hasError('pattern')">
                                            + Ingresa un correo valido
                                        </span>
                                    </div>
                                    <div
                                        *ngIf="form.get('correoelectronico').errors && form.get('correoelectronico').dirty">
                                        <small class="form-text text-danger"
                                            *ngIf="form.controls['correoelectronico'].hasError('required')">
                                            + Campo requerido
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Contraseña"
                                        formControlName="contrasena" required>
                                    <div *ngIf="form.get('contrasena').errors && form.get('contrasena').dirty">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['contrasena'].hasError('required')">
                                            + Campo requerido
                                        </span>
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['contrasena'].hasError('minlength')">
                                            + Debes ingresar más caracteres
                                        </span>
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['contrasena'].hasError('maxlength')">
                                            + Excediste el valor de caracteres
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group text-center" style="margin-top: -1rem;">
                                <button type="button" class="btn btn-secondary rounded-pill"
                                    style="border: 2px solid #fff !important;"
                                    *ngIf="form.invalid && isAccion === -1"><i class="fas fa-angle-double-right"></i>
                                    Iniciar</button>
                                <button type="submit" class="btn btn-outline-primary rounded-pill"
                                    *ngIf="form.valid && isAccion === -1"><i class="fas fa-angle-double-right"></i>
                                    Iniciar</button>
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </form>
                    </div>

                    <p class="text-center mt-4"><span style="color: #665C61 !important;">¿Olvidaste tu contraseña?
                        </span> <a [routerLink]="['/recuperar-experto']">Recuperar</a>
                    </p>
                    <br><br>

                </div>
            </div>
        </div>
    </div>
</section>