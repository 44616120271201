import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Faq } from './../interface/faq';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(
    private http: HttpClient
  ) { }

  public getAll(): Observable<Faq[]> {
    return this.http.get<Faq[]>(environment.urlApi + '/Faq');
  }
}
