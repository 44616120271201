import { Component, OnInit } from '@angular/core';
import { AuthService } from './../shared/service/-auth.service';
import { environment } from './../../environments/environment';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { QueryService } from './../shared/service/-query.service';
import { ExpertoService } from './../shared/service/experto.service';
import { Experto } from './../shared/interface/experto';

@Component({
  selector: 'app-tarifa',
  templateUrl: './tarifa.component.html',
  styleUrls: ['./tarifa.component.css']
})
export class TarifaComponent implements OnInit {

  public fk: number;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  // public jsonTarifas$: Observable<any[]>;
  public jsonTarifasNUEVA$: Observable<any[]>;
  public jsonTarifasHILO$: Observable<any[]>;
  public jsonTarifasENCUESTA$: Observable<any[]>;
  // public jsonTarifasdisponibles$: Observable<any[]>;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;
  public isBuscando = -1;
  public total = 0;

  constructor(
    private expertoService: ExpertoService,
    private queryService: QueryService,
    // private especialidadService: EspecialidadService,
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      this.fk = parseInt(this.auth.obtener('fk'));
      this.getTarifas();
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      fktarifanueva: [null, Validators.required],
      fktarifahilo: [null, Validators.required],
      fktarifaencuesta: [null, Validators.required],
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  public getTarifas(): void {
    this.queryService.query_70(this.fk).subscribe(
      (tarifas: any[]) => {
        this.jsonTarifasNUEVA$ = new Observable((observer) => {
          observer.next(tarifas.filter(option => option.tipo1 === 'NUEVA'));
          observer.complete();
        });
        this.jsonTarifasHILO$ = new Observable((observer) => {
          observer.next(tarifas.filter(option => option.tipo1 === 'HILO'));
          observer.complete();
        });
        this.jsonTarifasENCUESTA$ = new Observable((observer) => {
          observer.next(tarifas.filter(option => option.tipo1 === 'ENCUESTA'));
          observer.complete();
        });
      }
    );

    this.getContenido();
  }

  public getContenido(): void {
    this.expertoService.getOne(this.fk).subscribe(
      (response: Experto) => {
        try {
          this.form.patchValue(response);
          /** ADICIONALES */
          this.isBuscando = 1;
          this.total = 1;
        } catch (error) {
          // console.log(error);
          this.isBuscando = 0;
        }
      }, error => {
        // console.log(error);
        this.isBuscando = 0;
      }
    );
  }

  /** OBLIGATORIO */
  public update(event: Event): void {
    event.preventDefault();

    const object: Experto = this.form.value;
    this.isAccion = 0;

    this.expertoService.put(object).subscribe(
      (response: Experto) => {
        // response 200
        this.isAccion = 1;
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        });

        swal.fire({
          imageUrl: this._urlTest + '/logo_128.png',
          imageHeight: 64,
          icon: 'success',
          title: 'Hemos actualizado tu información ...',
          showConfirmButton: false,
          timer: 4000
        });
      },
      error => {
        // response 500
        this.isAccion = -1;
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Encontramos que tienes una cuenta asociada, intenta iniciar tu sesión',
          showConfirmButton: false,
          timer: 5000
        });
      }
    );
  }

}
