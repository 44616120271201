<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">Recibos de {{_usuario}}</h2>
    </div> -->
    <div class="container">
        <div class="animated fadeIn">
            <h2 class="titulo">RECIBOS</h2>
            <div class="row mt-3" *ngIf="(jsonRecibos$ | async)?.length === 0 && isBuscando === 1">
                <div class="card-body card-confirmacion text-center col-md-10 offset-md-1">
                    <div class="form-group">
                        <h2 class="titulo-mensaje">NO POSEES PAGOS</h2>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3" *ngIf="(jsonRecibos$ | async)?.length > 0">
                <div class="col-12 text-center" *ngIf="isBuscando === -1">
                    <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                </div>
                <div class="col-md-12 w-auto table-responsive p-1" *ngIf="isBuscando === 1">
                    <table class="table-sm table-striped table-hover" style="width: 100%!important;">
                        <!-- <div class="col-md-12">
                    <table class="table table-sm table-striped w-100"> -->
                        <thead>
                            <th>TÍTULO</th>
                            <th>TOTAL</th>
                            <th>ESTADO</th>
                            <th>RECIBO</th>
                            <th>MEDIO PAGO</th>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let item of jsonRecibos$ | async | sortBy: 'desc':'id' | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                <td>{{item.fk}} - {{item.titulo}}</td>
                                <td>{{ item.total | currency:'$':'symbol':'1.0' }}</td>
                                <td>{{item.estado}}</td>
                                <td>{{item.id}}</td>
                                <td>{{item.franquicia}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="row mt-3" *ngIf="(jsonRecibos$ | async)?.length > 0 && isBuscando === 1">
                <!-- <div class="col-6 text-right">
                    <button class="btn btn-outline-primary rounded-pill" disabled>
                        <i class="fas fa-step-backward"></i>&nbsp;Anterior</button>
                </div>
                <div class="col-6 text-left">
                    <button class="btn btn-outline-primary rounded-pill" disabled>
                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                </div> -->
                <div class="col-12 d-flex justify-content-center">
                    <ngb-pagination [collectionSize]="(jsonRecibos$ | async)?.length" [(page)]="page" [maxSize]="10"
                        [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</section>