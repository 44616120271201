import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import { AuthService } from './../shared/service/-auth.service';
import { QueryService } from './../shared/service/-query.service';
import { NotificacionUsuario } from './../shared/interface/notificacion-usuario';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-notificacion-usuario',
  templateUrl: './notificacion-usuario.component.html',
  styleUrls: ['./notificacion-usuario.component.css']
})
export class NotificacionUsuarioComponent implements OnInit {

  public jsonNotificacion$: Observable<any[]>;
  public total = 0;
  public isBuscando = -1;
  public page = 1;
  public pageSize =10;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  constructor(
    private auth: AuthService,
    private queryService: QueryService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        this.getNotificacion(params.fk);
      }
    });
  }

  private getNotificacion(id: any): void {
    this.queryService.query_23(id).subscribe(
      (expertos: NotificacionUsuario[]) => {
        this.jsonNotificacion$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.jsonNotificacion$.subscribe(response => {
          this.total = response.length;
        });

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
      }
    );
  }

}
