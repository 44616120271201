import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pregunta-pendiente',
  templateUrl: './pregunta-pendiente.component.html',
  styleUrls: ['./pregunta-pendiente.component.css']
})
export class PreguntaPendienteComponent implements OnInit {

  public fk: number;
  public id: number;

  public jsonPreguntaHilo$: Observable<any[]>;
  public jsonEstados$: Observable<any>;
  public total = 0;
  public isBuscando = -1;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private formBuilder: FormBuilder,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      bono: [null],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      // this.fk = parseInt(params.fk);
      this.fk = parseInt(this.auth.obtener('fk'));
      this.id = parseInt(params.id);//Pregunta.id
      this.getContenido();
    });
  }

  public getContenido(): void {
    this.queryService.query_34(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonPreguntaHilo$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });

        this.jsonPreguntaHilo$.subscribe(response => {
          this.total = response.length;
        });

        this.getEstados();

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
      }
    );
  }

  public go(id: any): void {
    window.location.href = this._urlPay+'?tipo=hilo&id=' + id + '&bono=' + this.form.get('bono').value;
  }

  public update(fk: number, idHilo: number): void {
    const confirmacionCarro = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary m-2',
        cancelButton: 'btn btn-secondary m-2'
      },
      buttonsStyling: false
    });

    confirmacionCarro.fire({
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si, estoy seguro de declinar',
      cancelButtonText: 'No, cancelar mi desición',
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
      // tslint:disable-next-line: no-shadowed-variable
    }).then((result) => {
      if (result.value) {
        this.queryService.query_36(fk, idHilo).subscribe(
          (response: any) => {
            // response 200
            this.isAccion = 1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              title: 'Hemos modificado tu ' + this._pregunta + ' ...',
              showConfirmButton: false,
              timer: 4000
            });
            
            setTimeout(function () {
              window.location.href = '/mi-usuario/' + fk;
            }, 4000);
          }, error => {
            // response 500
            this.isAccion = -1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
              showConfirmButton: false,
              timer: 5000
            });
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) { }
    });
  }

  public getEstados(): void {
    this.queryService.query_35(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });
      }, error => {
      }
    );
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

}
