<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <div class="container div-titulo">
        <h2 class="titulo">TU PERFIL DE {{_usuario.toUpperCase()}}</h2>
    </div>
    <div class="container mb-3">
        <div class="animated fadeIn">
            <div class="row" *ngIf="(jsonPanel$ | async)?.length === 0 && isBuscando === 1 && !isFiltrado">
                <div class="card-body card-confirmacion text-center col-md-10 offset-md-1">
                    <div class="form-group">
                        <h2 class="titulo-mensaje">NO POSEE SOLICITUDES ASOCIADAS</h2>
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="(jsonPanel$ | async)?.length > 0 || isFiltrado">
                <!-- <div class="alert alert-primary w-100" role="alert">{{_pregunta.toUpperCase()}}</div> -->
                <div class="col-md-12">
                    <div class="col input-group w-100">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-search"></i></span>
                        </div>
                        <select class="form-control text-center" [formControl]="myControl0">
                            <option value="{{_experto.toUpperCase()}}">{{_experto}}</option>
                            <option value="{{_pregunta.toUpperCase()}}">{{_pregunta}}</option>
                            <option value="SERVICIO">SERVICIO</option>
                            <option value="ESTADO">ESTADO</option>
                        </select>
                        <input *ngIf="myControl0.value !== 'SERVICIO'" class="form-control text-center"
                            placeholder="CRITERIO" type="text" [formControl]="myControl1">
                        <select *ngIf="myControl0.value === 'SERVICIO'" class="form-control text-center"
                            [formControl]="myControl1">
                            <option [ngValue]="null" disabled>... Seleccione</option>
                            <option [ngValue]="_pregunta">{{_pregunta}}</option>
                            <option [ngValue]="_hilo">{{_hilo}}</option>
                            <option [ngValue]="_encuesta">{{_encuesta}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="(jsonPanel$ | async)?.length > 0">
                <div class="col-12 text-center" *ngIf="isBuscando === -1">
                    <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                </div>
                <div class="col-md-12 w-auto table-responsive p-1" *ngIf="isBuscando === 1">
                    <table class="table-sm table-striped table-hover"
                        style="width: 100%!important; font: 500 14px/18px Poppins, sans-serif;">
                        <!-- <div class="col-md-12">
                    <table class="table table-sm table-striped w-100"> -->
                        <thead>
                            <th class="text-center">{{_experto.toUpperCase()}}</th>
                            <th class="text-center">TÍTULO DEL SERVICIO</th>
                            <th class="text-center">TIEMPO</th>
                            <th class="text-center">SERVICIO</th>
                            <th class="text-center">CLASE</th>
                            <th class="text-center">ESTADO</th>
                            <th class="text-center">ACCIÓN</th>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let item of jsonPanel$ | async | sortBy: 'asc':'-id' | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                <td *ngIf="parseInt(item.fkexperto) > 0">{{item.nickname}}</td>
                                <td *ngIf="parseInt(item.fkexperto) === 0">VARIOS EXPERTOS<br><b>Respuestas:</b>
                                    {{item.numerorespuestaencuesta}}</td>
                                <td>{{item.titulo}}</td>
                                <td
                                    *ngIf="item.estado2 === 'PENDIENTE' || item.estado2 === 'REVISION' || item.estado2 === 'RECHAZADA'">
                                    {{item.tiempoTotal}}</td>
                                <td
                                    *ngIf="item.estado2 === 'RESUELTA' || item.estado2 === 'NO_PAGO' || item.estado2 === 'DECLINADA'">
                                    <!-- {{item.tiempoRespuesta}} -->
                                </td>
                                <td *ngIf="item.estado1 === 'NUEVA'">{{_pregunta}}</td>
                                <td *ngIf="item.estado1 === 'HILO'">{{_hilo}}</td>
                                <td *ngIf="item.estado1 === 'ENCUESTA'">{{_encuesta}}</td>
                                <td>{{item.tipo}}</td>
                                <td>{{item.estado2}}</td>
                                <td colspan="2" *ngIf="item.estado1 !== 'ENCUESTA' &&  item.estado2 === 'REVISION'">
                                    <a class="btn btn-pendiente"
                                        (click)="aprobarPregunta(item.fkpregunta, item.fkhilo)">
                                        CONFIRMAR </a>
                                </td>
                                <td colspan="2" *ngIf="item.estado1 === 'ENCUESTA' &&  item.estado2 === 'REVISION'">
                                    <a class="btn btn-pendiente"
                                        (click)="aprobarEncuesta(item.fkencuesta)">
                                        CONFIRMAR </a>
                                </td>
                                <td class="text-center" *ngIf="item.tipo === 'DIRECTA' && item.estado2 === 'RECHAZADA'">
                                    <a class="btn btn-sm btn-pendiente" [routerLink]="[item.url]" style="width: 2rem;">
                                        <i class="far fa-eye"></i></a>
                                </td>
                                <td class="text-center" *ngIf="item.tipo === 'DIRECTA' && 
                                    item.estado1 !== 'ENCUESTA' && 
                                    item.estado2 === 'PENDIENTE'">
                                    <a class="btn btn-sm btn-pendiente" [routerLink]="[item.url]" style="width: 2rem;">
                                        <i class="far fa-eye"></i></a>
                                </td>
                                <td class="text-center" *ngIf="item.tipo === 'ABIERTA' &&
                                item.estado1 !== 'ENCUESTA' && 
                                 item.estado2 === 'PENDIENTE'">
                                    <a class="btn btn-sm btn-pendiente" [routerLink]="[item.url]" style="width: 2rem;">
                                        <i class="far fa-eye"></i></a>
                                </td>
                                <td class="text-center" *ngIf="item.tipo === 'ABIERTA' && 
                                item.estado1 === 'ENCUESTA' &&
                                 item.estado2 === 'PENDIENTE'">
                                    <a class="btn btn-sm btn-no-disponible" style="width: 2rem;">
                                        <i class="fas fa-clock"></i></a>
                                </td>
                                <td class="text-center" *ngIf="(item.tipo === 'DIRECTA' || item.tipo === 'ABIERTA') && 
                                    item.estado1 !== 'ENCUESTA' && 
                                    item.estado2 === 'DECLINADA'">
                                    <a class="btn btn-sm btn-no-disponible" [routerLink]="[item.url]"
                                        style="width: 2rem;">
                                        <i class="far fa-eye"></i></a>
                                </td>
                                <td class="text-center" *ngIf="(item.tipo === 'DIRECTA' || item.tipo === 'ABIERTA') && 
                                    item.estado1 !== 'ENCUESTA' && 
                                    item.estado2 === 'RESUELTA'">
                                    <a class="btn btn-sm btn-resuelta" [routerLink]="[item.url]" style="width: 2rem;">
                                        <i class="far fa-eye"></i></a>
                                </td>
                                <td class="text-center" *ngIf="(item.tipo === 'DIRECTA' || item.tipo === 'ABIERTA') && 
                                    item.estado1 !== 'ENCUESTA' && 
                                    item.estado2 === 'NO_PAGO'">
                                    <a class="btn btn-sm btn-resuelta" [routerLink]="[item.url]" style="width: 2rem;">
                                        <i class="far fa-eye"></i></a>
                                </td>
                                <td class="text-center" *ngIf="item.estado1 === 'ENCUESTA' 
                                    && item.estado2 === 'RESUELTA'">
                                    <a class="btn btn-sm btn-resuelta" [routerLink]="[item.url]" style="width: 2rem;">
                                        <i class="fas fa-chart-bar"></i></a>
                                </td>
                                <td class="text-center" *ngIf="item.estado1 === 'ENCUESTA' 
                                    && item.estado2 === 'NO_PAGO'">
                                    <a class="btn btn-sm btn-pendiente"
                                        [href]="_urlPay + '/?tipo=encuesta&id=' + item.fkencuesta" style="width: 2rem;">
                                        <i class="fas fa-chart-bar"></i></a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="row mt-3" *ngIf="(jsonPanel$ | async)?.length > 0 && isBuscando === 1">
                <!-- <div class="col-6 text-right">
                    <button class="btn btn-outline-primary rounded-pill" disabled>
                        <i class="fas fa-step-backward"></i>&nbsp;Anterior</button>
                </div>
                <div class="col-6 text-left">
                    <button class="btn btn-outline-primary rounded-pill" disabled>
                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                </div> -->
                <div class="col-12 d-flex justify-content-center">
                    <ngb-pagination [collectionSize]="(jsonPanel$ | async)?.length" [(page)]="page" [maxSize]="10"
                        [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>

</section>