// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlApi: 'https://slim.expertips.co/slim/public',
  urlApi2: 'https://slim2.expertips.co/slim/public',
  urlImage: 'https://image.expertips.co',
  urlFile: 'https://file.expertips.co',
  urlLoopback: 'https://image.expertips.co',
  urlNoreply: 'https://noreply.expertips.co',
  urlPay: 'https://pay.expertips.co',
  urlSlim: 'https://slim.expertips.co',
  urlSlim2: 'https://slim2.expertips.co',
  urlTest: 'https://test.expertips.co',
  urlWeb: 'localhost:4200',
  whatsApp: 'https://api.whatsapp.com/send?phone=573135881452&text=Hola, estoy interesado en informacion.',
  experto: 'Experto',
  usuario: 'Consultante',
  pregunta: 'Expert Tip',
  hilo: 'Expert Plus',
  encuesta: 'Expert Team',
  estado1: '--NUEVA--',
  estado2: '--HILO--',
  estado3: '--ENCUESTA--',
  tipo1: '--DIRECTA--',
  tipo2: '--ABIERTA--',
  area: 'Área',
  rama: 'Especialidad',
  especialidad: 'enfoque',
  destacado: 'Destacado',
};
// export const environment = {
//   production: false,
//   urlApi: 'https://localhost/slim.expertips.co/slim/public',
//   urlApi2: 'https://localhost/slim2.expertips.co/slim/public',
//   urlImage: 'https://image.expertips.co',
//   urlFile: 'https://file.expertips.co',
//   urlLoopback: 'https://image.expertips.co',
//   urlNoreply: 'https://localhost/Expertips_bcknd/noreply.expertips.co',
//   urlPay: 'https://localhost/Expertips_bcknd/pay.expertips.co',
//   urlSlim: 'https://localhost/slim.expertips.co',
//   urlSlim2: 'https://localhost/slim2.expertips.co',
//   urlTest: 'https://test.expertips.co',
//   urlWeb: 'localhost:4200',
//   whatsApp: 'https://api.whatsapp.com/send?phone=573135881452&text=Hola, estoy interesado en informacion.',
//   experto: 'Experto',
//   usuario: 'Consultante',
//   pregunta: 'Expert Tip',
//   hilo: 'Expert Plus',
//   encuesta: 'Expert Team',
//   estado1: '--NUEVA--',
//   estado2: '--HILO--',
//   estado3: '--ENCUESTA--',
//   tipo1: '--DIRECTA--',
//   tipo2: '--ABIERTA--',
//   area: 'Área',
//   rama: 'Especialidad',
//   especialidad: 'enfoque',
//   destacado: 'Destacado',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
