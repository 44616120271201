<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Recuperamos tu acceso de {{_experto}}</h2> -->
    </div>
    <div class="container">
        <div class="row">
            <!-- *** -->
            <div class="card mx-auto" style="background-color: #ffffff60; max-width: 650px;">
                <!-- *** -->
                <div class="card-body card-confirmacion text-center">
                    <!-- *** -->
                    <p style="color: #665C61 !important;">No hemos encontrado resultados que coincidan con tu búsquedad
                    </p>

                </div>
                <!-- *** -->
                <div class="form-group text-center" style="margin-top: -1rem;">
                    <a class="btn btn-outline-primary rounded-pill" href="/inicio">
                        Volver al Inicio
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>