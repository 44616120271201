import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Usuarios } from './../interface/usuarios';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    private http: HttpClient
  ) { }

  /**/public login(value1: any, value2: any): Observable<object> {
    return this.http.post(environment.urlApi2 + '/loginUsuario', { correoelectronico: value1, contrasena: value2 });
  }

  /**/public post(object: Usuarios): Observable<object> {
    return this.http.post(environment.urlApi2 + '/api/v1/users', object);
  }

  /**/public put(object: Usuarios): Observable<object> {
    return this.http.put(environment.urlApi2 + '/api/v1/users/' + object.fkusuario, { correoelectronico: object.correoelectronico, contrasena: object.contrasena });
  }
}
