import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Expertos } from './../interface/expertos';

@Injectable({
  providedIn: 'root'
})
export class ExpertosService {

  constructor(
    private http: HttpClient
  ) { }

  public login(value1: any, value2: any): Observable<object> {
    return this.http.post(environment.urlApi2 + '/loginExperto', { correoelectronico: value1, contrasena: value2 });
  }

  public post(object: Expertos): Observable<object> {
    return this.http.post(environment.urlApi2 + '/api/v1/experts', object);
  }

  public put(object: Expertos): Observable<object> {
    return this.http.put(environment.urlApi2 + '/api/v1/experts/' + object.fkexperto, { correoelectronico: object.correoelectronico, contrasena: object.contrasena });
  }
}
