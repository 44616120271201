<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <div class="container div-titulo">
        <!-- <h2 class="titulo">{{_pregunta.toUpperCase()}}</h2> -->
        <h2 class="titulo">VALORACIÓN</h2>
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row mb-3">
                <div class="col-md-12">
                    <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal">
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">VALORACIÓN<span
                                            *ngIf="validator('estrella')">*</span></label>
                                    <div class="col-md-9">
                                        <div class="form-group">
                                            <ng-template #t let-fill="fill">
                                                <span class="star" [class.full]="fill === 100">
                                                    <span class="half" [style.width.%]="fill">&#9734;</span>&#9734;
                                                </span>
                                            </ng-template>
                                            <ngb-rating formControlName="estrella" [starTemplate]="t" [max]="5">
                                            </ngb-rating>
                                        </div>
                                        <div *ngIf="form.get('estrella').errors && form.get('estrella').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['estrella'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">COMENTARIO<span
                                            *ngIf="validator('comentario')">*</span></label>
                                    <div class="col-md-9">
                                        <!-- <quill-editor [styles]="{height: '250px'}"
                                            (onContentChanged)="onChangue($event)" formControlName="comentario">
                                        </quill-editor> -->
                                        <textarea class="form-control" style="height: 180px;" formControlName="comentario" placeholder="..."></textarea>
                                        <small>Contador de caracteres, {{ totalCaracteres }} <span
                                                class="text-danger">Maximo 100</span></small>
                                        <div *ngIf="form.get('comentario').errors && form.get('comentario').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['comentario'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['comentario'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['comentario'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">SATISFECHO?<span
                                            *ngIf="validator('satisfecho')">*</span></label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="satisfecho">
                                            <option value="null" disabled>... Seleccione</option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                        </select>
                                        <div *ngIf="form.get('satisfecho').errors && form.get('satisfecho').dirty">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['satisfecho'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="text-center" *ngFor="let item of jsonEstados$ | async; let index = index">
                                    <!-- <b>{{item.tipo}}</b> - {{item.estado1}} -
                                    <b>{{item.estado2}}</b> -
                                    {{item.estado3}} - {{item.estado4}} - {{item.estado5}} -
                                    <b>{{item.estado6}}</b> -
                                    Experto {{item.fkexperto}} - {{item.horas}} horas -
                                    {{item.horasRespuesta}} horas *Respuesta<br>
                                    Pregunta {{item.fkpregunta}} - Hilo {{item.id}}<br>{{parseInt(item.estrella)}}
                                    estrellas<br> TOTAL {{item.total}} ... -->

                                    {{setInformacion(item.fkpregunta, item.titulo, item.horasRespuesta)}}
                                    <button type="button" class="btn btn-secondary rounded-pill" *ngIf="form.invalid &&
                                        isAccion === -1 &&
                                        item.estado1 === 'RESUELTA' &&
                                        item.estado4 === 'RESUELTA' &&
                                        item.estado5 === 'ACEPTADA' &&
                                        parseInt(item.fkexperto) > 0 &&
                                        parseInt(item.fkexperto) === fkexperto &&
                                        parseInt(item.estrella) === 0 &&
                                        parseInt(item.total) === 1">
                                        <i class="fas fa-angle-double-right"></i> Valorar {{_pregunta}}
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill" *ngIf="form.valid && isAccion === -1 &&
                                        item.estado1 === 'RESUELTA' &&
                                        item.estado4 === 'RESUELTA' &&
                                        item.estado5 === 'ACEPTADA' &&
                                        parseInt(item.fkexperto) > 0 &&
                                        parseInt(item.fkexperto) === fkexperto &&
                                        parseInt(item.estrella) === 0 &&
                                        parseInt(item.total) === 1">
                                        <i class="fas fa-angle-double-right"></i> Valorar {{_pregunta}}
                                    </button>
                                    <button type="button" class="btn btn-secondary rounded-pill" *ngIf="form.invalid &&
                                        isAccion === -1 &&
                                        item.estado1 === 'RESUELTA' &&
                                        item.estado4 === 'RESUELTA' &&
                                        item.estado5 === 'ACEPTADA' &&
                                        parseInt(item.fkexperto) > 0 &&
                                        parseInt(item.fkexperto) === fkexperto &&
                                        parseInt(item.estrella) === 0 &&
                                        parseInt(item.total) > 1">
                                        <i class="fas fa-angle-double-right"></i> Valorar {{_hilo}}
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill" *ngIf="form.valid && isAccion === -1 &&
                                        item.estado1 === 'RESUELTA' &&
                                        item.estado4 === 'RESUELTA' &&
                                        item.estado5 === 'ACEPTADA' &&
                                        parseInt(item.fkexperto) > 0 &&
                                        parseInt(item.fkexperto) === fkexperto &&
                                        parseInt(item.estrella) === 0 &&
                                        parseInt(item.total) > 1">
                                        <i class="fas fa-angle-double-right"></i> Valorar {{_hilo}}
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>