<section class="banner-all">
    <div class="container div-titulo" *ngIf="isEstado === 1">
        <h2 class="titulo">Requiero un {{_pregunta}}</h2>
        <p class="mt-3 text-justify">El Servicio Expert Tip pretende ayudarte con esas inquietudes sobre negocios que
            tienes en tu empresa, proyecto o emprendimiento. Recuerda ser muy específico en la pregunta, contextualiza
            la situación o problema puntual a resolver y cuales son las posibles causas de esa situación. Trata de no
            hacer preguntas generales como ¿Cómo aumentar mis ventas? ¿Cómo Cambiar mi Cultura Organizacional? ese tipo
            de preguntas requieren de una consultoría a profundidad.
        </p>
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isEstado === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="isEstado === 0">
                            <strong class="mr-md-auto mt-5 mb-5">No hemos encontrado resultados que coincidan con tu
                                búsqueda.</strong>
                            <!-- <a [routerLink]="['/consulta-experto/busquedad/%/1']" class="btn btn-block btn-primary">
                                <i class="fas fa-search"></i> <span class="text">Buscar</span>
                            </a> -->
                        </div>
                        <div class="form-inline" *ngIf="isEstado === 0">
                            <div class="col-xl-3 col-lg-3"></div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="col input-group w-100" style="padding-left: 0 !important; padding-right: 0!important;">
                                    <input class="form-control text-center"
                                        placeholder="Prueba con otro criterio de búsquedad" type="text"
                                        [(ngModel)]="otro" (keyup.enter)="otraBusquedad()">
                                    <div class="input-group-append" *ngIf="otro.length > 0">
                                        <button type="button" (click)="otraBusquedad()" class="btn btn-primary">
                                            <i class="fas fa-search"></i> <span class="text">Buscar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3"></div>
                        </div>
                    </header>
                    <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal">
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isEstado === 1">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">TÍTULO<span
                                            *ngIf="validator('titulo')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="titulo">
                                        <div *ngIf="form.get('titulo').errors && form.get('titulo').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['titulo'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['titulo'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['titulo'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CONTENIDO<span
                                            *ngIf="validator('contenido')">*</span></label>
                                    <div class="col-md-9">
                                        <quill-editor [styles]="{height: '250px'}"
                                            (onContentChanged)="onChangue($event)" formControlName="contenido">
                                        </quill-editor>
                                        <small>Contador de caracteres, {{ totalCaracteres }} <span
                                                class="text-danger">Maximo 500</span></small>
                                        <span class="help-block text-danger" *ngIf="isMailContenido">
                                            + No puedes incluir correos electronicos en el texto
                                        </span>
                                        <div *ngIf="form.get('contenido').errors && form.get('contenido').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['contenido'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CORREO ELECTRÓNICO<span
                                            *ngIf="validator('correoelectronicotemp')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoelectronicotemp"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form.get('correoelectronicotemp').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronicotemp'].hasError('pattern')">
                                                + Ingresa un correo valido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="form.get('correoelectronicotemp').errors && form.get('correoelectronicotemp').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronicotemp'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isEstado === 1">
                            <div class="card-body">
                                <div class="text-center">
                                    <button type="button" class="btn btn-secondary rounded-pill"
                                        *ngIf="(isMailContenido || form.invalid) && isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Enviar {{_pregunta}}
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill"
                                        *ngIf="form.valid && !isMailContenido && isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Enviar {{_pregunta}}
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="mt-5 mb-3" *ngIf="totalRelacionado > 0 && isEstado === 0">
                        <br><br>
                        <h4 class="titulo-confirmacion">También puede interesarte</h4>
                    </div>
                    <div *ngIf="isEstado === 0 && totalRelacionado > 0" class="row no-gutters p-1">
                        <article class="card card-product-list offset-md-1 col-md-5 col-12"
                            style="border: 1px solid #e5e7ea !important;"
                            *ngFor="let experto of jsonExpertosRelacionado$ | async">
                            <div class="row no-gutters">
                                <aside class="col-md-6">
                                    <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                        <span style="font-size: 1.5rem;" class="badge"
                                            *ngIf="experto.destacado === 'SI'">
                                            <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                        <img [src]="experto.urlfotografia">
                                        {{experto.urlfotografia}}
                                    </a>
                                </aside>
                                <div class="col-md-6">
                                    <div class="info-main">
                                        <span style="font-size: 1.1rem;" class="badge badge-danger w-100 mb-2">
                                            {{ experto.nombrenivel }} </span>

                                        <span class="h5 title" style="line-height: 1rem!important; color: #390796;">
                                            <b>{{ experto.nickname | uppercase }}</b>
                                        </span>

                                        <div class="m-2">
                                            <div class="rating-wrap mb-2" *ngIf="experto.calificaciones === 0">
                                                <ul class="rating-stars">
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star-half"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                            <div class="rating-wrap mb-2"
                                                *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                                <ul class="rating-stars">
                                                    <li style="width:100%" class="stars-active">
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                        <i class="fa fa-star"></i>
                                                    </li>
                                                </ul>
                                                <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                            </div>
                                        </div>

                                        <p class="mb-1">
                                            <span class="badge badge-dark mr-1" *ngIf="experto.fkarea1 > 0">{{
                                                getNombreArea(experto.fkarea1)[0].nombre
                                                }}</span>
                                            <span class="badge badge-dark mr-1" *ngIf="experto.fkarea2 > 0">{{
                                                getNombreArea(experto.fkarea2)[0].nombre
                                                }}</span>
                                        </p>

                                        <p class="mb-1">
                                            <span class="badge badge-secondary mr-1" *ngIf="experto.fkrama1 > 0">{{
                                                getNombreRama(experto.fkrama1)[0].nombre
                                                }}</span>
                                            <span class="badge badge-secondary mr-1" *ngIf="experto.fkrama2 > 0">{{
                                                getNombreRama(experto.fkrama2)[0].nombre
                                                }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>