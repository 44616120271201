import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Pregunta } from './../interface/pregunta';

@Injectable({
  providedIn: 'root'
})
export class PreguntaService {

  constructor(
    private http: HttpClient
  ) { }

  public post(object: Pregunta): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/Pregunta', object);
  }

  public postAuth(object: Pregunta): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/PreguntaAuth', object);
  }
}
