import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { EjemploEncuesta } from './../interface/ejemplo-encuesta';

@Injectable({
  providedIn: 'root'
})
export class EjemploEncuestaService {

  constructor(
    private http: HttpClient
  ) { }

  public getAll(): Observable<EjemploEncuesta[]> {
    return this.http.get<EjemploEncuesta[]>(environment.urlApi + '/EjemploEncuesta');
  }
}
