<section class="banner-all">
    <div class="container div-titulo">
        <h2 class="titulo">Crea tu perfil de {{_experto}}</h2>
        <p class="mt-3 text-justify">Regístrate diligenciando tu perfil lo más detallado posible, es clave especificar
            claramente tu área, especialidad y enfoque, ya que esto permitirá que los usuarios te encuentren fácilmente
            cuando necesiten tu ayuda. Luego comenzarás a recibir preguntas bajo 2 modelos. Para más información,
            ingresa al Link
            <a href="https://file.expertips.co/modeloservicios.pdf" target="_blank">
                <span style="color: #390796!important;">
                    Modelo de Servicios Expertips <i class="fas fa-arrow-down"></i>
                </span>
            </a>.<br>
            Te invitamos a ver nuestro vídeo introductorio para Expertos haciendo 
            <a href="https://www.youtube.com/watch?v=6QQ1hao86ig" target="_blank">
                <span style="color: #390796!important;">
                    clic aquí <i class="fas fa-arrow-down"></i>
                </span>
            </a>.
        </p>
    </div>
    <!-- <p>myForm value: {{ form.value | json }}</p>
    <p>myForm status: {{ form.status | json }}</p>
    <p>myForm ¿valid?: {{ form.valid }}</p> -->
    <div class="container">
        <div class="animated fadeIn">
            <div class="row">
                <div class="col-md-12">
                    <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal">
                        <p>* Campos obligatorios</p>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body" *ngIf="isParte1">
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">FECHA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="fecha" disabled>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label">Por favor no colocar tildes, números, signos
                                        de puntuación, espacios, símbolos u otras restricciones</label>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">PRIMER NOMBRE<span
                                            *ngIf="validator('nombre1')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nombre1">
                                        <div *ngIf="form.get('nombre1').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('pattern')">
                                                + Ingresa un nombre válido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('nombre1').errors && form.get('nombre1').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre1'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">SEGUNDO NOMBRE</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nombre2">
                                        <div *ngIf="form.get('nombre2').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre2'].hasError('pattern')">
                                                + Ingresa un nombre válido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('nombre2').errors && form.get('nombre2').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre2'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre2'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">PRIMER APELLIDO<span
                                            *ngIf="validator('apellido1')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="apellido1">
                                        <div *ngIf="form.get('apellido1').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido1'].hasError('pattern')">
                                                + Ingresa un apellido válido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('apellido1').errors && form.get('apellido1').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido1'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido1'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">SEGUNDO APELLIDO</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="apellido2">
                                        <div *ngIf="form.get('apellido2').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido2'].hasError('pattern')">
                                                + Ingresa un apellido válido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('apellido2').errors && form.get('apellido2').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido2'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['apellido2'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">NICKNAME</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nickname" disabled
                                            [value]="getNombre1 + getApellido1 + getApellido2">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">TIPO IDENTIFICACIÓN<span
                                            *ngIf="validator('tipoidentificacion')">*</span></label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="tipoidentificacion">
                                            <option value="null" disabled>... Seleccione</option>
                                            <option value="CC">CÉDULA DE CIUDADANÍA</option>
                                            <option value="CE">CÉDULA DE EXTRANJERÍA</option>
                                            <option value="PPN">PASAPORTE</option>
                                            <option value="SSN">NÚMERO DE SEGURIDAD SOCIAL</option>
                                            <option value="LIC">LICENCIA DE CONDUCCIÓN</option>
                                            <option value="NIT">NÚMERO DE IDENTIFICACIÓN TRIBUTARIA</option>
                                            <option value="TI">TARJETA DE IDENTIDAD</option>
                                            <option value="DNI">DOCUMENTO NACIONAL DE IDENTIFICACIÓN</option>
                                        </select>
                                        <div
                                            *ngIf="(form.get('tipoidentificacion').errors && form.get('tipoidentificacion').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['tipoidentificacion'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">IDENTIFICACIÓN<span
                                            *ngIf="validator('identificacion')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="identificacion">
                                        <div *ngIf="form.get('identificacion').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['identificacion'].hasError('identificacion')">
                                                + Ingresa una identificación válido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="(form.get('identificacion').errors && form.get('identificacion').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['identificacion'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['identificacion'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['identificacion'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">GÉNERO<span
                                            *ngIf="validator('genero')">*</span></label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="genero">
                                            <option value="null" disabled>... Seleccione</option>
                                            <option value="FEMENINO">FEMENINO</option>
                                            <option value="MASCULINO">MASCULINO</option>
                                            <option value="OTRO">OTRO</option>
                                        </select>
                                        <div *ngIf="(form.get('genero').errors && form.get('genero').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['genero'].hasError('required')">
                                                + Campo requerido
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label mb-1">FECHA DE NACIMIENTO<span
                                            *ngIf="validator('anoFechanacimiento')">*</span></label>
                                    <div class="col-md-2 col-6 mb-1">
                                        <input type="number" class="form-control" formControlName="anoFechanacimiento"
                                            placeholder="AAAA" min="1900" [max]="ano">
                                        <div
                                            *ngIf="(form.get('anoFechanacimiento').errors && form.get('anoFechanacimiento').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['anoFechanacimiento'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['anoFechanacimiento'].hasError('min') || form.controls['anoFechanacimiento'].hasError('max')">
                                                + Error
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-3 mb-1">
                                        <input type="number" class="form-control" formControlName="mesFechanacimiento"
                                            placeholder="MM" min="1" max="12">
                                        <div
                                            *ngIf="(form.get('mesFechanacimiento').errors && form.get('mesFechanacimiento').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['mesFechanacimiento'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['mesFechanacimiento'].hasError('min') || form.controls['mesFechanacimiento'].hasError('max')">
                                                + Error
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-3 mb-1">
                                        <input type="number" class="form-control" formControlName="diaFechanacimiento"
                                            placeholder="DD" min="1" max="31">
                                        <div
                                            *ngIf="(form.get('diaFechanacimiento').errors && form.get('diaFechanacimiento').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['diaFechanacimiento'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['diaFechanacimiento'].hasError('min') || form.controls['diaFechanacimiento'].hasError('max')">
                                                + Error
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-control alert alert-primary text-center" role="alert">
                                            AAAA-MM-DD</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label">¿Cuándo iniciaste tu experiencia
                                        laboral?</label>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label mb-1">FECHA DE INICIO LABORAL<span
                                            *ngIf="validator('anoFechainiciolaboral')">*</span></label>
                                    <div class="col-md-2 col-6 mb-1">
                                        <input type="number" class="form-control"
                                            formControlName="anoFechainiciolaboral" placeholder="AAAA" min="1900"
                                            [max]="ano">
                                        <div
                                            *ngIf="(form.get('anoFechainiciolaboral').errors && form.get('anoFechainiciolaboral').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['anoFechainiciolaboral'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['anoFechainiciolaboral'].hasError('min') || form.controls['anoFechainiciolaboral'].hasError('max')">
                                                + Error
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-3 mb-1">
                                        <input type="number" class="form-control"
                                            formControlName="mesFechainiciolaboral" placeholder="MM" min="1" max="12">
                                        <div
                                            *ngIf="(form.get('mesFechainiciolaboral').errors && form.get('mesFechainiciolaboral').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['mesFechainiciolaboral'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['mesFechainiciolaboral'].hasError('min') || form.controls['mesFechainiciolaboral'].hasError('max')">
                                                + Error
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-3 mb-1">
                                        <input type="number" class="form-control"
                                            formControlName="diaFechainiciolaboral" placeholder="DD" min="1" max="31">
                                        <div
                                            *ngIf="(form.get('diaFechainiciolaboral').errors && form.get('diaFechainiciolaboral').dirty) || isVerificar">
                                            <small class="help-block text-danger"
                                                *ngIf="form.controls['diaFechainiciolaboral'].hasError('required')">
                                                + Campo requerido
                                            </small>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['diaFechainiciolaboral'].hasError('min') || form.controls['diaFechainiciolaboral'].hasError('max')">
                                                + Error
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-control alert alert-primary text-center" role="alert">
                                            AAAA-MM-DD</div>
                                    </div>
                                </div>

                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">FKTARIFANUEVA</label>
                                    <div class="col-md-9">
                                        <input type="number" class="form-control" formControlName="fktarifanueva">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">FKTARIFAHILO</label>
                                    <div class="col-md-9">
                                        <input type="number" class="form-control" formControlName="fktarifahilo">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">FKTARIFAENCUESTA</label>
                                    <div class="col-md-9">
                                        <input type="number" class="form-control" formControlName="fktarifaencuesta">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">FKNIVEL</label>
                                    <div class="col-md-9">
                                        <input type="number" class="form-control" formControlName="fknivel">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">DESTACADO</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="destacado">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">CALIFICACIONES</label>
                                    <div class="col-md-9">
                                        <input class="form-control" formControlName="calificaciones">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">HOJA DE VIDA <a href="https://linkedin.com/"
                                            target="_blank">linkedin.com</a></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="urllinkedin">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label mb-1">RUT</label>
                                    <div class="col-md-9 text-center" *ngIf="loadingFileurlrut">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-7 mb-1" *ngIf="!loadingFileurlrut">
                                        <input type="text" class="form-control" formControlName="urlrut"
                                            *ngIf="isFileurlrut" disabled>
                                        <input style="display: none" type="file" accept="application/pdf"
                                            (change)="onFileChanged('urlrut', $event)" #fileInputurlrut>
                                        <div class="row" *ngIf="!isFileurlrut">
                                            <!-- <div class="col-md-6"> -->
                                            <div class="col-md-12">
                                                <button type="button"
                                                    [attr.class]="this.fileurlrut ? 'btn btn-block btn-secondary' : 'btn btn-block btn-light'"
                                                    (click)="fileInputurlrut.click()" [disabled]="this.fileurlrut"><i
                                                        class="far fa-folder-open"></i> Seleccione un
                                                    archivo</button>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <button type="button"
                                                    [attr.class]="!this.fileurlrut ? 'btn btn-block btn-secondary' : 'btn btn-block btn-light'"
                                                    (click)="onUpload('urlrut', 'experto_rut')"
                                                    [disabled]="!this.fileurlrut"><i class="fas fa-angle-double-up"></i>
                                                    Cargar al servidor</button>
                                            </div> -->
                                        </div>
                                        <small>Si eres Colombiano tienes hasta 6 meses a partir de hoy para subir tu
                                            RUT</small>
                                    </div>
                                    <div class="col-md-2" *ngIf="!loadingFileurlrut">
                                        <div class="form-control alert alert-primary text-center" role="alert">Máx.
                                            5MB</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label mb-1">FOTOGRAFÍA<span
                                            *ngIf="validator('urlfotografia')">*</span></label>
                                    <div class="col-md-9 text-center" *ngIf="loadingFileurlfotografia">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-7 mb-1" *ngIf="!loadingFileurlfotografia">
                                        <input type="text" class="form-control" formControlName="urlfotografia"
                                            *ngIf="isFileurlfotografia" disabled>
                                        <input style="display: none" type="file" accept="image/png, image/jpeg"
                                            (change)="onFileChanged('urlfotografia', $event)" #fileInputurlfotografia>
                                        <div class="row" *ngIf="!isFileurlfotografia">
                                            <!-- <div class="col-md-6"> -->
                                            <div class="col-md-12">
                                                <button type="button"
                                                    [attr.class]="this.fileurlfotografia ? 'btn btn-block btn-secondary' : 'btn btn-block btn-light'"
                                                    (click)="fileInputurlfotografia.click()"
                                                    [disabled]="this.fileurlfotografia"><i
                                                        class="far fa-folder-open"></i> Seleccione un
                                                    archivo</button>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <button type="button"
                                                    [attr.class]="!this.fileurlfotografia ? 'btn btn-block btn-secondary' : 'btn btn-block btn-light'"
                                                    (click)="onUpload('urlfotografia', 'experto_fotografia')"
                                                    [disabled]="!this.fileurlfotografia"><i
                                                        class="fas fa-angle-double-up"></i>
                                                    Cargar al servidor</button>
                                            </div> -->
                                        </div>
                                        <div
                                            *ngIf="(form.get('urlfotografia').errors && form.get('urlfotografia').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['urlfotografia'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                        <small>Tu foto y perfil estará disponible para todos los usuarios de la
                                            plataforma Expertips. Acorta la imagen y centra tu cara para tener una mejor
                                            visualización tuya, te recomendamos buena iluminación y fondo blanco.<br>
                                            <b>Usa una foto donde se pueda apreciar tu rostro, este debe estar
                                                centrado.</b><br></small>
                                        <small style="color: #390796!important;">Si deseas cambiar la fotografía, podrás
                                            realizarlo desde tu perfil una vez creada la cuenta.</small>
                                    </div>
                                    <div class="col-md-2" *ngIf="!loadingFileurlfotografia">
                                        <div class="form-control alert alert-primary text-center" role="alert">Máx.
                                            5MB</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" *ngIf="isParte2">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">PAÍS<span
                                            *ngIf="validator('pais')">*</span></label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonPaises$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonPaises$ | async)?.length > 0">
                                        <select class="form-control" formControlName="pais"
                                            (change)="getEstados($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let pais of jsonPaises$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="pais.name + ':' + pais.id">
                                                {{ pais.name }}</option>
                                        </select>
                                        <div *ngIf="(form.get('pais').errors && form.get('pais').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['pais'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">DEPARTAMENTO<span
                                            *ngIf="validator('departamento')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isEstado">
                                        <input type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="isEstado && (jsonEstados$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="isEstado && (jsonEstados$ | async)?.length > 0">
                                        <select class="form-control" formControlName="departamento"
                                            (change)="getCiudades($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let estado of jsonEstados$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="estado.name + ':' +estado.id">
                                                {{ estado.name }}</option>
                                        </select>
                                        <div *ngIf="(form.get('departamento').errors && form.get('departamento').dirty) || isVerificar">
                                            <span class="help-block text-danger" +
                                                *ngIf="form.controls['departamento'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CIUDAD / BARRIO (CAPITAL)<span
                                            *ngIf="validator('ciudad')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isCiudad">
                                        <input type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="isCiudad && (jsonCiudades$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="isCiudad && (jsonCiudades$ | async)?.length > 0">
                                        <select class="form-control" formControlName="ciudad">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let ciudad of jsonCiudades$ | async | sortBy: 'asc':'name'"
                                                [ngValue]="ciudad.name">
                                                {{ ciudad.name }}</option>
                                        </select>
                                        <div *ngIf="(form.get('ciudad').errors && form.get('ciudad').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['ciudad'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">DIRECCIÓN DE FACTURACIÓN<span
                                            *ngIf="validator('direccion')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="direccion">
                                        <small>Información necesaria para la elaboración de facturación
                                            electrónica</small>
                                        <div *ngIf="(form.get('direccion').errors && form.get('direccion').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['direccion'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CÓDIGO PAÍS + CELULAR<span
                                            *ngIf="validator('celular')">*</span></label>
                                    <div class="col-md-2 col-4">
                                        <input type="text" class="form-control" formControlName="codigopais">
                                        <div *ngIf="form.get('codigopais').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('pattern')">
                                                + Ingresa un número válido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('codigopais').errors && form.get('codigopais').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['codigopais'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-8">
                                        <input type="text" class="form-control" formControlName="celular">
                                        <div *ngIf="form.get('celular').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('pattern')">
                                                + Ingresa un número válido
                                            </span>
                                        </div>
                                        <div *ngIf="(form.get('celular').errors && form.get('celular').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['celular'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">MEDIO DE PAGO<span
                                            *ngIf="validator('banco')">*</span></label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="banco">
                                            <!-- <option value="null" disabled>... Seleccione</option> -->
                                            <option value="DaviPlata" *ngIf="pais.match('Colombia')">DaviPlata</option>
                                            <option value="PayPal" *ngIf="!pais.match('Colombia')">PayPal</option>
                                        </select>
                                        <div *ngIf="(form.get('banco').errors && form.get('banco').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['banco'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                        <small *ngIf="pais.match('Colombia')">Si no tienes una cuenta Daviplata puedes
                                            abrirla cuando efectues tu primer cobro. Ingresa a <a
                                                href="https://www.daviplata.com"
                                                target="_blank">www.daviplata.com</a></small>
                                        <small *ngIf="!pais.match('Colombia')">Si no tienes una cuenta PayPal puedes
                                            abrirla cuando efectues tu primer cobro. Ingresa a <a
                                                href="https://www.paypal.com" target="_blank">www.paypal.com</a></small>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label" *ngIf="pais.match('Colombia')">NÚMERO
                                        DaviPlata</label>
                                    <label class="col-md-3 col-form-label" *ngIf="!pais.match('Colombia')">USUARIO
                                        PayPal</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="numerocuenta">
                                        <div *ngIf="(form.get('numerocuenta').errors && form.get('numerocuenta').dirty) || isVerificar">
                                            <!-- <span class="help-block text-danger"
                                                *ngIf="form.controls['numerocuenta'].hasError('required')">
                                                + Campo requerido
                                            </span> -->
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['numerocuenta'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['numerocuenta'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CORREO ELECTRÓNICO<span
                                            *ngIf="validator('correoelectronico')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoelectronico"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form.get('correoelectronico').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('pattern')">
                                                + Ingresa un correo válido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="(form.get('correoelectronico').errors && form.get('correoelectronico').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CONTRASEÑA<span *ngIf="validator('Experto_contrasena')">*</span></label>
                                    <div class="col-md-9">
                                      <input type="password" class="form-control" formControlName="Experto_contrasena" autocomplete="nope">
                                      <div *ngIf="form.get('Experto_contrasena').errors?.pattern">
                                        <span class="help-block text-danger" *ngIf="form.controls['Experto_contrasena'].hasError('pattern')">
                                          + Ingresa una contraseña con el siguiente formato:
                                          <ul>
                                            <li>Mínimo 6 caracteres</li>
                                            <li>Máximo 10 caracteres</li>
                                            <li>No espacios en blanco</li>
                                            <li>Al menos una letra mayúscula</li>
                                            <li>Al menos una letra minúscula</li>
                                            <li>Al menos un dígito</li>
                                            <li>Al menos 1 caracter especial(No usar: ",",',',(,),[,])</li>
                                          </ul>
                                        </span>
                                      </div>
                                      <div *ngIf="(form.get('Experto_contrasena').errors && form.get('Experto_contrasena').dirty) || isVerificar">
                                        <span class="help-block text-danger" *ngIf="form.controls['Experto_contrasena'].hasError('required')">
                                          + Campo requerido
                                        </span>
                                        <span class="help-block text-danger" *ngIf="form.controls['Experto_contrasena'].hasError('minlength')">
                                          + Debes ingresar más caracteres
                                        </span>
                                        <span class="help-block text-danger" *ngIf="form.controls['Experto_contrasena'].hasError('maxlength')">
                                          + Excediste el valor de caracteres
                                        </span>
                                      </div>
                                    </div>
                                  </div>                                  
                                  
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CONFIRMA CONTRASEÑA<span
                                            *ngIf="validator('Experto_confirmacontrasena')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="password" class="form-control"
                                            formControlName="Experto_confirmacontrasena" autocomplete="nope">
                                        <div
                                            *ngIf="(form.get('Experto_confirmacontrasena').errors && form.get('Experto_confirmacontrasena').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Experto_confirmacontrasena'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Experto_confirmacontrasena'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['Experto_confirmacontrasena'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                        <div *ngIf="form.get('Experto_confirmacontrasena').value">
                                            <div
                                                *ngIf="(!form.controls['Experto_confirmacontrasena'].hasError('minlength') 
                                                && !form.controls['Experto_confirmacontrasena'].hasError('maxlength')) 
                                                && (form.get('Experto_contrasena').value !== form.get('Experto_confirmacontrasena').value)">
                                                <span class="help-block text-danger">
                                                    + Las contraseñas no coinciden
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body" *ngIf="isParte3">
                                <p class="my-3">A continuación especifica 3 nombres y correos electrónicos de personas
                                    que puedan validar tu experiencia profesional.</p>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">REFERENCIA (1)<span
                                            *ngIf="validator('referencia1')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="referencia1">
                                        <div *ngIf="(form.get('referencia1').errors && form.get('referencia1').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia1'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia1'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CORREO REFERENCIA (1)<span
                                            *ngIf="validator('correoreferencia1')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoreferencia1"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form.get('correoreferencia1').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia1'].hasError('pattern')">
                                                + Ingresa un correo válido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="(form.get('correoreferencia1').errors && form.get('correoreferencia1').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia1'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia1'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia1'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ISCONFIRMAREFERENCIA1</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="isconfirmareferencia1">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">REFERENCIA (2)<span
                                            *ngIf="validator('referencia2')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="referencia2">
                                        <div *ngIf="(form.get('referencia2').errors && form.get('referencia2').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia2'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia2'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia2'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CORREO REFERENCIA (2)<span
                                            *ngIf="validator('correoreferencia2')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoreferencia2"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form.get('correoreferencia2').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia2'].hasError('pattern')">
                                                + Ingresa un correo válido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="(form.get('correoreferencia2').errors && form.get('correoreferencia2').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia2'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia2'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia2'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ISCONFIRMAREFERENCIA2</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="isconfirmareferencia2">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">REFERENCIA (3)<span
                                            *ngIf="validator('referencia3')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="referencia3">
                                        <div *ngIf="(form.get('referencia3').errors && form.get('referencia3').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia3'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia3'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['referencia3'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CORREO REFERENCIA (3)<span
                                            *ngIf="validator('correoreferencia3')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoreferencia3"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form.get('correoreferencia3').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia3'].hasError('pattern')">
                                                + Ingresa un correo válido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="(form.get('correoreferencia3').errors && form.get('correoreferencia3').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia3'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia3'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoreferencia3'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ISCONFIRMAREFERENCIA3</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="isconfirmareferencia3">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="(form.get('correoreferencia1').value === form.get('correoelectronico').value) ||
                                (form.get('correoreferencia2').value === form.get('correoelectronico').value) ||
                                (form.get('correoreferencia3').value === form.get('correoelectronico').value) ||
                                (form.get('correoreferencia1').value === form.get('correoreferencia2').value) ||
                                (form.get('correoreferencia1').value === form.get('correoreferencia3').value) ||
                                (form.get('correoreferencia2').value === form.get('correoreferencia1').value) ||
                                (form.get('correoreferencia2').value === form.get('correoreferencia3').value)">
                                    <span class="help-block text-danger">
                                        + Los correos deben ser diferentes a tu correo personal y entre ellos
                                    </span>
                                </div>
                            </div>
                            <div class="card-body" *ngIf="isParte4">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">PERFIL PROFESIONAL<span
                                            *ngIf="validator('curriculum')">*</span></label>
                                    <div class="col-md-9">
                                        <quill-editor [styles]="{height: '100px'}"
                                            (onContentChanged)="onChangue($event)" formControlName="curriculum">
                                        </quill-editor>
                                        <small>Contador de caracteres, {{ totalCaracteres }} <span
                                                class="text-danger">Maximo 500</span></small><br>
                                        <small>Por favor especifica en este espacio tu Perfil Profesional. Recuerda que
                                            por politicas del sitio no debes incluir nombres de instituciones,
                                            compañias,
                                            personas, enlaces, direcciones web o numeros de contacto</small>
                                        <div *ngIf="(form.get('curriculum').errors && form.get('curriculum').dirty) || isVerificar">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['curriculum'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="card mb-3" style="background-color: #ffffff60;">
                                    <div class="card-header">
                                        Experiencia Profesional <span *ngIf="validator('experiencialaboral1')">*</span>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row" *ngIf="isExperiencialaboral">
                                            <div class="col-md-12">
                                                <small class="text-muted mb-3">Debes por lo menos agregar una experiencia laboral, diligencie los campos y de clic en el boton "Agregar".</small>

                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <!-- <small class="text-muted mb-3">¿Trabajas aquí actualmente?</small> -->
                                                <div class="col-md-12">
                                                    <input type="checkbox" class="form-check-input"
                                                        id="actualExperiencialaboral"
                                                        [checked]="actualExperiencialaboral"
                                                        (change)="changueExperiencialaboral($event)">
                                                    <small class="form-check-label text-muted"
                                                        for="actualExperiencialaboral">¿Trabajas
                                                        actualmente?</small>
                                                </div>
                                            </div>
                                            <div class="col-md-12 d-none d-md-block">
                                                <div class="input-group w-100">
                                                    <input type="text" class="form-control col-md-4"
                                                        [(ngModel)]="empresa" [ngModelOptions]="{standalone: true}"
                                                        placeholder="Empresa">
                                                    <input type="number" class="form-control col-md-2"
                                                        [(ngModel)]="anoinicio" [ngModelOptions]="{standalone: true}"
                                                        placeholder="Año Inicio">
                                                    <input type="number" class="form-control col-md-2"
                                                        [disabled]="actualExperiencialaboral" [(ngModel)]="anofin1"
                                                        [ngModelOptions]="{standalone: true}" placeholder="Año Fin">
                                                    <input type="text" class="form-control col-md-4" [(ngModel)]="cargo"
                                                        [ngModelOptions]="{standalone: true}" placeholder="Cargo">
                                                    <div class="input-group-prepend">
                                                        <button type="button" class="btn btn-primary" (click)="addExperiencialaboral()">Agregar</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 d-md-none">
                                                <input type="text" class="form-control col-md-4" [(ngModel)]="empresa"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Empresa">
                                                <input type="number" class="form-control col-md-2"
                                                    [(ngModel)]="anoinicio" [ngModelOptions]="{standalone: true}"
                                                    placeholder="Año Inicio">
                                                <input type="number" class="form-control col-md-2"
                                                    [disabled]="actualExperiencialaboral" [(ngModel)]="anofin1"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Año Fin">
                                                <div class="input-group w-100">
                                                    <input type="text" class="form-control col-md-4" [(ngModel)]="cargo"
                                                        [ngModelOptions]="{standalone: true}" placeholder="Cargo">
                                                    <div class="input-group-prepend">
                                                        <button type="button" class="btn btn-primary" (click)="addExperiencialaboral()">Agregar</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 w-auto table-responsive p-1">
                                                <table class="table-sm table-striped table-hover"
                                                    style="width: 100%!important;">
                                                    <!-- <div class="col-md-12">
                                                <table class="table table-sm table-striped w-100"> -->
                                                    <tbody>
                                                        <tr *ngIf="form.get('experiencialaboral1').value">
                                                            <td><button type="button" class="btn btn-primary"
                                                                    (click)="removeExperiencialaboral1()"><i
                                                                        class="fas fa-minus"></i></button></td>
                                                            <td>{{form.get('experiencialaboral1').value.split(':')[0]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral1').value.split(':')[1]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral1').value.split(':')[2]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral1').value.split(':')[3]}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="form.get('experiencialaboral2').value">
                                                            <td><button type="button" class="btn btn-primary"
                                                                    (click)="removeExperiencialaboral2()"><i
                                                                        class="fas fa-minus"></i></button></td>
                                                            <td>{{form.get('experiencialaboral2').value.split(':')[0]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral2').value.split(':')[1]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral2').value.split(':')[2]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral2').value.split(':')[3]}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="form.get('experiencialaboral3').value">
                                                            <td><button type="button" class="btn btn-primary"
                                                                    (click)="removeExperiencialaboral3()"><i
                                                                        class="fas fa-minus"></i></button></td>
                                                            <td>{{form.get('experiencialaboral3').value.split(':')[0]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral3').value.split(':')[1]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral3').value.split(':')[2]}}
                                                            </td>
                                                            <td>{{form.get('experiencialaboral3').value.split(':')[3]}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <input type="hidden" class="form-control"
                                                    formControlName="experiencialaboral1">
                                                <input type="hidden" class="form-control"
                                                    formControlName="experiencialaboral2">
                                                <input type="hidden" class="form-control"
                                                    formControlName="experiencialaboral3">
                                                <div
                                                    *ngIf="(form.get('experiencialaboral1').errors && form.get('experiencialaboral1').dirty) || isVerificar">
                                                    <span class="help-block text-danger"
                                                        *ngIf="form.controls['experiencialaboral1'].hasError('required')">
                                                        + Campo requerido
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-3" style="background-color: #ffffff60;">
                                    <div class="card-header">
                                        Experiencia Académica <span *ngIf="validator('experienciaacademica1')">*</span>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row" *ngIf="isExperienciaacademica">
                                            <div class="col-md-12 text-center"
                                                *ngIf="(jsonPregrados$ | async)?.length <= 0">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-12">
                                                <small class="text-muted mb-3">Debes por lo menos agregar una
                                                    experiencia
                                                    académica, diligencie los campos y de clic en el boton "Agregar".</small>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <!-- <small class="text-muted mb-3">¿Estudias aquí actualmente?</small> -->
                                                <!-- <div class="col-md-12">
                                                    <input type="checkbox" class="form-check-input"
                                                        id="actualExperienciaacademica"
                                                        [checked]="actualExperienciaacademica"
                                                        (change)="changueExperienciaacademica($event)">
                                                    <small class="form-check-label text-muted"
                                                        for="actualExperienciaacademica">¿Estudias 
                                                        actualmente?</small>
                                                </div> -->
                                            </div>

                                            <label class="col-md-12 col-form-label">PREGRADO</label>
                                            <div class="col-md-12 d-none d-md-block"
                                                *ngIf="(jsonPregrados$ | async)?.length > 0">
                                                <div class="input-group w-100">
                                                    <input id="typeahead-focus" type="text"
                                                        class="form-control col-md-6" [(ngModel)]="pregrado"
                                                        [ngModelOptions]="{standalone: true}" [ngbTypeahead]="search1"
                                                        (focus)="focus$.next($any($event).target.value)"
                                                        (click)="click$.next($any($event).target.value)"
                                                        #instance="ngbTypeahead" />
                                                    <!-- <select class="form-control col-md-6" [(ngModel)]="pregrado"
                                                        [ngModelOptions]="{standalone: true}">
                                                        <option [ngValue]="null" disabled>... Seleccione Pregrado
                                                        </option>
                                                        <option
                                                            *ngFor="let maestro of jsonPregrados$ | async | sortBy: 'asc':'texto'"
                                                            [ngValue]="maestro.texto">
                                                            {{ maestro.texto }}</option>
                                                    </select> -->
                                                    <input type="number" class="form-control col-md-2"
                                                        [disabled]="actualExperienciaacademica" [(ngModel)]="anofin2"
                                                        [ngModelOptions]="{standalone: true}" placeholder="Año Fin">
                                                    <input type="text" class="form-control col-md-4"
                                                        [(ngModel)]="universidad1" [ngModelOptions]="{standalone: true}"
                                                        placeholder="Universidad">
                                                    <div class="input-group-prepend">
                                                        <button type="button" class="btn btn-primary" (click)="addExperienciaacademicaPregrado()">Agregar</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 d-md-none text-center"
                                                *ngIf="(jsonPregrados$ | async)?.length <= 0">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-12 d-md-none"
                                                *ngIf="(jsonPregrados$ | async)?.length > 0">
                                                <select class="form-control" [(ngModel)]="pregrado"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <option [ngValue]="null" disabled>... Seleccione Pregrado
                                                    </option>
                                                    <option
                                                        *ngFor="let maestro of jsonPregrados$ | async | sortBy: 'asc':'texto'"
                                                        [ngValue]="maestro.texto">
                                                        {{ maestro.texto }}</option>
                                                </select>
                                                <input type="number" class="form-control"
                                                    [disabled]="actualExperienciaacademica" [(ngModel)]="anofin2"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Año Fin">
                                                <div class="input-group w-100">
                                                    <input type="text" class="form-control" [(ngModel)]="universidad1"
                                                        [ngModelOptions]="{standalone: true}" placeholder="Universidad">
                                                    <div class="input-group-prepend">
                                                        <button type="button" class="btn btn-primary" (click)="addExperienciaacademicaPregrado()">Agregar</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="isExperienciaacademica">
                                            <div class="col-md-12 text-center"
                                                *ngIf="(jsonPosgrados$ | async)?.length <= 0">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <label class="col-md-12 col-form-label">POSGRADO</label>
                                            <div class="col-md-12 d-none d-md-block"
                                                *ngIf="(jsonPosgrados$ | async)?.length > 0">
                                                <div class="input-group w-100">
                                                    <input id="typeahead-focus" type="text"
                                                        class="form-control col-md-6" [(ngModel)]="posgrado"
                                                        [ngModelOptions]="{standalone: true}" [ngbTypeahead]="search2"
                                                        (focus)="focus$.next($any($event).target.value)"
                                                        (click)="click$.next($any($event).target.value)"
                                                        #instance="ngbTypeahead" />
                                                    <!-- <select class="form-control col-md-6" [(ngModel)]="posgrado"
                                                        [ngModelOptions]="{standalone: true}">
                                                        <option [ngValue]="null" disabled>... Seleccione Posgrado
                                                        </option>
                                                        <option
                                                            *ngFor="let maestro of jsonPosgrados$ | async | sortBy: 'asc':'texto'"
                                                            [ngValue]="maestro.texto">
                                                            {{ maestro.texto }}</option>
                                                    </select> -->
                                                    <input type="number" class="form-control col-md-2"
                                                        [disabled]="actualExperienciaacademica" [(ngModel)]="anofin3"
                                                        [ngModelOptions]="{standalone: true}" placeholder="Año Fin">
                                                    <input type="text" class="form-control col-md-4"
                                                        [(ngModel)]="universidad2" [ngModelOptions]="{standalone: true}"
                                                        placeholder="Universidad">
                                                    <div class="input-group-prepend">
                                                        <button type="button" class="btn btn-primary" (click)="addExperienciaacademicaPosgrado()">Agregar</button>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 d-md-none text-center"
                                                *ngIf="(jsonPosgrados$ | async)?.length <= 0">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-12 d-md-none"
                                                *ngIf="(jsonPosgrados$ | async)?.length > 0">
                                                <select class="form-control" [(ngModel)]="posgrado"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <option [ngValue]="null" disabled>... Seleccione Posgrado
                                                    </option>
                                                    <option
                                                        *ngFor="let maestro of jsonPosgrados$ | async | sortBy: 'asc':'texto'"
                                                        [ngValue]="maestro.texto">
                                                        {{ maestro.texto }}</option>
                                                </select>
                                                <input type="number" class="form-control"
                                                    [disabled]="actualExperienciaacademica" [(ngModel)]="anofin3"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Año Fin">
                                                <div class="input-group w-100">
                                                    <input type="text" class="form-control" [(ngModel)]="universidad2"
                                                        [ngModelOptions]="{standalone: true}" placeholder="Universidad">
                                                    <div class="input-group-prepend">
                                                        <button type="button" class="btn btn-primary" (click)="addExperienciaacademicaPosgrado()">Agregar</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 w-auto table-responsive p-1">
                                                <table class="table-sm table-striped table-hover"
                                                    style="width: 100%!important;">
                                                    <!-- <div class="col-md-12">
                                                <table class="table table-sm table-striped w-100"> -->
                                                    <tbody>
                                                        <tr *ngIf="form.get('experienciaacademica1').value">
                                                            <td><button type="button" class="btn btn-sm btn-primary"
                                                                    (click)="removeExperienciaacademica1()"><i
                                                                        class="fas fa-minus"></i></button></td>
                                                            <td>{{form.get('experienciaacademica1').value.split(':')[0]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica1').value.split(':')[1]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica1').value.split(':')[2]}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="form.get('experienciaacademica2').value">
                                                            <td><button type="button" class="btn btn-sm btn-primary"
                                                                    (click)="removeExperienciaacademica2()"><i
                                                                        class="fas fa-minus"></i></button></td>
                                                            <td>{{form.get('experienciaacademica2').value.split(':')[0]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica2').value.split(':')[1]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica2').value.split(':')[2]}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="form.get('experienciaacademica3').value">
                                                            <td><button type="button" class="btn btn-sm btn-primary"
                                                                    (click)="removeExperienciaacademica3()"><i
                                                                        class="fas fa-minus"></i></button></td>
                                                            <td>{{form.get('experienciaacademica3').value.split(':')[0]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica3').value.split(':')[1]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica3').value.split(':')[2]}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="form.get('experienciaacademica4').value">
                                                            <td><button type="button" class="btn btn-sm btn-primary"
                                                                    (click)="removeExperienciaacademica4()"><i
                                                                        class="fas fa-minus"></i></button></td>
                                                            <td>{{form.get('experienciaacademica4').value.split(':')[0]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica4').value.split(':')[1]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica4').value.split(':')[2]}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="form.get('experienciaacademica5').value">
                                                            <td><button type="button" class="btn btn-sm btn-primary"
                                                                    (click)="removeExperienciaacademica5()"><i
                                                                        class="fas fa-minus"></i></button></td>
                                                            <td>{{form.get('experienciaacademica5').value.split(':')[0]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica5').value.split(':')[1]}}
                                                            </td>
                                                            <td>{{form.get('experienciaacademica5').value.split(':')[2]}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <input type="hidden" class="form-control"
                                                    formControlName="experienciaacademica1">
                                                <input type="hidden" class="form-control"
                                                    formControlName="experienciaacademica2">
                                                <input type="hidden" class="form-control"
                                                    formControlName="experienciaacademica3">
                                                <input type="hidden" class="form-control"
                                                    formControlName="experienciaacademica4">
                                                <input type="hidden" class="form-control"
                                                    formControlName="experienciaacademica5">
                                                <div
                                                    *ngIf="(form.get('experienciaacademica1').errors && form.get('experienciaacademica1').dirty) || isVerificar">
                                                    <span class="help-block text-danger"
                                                        *ngIf="form.controls['experienciaacademica1'].hasError('required')">
                                                        + Campo requerido
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" *ngIf="isParte5">
                                <div class="card mb-3" style="background-color: #ffffff60;">
                                    <div class="card-header">
                                        Grupo (1) de {{_especialidad}}(s)
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">{{_area}} (1) <span
                                                    *ngIf="validator('fkarea1')">*</span></label>
                                            <div class="col-md-9" *ngIf="isRama1">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" value="{{fkarea1}}"
                                                        disabled>
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-primary"
                                                            (click)="cancelarAreas1();"><span>Cancelar</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-9 text-center"
                                                *ngIf="!isRama1 && (jsonAreas1$ | async)?.length <= 0">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-9" *ngIf="!isRama1 && (jsonAreas1$ | async)?.length > 0">
                                                <select class="form-control" formControlName="fkarea1"
                                                    (change)="getRamas1($event)">
                                                    <option [ngValue]="null" disabled>... Seleccione</option>
                                                    <option
                                                        *ngFor="let area of jsonAreas1$ | async | sortBy: 'asc':'nombre'"
                                                        [ngValue]="area.id + ':' + area.nombre">
                                                        {{ area.nombre }}</option>
                                                </select>
                                                <div *ngIf="(form.get('fkarea1').errors && form.get('fkarea1').dirty) || isVerificar">
                                                    <span class="help-block text-danger"
                                                        *ngIf="form.controls['fkarea1'].hasError('required')">
                                                        + Campo requerido
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">{{_rama}} (1) <span
                                                    *ngIf="validator('fkrama1')">*</span></label>
                                            <div class="col-md-9" *ngIf="!isRama1 || isEspecialidad1">
                                                <input type="text" class="form-control" value="{{fkrama1}}" disabled>
                                            </div>
                                            <div class="col-md-9 text-center"
                                                *ngIf="(jsonRamas1$ | async)?.length <= 0 && isRama1">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-9"
                                                *ngIf="(jsonRamas1$ | async)?.length > 0 && !isEspecialidad1">
                                                <select class="form-control" formControlName="fkrama1"
                                                    (change)="getEspecialidads1($event)">
                                                    <option [ngValue]="null" disabled>... Seleccione</option>
                                                    <option
                                                        *ngFor="let rama of jsonRamas1$ | async | sortBy: 'asc':'nombre'"
                                                        [ngValue]="rama.id + ':' + rama.nombre">
                                                        {{ rama.nombre }}</option>
                                                </select>
                                                <div *ngIf="(form.get('fkrama1').errors && form.get('fkrama1').dirty) || isVerificar">
                                                    <span class="help-block text-danger"
                                                        *ngIf="form.controls['fkrama1'].hasError('required')">
                                                        + Campo requerido
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">{{_especialidad}}(s) (1) <span
                                                    *ngIf="validator('fkespecialidad11')">*</span></label>
                                            <div class="col-md-9" *ngIf="!isEspecialidad1">
                                                <input type="text" class="form-control" disabled>
                                            </div>
                                            <!-- <div class="col-md-9"
                                                *ngIf="((jsonEspecialidads1$ | async)?.length > 0) && !isOtro1"> -->
                                            <div class="col-md-9 text-center"
                                                *ngIf="(jsonEspecialidads1$ | async)?.length <= 0 && isEspecialidad1">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-9" *ngIf="(jsonEspecialidads1$ | async)?.length > 0">
                                                <label class="custom-control custom-checkbox"
                                                    *ngFor="let especialidad of jsonEspecialidads1$ | async | sortBy: 'asc':'nombre'">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [value]="especialidad.id"
                                                        (change)="changedEspecialidad1($event)" />
                                                    <div class="custom-control-label">{{ especialidad.nombre }}</div>
                                                </label>
                                                <label class="custom-control custom-checkbox"
                                                    *ngIf="contEspecilidad1 > 0 && contEspecilidad1 < 5">
                                                    <input type="checkbox" class="custom-control-input" value="otro"
                                                        (change)="changedEspecialidad1($event)" />
                                                    <div class="custom-control-label">No encuentras tus
                                                        {{_especialidad}}(s),
                                                        ¡inclúyelas!</div>
                                                </label>
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad11">
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad12">
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad13">
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad14">
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad15">
                                            </div>
                                            <div
                                                *ngIf="(form.get('fkespecialidad11').errors && form.get('fkespecialidad11').dirty) || isVerificar">
                                                <span class="help-block text-danger"
                                                    *ngIf="form.controls['fkespecialidad11'].hasError('required')">
                                                    + Campo requerido
                                                </span>
                                            </div>
                                            <div class="col-md-12" *ngIf="isOtro1 && contEspecilidad1 < 5">
                                                <input type="text" class="form-control"
                                                    formControlName="otraespecialidad1"
                                                    oninput="this.value = this.value.toUpperCase()">
                                                <small>Establece tus {{_especialidad}}(s) separadas por comas, no usar
                                                    tildes o símbolos y continua registrándote</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card mb-3" style="background-color: #ffffff60;">
                                    <div class="card-header">
                                        Grupo (2) de {{_especialidad}}(s)
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">{{_area}} (2)</label>
                                            <div class="col-md-9" *ngIf="isRama2">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" value="{{fkarea2}}"
                                                        disabled>
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-primary"
                                                            (click)="cancelarAreas2();"><span>Cancelar</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-9 text-center"
                                                *ngIf="!isRama2 && (jsonAreas2$ | async)?.length <= 0">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-9" *ngIf="!isRama2 && (jsonAreas2$ | async)?.length > 0">
                                                <select class="form-control" formControlName="fkarea2"
                                                    (change)="getRamas2($event)">
                                                    <option [ngValue]="null" disabled>... Seleccione</option>
                                                    <option
                                                        *ngFor="let area of jsonAreas2$ | async | sortBy: 'asc':'nombre'"
                                                        [ngValue]="area.id + ':' + area.nombre">
                                                        {{ area.nombre }}</option>
                                                </select>
                                                <!-- <div *ngIf="(form.get('fkarea2').errors && form.get('fkarea2').dirty) || isVerificar">
                                                    <span class="help-block text-danger"
                                                        *ngIf="form.controls['fkarea2'].hasError('required')">
                                                        + Campo requerido
                                                    </span>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">{{_rama}} (2)</label>
                                            <div class="col-md-9" *ngIf="!isRama2 || isEspecialidad2">
                                                <input type="text" class="form-control" value="{{fkrama2}}" disabled>
                                            </div>
                                            <div class="col-md-9 text-center"
                                                *ngIf="(jsonRamas2$ | async)?.length <= 0 && isRama2">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-9"
                                                *ngIf="(jsonRamas2$ | async)?.length > 0 && !isEspecialidad2">
                                                <select class="form-control" formControlName="fkrama2"
                                                    (change)="getEspecialidads2($event)">
                                                    <option [ngValue]="null" disabled>... Seleccione</option>
                                                    <option
                                                        *ngFor="let rama of jsonRamas2$ | async | sortBy: 'asc':'nombre'"
                                                        [ngValue]="rama.id + ':' + rama.nombre">
                                                        {{ rama.nombre }}</option>
                                                </select>
                                                <!-- <div *ngIf="(form.get('fkrama2').errors && form.get('fkrama2').dirty) || isVerificar">
                                                    <span class="help-block text-danger"
                                                        *ngIf="form.controls['fkrama2'].hasError('required')">
                                                        + Campo requerido
                                                    </span>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label"> {{_especialidad}}(s) (2)</label>
                                            <div class="col-md-9" *ngIf="!isEspecialidad2">
                                                <input type="text" class="form-control" disabled>
                                            </div>
                                            <!-- <div class="col-md-9"
                                                *ngIf="((jsonEspecialidads2$ | async)?.length > 0) && !isOtro2"> -->
                                            <div class="col-md-9 text-center"
                                                *ngIf="(jsonEspecialidads2$ | async)?.length <= 0 && isEspecialidad2">
                                                <img src="assets/spinner.gif" alt=""
                                                    style="width: auto; height: 2.5rem;">
                                            </div>
                                            <div class="col-md-9" *ngIf="(jsonEspecialidads2$ | async)?.length > 0">
                                                <label class="custom-control custom-checkbox"
                                                    *ngFor="let especialidad of jsonEspecialidads2$ | async | sortBy: 'asc':'nombre'">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [value]="especialidad.id"
                                                        (change)="changedEspecialidad2($event)" />
                                                    <div class="custom-control-label">{{ especialidad.nombre }}</div>
                                                </label>
                                                <label class="custom-control custom-checkbox"
                                                    *ngIf="contEspecilidad2 > 0 && contEspecilidad2 < 5">
                                                    <input type="checkbox" class="custom-control-input" value="otro"
                                                        (change)="changedEspecialidad2($event)" />
                                                    <div class="custom-control-label">No encuentras tus
                                                        {{_especialidad}}(s),
                                                        ¡inclúyelas!</div>
                                                </label>
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad21">
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad22">
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad23">
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad24">
                                                <input type="hidden" class="form-control"
                                                    formControlName="fkespecialidad25">
                                            </div>
                                            <!-- <div
                                                *ngIf="(form.get('fkespecialidad21').errors && form.get('fkespecialidad21').dirty) || isVerificar">
                                                <span class="help-block text-danger"
                                                    *ngIf="form.controls['fkespecialidad21'].hasError('required')">
                                                    + Campo requerido
                                                </span>
                                            </div> -->
                                            <div class="col-md-12" *ngIf="isOtro2 && contEspecilidad2 < 5">
                                                <input type="text" class="form-control"
                                                    formControlName="otraespecialidad2"
                                                    oninput="this.value = this.value.toUpperCase()">
                                                <small>Establece tus {{_especialidad}}(s) separadas por comas, no usar
                                                    tildes o símbolos y continua registrándote</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ISCONFIRMA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="isconfirma">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">TOKENCONFIRMA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="tokenconfirma">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ESTADOCONFIRMA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="estadoconfirma">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ISRECUPERA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="isrecupera">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">TOKENRECUPERA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="tokenrecupera">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ESTADORECUPERA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="estadorecupera">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">TOKENREFERENCIA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="tokenreferencia">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="false">
                                    <label class="col-md-3 col-form-label">ESTADO</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="estado">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12">
                                        <input type="checkbox" class="form-check-input" id="acepta"
                                            formControlName="acepta">
                                        <label class="form-check-label" for="acepta">Aceptas los
                                            <a href="https://file.expertips.co/terminosycondiciones.pdf"
                                                target="_blank">Términos & Condiciones</a>
                                            y
                                            <a href="https://file.expertips.co/contratomandato.pdf"
                                                target="_blank">Contrato de Mandato</a>
                                            <span *ngIf="validator('acepta')">*</span></label>
                                    </div>
                                    
                                    <!-- <div class="col-md-12"
                                    *ngIf="form.get('acepta').invalid && (form.get('acepta').dirty || form.get('acepta').touched)"> -->
                                    <div class="col-md-12"
                                    *ngIf="(form.get('acepta').errors && form.get('acepta').dirty) || isVerificar">
                                    <span class="help-block text-danger"
                                        *ngIf="form.controls['acepta'].hasError('required')">
                                        + Campo requerido
                                    </span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="text-center">
                                    <button class="btn btn-secondary rounded-pill float-right mt-1"
                                        (click)="isVerificar = true;" *ngIf="isParte1 && !validar1()">
                                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                                    <button class="btn btn-outline-primary rounded-pill float-right mt-1"
                                        (click)="isParte1 = false; isParte2 = true; isVerificar = false;"
                                        *ngIf="isParte1 && validar1()">
                                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                                    <button class="btn btn-secondary rounded-pill float-right mt-1"
                                        (click)="isVerificar = true;" *ngIf="isParte2 && !validar2()">
                                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                                    <button class="btn btn-outline-primary rounded-pill float-right mt-1"
                                        (click)="isParte2 = false; isParte3 = true; isVerificar = false;"
                                        *ngIf="isParte2 && validar2()">
                                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                                    <button class="btn btn-secondary rounded-pill float-right mt-1"
                                        (click)="isVerificar = true;" *ngIf="isParte3 && !validar3()">
                                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                                    <button class="btn btn-outline-primary rounded-pill float-right mt-1"
                                        (click)="isParte3 = false; isParte4 = true; isVerificar = false;"
                                        *ngIf="isParte3 && validar3()">
                                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                                    <button class="btn btn-secondary rounded-pill float-right mt-1"
                                        (click)="isVerificar = true;" *ngIf="isParte4 && !validar4()">
                                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                                    <button class="btn btn-outline-primary rounded-pill float-right mt-1"
                                        (click)="isParte4 = false; isParte5 = true; isVerificar = false;"
                                        *ngIf="isParte4 && validar4()">
                                        Siguiente&nbsp;<i class="fas fa-step-forward"></i></button>
                                    <button type="button" class="btn btn-secondary rounded-pill float-right mt-1"
                                        (click)="isVerificar = true;"
                                        *ngIf="form.invalid && isAccion === -1 && isParte5">
                                        <i class="fas fa-angle-double-right"></i> Guardar formulario
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill float-right mt-1"
                                        *ngIf="form.valid && isAccion === -1 && isParte5">
                                        <i class="fas fa-angle-double-right"></i> Guardar formulario
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt=""
                                        *ngIf="isAccion === 0 && isParte5">
                                    <a (click)="regresar2()" *ngIf="isParte2"
                                        class="btn btn-outline-primary rounded-pill float-left mr-3 mt-1"><i
                                            class="fas fa-step-backward"></i>&nbsp;Regresar</a>
                                    <a (click)="regresar3()" *ngIf="isParte3"
                                        class="btn btn-outline-primary rounded-pill float-left mr-3 mt-1"><i
                                            class="fas fa-step-backward"></i>&nbsp;Regresar</a>
                                    <a (click)="regresar4()" *ngIf="isParte4"
                                        class="btn btn-outline-primary rounded-pill float-left mr-3 mt-1"><i
                                            class="fas fa-step-backward"></i>&nbsp;Regresar</a>
                                    <a (click)="regresar5()" *ngIf="isParte5"
                                        class="btn btn-outline-primary rounded-pill float-left mr-3 mt-1"><i
                                            class="fas fa-step-backward"></i>&nbsp;Regresar</a>
                                    <a [href]="_urlWeb"
                                        class="btn btn-secondary rounded-pill float-left mt-1">Cancelar</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>