import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
// import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { AuthService } from './../shared/service/-auth.service';
import { QueryService } from './../shared/service/-query.service';

@Component({
  selector: 'app-mi-usuario',
  templateUrl: './mi-usuario.component.html',
  styleUrls: ['./mi-usuario.component.css']
})
export class MiUsuarioComponent implements OnInit {

  public fk: number;
  public myControl0 = new FormControl();
  public myControl1 = new FormControl();
  public jsonPanel$: Observable<any[]>;
  public jsonPanel: any[] = [];
  public isBuscando = -1;
  public page = 1;
  public pageSize = 10;
  public isFiltrado = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  public _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private queryService: QueryService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }
    });

    this.fk = parseInt(this.auth.obtener('fk'));

    this.queryService.query_45(this.fk).subscribe(
      (response: any[]) => {
        this.jsonPanel = response;
        this.jsonPanel$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
      }
    );

    this.myControl0.setValue(this._experto.toUpperCase());
    // this.jsonPanel$ = this.myControl1.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => this._filter(value))
    //   );

    this.myControl1.valueChanges.subscribe(value => {
      this.isFiltrado = true;
      if (this.myControl0.value === 'SERVICIO') {
        if (value === this._pregunta) {
          value = 'NUEVA';
        } else if (value === this._hilo) {
          value = 'HILO';
        } else if (value === this._encuesta) {
          value = 'ENCUESTA';
        }
      }
      this.jsonPanel$ = new Observable((observer) => {
        observer.next(this._filter(value));
        observer.complete();
      });
    })
  }

  private _filter(value: string): any[] {
    const filterValue = value;
    if (this.myControl0.value === this._experto.toUpperCase()) {
      return this.jsonPanel.filter(option => option.nickname.toLowerCase().includes(filterValue.toLowerCase()));
    } else if (this.myControl0.value === 'SERVICIO') {
      return this.jsonPanel.filter(option => option.estado1.toLowerCase().includes(filterValue.toLowerCase()));
    } else if (this.myControl0.value === 'ESTADO') {
      return this.jsonPanel.filter(option => option.estado2.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      return this.jsonPanel.filter(option => option.titulo.toLowerCase().includes(filterValue.toLowerCase()));
    }
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  public aprobarPregunta(id: any, idHilo: any): void {
    try {
      this.queryService.query_73(this.fk, id, idHilo).subscribe(
        (response: any) => {
          //   console.log(response);
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos enviado tu inquietud ...',
            showConfirmButton: false,
            timer: 4000
          });
          let fkAux = this.fk;
          setTimeout(function () {
            window.location.href = '/mi-usuario/' + fkAux;
          }, 4000);
        }, error => {
          // console.log(error);
        }
      );
    } catch (error) {
      // console.log(error);
    }
  }

  public aprobarEncuesta(id: any): void {
    try {
      this.queryService.query_74(this.fk, id).subscribe(
        (response: any) => {
          //   console.log(response);
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos enviado tus inquietudes ...',
            showConfirmButton: false,
            timer: 4000
          });
          let fkAux = this.fk;
          setTimeout(function () {
            window.location.href = '/mi-usuario/' + fkAux;
          }, 4000);
        }, error => {
          // console.log(error);
        }
      );
    } catch (error) {
      // console.log(error);
    }
  }

}
