import { Component, OnInit } from '@angular/core'; import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { Experto } from './../shared/interface/experto';
// import { Area } from './../shared/interface/area';
import { AreaService } from './../shared/service/area.service';
// import { Rama } from './../shared/interface/rama';
// import { RamaService } from './../shared/service/rama.service';
// import { Especialidad } from './../shared/interface/especialidad';
// import { PreguntaService } from './../shared/service/pregunta.service';

@Component({
  selector: 'app-pregunta-cambio-experto',
  templateUrl: './pregunta-cambio-experto.component.html',
  styleUrls: ['./pregunta-cambio-experto.component.css']
})
export class PreguntaCambioExpertoComponent implements OnInit {

  public fk: number;
  public idHilo: number;
  public fkexperto: number;
  public fkespecialidad: number;
  private valor: number;
  private valorcomision: number;

  private estado1: string;
  private estado4: string;
  private estado5: string;

  public jsonExpertos$: Observable<Experto[]>;
  public jsonEstados$: Observable<any>;
  public isExperto = false;
  public isBuscandoExperto = false;
  public total = 0;
  public isBuscando = -1;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private areaService: AreaService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      // this.fk = parseInt(params.fk);
      this.fk = parseInt(this.auth.obtener('fk'));
      this.idHilo = parseInt(params.id);//Hilo.id
      this.fkexperto = parseInt(params.experto);
      this.fkespecialidad = parseInt(params.especialidad);
      this.getExpertos();
      this.getContenido();
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      /** Pregunta */
      fecha: [''],
      titulo: [null],
      tipo: [null], //ABIERTA ó DIRECTA
      fkusuario: [null], //this.fk
      estado1: [null], //PENDIENTE
      estado2: [null], //*PENDIENTE
      /** Hilo */
      id: [null], //this.id
      fkexperto: [null, Validators.required], //this.fkexperto ó 0(cero)
      valor: [null],
      valorcomision: [null],
      fkpregunta: [null],
      contenido: [null],
      estado4: [null], //PENDIENTE
      estado5: [null], //PENDIENTE
      estado6: [null], //*PENDIENTE
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  public setEstados(estado1: string, estado4: string, estado5: string) {
    this.estado1 = estado1;
    this.estado4 = estado4;
    this.estado5 = estado5;
  }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.valid) {
      const object = this.form.value;
      /** Pregunta */
      object.fkusuario = this.getFkusuario();
      object.estado1 = 'PENDIENTE';
      object.estado2 = 'PENDIENTE';
      /** Hilo */
      object.fkexperto = this.form.get('fkexperto').value.split(':')[0];
      if (this.estado1.toString().match('PENDIENTE') &&
        this.estado4.toString().match('PENDIENTE') &&
        this.estado5.toString().match('PENDIENTE')) {
        object.fkexpertoAnterior = this.fkexperto;
      } else {
        object.fkexpertoAnterior = 0;
      }
      /** Pregunta */
      if (parseInt(object.fkexperto) === 0) {
        object.tipo = 'ABIERTA';
      } else {
        object.tipo = 'DIRECTA';
      }
      /** Hilo */
      object.valor = this.valor;
      object.valorcomision = this.valorcomision;
      object.estado4 = 'PENDIENTE';
      object.estado5 = 'PENDIENTE';
      object.estado6 = 'PENDIENTE';
      this.isAccion = 0;

      this.queryService.query_32(object).subscribe(
        (response: any) => {
          // response 200
          this.isAccion = 1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos modificado el ' + this._experto + ' que resolvera tu inquietud ...',
            showConfirmButton: false,
            timer: 4000
          });

          setTimeout(function () {
            window.location.href = '/mi-usuario/' + object.fkusuario;
          }, 4000);
        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

    }
  }

  public getFkusuario(): number {
    return this.fk;
  }

  public getTarifasExperto(id: any): any {
    id = id.toString().trim();
    this.queryService.query_19(id).subscribe(
      (response: any) => {
        try {
          this.valor = response[0].valor;
          this.valorcomision = response[0].valorcomision;
        } catch (error) {
          this.valor = null;
          this.valorcomision = null;
        }
      },
      error => {
        this.valor = null;
        this.valorcomision = null;
      }
    );
  }

  public getExpertos(): void {
    this.isBuscandoExperto = true;
    this.isExperto = true;
    const fkespecialidad = this.fkespecialidad
    const fkexperto = this.fkexperto

    this.queryService.query_33(fkespecialidad, fkexperto).subscribe(
      (expertos: Experto[]) => {
        this.isBuscandoExperto = false;
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });
      }
    );
  }

  public getContenido(): void {
    this.queryService.query_31(this.fk, this.idHilo).subscribe(
      (response: any[]) => {
        try {
          if (response[0].fkusuario) {
            this.form.patchValue(response[0]);
            this.isBuscando = 1;
            this.total = response.length;

            this.getEstados();
          } else {
            this.isBuscando = 0;
          }
        } catch (error) {
          this.isBuscando = 0;
        }
      }, error => {
        this.isBuscando = 0;
      }
    );
  }

  public getEstados(): void {
    this.queryService.query_41(this.fk, this.idHilo).subscribe(
      (response: any[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });
      }, error => {
      }
    );
  }

  public changedExperto(experto: any): void {
    const fkexperto = experto.target.value.split(':')[1];
    this.getTarifasExperto(fkexperto);
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

}
