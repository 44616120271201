import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Especialidad } from './../interface/especialidad';

@Injectable({
  providedIn: 'root'
})
export class EspecialidadService {

  constructor(
    private http: HttpClient
  ) { }

  public getAll(): Observable<Especialidad[]> {
    return this.http.get<Especialidad[]>(environment.urlApi + '/Especialidad');
  }
}
