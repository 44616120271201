<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-experto></app-menu-experto>
    </div>
    <div class="container div-titulo">
        <h2 class="titulo">{{_encuesta.toUpperCase()}}</h2>
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isBuscando === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_encuesta}}, intenta
                                nuevamente.</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                    </div>

                    <div *ngIf="isBuscando === 1 && (jsonEstados$ | async)?.length > 0">
                        <div *ngFor="let item of jsonEstados$ | async; let index = index">
                            <!-- Preguntas ({{total}}) Respuestas (cargadas)({{totalPreguntasRespuestas}}) Respuestas (actuales)({{contadorRespuestas}}) 
                            <b>{{item.idEncuesta}}</b> - {{item.fecha}} - Respuestas <b>{{item.respuestas}}</b> <= Total Respuestas <b>{{item.totalRespuestas}}</b><br>
                            {{item.fkarea}} - {{item.fkrama}} - {{item.fkespecialidad}}<br>
                            {{item.estado1}} - {{item.estado2}} - {{item.horas}} horas<br> -->

                            <div class="card-body card-confirmacion text-center" *ngIf="parseInt(item.horas) < 24 && 
                                (totalPreguntasRespuestas < total) && 
                                parseInt(item.totalRespuestas) === parseInt(item.respuestas)">
                                <div class="form-group">
                                    <h2 class="titulo-mensaje">Este {{_encuesta}} ya fue completado</h2>
                                    <h2 class="titulo-mensaje">¡Gracias por tu interés!</h2>
                                </div>
                            </div>

                            
                            <p style="color: #665C61 !important;" *ngIf="isBuscando === 1 && 
                            (jsonPreguntas$ | async)?.length > 0 &&
                            parseInt(item.horas) < 24 && 
                            (totalPreguntasRespuestas <= total) &&
                            parseInt(item.totalRespuestas) < parseInt(item.respuestas)">
                            Recuerda que de la calidad de tus respuestas dependerá
                                tu
                                calificación, por eso te recomendamos ser claro, argumentando tu respuesta de la forma
                                más
                                detallada posible e incluso utiliza ejemplos en caso de que se requiera.</p>

                            <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1 && 
                                (jsonPreguntas$ | async)?.length > 0 &&
                                parseInt(item.horas) < 24 && 
                                (totalPreguntasRespuestas <= total) &&
                                parseInt(item.totalRespuestas) < parseInt(item.respuestas)">
                                <div class="card-body">
                                    <div
                                        *ngFor="let item of jsonPreguntas$ | async | sortBy: 'asc':'idPreguntas'; let index = index">

                                        <div class="form-group row" *ngIf="index === 0">
                                            <label class="col-md-3 col-form-label">FECHA</label>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control" [value]="item.fecha" disabled>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="index === 0">
                                            <label class="col-md-3 col-form-label">TÍTULO</label>
                                            <div class="col-md-9">
                                                <div class="p-3" [innerHtml]="'<b>' + item.tituloEncuesta + '</b>'"
                                                    style="border: 1px solid #ced4da; border-radius: .25rem;">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group row" *ngIf="index === 0">
                                            <label class="col-md-3 col-form-label">OBSERVACIÓN</label>
                                            <div class="col-md-9">
                                                <div class="p-3" [innerHtml]="'<b>' + item.observacion + '</b>'"
                                                    style="border: 1px solid #ced4da; border-radius: .25rem;">
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div *ngFor="let item of res.controls; let index = index">
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <div class="card" style="background-color: #ffffff60;">
                                                    <form [formGroup]="item" (ngSubmit)="save($event, index, item)"
                                                        enctype="multipart/form-data" class="form-horizontal">
                                                        <div class="card-header"><b>{{index + 1}}</b>.
                                                            {{item.get('titulo').value}}
                                                        </div>
                                                        <div class="card-body" *ngIf="!preguntasRespuestas[index][0]">
                                                            <div *ngIf="item.get('tipo').value !== 'MULTIPLE'"
                                                                style="text-align: justify;">
                                                                <input type="hidden" formControlName="fkencuesta">
                                                                <input type="hidden" formControlName="fkexperto">
                                                                <input type="hidden" formControlName="fkpregunta">
                                                                <input type="hidden" formControlName="tipo">
                                                                <input type="hidden" formControlName="titulo">
                                                                <mat-radio-group aria-labelledby="radio-group-label"
                                                                    class="radio-group" formControlName="respuestas">
                                                                    <mat-radio-button class="radio-button"
                                                                        *ngFor="let opcion of preguntas[index]"
                                                                        [value]="opcion">
                                                                        {{opcion}}
                                                                    </mat-radio-button>
                                                                </mat-radio-group>
                                                                <button type="button"
                                                                    class="btn btn-secondary rounded-pill"
                                                                    *ngIf="item.invalid && isAccion[index] === -1">
                                                                    <i class="fas fa-angle-double-right"></i> Guardar
                                                                </button>
                                                                <button type="submit"
                                                                    class="btn btn-outline-primary rounded-pill"
                                                                    *ngIf="item.valid && isAccion[index] === -1">
                                                                    <i class="fas fa-angle-double-right"></i> Guardar
                                                                </button>
                                                                <img src="assets/template/images/loading_16.gif" alt=""
                                                                    *ngIf="isAccion[index] === 0">

                                                            </div>
                                                            <div *ngIf="item.get('tipo').value === 'MULTIPLE'"
                                                                style="text-align: justify;">
                                                                <input type="hidden" formControlName="fkencuesta">
                                                                <input type="hidden" formControlName="fkexperto">
                                                                <input type="hidden" formControlName="fkpregunta">
                                                                <input type="hidden" formControlName="tipo">
                                                                <input type="hidden" formControlName="titulo">
                                                                <section class="example-section">
                                                                    <span class="example-list-section">
                                                                        <ul>
                                                                            <li *ngFor="let opcion of preguntas[index]">
                                                                                <mat-checkbox
                                                                                    formControlName="respuestas"
                                                                                    (change)="onCheckboxChecked($event, opcion, index)"
                                                                                    [value]="opcion" [checked]="opcion">
                                                                                    {{opcion}}
                                                                                </mat-checkbox>
                                                                            </li>
                                                                        </ul>
                                                                    </span>
                                                                </section>
                                                                <button type="button"
                                                                    class="btn btn-secondary rounded-pill"
                                                                    *ngIf="item.invalid && isAccion[index] === -1">
                                                                    <i class="fas fa-angle-double-right"></i> Guardar
                                                                </button>
                                                                <button type="submit"
                                                                    class="btn btn-outline-primary rounded-pill"
                                                                    *ngIf="item.valid && isAccion[index] === -1">
                                                                    <i class="fas fa-angle-double-right"></i> Guardar
                                                                </button>
                                                                <img src="assets/template/images/loading_16.gif" alt=""
                                                                    *ngIf="isAccion[index] === 0">
                                                            </div>
                                                        </div>
                                                        <div class="card-body" *ngIf="preguntasRespuestas[index][0]">
                                                            <div class="p-3"
                                                                [innerHtml]="'' + preguntasRespuestas[index][0] + ''"
                                                                style="border: 1px solid #ced4da; border-radius: .25rem;">
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body card-confirmacion text-center" *ngIf="parseInt(item.horas) >= 24 &&
                                (totalPreguntasRespuestas < total)">
                                <div class="form-group">
                                    <h2 class="titulo-mensaje">Se ha agotado el tiempo para realizar tu {{_encuesta}}
                                    </h2>
                                </div>
                            </div>

                            <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1 &&
                                (jsonPreguntas$ | async)?.length > 0 &&
                                totalPreguntasRespuestas === total &&
                                parseInt(item.totalRespuestas) === parseInt(item.respuestas)">
                                <div class="card-body">
                                    <div
                                        *ngFor="let item of jsonPreguntas$ | async | sortBy: 'asc':'idPreguntas'; let index = index">

                                        <div class="form-group row" *ngIf="index === 0">
                                            <label class="col-md-3 col-form-label">FECHA</label>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control" [value]="item.fecha" disabled>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="index === 0">
                                            <label class="col-md-3 col-form-label">TÍTULO</label>
                                            <div class="col-md-9">
                                                <div class="p-3" [innerHtml]="'<b>' + item.tituloEncuesta + '</b>'"
                                                    style="border: 1px solid #ced4da; border-radius: .25rem;">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group row" *ngIf="index === 0">
                                            <label class="col-md-3 col-form-label">OBSERVACIÓN</label>
                                            <div class="col-md-9">
                                                <div class="p-3" [innerHtml]="'<b>' + item.observacion + '</b>'"
                                                    style="border: 1px solid #ced4da; border-radius: .25rem;">
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div *ngFor="let item of res.controls; let index = index">
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <div class="card" style="background-color: #ffffff60;">
                                                    <form [formGroup]="item" (ngSubmit)="save($event, index, item)"
                                                        enctype="multipart/form-data" class="form-horizontal">
                                                        <div class="card-header"><b>{{index + 1}}</b>.
                                                            {{item.get('titulo').value}}
                                                        </div>
                                                        <div class="card-body" *ngIf="preguntasRespuestas[index][0]">
                                                            <div class="p-3"
                                                                [innerHtml]="'' + preguntasRespuestas[index][0] + ''"
                                                                style="border: 1px solid #ced4da; border-radius: .25rem;">
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>