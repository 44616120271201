import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Rama } from './../interface/rama';

@Injectable({
  providedIn: 'root'
})
export class RamaService {

  constructor(
    private http: HttpClient
  ) { }

  public getAll(): Observable<Rama[]> {
    return this.http.get<Rama[]>(environment.urlApi + '/Rama');
  }
}
