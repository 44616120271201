<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-experto></app-menu-experto>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">¿ DESEAS SER {{_destacado.toUpperCase()}} ?</h2>
    </div> -->
    <div class="container mb-3">
        <div class="row mt-3">
            <img src="assets/cuadro_destacado_escritorio.png" alt="" class="img-fluid normal">
            <img src="assets/cuadro_destacado_responsive.png" alt="" class="img-fluid responsive px-2"><br>
            <a [href]="_urlPay + '/?tipo=destacado&id=' + this.fk" type="button" class="btn btn-outline-primary rounded-pill mt-3 mx-auto">Proceder al pago</a>
        </div>
    </div>
</section>