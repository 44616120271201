import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Especialidad } from '../interface/especialidad';
import { Rama } from './../interface/rama';
import { Maestro } from '../interface/maestro';
import { Tarifa } from './../interface/tarifa';
import { NotificacionExperto } from './../interface/notificacion-experto';
import { NotificacionUsuario } from './../interface/notificacion-usuario';
import { LocalStorageService } from './-local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class QueryService {

  // private headers;

  constructor(
    private http: HttpClient,
    // private localstorageService: LocalStorageService,
  ) {
    // try {
    //   this.headers = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json, text/plain, */*',
    //     'Accept-Encoding': 'gzip, deflate, br',
    //     'Accept-Language': 'es-ES,es;q=0.9',
    //     'Connection': 'keep-alive',
    //     // 'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Accept, Origin, Authorization, HTTP_JWT, tipo, fk',
    //     'JWT': this.localstorageService.get('QYfRuxeadphBcSuOAczF').toString(),
    //     'tipo': this.localstorageService.get('qFUNseVbOFIMuWyQSdxC').toString(),
    //     'fk': this.localstorageService.get('eVBEvatmKiVlokbfjfwJ').toString(),
    //     'correoelectronico': this.localstorageService.get('CBIfXgisPiSqolrJrNFd').toString(),
    //   });
    // } catch (error) {
    // }

  }

  public query_0(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/0', { http_code: value });
  }

  // public query_1(value: any): Observable<Rama[]> {
  //   return this.http.post<Rama[]>(environment.urlApi + '/Query/1', { nombre: value });
  // }
  public query_1(value: any): Observable<Rama[]> {
    return this.http.post<Rama[]>(environment.urlApi + '/Query/1', { id: value });
  }

  // public query_2(value: any): Observable<Especialidad[]> {
  //   return this.http.post<Especialidad[]>(environment.urlApi + '/Query/2', { nombre: value });
  // }
  public query_2(value: any): Observable<Especialidad[]> {
    return this.http.post<Especialidad[]>(environment.urlApi + '/Query/2', { id: value });
  }

  public query_3(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/3', { correoelectronico: value1, tokenconfirma: value2 });
  }

  public query_4(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/4', { correoelectronico: value });
  }

  public query_5(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/5', { correoelectronico: value1, tokenrecupera: value2 });
  }

  public query_6(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/6', { correoelectronico: value1, tokenconfirma: value2 });
  }

  public query_7(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/7', { correoelectronico: value });
  }

  public query_8(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/8', { correoelectronico: value1, tokenrecupera: value2 });
  }

  public query_9(value1: any, value2: any, value3: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/9', { correoreferencia: value1, tokenreferencia: value2, numero: value3 });
  }

  public query_10(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/10', { id: value });
  }

  public query_11(value1: any, value2: any, value3: any, value4: any, value5: any): Observable<any[]> {
    if (value5 === 'normal') {
      value5 = 'posicion ASC';
    } else if (value5 === 'tarifaMenorMayor') {
      value5 = 'tarifa ASC';
    } else if (value5 === 'tarifaMayorMenor') {
      value5 = 'tarifa DESC';
    } else if (value5 === 'experienciaMenorMayor') {
      value5 = 'anosexperiencia ASC';
    } else if (value5 === 'experienciaMayorMenor') {
      value5 = 'anosexperiencia DESC';
    } else if (value5 === 'calificacionMenorMayor') {
      value5 = 'calificaciones ASC';
    } else if (value5 === 'calificacionMayorMenor') {
      value5 = 'calificaciones DESC';
    }

    return this.http.post<any[]>(environment.urlApi + '/Query/11', { tipo: value1, criterio: value2, inicio: value3, fin: value4, order: value5 });
  }

  public query_911(value1: any, value2: any, value3: any, value4: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/911', { tipo: value1, criterio: value2, inicio: value3, fin: value4 });
  }

  /*** Maestro */
  public query_12(value: any): Observable<Maestro[]> {
    return this.http.post<Maestro[]>(environment.urlApi + '/Query/12', { campo: value });
  }

  /*** Maestro */
  public query_121(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/121', { campo: value });
  }


  /*** Auth */
  public query_13(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/13', { value: null });
  }

  public query_14(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/14', { tipo: value1, criterio: value2 });
  }

  public query_15(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/15', { tipo: value1, criterio: value2 });
  }

  public query_16(value1: any, value2: any, value3: any, value4: any): Observable<any[]> {
    if (value4 === 'normal') {
      value4 = 'posicion ASC';
    } else if (value4 === 'tarifaMenorMayor') {
      value4 = 'tarifa ASC';
    } else if (value4 === 'tarifaMayorMenor') {
      value4 = 'tarifa DESC';
    } else if (value4 === 'experienciaMenorMayor') {
      value4 = 'anosexperiencia ASC';
    } else if (value4 === 'experienciaMayorMenor') {
      value4 = 'anosexperiencia DESC';
    } else if (value4 === 'calificacionMenorMayor') {
      value4 = 'calificaciones ASC';
    } else if (value4 === 'calificacionMayorMenor') {
      value4 = 'calificaciones DESC';
    }

    return this.http.post<any[]>(environment.urlApi + '/Query/16', { criterio: value1, inicio: value2, fin: value3, order: value4 });
  }

  public query_916(value1: any, value2: any, value3: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/916', { criterio: value1, inicio: value2, fin: value3 });
  }

  public query_17(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/17', { criterio: value });
  }

  public query_18(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/18', { criterio: value });
  }

  public query_19(value: any): Observable<Tarifa[]> {
    return this.http.post<Tarifa[]>(environment.urlApi + '/Query/19', { id: value });
  }

  // public query_20(value: any): Observable<ReciboHilo[]> {
  //   return this.http.post<ReciboHilo[]>(environment.urlApi + '/Query/20', { id: value });
  // }

  public query_21(value1: any, value2: any, value3: any, value4: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/21', { id: value1, fkarea: value2, fkrama: value3, fkespecialidad: value4 });
  }

  public query_22(value: any): Observable<NotificacionExperto[]> { /*** Auth */
    return this.http.post<NotificacionExperto[]>(environment.urlApi + '/Query/22', { id: value }/*, {
      headers: this.headers
    }*/);
  }

  public query_23(value: any): Observable<NotificacionUsuario[]> { /*** Auth */
    return this.http.post<NotificacionUsuario[]>(environment.urlApi + '/Query/23', { id: value }/*, {
      headers: this.headers
    }*/);
  }

  public query_24(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/24', { criterio: value });
  }

  public query_25(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/25', { value: null });
  }

  public query_26(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/26', { value: null });
  }

  public query_27(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/27', { value: null });
  }

  public query_28(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/28', { value: null });
  }

  public query_29(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/29', { value: null });
  }
  public query_291(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/291', { value: null });
  }

  public query_30(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/30', { criterio: value });
  }

  public query_31(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/31', { fk: value1, id: value2 });
  }

  public query_32(objeto: object): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/32', objeto);
  }

  public query_33(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/33', { criterio: value1, fkexperto: value2 });
  }

  public query_34(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/34', { fk: value1, id: value2 });
  }

  public query_35(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/35', { fk: value1, id: value2 });
  }

  public query_36(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/36', { fk: value1, id: value2 });
  }

  public query_37(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/37', { fk: value1, id: value2 });
  }

  public query_38(objeto: object): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/38', objeto);
  }

  public query_39(value1: any, value2: any, value3: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/39', { fk: value1, id: value2, fkpregunta: value3 });
  }

  public query_40(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/40', { fk: value1, id: value2 });
  }

  public query_41(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/41', { fk: value1, id: value2 });
  }

  public query_42(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/42', { fk: value });
  }

  public query_43(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/43', { fk: value });
  }

  public query_44(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/44', { fk: value });
  }

  public query_45(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/45', { fk: value });
  }

  public query_46(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/46', { fk: value });
  }

  public query_47(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/47', { fk: value });
  }

  public query_48(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/48', { fk: value });
  }

  public query_49(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/49', { fk: value });
  }

  public query_50(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/50', { fk: value });
  }

  public query_51(value1: any, value2: any, value3: any, value4: any, value5: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/51', { criterio: value1, tarifamin: value2, tarifamax: value3, anosmin: value4, anosmax: value5 });
  }

  public query_511(value1: any, value2: any, value3: any, value4: any, value5: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/511', { criterio: value1, tarifamin: value2, tarifamax: value3, anosmin: value4, anosmax: value5 });
  }

  public query_52(value: any): Observable<Tarifa[]> {
    return this.http.post<Tarifa[]>(environment.urlApi + '/Query/52', { id: value });
  }

  public query_53(): Observable<Tarifa[]> {
    return this.http.post<Tarifa[]>(environment.urlApi + '/Query/53', { value: null });
  }

  public query_54(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/54', { fk: value1, id: value2 });
  }

  public query_55(value1: any, value2: any, value3: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/55', { fk: value1, fkencuesta: value2, id: value3 });
  }

  public query_56(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/56', { fk: value1, fkencuesta: value2 });
  }

  public query_57(value1: any, value2: any, value3: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/57', { token: value1, fk: value2, id: value3 });
  }

  public query_58(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/58', { fk: value1, id: value2 });
  }

  public query_59(value1: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/59', { id: value1 });
  }

  public query_60(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/60', { fkencuesta: value1, id: value2 });
  }

  public query_61(value1: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/61', { fkencuesta: value1 });
  }
  
  public query_62(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/62', { fk: value1, id: value2 });
  }
  
  public query_63(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/63', { fk: value1, id: value2 });
  }

  public query_64(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/64', { fk: value1, id: value2 });
  }

  public query_65(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/65', { value: null });
  }

  public query_66(value1: any, value2: any, value3: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/66', { token: value1, fk: value2, id: value3 });
  }

  public query_67(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/67', { fk: value1, id: value2 });
  }

  public query_68(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/68', { fk: value });
  }

  public query_69(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/69', { fk: value });
  }

  public query_70(value: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/70', { fk: value });
  }

  public query_71(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/71', { correoelectronico: value });
  }

  public query_72(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/72', { correoelectronico: value });
  }

  public query_73(value1: any, value2: any, value3: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/73', { fk: value1, id: value2, idHilo: value3 });
  }
  
  public query_74(value1: any, value2: any): Observable<any[]> { /*** Auth */
    return this.http.post<any[]>(environment.urlApi + '/Query/74', { fk: value1, id: value2 });
  }
  public query_75(value1: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/75', { id: value1 });
  }
  public query_76(value1: any): Observable<any[]> { 
    return this.http.post<any[]>(environment.urlApi + '/Query/76', { id: value1 });
  }
  public query_77(value1: any): Observable<any[]> { 
    return this.http.post<any[]>(environment.urlApi + '/Query/77', { id: value1 });
  }

  



  public query_100(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/100', { value: null });
  }

}
