import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { ValoracionService } from './../shared/service/valoracion.service';

@Component({
  selector: 'app-valoracion-respuesta',
  templateUrl: './valoracion-respuesta.component.html',
  styleUrls: ['./valoracion-respuesta.component.css']
})
export class ValoracionRespuestaComponent implements OnInit {

  public idHilo: number;
  public fk: number;
  public fkexperto: number;

  public hoy = new Date();
  private titulo: string;
  private correoelectronico: string;
  private nickname: string;
  private fkpregunta: number;
  private horasRespuesta: number;
  public totalCaracteres = 0;
  private comentario: string;

  public jsonEstados$: Observable<any>;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private valoracionService: ValoracionService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      this.idHilo = parseInt(params.id);//Pregunta.id
      // this.fk = parseInt(params.fk);
      this.fk = parseInt(this.auth.obtener('fk'));
      this.fkexperto = parseInt(params.experto);
      this.correoelectronico = this.auth.obtener('correoelectronico').toString();
      this.nickname = this.auth.obtener('nickname').toString();

      this.getEstados();
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      fecha: [''],
      fkhilo: [null],
      estrella: [null, Validators.required],
      comentario: [null, Validators.required],
      satisfecho: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(2)]],
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  public setInformacion(fkpregunta: number, titulo: string, horasRespuesta: number) {
    this.fkpregunta = fkpregunta;
    this.titulo = titulo;
    this.horasRespuesta = horasRespuesta;
  }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.valid) {
      const object = this.form.value;
      object.fkhilo = this.idHilo;
      // object.comentario = this.comentario;
      object.fkusuario = this.fk;
      object.fkexperto = this.fkexperto;
      object.fkexperto = this.fkexperto;
      object.horasRespuesta = this.horasRespuesta;

      object.titulo = this.titulo;
      // object.comentarios = '[{"fecha": "' + this.hoy + '", "usuario": "' + this.correoelectronico + '", "comentario": "' + this.comentario + ', Valoración: ' + object.estrella + '"}]';
      object.comentarios = '[{"fecha": "' + this.hoy + '", "usuario": "' + this.correoelectronico + '", "comentario": "' + this.form.get('comentario').value + ', Valoración: ' + object.estrella + '"}]';
      object.nombrecompleto = this.nickname;
      object.correoelectronico = this.correoelectronico;
      object.fkusuario = this.fk;
      object.estado = this.getEstadoInicial();
      object.accion = 'ABIERTO';
      object.fkpregunta = this.fkpregunta;
      object.fkhilo = this.idHilo;
      object.fkexperto = this.fkexperto;

      this.isAccion = 0;

      this.valoracionService.post(object).subscribe(
        (response: any) => {
          // response 200
          this.isAccion = 1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos enviado tu valoración...',
            showConfirmButton: false,
            timer: 4000
          });
          
          setTimeout(function () {
            window.location.href = '/pregunta-resuelta/' + object.fkusuario + '/' + object.fkpregunta;
          }, 4000);
        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

    }
  }

  public getFkusuario(): number {
    return this.fk;
  }

  public getEstadoInicial(): string {
    return 'ACTIVO';
  }

  public getEstados(): void {
    this.queryService.query_41(this.fk, this.idHilo).subscribe(
      (response: any[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });
      }, error => {
      }
    );
  }

  public onChangue(event: any): void {
    this.totalCaracteres = event.editor.getLength();
    if (this.totalCaracteres > 99) {
      event.editor.deleteText(99, event.editor.getLength());
    }
    this.comentario = event.editor.getText();
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

}
