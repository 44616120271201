<section class="banner-all">
    <div class="container div-titulo">
        <h2 class="titulo">Requiero un {{_encuesta}}</h2>
        <p class="mt-3 text-justify">
            Realiza una encuesta a varios Expertos y obtén el resultado para tomar la decisión correcta. 
        </p>
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isEstado === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline mt-5" *ngIf="isEstado === 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_experto}}, intenta
                                nuevamente.</strong>
                            <a [routerLink]="['/consulta-experto/busquedad/%/1']"
                                class="btn btn-block btn-primary ml-2">
                                <i class="fas fa-search"></i> <span class="text">Buscar</span>
                            </a>
                        </div>
                    </header>

                    <form [formGroup]="form1" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal" *ngIf="!isIngresarPreguntas">
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isEstado === 1">
                            <div class="card-body">
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 col-form-label">{{_area}}<span
                                            *ngIf="validator1('fkarea')">*</span></label>
                                    <div class="col-md-9" *ngIf="isRama">
                                        <div class="input-group">
                                            <input type="text" class="form-control" value="{{fkarea}}" disabled>
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-primary py-0 px-2"
                                                    (click)="refresh()"> <i class="fas fa-sync"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9 text-center" *ngIf="(jsonAreas$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonAreas$ | async)?.length > 0 && !isRama">
                                        <select class="form-control" formControlName="fkarea"
                                            (change)="getRamas($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let area of jsonAreas$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="area.id + ':' + area.nombre">
                                                {{ area.nombre }}</option>
                                        </select>
                                        <div *ngIf="form1.get('fkarea').errors && form1.get('fkarea').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['fkarea'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 col-form-label">{{_rama}}<span
                                            *ngIf="validator1('fkrama')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isRama || isEspecialidad">
                                        <input type="text" class="form-control" value="{{fkrama}}" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonRamas$ | async)?.length <= 0 && isRama">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonRamas$ | async)?.length > 0 && !isEspecialidad">
                                        <select class="form-control" formControlName="fkrama"
                                            (change)="getEspecialidads($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option *ngFor="let rama of jsonRamas$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="rama.id + ':' + rama.nombre">
                                                {{ rama.nombre }}</option>
                                        </select>
                                        <div *ngIf="form1.get('fkrama').errors && form1.get('fkrama').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['fkrama'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 col-form-label">{{_especialidad}}<span
                                            *ngIf="validator1('fkespecialidad')">*</span></label>
                                    <div class="col-md-9" *ngIf="!isEspecialidad || isExperto">
                                        <input type="text" class="form-control" value="{{fkespecialidad}}" disabled>
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonEspecialidads$ | async)?.length <= 0 && isEspecialidad">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9"
                                        *ngIf="(jsonEspecialidads$ | async)?.length > 0 && !isExperto">
                                        <select class="form-control" formControlName="fkespecialidad"
                                            (change)="changueEspecialidads($event)">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option
                                                *ngFor="let especialidad of jsonEspecialidads$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="especialidad.id + ':' + especialidad.nombre">
                                                {{ especialidad.nombre }}</option>
                                        </select>
                                        <div
                                            *ngIf="form1.get('fkespecialidad').errors && form1.get('fkespecialidad').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['fkespecialidad'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 offset-md-3 col-form-label">TARIFAS<span
                                            *ngIf="validator1('tarifamin')">*</span></label>
                                    <div class="col-md-8 text-center" *ngIf="(jsonTarifas$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-3 col-6" *ngIf="(jsonTarifas$ | async)?.length > 0">
                                        <select class="form-control" formControlName="tarifamin"
                                            (change)="setTarifamax($event)">
                                            <option [ngValue]="null" disabled>... Desde </option>
                                            <option *ngFor="let tarifa of jsonTarifas | sortBy: 'asc':'valor'"
                                                [ngValue]="tarifa.valor">
                                                {{ tarifa.valor | currency:'$':'symbol':'1.0' }}</option>
                                        </select>
                                        <div *ngIf="form1.get('tarifamin').errors && form1.get('tarifamin').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['tarifamin'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-6"
                                        *ngIf="(jsonTarifas$ | async)?.length > 0 && form1.get('tarifamin').value">
                                        <select class="form-control" formControlName="tarifamax">
                                            <option [ngValue]="null" disabled>... Hasta </option>
                                            <option *ngFor="let tarifa of jsonTarifas$ | async | sortBy: 'asc':'valor'"
                                                [ngValue]="tarifa.valor">
                                                {{ tarifa.valor | currency:'$':'symbol':'1.0' }}</option>
                                        </select>
                                        <div *ngIf="form1.get('tarifamax').errors && form1.get('tarifamax').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['tarifamax'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-6"
                                        *ngIf="(jsonTarifas$ | async)?.length > 0 && !form1.get('tarifamin').value">
                                        <select class="form-control">
                                            <option [ngValue]="null" disabled>... Hasta </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="!isSeleccionar">
                                    <label class="col-md-3 offset-md-3 col-form-label">AÑOS DE EXPERIENCIA<span
                                            *ngIf="validator1('anosmin')">*</span></label>
                                    <div class="col-md-8 text-center"
                                        *ngIf="(jsonAnosExperiencias$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-3 col-6" *ngIf="(jsonAnosExperiencias$ | async)?.length > 0">
                                        <select class="form-control" formControlName="anosmin"
                                            (change)="setAnosmax($event)">
                                            <option [ngValue]="null" disabled>... Desde </option>
                                            <option
                                                *ngFor="let ano of jsonAnosExperiencias | sortBy: 'asc':'id'"
                                                [ngValue]="ano.anos">
                                                {{ano.anos}}</option>
                                        </select>
                                        <div *ngIf="form1.get('anosmin').errors && form1.get('anosmin').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['anosmin'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-6"
                                        *ngIf="(jsonAnosExperiencias$ | async)?.length > 0 && form1.get('anosmin').value">
                                        <select class="form-control" formControlName="anosmax">
                                            <option [ngValue]="null" disabled>... Hasta </option>
                                            <option
                                                *ngFor="let ano of jsonAnosExperiencias$ | async | sortBy: 'asc':'id'"
                                                [ngValue]="ano.anos">
                                                {{ano.anos}}</option>
                                        </select>
                                        <div *ngIf="form1.get('anosmax').errors && form1.get('anosmax').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['anosmax'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-6"
                                        *ngIf="(jsonAnosExperiencias$ | async)?.length > 0 && !form1.get('anosmin').value">
                                        <select class="form-control">
                                            <option [ngValue]="null" disabled>... Hasta </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-2 col-12 d-flex mb-1">
                                        <button type="button" class="btn btn-sm btn-block btn-primary mx-auto" *ngIf="form1.get('fkarea').value && 
                                            form1.get('fkrama').value &&
                                            form1.get('fkespecialidad').value &&
                                            form1.get('tarifamin').value &&
                                            form1.get('tarifamax').value &&
                                            form1.get('anosmin').value &&
                                            form1.get('anosmax').value &&
                                            !isExpertos" (click)="getExpertos()">
                                            <i class="fas fa-angle-double-right"></i> Buscar {{_experto}}s
                                        </button>
                                        <!-- <button type="button" class="btn btn-sm btn-block btn-secondary mx-auto" *ngIf="!form1.get('fkarea').value || 
                                            !form1.get('fkrama').value ||
                                            !form1.get('fkespecialidad').value ||
                                            !form1.get('tarifamin').value ||
                                            !form1.get('tarifamax').value ||
                                            !form1.get('anosmin').value ||
                                            !form1.get('anosmax').value">
                                            <i class="fas fa-angle-double-right"></i> Buscar {{_experto}}s
                                        </button> -->
                                        <button type="button" class="btn btn-sm btn-block btn-primary mx-auto" *ngIf="form1.get('fkarea').value && 
                                            form1.get('fkrama').value &&
                                            isRamaTodos &&
                                            form1.get('tarifamin').value &&
                                            form1.get('tarifamax').value &&
                                            form1.get('anosmin').value &&
                                            form1.get('anosmax').value &&
                                            !isExpertos" (click)="getExpertosTodosAdicional()">
                                            <i class="fas fa-angle-double-right"></i> Buscar {{_experto}}s
                                        </button>
                                    </div>
                                    <label class="col-md-3 col-12 col-form-label"
                                        *ngIf="totalExpertos > 1">{{_experto}}(s) disponibles<span
                                            *ngIf="validator1('respuestas')">*</span></label>
                                    <div class="col-md-2 col-12" *ngIf="totalExpertos > 1">
                                        <input type="text" class="form-control" [value]="totalExpertos" disabled>
                                    </div>
                                    <label class="col-md-3 col-12 col-form-label" *ngIf="totalExpertos > 1">Respuestas
                                        requeridas <span *ngIf="validator1('respuestas')">*</span></label>
                                    <div class="col-md-2 col-12" *ngIf="totalExpertos > 1">
                                        <input type="text" class="form-control" formControlName="respuestas">
                                        <div *ngIf="form1.get('respuestas').errors && form1.get('respuestas').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['respuestas'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['respuestas'].hasError('min') || form1.controls['respuestas'].hasError('max')">
                                                + Error
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-9 col-12 text-center" *ngIf="totalExpertos <= 1 && isExpertos">
                                        <span class="mr-md-auto mb-5">No hemos encontrado tu(s) {{_experto}}, intenta
                                            nuevamente.</span>
                                        <a (click)="reload()" class="btn btn-primary ml-2">
                                            <i class="fas fa-sync"></i>
                                        </a>
                                    </div>
                                </div>
                                <!-- isExpertos {{isExpertos}}<br>
                                totalExpertos {{totalExpertos}}<br>
                                {{(jsonExpertos$ | async) | json}} -->
                                <!-- <div class="form-group row" *ngIf="(jsonExpertos$ | async)?.length > 1"> ORIGINAL-->
                                    <!-- MODIFICADO -->
                                    <div class="form-group row"> 
                                    <label class="col-md-3 col-form-label">TÍTULO ENCUESTA<span
                                            *ngIf="validator1('titulo')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="titulo">
                                        <div *ngIf="form1.get('titulo').errors && form1.get('titulo').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['titulo'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['titulo'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['titulo'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row" *ngIf="(jsonExpertos$ | async)?.length > 0">
                                    <label class="col-md-3 col-form-label">OBSERVACIÓN </label>
                                    <div class="col-md-9">
                                        <quill-editor [styles]="{height: '250px'}"
                                            (onContentChanged)="onChangue($event)" formControlName="observacion">
                                        </quill-editor>
                                        <small>Contador de caracteres, {{ totalCaracteres }} <span
                                                class="text-danger">Maximo 500</span></small>
                                    </div>
                                </div> -->
                                <div class="form-group row" *ngIf="(jsonExpertos$ | async)?.length > 1">
                                    <label class="col-md-3 col-form-label">CORREO ELECTRÓNICO<span
                                            *ngIf="validator1('correoelectronicotemp')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoelectronicotemp"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form1.get('correoelectronicotemp').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['correoelectronicotemp'].hasError('pattern')">
                                                + Ingresa un correo valido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="form1.get('correoelectronicotemp').errors && form1.get('correoelectronicotemp').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form1.controls['correoelectronicotemp'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isEstado === 1">
                            <div class="card-body">
                                <div class="text-center">
                                    <button type="button" class="btn btn-secondary rounded-pill"
                                        *ngIf="form1.invalid && isAccion1 === -1">
                                        <i class="fas fa-angle-double-right"></i> Iniciar {{_encuesta}}
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill"
                                        *ngIf="form1.valid && isAccion1 === -1">
                                        <i class="fas fa-angle-double-right"></i> Iniciar {{_encuesta}}
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion1 === 0">
                                </div>
                            </div>
                        </div>
                    </form>

                    <form [formGroup]="form2" (ngSubmit)="savePreguntas($event)" enctype="multipart/form-data"
                        class="form-horizontal" *ngIf="isIngresarPreguntas">
                        <div class="card" style="background-color: #ffffff60;" *ngIf="idEncuesta > 0">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">PREGUNTA<span
                                            *ngIf="validator2('titulo')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="titulo">
                                        <div *ngIf="form2.get('titulo').errors && form2.get('titulo').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form2.controls['titulo'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form2.controls['titulo'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form2.controls['titulo'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">TIPO<span
                                            *ngIf="validator2('tipo')">*</span></label>
                                    <div class="col-md-9">
                                        <select class="form-control" formControlName="tipo" (change)="setTipo()">
                                            <option [ngValue]="null" disabled>... Seleccione</option>
                                            <option value="BINARIA">SI / NO</option>
                                            <option value="UNICA">Opción múltiple</option>
                                            <option value="MULTIPLE">Casilla de verificación</option>
                                            <option value="LINEAL">Escala lineal</option>
                                        </select>
                                        <div *ngIf="form2.get('tipo').errors && form2.get('tipo').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form2.controls['tipo'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">OPCIONES</label>
                                    <div class="col-md-9">
                                        <div class="row">
                                            <div class="col-md-12 input-group">
                                                <input type="hidden" formControlName="Opciones_posicion">
                                                <input type="hidden" formControlName="Opciones_cantidad">
                                                <input type="text" class="form-control" placeholder="Opción"
                                                    formControlName="Opciones_texto">
                                                <div class="input-group-append"
                                                    *ngIf="!form2.controls['Opciones_cantidad'].hasError('max') &&
                                                    (form2.get('Opciones_texto').value.length > 0 && form2.get('Opciones_texto').value.length <= 50)">
                                                    <button type="button" class="btn btn-primary py-0 px-2"
                                                        (click)="addItemArray1()"> <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-1" *ngIf="opciones.length > 0">
                                            <div class="col-md-12">
                                                <table class="table table-bordered table-striped table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center">OPCIÓN</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of opciones">
                                                            <td class="text-left">
                                                                <button type="button" (click)="removeItemArray1(item)"
                                                                    class="btn btn-sm btn-danger mr-2">
                                                                    <i class="fa fa-trash fa-lg "></i>
                                                                </button>
                                                                {{ item.texto }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <small>*Maximo 10 opciones por Pregunta</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style="background-color: #ffffff60;" *ngIf="isEstado === 1">
                                <div class="card-body">
                                    <div class="text-center">
                                        <button type="button" class="btn btn-secondary rounded-pill"
                                            *ngIf="form2.invalid && isAccion2 === -1">
                                            <i class="fas fa-angle-double-right"></i> Guardar Pregunta
                                        </button>
                                        <button type="submit" class="btn btn-outline-primary rounded-pill"
                                            *ngIf="form2.valid && isAccion2 === -1">
                                            <i class="fas fa-angle-double-right"></i> Guardar Pregunta
                                        </button>
                                        <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion1 === 0">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isEstado === 1">
                            <div class="form-group row">
                                <div class="col-md-12" *ngIf="(jsonPreguntas$ | async)?.length > 0">
                                    <h4 class="titulo mt-3" style="font-size: 26px !important;">Preguntas</h4>
                                </div>
                                <div class="col-md-12">
                                    <div class="row mt-1" *ngIf="(jsonPreguntas$ | async)?.length > 0">
                                        <div class="col-md-12">
                                            <table class="table table-bordered table-striped table-sm">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">...</th>
                                                        <th class="text-center">PREGUNTA</th>
                                                        <th class="text-center">TIPO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let item of jsonPreguntas$ | async | sortBy: 'asc':'id'; let index = index">
                                                        <td class="text-center" style="width: 10%;">
                                                            <button type="button" class="btn btn-sm btn-danger"
                                                                (click)="deletePreguntas(item.id)">
                                                                <i class="fa fa-trash fa-lg "></i>
                                                            </button>
                                                        </td>
                                                        <td class="text-left" style="width: 70%;">
                                                            {{item.titulo}}
                                                        </td>
                                                        <td class="text-left" style="width: 20%;">
                                                            {{item.tipo}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="text-center">
                                    <button type="button" class="btn btn-outline-primary rounded-pill"
                                        *ngIf="isAccion2 === -1 && (jsonPreguntas$ | async)?.length > 0"
                                        (click)="update()">
                                        <i class="fas fa-angle-double-right"></i> Enviar {{_encuesta}}
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion2 === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>