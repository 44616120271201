import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { RespuestasService } from './../shared/service/respuestas.service';

interface Opcion {
  posicion: number;
  texto: string;
}

@Component({
  selector: 'app-opinion-abierta',
  templateUrl: './opinion-abierta.component.html',
  styleUrls: ['./opinion-abierta.component.css']
})
export class OpinionAbiertaComponent implements OnInit {

  public preguntas: string[][] = [[]];
  public preguntasRespuestas: string[][] = [[]];
  public respuestas: string[][] = [[]];
  public totalPreguntasRespuestas: number;
  public contadorRespuestas: number;

  public token: string;
  public fk: number;
  public id: number;

  public jsonPreguntas$: Observable<any[]>;
  public jsonEstados$: Observable<any>;
  public total = 0;
  public isBuscando = -1;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = [];

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private respuestasService: RespuestasService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      // this.fk = parseInt(params.fk);
      this.token = params.token;
      this.fk = parseInt(this.auth.obtener('fk'));
      this.id = parseInt(params.id);//Pregunta.id
      this.getContenido();
    });
  }

  public getContenido(): void {
    this.queryService.query_57(this.token, this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonPreguntas$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });

        let i = 0;
        this.totalPreguntasRespuestas = 0;
        for (var value of response) {
          let opciones: Opcion[] = [];
          opciones = JSON.parse(`${value.opciones}`);
          this.preguntas[i] = [];
          this.preguntasRespuestas[i] = [];

          for (var opcion of opciones) {
            this.preguntas[i].push(opcion.texto);
          }

          if (value.respuestas) {
            this.preguntasRespuestas[i].push(value.respuestas);
            this.totalPreguntasRespuestas++;
          }

          i++;

          this.res.push(this.formBuilder.group({
            fecha: [null],
            fkencuesta: [value.idEncuesta],
            fkpregunta: [value.idPreguntas],
            fkexperto: [this.fk],
            titulo: [value.tituloPreguntas],
            tipo: [value.tipoPreguntas],
            respuestas: [null, Validators.required],
          }));

        }

        this.contadorRespuestas = this.totalPreguntasRespuestas;

        this.jsonPreguntas$.subscribe(response => {
          this.total = response.length;
        });

        this.getEstados();
        this.setAcciones();

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
      }
    );
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      items: new FormArray([])
    });
  }

  get frm() { return this.form.controls; }
  get res() { return this.frm.items as FormArray; }

  /** OBLIGATORIO */
  // private buildForm(): void {
  //   this.form = this.formBuilder.group({
  //     fecha: [null],
  //     fkencuesta: [null],
  //     fkpregunta: [null],
  //     fkexperto: [null],
  //     titulo: [null],
  //     tipo: [null],
  //     respuestas: [null, Validators.required],
  //   });
  // }

  /** OBLIGATORIO */
  public save(event: Event, pos: number, form: FormGroup): void {
    event.preventDefault();
    const object = form.value;
    this.isAccion[pos] = 0;

    if (!object.respuestas) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error'
        },
        buttonsStyling: false
      });

      swal.fire({
        // imageUrl: this._urlTest + '/logo_128.png',
        // imageHeight: 64,
        icon: 'error',
        title: 'Falta incluir tu respuesta',
        showConfirmButton: false,
        timer: 2000
      });
    } else {
      if (object.tipo === 'MULTIPLE') {
        let texto: string = '';
        for (var item of this.respuestas[pos]) {
          texto += '[' + item + '] ';
        }
        object.respuestas = texto;
      }

      this.respuestasService.postAuth(object).subscribe(
        (response: any) => {
          // response 200
          this.contadorRespuestas += 1;
          if (this.contadorRespuestas === this.total) {
            this.isAccion[pos] = 1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              title: 'Hemos enviado tu ' + this._encuesta + '...',
              showConfirmButton: false,
              timer: 4000
            });

            setTimeout(function () {
              window.location.href = '/mi-experto/' + object.fkexperto;
            }, 4000);
          } else {
            this.isAccion[pos] = 1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              title: 'Hemos enviado tu respuesta ...',
              showConfirmButton: false,
              timer: 2000
            });
          }

        },
        error => {
          this.getEstados();
          // response 500
          // this.isAccion[pos] = -1;
          // const swal = Swal.mixin({
          //   customClass: {
          //     confirmButton: 'btn btn-error'
          //   },
          //   buttonsStyling: false
          // });

          // swal.fire({
          //   // imageUrl: this._urlTest + '/logo_128.png',
          //   // imageHeight: 64,
          //   icon: 'error',
          //   title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
          //   showConfirmButton: false,
          //   timer: 5000
          // });
        }
      );
    }

    this.isAccion[pos] = -1;
  }

  public getFkexperto(): number {
    return this.fk;
  }

  public setAcciones() {
    for (let i = 0; i < this.total; i++) {
      this.isAccion[i] = -1;
    }
  }

  public getEstados(): void {
    this.queryService.query_58(this.fk, this.id).subscribe(
      (response: any[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          observer.next(response);
          observer.complete();
        });
      }, error => {
      }
    );
  }

  onCheckboxChecked(event: any, item: any, pos: number) {
    try {
      if (!this.respuestas[pos]) {
        this.respuestas[pos] = [];
      }
    } catch (error) {
      this.respuestas[pos] = [];
    }

    if (event.checked) {
      this.respuestas[pos].push(item);
    } else {
      let index = this.respuestas[pos].indexOf(item);
      if (index > -1) {
        this.respuestas[pos].splice(index, 1);
      }
    }
  }

  public parseInt(value: any): number {
    try {
      if (parseInt(value) >= 0) {
        return parseInt(value);
      } else if (!value) {
        return 0;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  // public convertToOpcion(array: string): Opcion[] {
  //   // return array.split(',').map(String);
  //   try {
  //     let json: Opcion[];
  //     json = JSON.parse(`${array}`);
  //     return json;
  //   } catch (error) {
  //     return [];
  //   }
  // }

}
