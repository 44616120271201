import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { RecomendarAmigo } from './../interface/recomendar-amigo';

@Injectable({
  providedIn: 'root'
})
export class RecomendarAmigoService {

  constructor(
    private http: HttpClient
  ) { }

  public post(object: RecomendarAmigo): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/RecomendarAmigo', object);
  }
}