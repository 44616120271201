<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Confirma tu referencia del {{_experto}}</h2> -->
    </div>
    <div class="container">
        <div class="row" *ngIf="isAccion === 1">
            <div class="card-body card-confirmacion text-center">
                <div class="form-group">
                    <h2 class="titulo-mensaje">GRACIAS POR CONFIRMAR TU REFERENCIA</h2>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isAccion === -1">
            <div class="card-body card-confirmacion text-center">
                <div class="form-group">
                    <h2 class="titulo-mensaje">ESTA REFERENCIA YA FUE VALIDADA</h2>
                </div>
            </div>
            <!-- <div class="col-md-6 offset-md-3 px-1 pb-3">
                <h1 class>ERROR</h1>
                <img src="assets/template/images/600x300.jpg" alt="" class="img-fluid">
            </div> -->
        </div>

        <p class="text-center mt-4"><span style="color: #665C61 !important;">¿Todavía sin cuenta?
        </span> <a [routerLink]="['/registro']">Registrarse</a>
    </p>
    <br><br>
    </div>
</section>