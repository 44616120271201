import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { QueryService } from './../shared/service/-query.service';
import { environment } from './../../environments/environment';
import { Especialidad } from './../shared/interface/especialidad';
import { EspecialidadService } from './../shared/service/especialidad.service';
import { FacebookService, UIResponse, UIParams } from 'ngx-facebook';
import { ClipboardService } from 'ngx-clipboard';
import Swal from 'sweetalert2';
import { AuthService } from './../shared/service/-auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Area } from './../shared/interface/area';
import { RamaService } from './../shared/service/rama.service';
import { Rama } from './../shared/interface/rama';
import { Tarifa } from './../shared/interface/tarifa';
import { EjemploPreguntaService } from './../shared/service/ejemplo-pregunta.service';
import { EjemploPregunta } from './../shared/interface/ejemplo-pregunta';
import { EjemploEncuestaService } from './../shared/service/ejemplo-encuesta.service';
import { EjemploEncuesta } from './../shared/interface/ejemplo-encuesta';
import { HomeService } from './../shared/service/home.service';
import { Home } from './../shared/interface/home';
import { Experto } from './../shared/interface/experto';
import { map, startWith } from 'rxjs/operators';
import { NgwWowService } from 'ngx-wow';
import { AreaService } from './../shared/service/area.service';
import { AnosExperienciaService } from './../shared/service/anos-experiencia.service';
import { AnosExperiencia } from './../shared/interface/anos-experiencia';
import * as jQuery from 'jquery';
import { LocalStorageService } from '../shared/service/-local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consulta-experto',
  templateUrl: './consulta-experto.component.html',
  styleUrls: ['./consulta-experto.component.css']
})
export class ConsultaExpertoComponent implements OnInit {

  public fk: number;
  public jsonExpertos$: Observable<any[]>;
  public minTarifaExpertos$: Observable<any>;
  public maxTarifaExpertos$: Observable<any>;
  public jsonEspecialidads: Especialidad[];
  public total = 0;
  public otro = '';
  public filtro = '';
  public isBuscando = -1;
  public isEspecialidad = -1;
  public isEspecialidad2 = false;
  public isAccion = 1;
  public especialidad = 0;
  public isfinal = false;

  private handleError: any;
  private cantidad = 5;
  private paginaFinal = 20;
  private paginaActual: number;

  public tipoAux: any;
  private valueAux: any;
  private filtroAux: any;

  // FILTROS
  public isNormal = false;
  public isTarifaMenorMayor = false;
  public isTarifaMayorMenor = false;
  public isExperienciaMenorMayor = false;
  public isExperienciaMayorMenor = false;
  public isCalificacionMenorMayor = false;
  public isCalificacionMayorMenor = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;
   /** OBLIGATORIO */
  public form1: FormGroup;
  public form2: FormGroup;

  public areas: Area[] = [];
  public ramas: Rama[] = [];
  public especialidads: Especialidad[] = [];
  public areas_Verify: Area[][] = [[]];
  public ramas_Verify: Rama[][] = [[]];
  public especialidads_Verify: Especialidad[][] = [[]];
  public tarifas: Tarifa[] = [];
  public anosExperiencias: AnosExperiencia[] = [];

  private homeObservable = new BehaviorSubject<Home[]>(null);
  public home$ = this.homeObservable.asObservable();
  public home: Home[] = [];
  private expertosObservable = new BehaviorSubject<Experto[]>(null);
  public expertos2: Experto[] = [];
  public expertos4: Experto[] = [];
  public expertos$ = this.expertosObservable.asObservable();

  public filteredAreas1$: Observable<Area[]>;
  public filteredAreas2$: Observable<Area[]>;
  public filteredRamas1$: Observable<Rama[]>;
  public filteredRamas2$: Observable<Rama[]>;
  public filteredEspecialidads1$: Observable<Especialidad[]>;
  public filteredEspecialidads2$: Observable<Especialidad[]>;
  public filteredTarifas1$: Observable<Tarifa[]>;
  public filteredTarifas2$: Observable<Tarifa[]>;
  public filteredAnosExperiencias1$: Observable<AnosExperiencia[]>;
  public filteredAnosExperiencias2$: Observable<AnosExperiencia[]>;

  public form1Visible = true;
  public form2Visible = true;
  public isRama = false;

  private ejemploPreguntasObservable = new BehaviorSubject<EjemploPregunta[]>(null);
  public ejemploPreguntas$ = this.ejemploPreguntasObservable.asObservable();
  public ejemploPreguntas: EjemploPregunta[] = [];
  private ejemploEncuestasObservable = new BehaviorSubject<EjemploEncuesta[]>(null);
  public ejemploEncuestas$ = this.ejemploEncuestasObservable.asObservable();

  /**NUEVAS 05-2024 */
  public tipoFiltro= "";
  public storedArea:string;
  public storedRa:string;
  public storedEs:string;

  

  constructor(
    private queryService: QueryService,
    private especialidadService: EspecialidadService,
    private route: ActivatedRoute,
    private fb: FacebookService,
    private clipboardService: ClipboardService,
    private auth: AuthService,
    private formBuilder1: FormBuilder,
    private formBuilder2: FormBuilder,
    private wowService: NgwWowService,
    private areaService: AreaService,
    private ramaService: RamaService,
    private anosExperienciaService: AnosExperienciaService,
    private ejemploPreguntaService: EjemploPreguntaService,
    private ejemploEncuestaService: EjemploEncuestaService,
    private homeService: HomeService,
    private localStorageService: LocalStorageService,
    private router: Router
    
    
  ) {
    this.paginaActual = 1;
    this.getEspecialidads();
    this._buildForm1();
    this._buildForm2();
    this.wowService.init();

    
    fb.init({
      appId: '206187567646346',
      version: 'v9.0'
    });
  }
  /** OBLIGATORIO */
  private _buildForm1(): void {
    this.form1 = this.formBuilder1.group({
      area: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      rama: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      especialidad: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
    });
  }

  private _buildForm2(): void {
    this.form2 = this.formBuilder2.group({
      area: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      rama: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      especialidad: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      tarifamin: [null, Validators.required],
      tarifamax: [null, Validators.required],
      anosmin: [null, Validators.required],
      anosmax: [null, Validators.required],
    });
  }

  ngOnInit(): void {

     this.storedArea = this.localStorageService.get('selectedArea');
    if (this.storedArea && this.form1.get('area')) {      
      this.form1.get('area').setValue(this.storedArea.split(':')[1]);
      this.tipoFiltro='area'
      this.isRama = false;
      const id = this.storedArea.split(':')[0];      
      this.queryService.query_1(id).subscribe(
        (ramas: Rama[]) => {
          this.ramas = ramas;
          this.isRama = true;
        },
        error => {
          this.ramas = null;
          this.isRama = false;
        }
      );
      this.queryService.query_77(id).subscribe(
        (especialidads: Especialidad[]) => {
          this.especialidads = especialidads;
          this.isEspecialidad2 = true;
        },
        error => {
          this.especialidads = null;
          this.isEspecialidad2 = false;
        },
        () => {
        });
    }
    this.storedRa = this.localStorageService.get('selectedRa');
    if (this.storedRa && this.form1.get('rama')) {      
      this.form1.get('rama').setValue(this.storedRa.split(':')[1]);
      this.tipoFiltro='rama'
      this.isEspecialidad2 = false;
      const id = this.storedRa.split(':')[0];
      this.queryService.query_2(id).subscribe(
        (especialidads: Especialidad[]) => {
          this.especialidads = especialidads;
          this.isEspecialidad2 = true;
        },
        error => {
          this.especialidads = null;
          this.isEspecialidad2 = false;
        },
        () => {
        }
      );
    }
    this.storedEs = this.localStorageService.get('selectedEs');
    if (this.storedEs && this.form1.get('especialidad')) {
      this.form1.get('especialidad').setValue(this.storedEs.split(':')[1]);
      this.tipoFiltro='especialidad'
    }

    this.route.params.subscribe((params: Params) => {
      this.tipoAux = params.tipo;
      this.valueAux = params.value;
      this.filtroAux = params.filtro;
      this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.areas = areas;
      },
      error => {
        this.areas = null;
      },
      () => {
      }
    );
    this.filteredAreas1$ = this.form1.get('area').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterArea(value))
      );
    this.filteredAreas2$ = this.form2.get('area').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterArea(value))
      );

    this.ramaService.getAll().subscribe(
      (ramas: Rama[]) => {
        this.ramas = ramas;
      },
      error => {
        this.ramas = null;
      },
      () => {
      }
    );
    this.filteredRamas1$ = this.form1.get('rama').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterRama(value))
      );
    this.filteredRamas2$ = this.form2.get('rama').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterRama(value))
      );

    this.especialidadService.getAll().subscribe(
      (especialidads: Especialidad[]) => {
        this.especialidads = especialidads;
        // console.log(especialidads, 'especialidad');
      },
      error => {
        this.especialidads = null;
      },
      () => {
      }
    );
    
    this.filteredEspecialidads1$ = this.form1.get('especialidad').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterEspecialidad(value))
      );
    this.filteredEspecialidads2$ = this.form2.get('especialidad').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterEspecialidad(value))
      );


    this.queryService.query_53().subscribe(
      (tarifas: Tarifa[]) => {
        this.tarifas = tarifas;
      },
      error => {
        this.tarifas = null;
      },
      () => {
      }
    );
    this.filteredTarifas1$ = this.form2.get('tarifamin').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterTarifa(value))
      );
    this.filteredTarifas2$ = this.form2.get('tarifamax').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterTarifa(value))
      );


    this.anosExperienciaService.getAll().subscribe(
      (anosExperiencias: AnosExperiencia[]) => {
        this.anosExperiencias = anosExperiencias;
      },
      error => {
        this.anosExperiencias = null;
      },
      () => {
      }
    );
    this.filteredAnosExperiencias1$ = this.form2.get('anosmin').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterAnosExperiencia(value))
      );
    this.filteredAnosExperiencias2$ = this.form2.get('anosmax').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterAnosExperiencia(value))
      );


    this.queryService.query_65().subscribe(
      (expertos: Experto[]) => {
        // this.expertos = expertos;
        let k = 0;
        let expertoPara4 = [];
        const expertosEn4 = [];
        for (let i = 0; i <= expertos.length - 1; i++) {
          const j = i + Math.floor(Math.random() * (expertos.length - i));

          const temp = expertos[j];
          expertos[j] = expertos[i];
          expertos[i] = temp;

          if (k < 3) {
            expertoPara4.push(temp);
            k++;
          } else if (k === 3) {
            expertoPara4.push(temp);
            expertosEn4.push(expertoPara4);
            expertoPara4 = [];
            k = 0;
          }

        }

        this.expertos4 = expertosEn4;

        k = 0;
        let expertoPara2 = [];
        const expertosEn2 = [];
        for (let i = 0; i <= expertos.length - 1; i++) {
          const j = i + Math.floor(Math.random() * (expertos.length - i));

          const temp = expertos[j];
          expertos[j] = expertos[i];
          expertos[i] = temp;

          if (k < 1) {
            expertoPara2.push(temp);
            k++;
          } else if (k === 1) {
            expertoPara2.push(temp);
            expertosEn2.push(expertoPara2);
            expertoPara2 = [];
            k = 0;
          }

        }

        this.expertos2 = expertosEn2;

        this.expertosObservable.next(expertos);
      },
      error => {
        this.expertosObservable = null;
      },
      () => {
      }
    );


    this.ejemploPreguntaService.getAll().subscribe(
      (ejemploPreguntas: EjemploPregunta[]) => {
        this.ejemploPreguntas = ejemploPreguntas;
        this.ejemploPreguntasObservable.next(ejemploPreguntas);

        this.ejemploEncuestaService.getAll().subscribe(
          (ejemploEncuesta: EjemploEncuesta[]) => {
            this.ejemploEncuestasObservable.next(ejemploEncuesta);

            this.queryService.query_100().subscribe(
              (home: Home[]) => {
                this.home = home;
                this.homeObservable.next(home);

                jQuery(window).on('scroll', function () {
                  if (jQuery('.stricked-menu').length) {
                    var headerScrollPos = 100;
                    var stricky = jQuery('.stricked-menu');
                    if (jQuery(window).scrollTop() > headerScrollPos) {
                      stricky.addClass('stricky-fixed');
                    } else if (jQuery(this).scrollTop() <= headerScrollPos) {
                      stricky.removeClass('stricky-fixed');
                    }
                  }

                  var windscroll = jQuery(window).scrollTop();
                  if (windscroll >= 100) {
                    var menuAnchor = jQuery('.one-page-scroll-menu .scrollToLink').children('a');
                  } else {
                    // jQuery('.one-page-scroll-menu li.current').removeClass('current');
                    // jQuery('.one-page-scroll-menu li:first').addClass('current');
                  }
                });
              },
              error => {
                this.homeObservable = null;
              },
              () => {
              }
            );
          },
          error => {
            this.ejemploEncuestasObservable = null;
          },
          () => {
          }
        );
      },
      error => {
        this.ejemploPreguntasObservable = null;
      },
      () => {
      }
    );


      if (params.filtro === '1') {
        this.filtro = 'normal';
        this.isNormal = true;
      } else if (params.filtro === '2') {
        this.filtro = 'tarifaMenorMayor';
        this.isTarifaMenorMayor = true;
      } else if (params.filtro === '3') {
        this.filtro = 'tarifaMayorMenor';
        this.isTarifaMayorMenor = true;
      } else if (params.filtro === '4') {
        this.filtro = 'experienciaMenorMayor';
        this.isExperienciaMenorMayor = true;
      } else if (params.filtro === '5') {
        this.filtro = 'experienciaMayorMenor';
        this.isExperienciaMayorMenor = true;
      } else if (params.filtro === '6') {
        this.filtro = 'calificacionMenorMayor';
        this.isCalificacionMenorMayor = true;
      } else if (params.filtro === '7') {
        this.filtro = 'calificacionMayorMenor';
        this.isCalificacionMayorMenor = true;
      } else {
        window.location.href = '/inicio';
      }

      if (params.tipo === 'area') {
        this.getExpertos(params.tipo, params.value, 0, this.cantidad, this.filtro);
        this.getMinTarifaExpertos(params.tipo, params.value);
        this.getMaxTarifaExpertos(params.tipo, params.value);
        this.isEspecialidad = 0;
      } else if (params.tipo === 'rama') {
        this.getExpertos(params.tipo, params.value, 0, this.cantidad, this.filtro);
        this.getMinTarifaExpertos(params.tipo, params.value);
        this.getMaxTarifaExpertos(params.tipo, params.value);
        this.isEspecialidad = 0;      
      } else if (params.tipo === 'especialidad') {
        this.getExpertos(params.tipo, params.value, 0, this.cantidad, this.filtro);
        this.getMinTarifaExpertos(params.tipo, params.value);
        this.getMaxTarifaExpertos(params.tipo, params.value);
        this.isEspecialidad = 1;
        this.especialidad = parseInt(params.value);
      } else if (params.tipo === 'all') {
        this.getExpertos(params.tipo, params.value, 0, this.cantidad, this.filtro);
        this.getMinTarifaExpertos(params.tipo, params.value);
        this.getMaxTarifaExpertos(params.tipo, params.value);
        this.isEspecialidad = 0;
      } else if (params.tipo === 'busquedad') {
        this.getOtrosExpertos(params.value, 0, this.cantidad, this.filtro);
        this.getMinTarifaOtrosExpertos(params.value);
        this.getMaxTarifaOtrosExpertos(params.value);
        this.isEspecialidad = 0;
      } else {
        window.location.href = '/inicio';
      }
    });

    if (this.auth.validar()) {
      this.fk = parseInt(this.auth.obtener('fk'));
    }
  }

  public getRouterLink(tipo,id) {   
    
    if (tipo === 'area') {
      this.router.navigate( ['/pregunta', id, this.storedArea.split(':')[0], 0, 0, 'todos','area']);
    } else if (tipo === 'rama') {
      this.router.navigate( ['/pregunta',id, this.storedArea.split(':')[0], this.storedRa.split(':')[0], 0, 'todos','rama']);
    } else if (tipo === 'especialidad'){
    this.router.navigate( ['/pregunta', id, this.storedArea.split(':')[0], this.storedRa.split(':')[0], this.storedEs.split(':')[0], 'todos','especialidad']);
    }else{
      this.router.navigate( ['/pregunta', id, 0, 0, 0, 'todos','all']);
    }
  }

    //CAMBIOS LORE
  // public cancelarForm1(): void {
  //   this.form1Visible = false;
  //   this.form2Visible = false;
  //   this.isRama = false;
  //   this.isEspecialidad2 = false;
  //   // this.ramas = [];
  //   // this.especialidads = [];
  //   // this.filteredRamas1$ = new Observable<Rama[]>();
  //   // this.filteredRamas2$ = new Observable<Rama[]>();
  //   // this.filteredEspecialidads1$ = new Observable<Especialidad[]>();
  //   // this.filteredEspecialidads2$ = new Observable<Especialidad[]>();
  //   this.form1.get('area').setValue(null);
  //   this.form1.get('rama').setValue(null);
  //   this.form1.get('especialidad').setValue(null);
  // }

  public getAreaFromRama(id){    
    this.queryService.query_76(id).subscribe(
      (value) => {        
        this.localStorageService.set('selectedArea', value[0].Area);        
        window.location.href = '/consulta-experto/rama/' + this.form1.get('rama').value.split(':')[1] + '/1';     
      },
      error => {
        this.localStorageService.remove('selectedArea')
      }
    );
  }

  public getAreaRamaFromEspecialidad(id){    
    this.queryService.query_75(id).subscribe(
      (value) => {        
        this.localStorageService.set('selectedArea', value[0].Area);        
        this.localStorageService.set('selectedRa', value[0].Rama);        
        window.location.href = '/consulta-experto/especialidad/' + this.form1.get('especialidad').value.split(':')[0] + '/1';  
      },
      error => {
        this.localStorageService.remove('selectedArea')
        this.localStorageService.remove('selectedRa')
      }
    );
  }

  /** OBLIGATORIO */
  public accion1(event: Event,tipo: string): void {
    // event.preventDefault();    
    if(tipo==='area'){      
      this.localStorageService.remove('selectedRa');
      this.localStorageService.remove('selectedEs');
      this.localStorageService.set('selectedArea',this.form1.get('area').value);    
      window.location.href = '/consulta-experto/area/' + this.form1.get('area').value.split(':')[1] + '/1';
    }
    if(tipo==='rama'){
      this.localStorageService.remove('selectedEs');
      this.localStorageService.set('selectedRa',this.form1.get('rama').value);
      this.getAreaFromRama(this.form1.get('rama').value.split(':')[0]);        
    }
    if(tipo==='especialidad'){
      this.localStorageService.set('selectedEs',this.form1.get('especialidad').value);
      this.getAreaRamaFromEspecialidad(this.form1.get('especialidad').value.split(':')[0]);
    }
  }
  
  /** OBLIGATORIO */
  public accion2(event: Event): void {
    event.preventDefault();

    if (this.form2.valid) {
      const object = this.form2.value;
      if (object.rama === 'todos:todos') {
        window.location.href = '/encuesta/' + object.area.split(':')[0] + '/todos/' +
          object.tarifamin + '/' + object.tarifamax + '/' + object.anosmin + '/' + object.anosmax;
      } else {
        window.location.href = '/encuesta/' + object.area.split(':')[0] + '/' + object.rama.split(':')[0] + '/' + object.especialidad.split(':')[0] + '/' +
        object.tarifamin + '/' + object.tarifamax + '/' + object.anosmin + '/' + object.anosmax;
      }
    }
  }

  public activarRama(area: any, form: number, e) {
    this.accion1(e,'area');
  }

  public activarEspecialidad(rama: any, form: number,e) {
    this.accion1(e,'rama');
  }
  public activarFinal(e) {
    this.isfinal = true;
    this.accion1(e,'especialidad');
  }
  

  public getSplit(value: string): string {
    try {
      let id = value.split(':')[0];
      if (parseInt(id) > 0) {
        return value.split(':')[1];
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  private _filterArea(value: string): Area[] {
    try {
      const filterValue = value;
      return this.areas.filter(option => option.nombre.toLowerCase().includes(filterValue.toLowerCase()));
    } catch (error) { }
  }

  private _filterRama(value: string): Rama[] {
    try {
      const filterValue = value;
      return this.ramas.filter(option => option.nombre.toLowerCase().includes(filterValue.toLowerCase()));
    } catch (error) { }
  }

  private _filterEspecialidad(value: string): Especialidad[] {
    try {
      const filterValue = value;
      return this.especialidads.filter(option => option.nombre.toLowerCase().includes(filterValue.toLowerCase()));
    } catch (error) { }
  }

  private _filterTarifa(value: string): Tarifa[] {
    try {
      const filterValue = value.toString();
      return this.tarifas.filter(option => option.valor.toString().toLowerCase().includes(filterValue.toLowerCase()));
    } catch (error) { }
  }

  private _filterAnosExperiencia(value: string): AnosExperiencia[] {
    try {
      const filterValue = value;
      return this.anosExperiencias.filter(option => option.anos.toLowerCase().includes(filterValue.toLowerCase()));
    } catch (error) { }
  }

  public filterHomeHorizontal(): Home[] {
    try {
      return this.home.filter(option => option.tipo.toLowerCase().includes('videohorizontal'));
    } catch (error) { }
  }

  public filterHomeVertical(): Home[] {
    try {
      return this.home.filter(option => option.tipo.toLowerCase().includes('videovertical'));
    } catch (error) { }
  }

  public cancelarForm2(): void {
    this.form1Visible = false;
    this.form2Visible = false;
    this.isRama = false;
    this.isEspecialidad2 = false;
    // this.ramas = [];
    // this.especialidads = [];
    // this.filteredRamas1$ = new Observable<Rama[]>();
    // this.filteredRamas2$ = new Observable<Rama[]>();
    // this.filteredEspecialidads1$ = new Observable<Especialidad[]>();
    // this.filteredEspecialidads2$ = new Observable<Especialidad[]>();
    this.form2.get('area').setValue(null);
    this.form2.get('rama').setValue(null);
    this.form2.get('especialidad').setValue(null);
    this.form2.get('tarifamin').setValue(null);
    this.form2.get('tarifamax').setValue(null);
    this.form2.get('anosmin').setValue(null);
    this.form2.get('anosmax').setValue(null);
  }

  public adicionar(cantidad: any): void {
    this.isAccion = 0;
    this.route.params.subscribe((params: Params) => {
      if (params.tipo === 'area') {
        this.getExpertos(params.tipo, params.value, 0, cantidad, this.filtro);
      } else if (params.tipo === 'especialidad') {
        this.getExpertos(params.tipo, params.value, 0, cantidad, this.filtro);
      }else if (params.tipo === 'rama') {
        this.getExpertos(params.tipo, params.value, 0, cantidad, this.filtro);
      } else if (params.tipo === 'all') {
        this.getExpertos(params.tipo, params.value, 0, cantidad, this.filtro);
      }else if (params.tipo === 'busquedad') {
        this.getOtrosExpertos(params.value, 0, cantidad, this.filtro);
      } else {
        window.location.href = '/inicio';
      }
    });
  }

  public onScroll(): void {
    if (this.paginaActual < this.paginaFinal) {
      this.disableScrolling()
      this.adicionar(this.cantidad * 2);
      this.cantidad = this.cantidad * 2;
      this.paginaActual++;
    } else {
      // console.log('No more lines. Finish page!');
    }
  }

  public disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y); };
  }

  public enableScrolling() {
    window.onscroll = function () { };
  }

  // *** OJO IMPORTANTE CAMBIAR ***
  share(url: string): void {
    const options: UIParams = {
      method: 'share',
      href: this._urlWeb + '/' + url
      // href: 'https://expertips.co'
    };

    this.fb.ui(options)
      .then((res: UIResponse) => {
      })
      .catch(this.handleError);
  }

  public copy(url: string): void {
    this.clipboardService.copyFromContent(this._urlWeb + '/' + url);

    const swal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success'
      },
      buttonsStyling: false
    });

    swal.fire({
      imageUrl: this._urlTest + '/logo_128.png',
      imageHeight: 64,
      icon: 'success',
      title: 'Copiado',
      showConfirmButton: false,
      timer: 5000
    });
  }

  private getExpertos(tipo: any, fkespecialidad: any, inicio: any, fin: any, filtro: any): void {
    this.queryService.query_911(tipo, fkespecialidad, inicio, fin).subscribe(
      (response: any[]) => {
        this.total = response[0].cantidad;
        if (this.isAccion === 0) {
          this.isAccion = 1;
        }
        this.enableScrolling();
      }, error => {
        this.total = 0;
        if (this.isAccion === 0) {
          this.isAccion = 1;
          this.enableScrolling();
        }
      }
    );

    this.queryService.query_11(tipo, fkespecialidad, inicio, fin, filtro).subscribe(
      (expertos: any[]) => {
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
      }
    );

  }

  private getMinTarifaExpertos(tipo: any, fkespecialidad: any): void {
    this.queryService.query_14(tipo, fkespecialidad).subscribe(
      (tarifas: any[]) => {
        this.minTarifaExpertos$ = new Observable((observer) => {
          observer.next(tarifas[0].min);
          observer.complete();
        });
      }
    );
  }

  private getMaxTarifaExpertos(tipo: any, fkespecialidad: any): void {
    this.queryService.query_15(tipo, fkespecialidad).subscribe(
      (tarifas: any[]) => {
        this.maxTarifaExpertos$ = new Observable((observer) => {
          observer.next(tarifas[0].max);
          observer.complete();
        });
      }
    );
  }

  private getOtrosExpertos(fkespecialidad: any, inicio: any, fin: any, filtro: any): void {
    this.queryService.query_916(fkespecialidad, inicio, fin).subscribe(
      (response: any[]) => {
        // this.total = response[0].cantidad;
        this.total = response.length;
        if (this.isAccion === 0) {
          this.isAccion = 1;
        }
        this.enableScrolling();
      }, error => {
        this.total = 0;
        if (this.isAccion === 0) {
          this.isAccion = 1;
        }
      }
    );

    this.queryService.query_16(fkespecialidad, inicio, fin, filtro).subscribe(
      (expertos: any[]) => {
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });

        this.isBuscando = 1;
      }, error => {
        this.isBuscando = 0;
        this.enableScrolling();
      }
    );

  }

  private getMinTarifaOtrosExpertos(fkespecialidad: any): void {
    this.queryService.query_17(fkespecialidad).subscribe(
      (tarifas: any[]) => {
        this.minTarifaExpertos$ = new Observable((observer) => {
          observer.next(tarifas[0].min);
          observer.complete();
        });
      }
    );
  }

  private getMaxTarifaOtrosExpertos(fkespecialidad: any): void {
    this.queryService.query_18(fkespecialidad).subscribe(
      (tarifas: any[]) => {
        this.maxTarifaExpertos$ = new Observable((observer) => {
          observer.next(tarifas[0].max);
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads(): void {
    this.especialidadService.getAll().subscribe(
      (especialidads: Especialidad[]) => {
        this.jsonEspecialidads = especialidads;
      }
    );
  }

  public getNombreEspecialidad(id: number): Especialidad[] {
    let aux = this.jsonEspecialidads.filter(especialidad => especialidad.id === id);

    try {
      if (aux[0].nombre) {
        return aux;
      }
    } catch (error) {
      return [{
        id: 0, fecha: new Date(), fkarea: 0, fkrama: 0, nombre: ''
      }];
    }
  }

  public otraBusquedad(): void {
    window.location.href = '/consulta-experto/busquedad/' + this.otro + '/' + this.filtroAux;
    //window.location.href = '/consulta-experto/busquedad/' + this.otro + '/1';
  }

  public setFiltro(value: any): void {
    const filtro = value.target.value;

    if (filtro === 'normal') {
      window.location.href = '/consulta-experto/' + this.tipoAux + '/' + this.valueAux + '/1';
    } else if (filtro === 'tarifaMenorMayor') {
      window.location.href = '/consulta-experto/' + this.tipoAux + '/' + this.valueAux + '/2';
    } else if (filtro === 'tarifaMayorMenor') {
      window.location.href = '/consulta-experto/' + this.tipoAux + '/' + this.valueAux + '/3';
    } else if (filtro === 'experienciaMenorMayor') {
      window.location.href = '/consulta-experto/' + this.tipoAux + '/' + this.valueAux + '/4';
    } else if (filtro === 'experienciaMayorMenor') {
      window.location.href = '/consulta-experto/' + this.tipoAux + '/' + this.valueAux + '/5';
    } else if (filtro === 'calificacionMenorMayor') {
      window.location.href = '/consulta-experto/' + this.tipoAux + '/' + this.valueAux + '/6';
    } else if (filtro === 'calificacionMayorMenor') {
      window.location.href = '/consulta-experto/' + this.tipoAux + '/' + this.valueAux + '/7';
    }
  }

}
