import { Component, OnInit } from '@angular/core';
import { AuthService } from './../shared/service/-auth.service';
import { environment } from './../../environments/environment';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { QueryService } from './../shared/service/-query.service';
import { UsuarioService } from './../shared/service/usuario.service';
import { Usuario } from './../shared/interface/usuario';

interface Country {
  id: string;
  name: string;
}

interface State {
  id: string;
  id_country: string;
  name: string;
}

interface City {
  id: string;
  id_state: string;
  name: string;
}

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {

  // public tipo: string;
  public fk: number;
  public imagenAux: string;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** ADICIONALES */
  public fileurlfotografia: File;
  public isFileurlfotografia = false;
  public loadingFileurlfotografia = false;
  private urlfotografiaTemp: string;

  public jsonPaises$: Observable<Country[]>;
  public jsonEstados$: Observable<State[]>;
  public jsonCiudades$: Observable<City[]>;
  public isPais = true;
  public isEstado = false;
  public isCiudad = false;

  // public jsonEspecialidads: any[];
  public jsonEspecialidads1$: Observable<any[]>;
  public isEspecialidad1 = false;
  public jsonEspecialidads2$: Observable<any[]>;
  public isEspecialidad2 = false;
  public jsonEspecialidads3$: Observable<any[]>;
  public isEspecialidad3 = false;
  public jsonEspecialidads4$: Observable<any[]>;
  public isEspecialidad4 = false;
  public jsonEspecialidads5$: Observable<any[]>;
  public isEspecialidad5 = false;

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public hoy = new Date();

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;
  public isBuscando = -1;
  public total = 0;

  constructor(
    private usuarioService: UsuarioService,
    private queryService: QueryService,
    // private especialidadService: EspecialidadService,
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
    this.getPaises();
    this.getEspecialidads();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      this.fk = parseInt(this.auth.obtener('fk'));
      this.getEspecialidads();
      
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      // fecha: [''],
      nickname: [null],
      // nombre1: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      // nombre2: [null, [Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      // apellido1: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      // apellido2: [null, [Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      // identificacion: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9]*$')]],
      // tipoidentificacion: [null, Validators.required],
      // genero: [null, Validators.required],
      // fechanacimiento: [null, Validators.required],
      urlfotografia: [null],
      pais: [null, Validators.required],
      departamento: [null, Validators.required],
      ciudad: [null, Validators.required],
      direccion: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      codigopais: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(3), Validators.pattern('^[0-9 ]*$')]],
      celular: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(15), Validators.pattern('^[0-9 ]*$')]],
      correoelectronico: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      // tipo: [null],
      fkespecialidad1: [null, Validators.required],
      fkespecialidad2: [null],
      fkespecialidad3: [null],
      fkespecialidad4: [null],
      fkespecialidad5: [null],
    //   otraespecialidad: [null],
    //   isconfirma: [null],
    //   tokenconfirma: [null],
    //   estadoconfirma: [null],
    //   isrecupera: [null],
    //   tokenrecupera: [null],
    //   estadorecupera: [null],
    //   estado: [null],
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  private getPaises(): void {
    this.usuarioService.getPais().subscribe(
      (paises: Country[]) => {
        this.jsonPaises$ = new Observable((observer) => {
          observer.next(paises);
          observer.complete();
        });
      }
    );
  }

  public getEstados(pais: any): void {
    this.isEstado = true;
    const id_country = pais.target.value.split(':')[2];
    this.usuarioService.getEstado().subscribe(
      (estados: State[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          // tslint:disable-next-line: radix
          observer.next(estados.filter(option => parseInt('' + option.id_country) === parseInt('' + id_country)));
          observer.complete();
        });
      }
    );
  }

  public getCiudades(estado: any): void {
    this.isCiudad = true;
    const id_state = estado.target.value.split(':')[2];
    this.usuarioService.getCiudad().subscribe(
      (ciudades: City[]) => {
        this.jsonCiudades$ = new Observable((observer) => {
          // tslint:disable-next-line: radix
          observer.next(ciudades.filter(option => parseInt('' + option.id_state) === parseInt('' + id_state)));
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads(): void {
    // this.queryService.query_29().subscribe(
    this.queryService.query_291().subscribe(
      (especialidads: any[]) => {
        this.jsonEspecialidads1$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
        this.jsonEspecialidads2$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
        this.jsonEspecialidads3$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
        this.jsonEspecialidads4$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
        this.jsonEspecialidads5$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });

        // this.jsonEspecialidads = especialidads;
        
        this.getContenido();
      }
    );
  }

  public setEspecialidad1(especialidad: any): void {
    this.isEspecialidad1 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public setEspecialidad2(especialidad: any): void {
    this.isEspecialidad2 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public setEspecialidad3(especialidad: any): void {
    this.isEspecialidad3 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public setEspecialidad4(especialidad: any): void {
    this.isEspecialidad4 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public setEspecialidad5(especialidad: any): void {
    this.isEspecialidad5 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  // public disabledEspecialidad(id: any): void {
  //   if (!this.isEspecialidad1) {
  //     this.jsonEspecialidads1$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   if (!this.isEspecialidad2) {
  //     this.jsonEspecialidads2$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   if (!this.isEspecialidad3) {
  //     this.jsonEspecialidads3$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   if (!this.isEspecialidad4) {
  //     this.jsonEspecialidads4$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   if (!this.isEspecialidad5) {
  //     this.jsonEspecialidads5$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   this.jsonEspecialidads = this.jsonEspecialidads.filter(option => option.id !== parseInt(id));
  // }

  public getContenido(): void {
    this.usuarioService.getOne(this.fk).subscribe(
      (response: Usuario) => {
        try {
          this.form.patchValue(response);
          /** ADICIONALES */
          this.isBuscando = 1;
          this.total = 1;
          this.imagenAux = this.form.get('urlfotografia').value;
          // this.tipo = this.form.get('tipo').value;

          try {
            if (this.form.get('fkespecialidad1').value) {
              this.isEspecialidad1 = true;
              // this.disabledEspecialidad(parseInt(this.form.get('fkespecialidad1').value));
            }
            if (this.form.get('fkespecialidad2').value) {
              this.isEspecialidad2 = true;
              // this.disabledEspecialidad(parseInt(this.form.get('fkespecialidad2').value));
            }
            if (this.form.get('fkespecialidad3').value) {
              this.isEspecialidad3 = true;
              // this.disabledEspecialidad(parseInt(this.form.get('fkespecialidad3').value));
            }
            if (this.form.get('fkespecialidad4').value) {
              this.isEspecialidad4 = true;
              // this.disabledEspecialidad(parseInt(this.form.get('fkespecialidad4').value));
            }
            if (this.form.get('fkespecialidad5').value) {
              this.isEspecialidad5 = true;
              // this.disabledEspecialidad(parseInt(this.form.get('fkespecialidad5').value));
            }
          } catch (error) {
          }
        } catch (error) {
          // console.log(error);
          this.isBuscando = 0;
        }
      }, error => {
        // console.log(error);
        this.isBuscando = 0;
      }
    );
  }

  /** OBLIGATORIO */
  public update(event: Event): void {
    event.preventDefault();

    const object: Usuario = this.form.value;
    object.urlfotografia = this.urlfotografiaTemp;
    object.pais = this.form.get('pais').value.split(':')[0].trim();
    object.departamento = this.form.get('departamento').value.split(':')[0];
    this.isAccion = 0;

    this.usuarioService.put(object).subscribe(
      (response: Usuario) => {
        // response 200
        this.isAccion = 1;
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false
        });

        swal.fire({
          imageUrl: this._urlTest + '/logo_128.png',
          imageHeight: 64,
          icon: 'success',
          title: 'Hemos actualizado tu información ...',
          showConfirmButton: false,
          timer: 4000
        });
      },
      error => {
        // response 500
        this.isAccion = -1;
        const swal = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-error'
          },
          buttonsStyling: false
        });

        swal.fire({
          // imageUrl: this._urlTest + '/logo_128.png',
          // imageHeight: 64,
          icon: 'error',
          title: 'Encontramos que tienes una cuenta asociada, intenta iniciar tu sesión',
          showConfirmButton: false,
          timer: 5000
        });
      }
    );
  }

  /** ADICIONALES */
  public onFileChanged(input: string, event: any): void {
    this.fileurlfotografia = event.target.files[0];
    this.loadingFileurlfotografia = true;
    this.onUpload('urlfotografia', 'usuario_fotografia');
  }

  /** ADICIONALES */
  public onUpload(input: string, dir: string): void {
    const form = new FormData();
    form.append('imagen', this.fileurlfotografia, this.fileurlfotografia.name);
    form.append('dir', dir);

    this.usuarioService.uploadImage(form).subscribe(
      (response: any) => {
        this.loadingFileurlfotografia = false;
        try {
          if (response.url) {
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              confirmButtonText: 'Exitoso, puedes continuar',
              showConfirmButton: true,
              timer: 5000
            });

            this.isFileurlfotografia = true;
            this.form.get(input).setValue(response.url);
            // this.urlfotografiaTemp = this._urlImage + '/' + this.fileurlfotografia.name;
            this.urlfotografiaTemp = response.url;
          } else {
            this.fileurlfotografia = null;

            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'ERROR al intentar cargar el archivo',
              showConfirmButton: false,
              timer: 5000
            });
          }
        } catch (error) {
          this.fileurlfotografia = null;

          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'ERROR al intentar cargar el archivo',
            showConfirmButton: false,
            timer: 5000
          });
        }
      }
    );
  }

  public refresh(): void {
    this.isPais = false;
  }
}