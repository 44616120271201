import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import {
  NgbModule,
  NgbPaginationModule,
  NgbAlertModule,
  NgbAccordion,
  NgbPanel,
  NgbRating,
} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgbModule,
  ], exports: [
    NgbModule,
  ]
})
export class NgbootstrapModule { }
