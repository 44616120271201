import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Preguntas } from './../interface/preguntas';

@Injectable({
  providedIn: 'root'
})
export class PreguntasService {

  constructor(
    private http: HttpClient
  ) { }

  public post(object: Preguntas): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/Preguntas', object);
  }

  public postAuth(object: Preguntas): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/PreguntasAuth', object);
  }
}
