<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Confirmamos tu registro de {{_experto}}</h2> -->
    </div>
    <div class="container">
        <div class="row">
            <div class="card mx-auto" style="background-color: #ffffff60; max-width: 650px;">
                <span class="span-titulo-confirmacion">
                    <mark class="titulo-confirmacion">Gracias por compartir tu conocimiento</mark>
                </span>
                <div class="card-body card-confirmacion text-center">
                    <p style="color: #665C61 !important;">Hasta el momento {{_experto}}(s) han aportado su conocimiento,
                        debes responder todas las preguntas, y finalizar dando clic en el botón Enviar {{_encuesta}}, si
                        al momento de enviar tu aporte se ha completado el numero de respuesta, no será recibido tu
                        aporte.</p>
                    <p style="color: #665C61 !important;">Recuerda que de la calidad de tus respuestas dependerá tu
                        calificación, por eso te recomendamos ser claro, argumentando tu respuesta de la forma más
                        detallada posible e incluso utiliza ejemplos en caso de que se requiera.</p>
                    <p style="color: #665C61 !important;">Puedes iniciar tu aporte dando clic en el botón RESPONDER,
                        éxitos en tus respuestas.</p>

                </div>
                <div class="form-group text-center" style="margin-top: -1rem;">
                    <a class="btn btn-outline-primary rounded-pill">
                        Opinar <i class="fas fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>