<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-experto></app-menu-experto>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">{{_pregunta}}</h2>
    </div> -->
    <div class="container">
        <div class="animated fadeIn">
            <h2 class="titulo">{{_pregunta.toUpperCase()}}</h2>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isBuscando === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_hilo}}, intenta
                                nuevamente.</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                    </div>
                    <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1">
                        <div class="card-body">
                            <div
                                *ngFor="let item of jsonPreguntaHilo$ | async | sortBy: 'asc':'idHilo'; let index = index">

                                <div class="form-group row" *ngIf="index === 0">
                                    <label class="col-md-3 col-form-label">FECHA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" [value]="item.fechaHilo" disabled>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="index === 0">
                                    <label class="col-md-3 col-form-label">TÍTULO</label>
                                    <div class="col-md-9">
                                        <div class="p-3" [innerHtml]="'<b>' + item.titulo + '</b>'"
                                            style="border: 1px solid #ced4da; border-radius: .25rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <div class="card" style="background-color: #ffffff60;">
                                            <div class="card-header" *ngIf="index === 0"><b>{{_pregunta}}</b></div>
                                            <div class="card-header" *ngIf="index > 0"><b>{{_hilo}}</b></div>
                                            <div class="card-body">
                                                <div [innerHtml]="item.contenido" style="text-align: justify;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3" *ngIf="item.respuesta">
                                    <div class="offset-md-3 col-md-9">
                                        <div class="card" style="background-color: #ffffff60;">
                                            <div class="card-header" style="background-color: #b44ad2!important;">
                                                <b>[{{_experto}}] {{item.nickname}}</b> dice :
                                            </div>
                                            <div class="card-body">
                                                <div [innerHtml]="item.respuesta" style="text-align: justify;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>