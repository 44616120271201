import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { FaqService } from './../shared/service/faq.service';
import { Faq } from './../shared/interface/faq';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  // private faqsObservable = new BehaviorSubject<Faq[]>(null);
  // public faqs$ = this.faqsObservable.asObservable();

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  public myControl = new FormControl();
  public faqs: Faq[] = [];
  public filteredFaqs$: Observable<Faq[]>;
  public primerFiltro = false;

  constructor(
    private faqService: FaqService,
  ) { }

  ngOnInit(): void {
    this.faqService.getAll().subscribe(
      (faqs: Faq[]) => {
        // this.faqsObservable.next(faqs);
        this.faqs = faqs;
        // this.filteredFaqs = this.filteredFaqs.pipe(map(value => faqs));
      },
      error => {
        // this.faqsObservable = null;
        this.faqs = null;
      },
      () => {
      }
    );
    this.filteredFaqs$ = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): Faq[] {
    if (value.length > 0 && !this.primerFiltro) {
      this.primerFiltro = true;
    }

    const filterValue = value.toLowerCase();
    return this.faqs.filter(option => option.pregunta.toLowerCase().includes(filterValue));
  }

}
