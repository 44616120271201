import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { UsuariosService } from './../shared/service/usuarios.service';
import { Usuarios } from './../shared/interface/usuarios';

@Component({
  selector: 'app-activacion-recuperar-usuario',
  templateUrl: './activacion-recuperar-usuario.component.html',
  styleUrls: ['./activacion-recuperar-usuario.component.css']
})
export class ActivacionRecuperarUsuarioComponent implements OnInit {

  public correoelectronico: string;
  public token: string;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private usuariosService: UsuariosService,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.correoelectronico = params.correoelectronico;
      this.form.get('correoelectronico').setValue(this.correoelectronico);
      this.token = params.token;
      this.form.get('tokenrecupera').setValue(this.token);
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      correoelectronico: [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      tokenrecupera: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      contrasena: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10), Validators.pattern('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,10}$')]],
      confirmacontrasena: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
    });
  }

  /** OBLIGATORIO */
  public update(event: Event): void {
    event.preventDefault();

    if (this.form.get('contrasena').value !== this.form.get('confirmacontrasena').value) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error'
        },
        buttonsStyling: false
      });

      swal.fire({
        // imageUrl: this._urlTest + '/logo_128.png',
        // imageHeight: 64,
        icon: 'error',
        title: 'Las contraseñas no coinciden, favor verificar!',
        showConfirmButton: false,
        timer: 5000
      });
      // } else if (this.form.valid) {
      //   const object = this.form.value;
      //   console.log('update');
      //   console.log(object);
      //   this.isAccion = 0;


      //   // this.queryService.query_8(object.correoelectronico, object.tokenrecupera, object.contrasena).subscribe(
      //   this.queryService.query_0('200').subscribe(
      //     (response: any) => {
      //       // response 200
      //       this.isAccion = 1;
      //       const swal = Swal.mixin({
      //         customClass: {
      //           confirmButton: 'btn btn-success'
      //         },
      //         buttonsStyling: false
      //       });

      //       swal.fire({
      //         imageUrl: this._urlTest + '/logo_128.png',
      //         imageHeight: 64,
      //         icon: 'success',
      //         title: 'Bienvenido, hemos modificado tu acceso, puedes continuar ingresando usuario y contraseña',
      //         showConfirmButton: false,
      //         timer: 4000
      //       });

      //       setTimeout(function () {
      //         window.location.href = '/login-usuario/' + object.correoelectronico;
      //       }, 4000);
      //     },
      //     error => {
      //       // response 500
      //       this.isAccion = -1;
      //       const swal = Swal.mixin({
      //         customClass: {
      //           confirmButton: 'btn btn-error'
      //         },
      //         buttonsStyling: false
      //       });

      //       swal.fire({
      //         // imageUrl: this._urlTest + '/logo_128.png',
      //         // imageHeight: 64,
      //         icon: 'error',
      //         title: 'No fue posible verificar tu token',
      //         showConfirmButton: false,
      //         timer: 5000
      //       });
      //     }
      //   );

      //   // });
      // }

    } else if (this.form.valid) {
      const object = this.form.value;
      this.isAccion = 0;

      this.queryService.query_8(object.correoelectronico, object.tokenrecupera).subscribe(
        (response: any) => {
          // response 200
          try {
            if (response[0].isrecupera === 'SI') {
              const object: Usuarios = {
                id: 0, fecha: new Date(), fkusuario: response[0].id, correoelectronico: response[0].correoelectronico, contrasena: this.form.get('contrasena').value, ultimoacceso: new Date()
              };

              this.usuariosService.put(object).subscribe(
                (response: Usuarios) => {
                  // response 200
                  this.isAccion = 1;
                  const swal = Swal.mixin({
                    customClass: {
                      confirmButton: 'btn btn-success'
                    },
                    buttonsStyling: false
                  });

                  swal.fire({
                    imageUrl: this._urlTest + '/logo_128.png',
                    imageHeight: 64,
                    icon: 'success',
                    title: 'La verificación de tu identidad ha sido exitosa ¡Bienvenido!',
                    showConfirmButton: false,
                    timer: 4000
                  });

                  setTimeout(function () {
                    window.location.href = '/login-usuario/' + object.correoelectronico;
                  }, 4000);
                },
                error => {
                  // response 500
                  this.isAccion = -1;
                  const swal = Swal.mixin({
                    customClass: {
                      confirmButton: 'btn btn-error'
                    },
                    buttonsStyling: false
                  });

                  swal.fire({
                    // imageUrl: this._urlTest + '/logo_128.png',
                    // imageHeight: 64,
                    icon: 'error',
                    title: 'Encontramos un ERROR al modificar tu cuenta, intenta nuevamente',
                    showConfirmButton: false,
                    timer: 5000
                  });
                }
              );
            } else {
              this.isAccion = -1;
              const swal = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-error'
                },
                buttonsStyling: false
              });

              swal.fire({
                // imageUrl: this._urlTest + '/logo_128.png',
                // imageHeight: 64,
                icon: 'error',
                title: 'No fue posible verificar tu Código de confirmación',
                showConfirmButton: false,
                timer: 5000
              });
            }
          } catch (error) {
            this.isAccion = -1;
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'No fue posible verificar tu Código de confirmación',
              showConfirmButton: false,
              timer: 5000
            });
          }
        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'No fue posible verificar tu Código de confirmación',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

      // });
    }
  }

}
