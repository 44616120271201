<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">Recomendar Amigo</h2>
    </div> -->
    <div class="container">
        <div class="animated fadeIn">
            <h2 class="titulo">RECOMENDAR AMIGO</h2>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal">
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">NOMBRE<span
                                            *ngIf="validator('nombre')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="nombre">
                                        <div *ngIf="form.get('nombre').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre'].hasError('pattern')">
                                                + Ingresa un nombre valido
                                            </span>
                                        </div>
                                        <div *ngIf="form.get('nombre').errors && form.get('nombre').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['nombre'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CORREO ELECTRÓNICO<span
                                            *ngIf="validator('correoelectronico')">*</span></label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="correoelectronico"
                                            autocomplete="off" oninput="this.value = this.value.toLowerCase()">
                                        <div *ngIf="form.get('correoelectronico').errors?.pattern">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('pattern')">
                                                + Ingresa un correo valido
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="form.get('correoelectronico').errors && form.get('correoelectronico').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['correoelectronico'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">MENSAJE<span
                                            *ngIf="validator('mensaje')">*</span></label>
                                    <div class="col-md-9">
                                        <quill-editor [styles]="{height: '250px'}"
                                            (onContentChanged)="onChangue($event)" formControlName="mensaje">
                                        </quill-editor>
                                        <small>Contador de caracteres, {{ totalCaracteres }} <span
                                                class="text-danger">Maximo 200</span></small>
                                        <div *ngIf="form.get('mensaje').errors && form.get('mensaje').dirty">
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['mensaje'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['mensaje'].hasError('minlength')">
                                                + Debes ingresar más caracteres
                                            </span>
                                            <span class="help-block text-danger"
                                                *ngIf="form.controls['mensaje'].hasError('maxlength')">
                                                + Excediste el valor de caracteres
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="text-center">
                                    <button type="button" class="btn btn-secondary rounded-pill"
                                        *ngIf="form.invalid && isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Enviar correo electrónico
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill"
                                        *ngIf="form.valid && isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Enviar correo electrónico
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>