import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Home } from './../interface/home';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient
  ) { }

  public getAll(): Observable<Home[]> {
    return this.http.get<Home[]>(environment.urlApi + '/Home');
  }
}
