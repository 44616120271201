import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { UsuarioService } from './../shared/service/usuario.service';
import { Usuario } from './../shared/interface/usuario';
import { UsuariosService } from './../shared/service/usuarios.service';
import { Usuarios } from './../shared/interface/usuarios';
// import { Especialidad } from './../shared/interface/especialidad';
// import { EspecialidadService } from './../shared/service/especialidad.service';
// import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// import { AppDateAdapter, APP_DATE_FORMATS } from './../shared/service/-format-datepicker.service';

interface Country {
  id: string;
  name: string;
}

interface State {
  id: string;
  id_country: string;
  name: string;
}

interface City {
  id: string;
  id_state: string;
  name: string;
}

@Component({
  selector: 'app-registro-usuario',
  templateUrl: './registro-usuario.component.html',
  styleUrls: ['./registro-usuario.component.css'],
  // providers: [
  //   { provide: DateAdapter, useClass: AppDateAdapter },
  //   { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  // ]
})
export class RegistroUsuarioComponent implements OnInit {

  // maxDate = new Date();
  public anoFechanacimiento: string;
  public mesFechanacimiento: string;
  public diaFechanacimiento: string;
  public anoFechainiciolaboral: string;
  public mesFechainiciolaboral: string;
  public diaFechainiciolaboral: string;
  public tipo: string;
  public isVerificar = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  public _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** ADICIONALES */
  public fileurlfotografia: File;
  public isFileurlfotografia = false;
  public loadingFileurlfotografia = false;
  private urlfotografiaTemp: string;

  public jsonPaises$: Observable<Country[]>;
  public jsonEstados$: Observable<State[]>;
  public jsonCiudades$: Observable<City[]>;
  public isEstado = false;
  public isCiudad = false;

  public jsonEspecialidads: any[];
  public jsonEspecialidads1$: Observable<any[]>;
  public isEspecialidad1 = false;
  public jsonEspecialidads2$: Observable<any[]>;
  public isEspecialidad2 = false;
  public jsonEspecialidads3$: Observable<any[]>;
  public isEspecialidad3 = false;
  public jsonEspecialidads4$: Observable<any[]>;
  public isEspecialidad4 = false;
  public jsonEspecialidads5$: Observable<any[]>;
  public isEspecialidad5 = false;

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public hoy = new Date();
  public ano = this.hoy.getFullYear();
  public mes = this.hoy.getMonth() + 1;
  public dia = this.hoy.getDate();
  // private fechanacimiento: Date;

  public isParte1 = true;
  public isParte2 = false;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;

  constructor(
    private usuarioService: UsuarioService,
    private usuariosService: UsuariosService,
    private queryService: QueryService,
    // private especialidadService: EspecialidadService,
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    // private dateAdapter: DateAdapter<Date>,
  ) {
    /** OBLIGATORIO */
    this.buildForm();
    this.getPaises();
    this.getEspecialidads();

    // this.dateAdapter.setLocale('es-CO');

    this.form.get('mesFechanacimiento').valueChanges.subscribe(
      (value: number) => {
        if (value === 2) {
          this.form.get('diaFechanacimiento').setValidators([Validators.required, Validators.min(1), Validators.max(29)]);
        } else if (value === 4 || value === 6 || value === 9 || value === 11) {
          this.form.get('diaFechanacimiento').setValidators([Validators.required, Validators.min(1), Validators.max(30)]);
        } else {
          this.form.get('diaFechanacimiento').setValidators([Validators.required, Validators.min(1), Validators.max(31)]);
        }
      }
    );
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.tipo = params.tipo;
      this.isVerificar = false;

      if (this.tipo === 'usuario') {
        /*** SOLO */
      } else {
        this.form.get('nombre1').setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[.a-zA-Z0-9- ]*$')]);
        this.form.get('apellido1').clearValidators();
        this.form.get('genero').clearValidators();
        this.form.get('anoFechanacimiento').clearValidators();
        this.form.get('mesFechanacimiento').clearValidators();
        this.form.get('diaFechanacimiento').clearValidators();
        const hoy = new Date();
        this.form.get('nickname').setValue(hoy.getTime());
        this.form.get('tipoidentificacion').setValue('NIT');
        this.form.get('genero').setValue('OTRO');
      }
    });
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      fecha: [''],
      nickname: [null],
      nombre1: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$')]],
      nombre2: [null, [Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$')]],
      apellido1: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$')]],
      apellido2: [null, [Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ ]*$')]],
      identificacion: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9-]*$')]],
      tipoidentificacion: [null, Validators.required],
      genero: [null, Validators.required],
      anoFechanacimiento: [null, [Validators.required, Validators.min(1900), Validators.max(this.ano)]],
      mesFechanacimiento: [null, [Validators.required, Validators.min(1), Validators.max(12)]],
      diaFechanacimiento: [null, [Validators.required, Validators.min(1), Validators.max(31)]],
      fechanacimiento: [null],
      urlfotografia: [null],
      pais: [null, Validators.required],
      departamento: [null, Validators.required],
      ciudad: [null, Validators.required],
      direccion: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      codigopais: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(3), Validators.pattern('^[0-9 ]*$')]],
      celular: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(15), Validators.pattern('^[0-9 ]*$')]],
      correoelectronico: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      tipo: [null],
      Usuario_contrasena: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$#!%*?&•|°()=‘?¿¡´¨+_:.,;<>¬~`])([A-Za-z\d$@$#!%*?&+•|°()=‘?¿¡´¨_:.,;<>¬~`]|[^ ]){6,10}$/)]],
      Usuario_confirmacontrasena: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      fkespecialidad1: [null, Validators.required],
      fkespecialidad2: [null],
      fkespecialidad3: [null],
      fkespecialidad4: [null],
      fkespecialidad5: [null],
      otraespecialidad: [null],
      isconfirma: [null],
      tokenconfirma: [null],
      estadoconfirma: [null],
      isrecupera: [null],
      tokenrecupera: [null],
      estadorecupera: [null],
      estado: [null],
      acepta: [null, Validators.requiredTrue], //agregado
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  public validar1() {
    if (this.form.get('nombre1').valid &&
      this.form.get('apellido1').valid &&
      this.form.get('identificacion').valid &&
      this.form.get('tipoidentificacion').valid &&
      this.form.get('genero').valid &&
      // this.form.get('fechanacimiento').valid &&
      this.form.get('anoFechanacimiento').valid &&
      this.form.get('mesFechanacimiento').valid &&
      this.form.get('diaFechanacimiento').valid) {
      return true;
    } else {
      return false;
    }
  }

  get getNombre1(): string {
    if (this.form.get('nombre1').value) {
      if (this.form.get('nombre1').value.includes(' ')) {
        return this.form.get('nombre1').value.toUpperCase().trim().split(' ')[0];
      } else {
        return this.form.get('nombre1').value.toUpperCase().trim();
      }
    } else {
      return '';
    }
  }

  get getApellido1(): string {
    if (this.form.get('apellido1').value) {
      return ' ' + this.form.get('apellido1').value.toUpperCase().trim().substring(0, 1) + '.';
    } else {
      return '';
    }
  }
  get getApellido2(): string {
    if (this.form.get('apellido2').value) {
      return ' ' + this.form.get('apellido2').value.toUpperCase().trim().substring(0, 1) + '.';
    } else {
      return '';
    }
  }

  private getPaises(): void {
    this.usuarioService.getPais().subscribe(
      (paises: Country[]) => {
        this.jsonPaises$ = new Observable((observer) => {
          observer.next(paises);
          observer.complete();
        });
      }
    );
  }

  public getEstados(pais: any): void {
    this.isEstado = true;
    const id_country = pais.target.value.split(':')[2];
    this.usuarioService.getEstado().subscribe(
      (estados: State[]) => {
        this.jsonEstados$ = new Observable((observer) => {
          // tslint:disable-next-line: radix
          observer.next(estados.filter(option => parseInt('' + option.id_country) === parseInt('' + id_country)));
          observer.complete();
        });
      }
    );
  }

  public getCiudades(estado: any): void {
    this.isCiudad = true;
    const id_state = estado.target.value.split(':')[2];
    this.usuarioService.getCiudad().subscribe(
      (ciudades: City[]) => {
        this.jsonCiudades$ = new Observable((observer) => {
          // tslint:disable-next-line: radix
          observer.next(ciudades.filter(option => parseInt('' + option.id_state) === parseInt('' + id_state)));
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads(): void {
    // this.queryService.query_29().subscribe(
    this.queryService.query_291().subscribe(
      (especialidads: any[]) => {
        this.jsonEspecialidads1$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
        this.jsonEspecialidads2$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
        this.jsonEspecialidads3$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
        this.jsonEspecialidads4$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
        this.jsonEspecialidads5$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });

        this.jsonEspecialidads = especialidads;
      }
    );
  }

  public setEspecialidad1(especialidad: any): void {
    this.isEspecialidad1 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public setEspecialidad2(especialidad: any): void {
    this.isEspecialidad2 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public setEspecialidad3(especialidad: any): void {
    this.isEspecialidad3 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public setEspecialidad4(especialidad: any): void {
    this.isEspecialidad4 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public setEspecialidad5(especialidad: any): void {
    this.isEspecialidad5 = true;
    // const id = especialidad.target.value.split(':')[1];
    // this.disabledEspecialidad(id);
  }

  public refreshEspecialidads(): void {
    this.isEspecialidad1 = false;
    this.isEspecialidad2 = false;
    this.isEspecialidad3 = false;
    this.isEspecialidad4 = false;
    this.isEspecialidad5 = false;
    this.form.get('otraespecialidad').setValue(null);
    this.getEspecialidads();
  }

  public refreshEspecialidads1(): void {
    this.isEspecialidad1 = false;
    this.form.get('otraespecialidad').setValue(null);
    this.getEspecialidads();
  }

  public refreshEspecialidads2(): void {
    this.isEspecialidad2 = false;
    this.form.get('otraespecialidad').setValue(null);
    this.getEspecialidads();
  }
  public refreshEspecialidads3(): void {
    this.isEspecialidad3 = false;
    this.form.get('otraespecialidad').setValue(null);
    this.getEspecialidads();
  }
  public refreshEspecialidads4(): void {
    this.isEspecialidad4 = false;
    this.form.get('otraespecialidad').setValue(null);
    this.getEspecialidads();
  }
  public refreshEspecialidads5(): void {
    this.isEspecialidad5 = false;
    this.form.get('otraespecialidad').setValue(null);
    this.getEspecialidads();
  }

  // public disabledEspecialidad(id: any): void {
  //   if (!this.isEspecialidad1) {
  //     this.jsonEspecialidads1$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   if (!this.isEspecialidad2) {
  //     this.jsonEspecialidads2$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   if (!this.isEspecialidad3) {
  //     this.jsonEspecialidads3$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   if (!this.isEspecialidad4) {
  //     this.jsonEspecialidads4$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   if (!this.isEspecialidad5) {
  //     this.jsonEspecialidads5$ = new Observable((observer) => {
  //       // tslint:disable-next-line: radix
  //       observer.next(this.jsonEspecialidads.filter(option => option.id !== parseInt(id)));
  //       observer.complete();
  //     });
  //   }

  //   this.jsonEspecialidads = this.jsonEspecialidads.filter(option => option.id !== parseInt(id));
  // }

  // public setFechanacimiento(fecha: any) {
  //   this.fechanacimiento = fecha;
  // }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.get('Usuario_contrasena').value !== this.form.get('Usuario_confirmacontrasena').value) {
      const swal = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-error'
        },
        buttonsStyling: false
      });

      swal.fire({
        // imageUrl: this._urlTest + '/logo_128.png',
        // imageHeight: 64,
        icon: 'error',
        title: 'Las contraseñas no coinciden, favor verificar!',
        showConfirmButton: false,
        timer: 5000
      });
    } else if (this.form.valid) {
      const object: any = this.form.value;

      if (this.tipo === 'usuario') {
        object.nickname = this.getNombre1 + this.getApellido1 + this.getApellido2;
      } else {
        const hoy = new Date();
        object.nickname = hoy.getTime().toString();
      }
      object.fechanacimiento = object.anoFechanacimiento + '-' + object.mesFechanacimiento + '-' + object.diaFechanacimiento;
      object.tipo = this.tipo;
      object.urlfotografia = this.urlfotografiaTemp;
      object.pais = this.form.get('pais').value.split(':')[0].trim();
      object.departamento = this.form.get('departamento').value.split(':')[0];
      object.isconfirma = 'NO';
      object.aceptaterminos = 'SI';
      /** TEMPORAL */
      // object.tokenconfirma = generar en PHP
      object.estadoconfirma = 'PENDIENTE';
      /** TEMPORAL */
      // añadir CRON para que envie correo con 'estadoconfirma' con 'PENDIENTE' y 'isconfirma' con 'NO',
      // envia correo y luego coloca 'estadoconfirma' con 'ENVIADO'
      object.estado = this.getEstadoInicial();
      this.isAccion = 0;

      this.usuarioService.post(object).subscribe(
        (response: Usuario) => {
          // response 200

          // this.isAccion = 1;
          // const swal = Swal.mixin({
          //   customClass: {
          //     confirmButton: 'btn btn-success'
          //   },
          //   buttonsStyling: false
          // });

          // swal.fire({
          //   imageUrl: this._urlTest + '/logo_128.png',
          //   imageHeight: 64,
          //   icon: 'success',
          //   title: 'Hemos registrado tu información, espera unos segundos ...',
          //   showConfirmButton: false,
          //   timer: 4000
          // });

          // setTimeout(function () {
          //   window.location.href = '/confirmacion-registro-usuario/' + object.correoelectronico;
          // }, 4000);

          const object: Usuarios = {
            id: 0, fecha: new Date(), fkusuario: response.id, correoelectronico: response.correoelectronico, contrasena: this.form.get('Usuario_contrasena').value, ultimoacceso: new Date()
          };

          this.usuariosService.post(object).subscribe(
            (response: Usuarios) => {
              // response 200
              this.isAccion = 1;
              const swal = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
              });

              swal.fire({
                imageUrl: this._urlTest + '/logo_128.png',
                imageHeight: 64,
                icon: 'success',
                title: 'Hemos registrado tu información, espera unos segundos ...',
                showConfirmButton: false,
                timer: 4000
              });

              setTimeout(function () {
                window.location.href = '/confirmacion-registro-usuario/' + object.correoelectronico;
              }, 4000);
            },
            error => {
              // response 500
              this.isAccion = -1;
              const swal = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-error'
                },
                buttonsStyling: false
              });

              swal.fire({
                // imageUrl: this._urlTest + '/logo_128.png',
                // imageHeight: 64,
                icon: 'error',
                title: 'Encontramos un ERROR al crear tu cuenta, intenta nuevamente',
                showConfirmButton: false,
                timer: 5000
              });
            }
          );

        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos que tienes una cuenta asociada, intenta iniciar tu sesión',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

      // });
    }
  }

  /** ADICIONALES */
  public onFileChanged(input: string, event: any): void {
    this.fileurlfotografia = event.target.files[0];
    this.loadingFileurlfotografia = true;
    this.onUpload('urlfotografia', 'usuario_fotografia');
  }

  /** ADICIONALES */
  public onUpload(input: string, dir: string): void {
    const form = new FormData();
    form.append('imagen', this.fileurlfotografia, this.fileurlfotografia.name);
    form.append('dir', dir);

    this.usuarioService.uploadImage(form).subscribe(
      (response: any) => {
        this.loadingFileurlfotografia = false;
        try {
          if (response.url) {
            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-success'
              },
              buttonsStyling: false
            });

            swal.fire({
              imageUrl: this._urlTest + '/logo_128.png',
              imageHeight: 64,
              icon: 'success',
              confirmButtonText: 'Exitoso, puedes continuar',
              showConfirmButton: true,
              timer: 5000
            });

            this.isFileurlfotografia = true;
            //this.form.get(input).setValue(this.fileurlfotografia.name);
            this.form.get(input).setValue(response.url);
            this.urlfotografiaTemp = response.url;
          } else {
            this.fileurlfotografia = null;

            const swal = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-error'
              },
              buttonsStyling: false
            });

            swal.fire({
              // imageUrl: this._urlTest + '/logo_128.png',
              // imageHeight: 64,
              icon: 'error',
              title: 'ERROR al intentar cargar el archivo',
              showConfirmButton: false,
              timer: 5000
            });
          }
        } catch (error) {
          this.fileurlfotografia = null;

          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'ERROR al intentar cargar el archivo',
            showConfirmButton: false,
            timer: 5000
          });
        }
      }
    );
  }

  public getEstadoInicial(): string {
    return 'INACTIVO';
  }

  public regresar2(): void {
    this.isParte1 = true;
    this.isParte2 = false;
  }

}
