<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-experto></app-menu-experto>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">{{_usuario}}</h2>
    </div> -->
    <div class="container">
        <div class="animated fadeIn">
            <h2 class="titulo">MIS TARIFAS</h2>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isBuscando === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_usuario}}, intenta
                                nuevamente.</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: s
                        auto; height: 8rem;">
                    </div>
                    <!-- <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal"> -->
                    <form [formGroup]="form" enctype="multipart/form-data" class="form-horizontal"
                        (ngSubmit)="update($event)">
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">TARIFA {{_pregunta}}<span
                                            *ngIf="validator('fktarifanueva')">*</span></label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonTarifasNUEVA$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonTarifasNUEVA$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fktarifanueva">
                                            <option
                                                *ngFor="let tarifa of jsonTarifasNUEVA$ | async | sortBy: 'asc':'tarifa'"
                                                [ngValue]="tarifa.id">
                                                {{ tarifa.tarifa | currency:'$':'symbol':'1.0' }}</option>
                                        </select>
                                        <div
                                            *ngIf="form.get('fktarifanueva').errors && form.get('fktarifanueva').dirty">
                                            <span class="help-block text-danger" +
                                                *ngIf="form.controls['fktarifanueva'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">TARIFA {{_hilo}}<span
                                            *ngIf="validator('fktarifahilo')">*</span></label>
                                    <div class="col-md-9 text-center" *ngIf="(jsonTarifasHILO$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonTarifasHILO$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fktarifahilo">
                                            <option
                                                *ngFor="let tarifa of jsonTarifasHILO$ | async | sortBy: 'asc':'tarifa'"
                                                [ngValue]="tarifa.id">
                                                {{ tarifa.tarifa | currency:'$':'symbol':'1.0' }}</option>
                                        </select>
                                        <div *ngIf="form.get('fktarifahilo').errors && form.get('fktarifahilo').dirty">
                                            <span class="help-block text-danger" +
                                                *ngIf="form.controls['fktarifahilo'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">TARIFA {{_encuesta}}<span
                                            *ngIf="validator('fktarifaencuesta')">*</span></label>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonTarifasENCUESTA$ | async)?.length <= 0">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonTarifasENCUESTA$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fktarifaencuesta">
                                            <option
                                                *ngFor="let tarifa of jsonTarifasENCUESTA$ | async | sortBy: 'asc':'tarifa'"
                                                [ngValue]="tarifa.id">
                                                {{ tarifa.tarifa | currency:'$':'symbol':'1.0' }}</option>
                                        </select>
                                        <div
                                            *ngIf="form.get('fktarifaencuesta').errors && form.get('fktarifaencuesta').dirty">
                                            <span class="help-block text-danger" +
                                                *ngIf="form.controls['fktarifaencuesta'].hasError('required')">
                                                + Campo requerido
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;">
                            <div class="card-body">
                                <div class="text-center">
                                    <button type="button" class="btn btn-secondary rounded-pill"
                                        *ngIf="form.invalid && isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Actualizar información
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill"
                                        *ngIf="form.valid && isAccion === -1">
                                        <i class="fas fa-angle-double-right"></i> Actualizar información
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>