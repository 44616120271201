<section class="banner-all">
    <!-- <div class="container div-titulo">
        <h2 class="titulo">Resultado de busquedad</h2>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-md-12 pt-5 pt-lg-1" infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
                <header class="mb-5">
        
                    <div class="form-inline mb-5" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                        <strong class="mr-md-auto">No hemos encontrado resultados que coincidan con tu
                            búsqueda.</strong>
                    </div>
                    
                    <div class="row mb-5">
                        <div class="col-xl-3 col-lg-3"></div>
                        <div class="col-xl-6 col-lg-6">
                            <div class="col input-group w-100">
                                <input class="form-control text-center"
                                    placeholder="Prueba con otro criterio de búsquedad" type="text" [(ngModel)]="otro"
                                    (keyup.enter)="otraBusquedad()">
                                <div class="input-group-append" *ngIf="otro.length > 0">
                                    <button type="button" (click)="otraBusquedad()" class="btn btn-block btn-primary">
                                        <i class="fas fa-search"></i> <span class="text">Buscar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3"></div>
                    </div>
                    <div class="form-inline" *ngIf="isBuscando === -1">
                        <strong class="mr-md-auto">Buscando ...</strong>
                    </div>
                    <div class="form-inline" *ngIf="isBuscando === 1 && total > 0">
                        <!-- <strong class="mr-md-auto">Hemos encontrado {{ total }} {{_experto}}(s), con -->
                        <strong class="mr-md-auto">Hemos encontrado {{total}} {{_experto}}(s), con
                            tarifas desde {{ minTarifaExpertos$ | async | currency:'$':'symbol':'1.0' }} hasta
                            {{ maxTarifaExpertos$ | async | currency:'$':'symbol':'1.0' }}.</strong>
                    </div>

                    

                    <div class="form-inline mt-3" *ngIf="isBuscando === 1 && total > 0">
                        <div class="head-options-container">
                            <strong class="col-md-3" style="padding: 0!important">Ordenar resultados por</strong>
                            
                            <div class="col-md-9 filtersCont">
                                <select class="form-control" (change)="setFiltro($event)" [(ngModel)]="filtro">
                                    <option value="normal" disabled selected>... Seleccione</option>
                                    <option value="tarifaMenorMayor">TARIFA (Menor a Mayor)</option>
                                    <option value="tarifaMayorMenor">TARIFA (Mayor a Menor)</option>
                                    <option value="experienciaMenorMayor">AÑOS DE EXPERIENCIA (Menor a Mayor)</option>
                                    <option value="experienciaMayorMenor">AÑOS DE EXPERIENCIA (Mayor a Menor)</option>
                                    <option value="calificacionMenorMayor">CALIFICACIÓN (Menor a Mayor)</option>
                                    <option value="calificacionMayorMenor">CALIFICACIÓN (Mayor a Menor)</option>
                                </select>
                            
                            </div> 
                        </div>
                        <!-- INICIO CAMBIOS JP -->
                        <div class="head-options-container">
                            <strong class="col-md-3" style="padding: 0!important">Filtros</strong>
                            <div class="col-lg-7 d-flex justify-content-end align-items-start wow fadeInUp row_formulario_banner floatForm"
                                data-wow-duration="1500ms" *ngIf="form1Visible">

                                <form class="contact-form-validated contact-one__form w-100" *ngIf="form1Visible" [formGroup]="form1"
                                    (ngSubmit)="accion1($event,'especialidad')">
                                    <div class="cont-filter row-res">
                                        <div class="col-md-8 center-content c-cont" *ngIf="getSplit(form1.get('area').value) === null">
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{_area}}</mat-label>
                                                <input type="text" matInput formControlName="area" [matAutocomplete]="autoArea">
                                                <mat-autocomplete #autoArea="matAutocomplete"
                                                    (optionSelected)="activarRama($event.option.value, 1,$event);">
                                                    <!-- (optionSelected)="activarRama($event.option.value, 1);"> -->
                                                    <mat-option *ngFor="let option of filteredAreas1$ | async | sortBy :'asc':'nombre'"
                                                        [value]="option.id + ':' + option.nombre">
                                                        {{option.nombre}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>

                                            
                                            <div *ngIf="form1.get('area').errors && form1.get('area').dirty">
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['area'].hasError('required')">
                                                    + Campo requerido
                                                </span>
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['area'].hasError('minlength')">
                                                    + Debes ingresar más caracteres
                                                </span>
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['area'].hasError('maxlength')">
                                                    + Excediste el valor de caracteres
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-8 center-content c-cont" *ngIf="getSplit(form1.get('area').value) !== null">
                                            <mat-form-field class="example-full-width">
                                                <!-- <mat-label>{{_area}}</mat-label> -->
                                                <mat-label></mat-label>
                                                <input matInput [value]="getSplit(form1.get('area').value)" disabled
                                                    style="padding-left: 10px; padding-right: 10px;">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-8 center-content c-cont " style="min-height: 20px; text-align: center; background-color: #fff;"
                                            *ngIf="!isRama && getSplit(form1.get('area').value) !== null">
                                            <img src="assets/template/images/loading_16.gif" alt="">
                                        </div>
                                        <div class="col-md-8 center-content c-cont"
                                            *ngIf="getSplit(form1.get('rama').value) === null && form1.get('rama').value !== 'todos:todos'">
                                            <!-- *ngIf="isRama && getSplit(form1.get('rama').value) === null && form1.get('rama').value !== 'todos:todos'"> -->
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{_rama}}</mat-label>
                                                <input type="text" matInput formControlName="rama" [matAutocomplete]="autoRama">
                                                <mat-autocomplete #autoRama="matAutocomplete"
                                                    (optionSelected)="activarEspecialidad($event.option.value, 1,$event);">
                                                    <mat-option *ngFor="let option of filteredRamas1$ | async | sortBy :'asc':'nombre'"
                                                        [value]="option.id + ':' + option.nombre">
                                                        {{option.nombre}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <div *ngIf="form1.get('rama').errors && form1.get('rama').dirty">
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['rama'].hasError('required')">
                                                    + Campo requerido
                                                </span>
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['rama'].hasError('minlength')">
                                                    + Debes ingresar más caracteres
                                                </span>
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['rama'].hasError('maxlength')">
                                                    + Excediste el valor de caracteres
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-8 center-content c-cont"
                                            *ngIf="getSplit(form1.get('rama').value) !== null && form1.get('rama').value !== 'todos:todos'">
                                            <mat-form-field class="example-full-width">
                                                <!-- <mat-label>{{_rama}}</mat-label> -->
                                                <mat-label></mat-label>
                                                <input matInput [value]="getSplit(form1.get('rama').value)" disabled
                                                    style="padding-left: 10px; padding-right: 10px;">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-8 center-content c-cont"
                                            *ngIf="getSplit(form1.get('rama').value) === null && form1.get('rama').value === 'todos:todos'">
                                            <mat-form-field class="example-full-width">
                                                <!-- <mat-label>{{_rama}}</mat-label> -->
                                                <mat-label></mat-label>
                                                <input matInput [value]="'Todos'" disabled
                                                    style="padding-left: 10px; padding-right: 10px;">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-8 center-content c-cont" style="min-height: 20px; text-align: center; background-color: #fff;"
                                            *ngIf="!isEspecialidad2 && getSplit(form1.get('rama').value) !== null && form1.get('rama').value !== 'todos:todos'">
                                            <img src="assets/template/images/loading_16.gif" alt="">
                                        </div>
                                        <div class="col-md-8 center-content c-cont"
                                            *ngIf="getSplit(form1.get('especialidad').value) === null && form1.get('rama').value !== 'todos:todos'">
                                            <!-- *ngIf="isEspecialidad2 && getSplit(form1.get('especialidad').value) === null && form1.get('rama').value !== 'todos:todos'"> -->
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{_especialidad}}</mat-label>
                                                <input type="text" matInput formControlName="especialidad"
                                                    [matAutocomplete]="autoEspecialidad">
                                                <mat-autocomplete #autoEspecialidad="matAutocomplete"
                                                (optionSelected)="activarFinal($event);">
                                                    <mat-option
                                                        *ngFor="let option of filteredEspecialidads1$ | async | sortBy :'asc':'nombre'"
                                                        [value]="option.id + ':' + option.nombre">
                                                        {{option.nombre}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <div *ngIf="form1.get('especialidad').errors && form1.get('especialidad').dirty">
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['especialidad'].hasError('required')">
                                                    + Campo requerido
                                                </span>
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['especialidad'].hasError('minlength')">
                                                    + Debes ingresar más caracteres
                                                </span>
                                                <span class="help-block text-danger"
                                                    *ngIf="form1.controls['especialidad'].hasError('maxlength')">
                                                    + Excediste el valor de caracteres
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-8 center-content c-cont"
                                            *ngIf="getSplit(form1.get('especialidad').value) !== null && form1.get('rama').value !== 'todos:todos'">
                                            <mat-form-field class="example-full-width">
                                                <!-- <mat-label>{{_especialidad}}</mat-label> -->
                                                <mat-label></mat-label>
                                                <input matInput [value]="getSplit(form1.get('especialidad').value)" disabled
                                                    style="padding-left: 10px; padding-right: 10px;">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                            <!-- FIN CAMBIOS JP -->
                    </div>


                    <!-- <div class="row mt-5" *ngIf="isEspecialidad === 1 && total > 1"> -->
                    <div class="row mt-5" >
                        <div class="col-xl-3 col-lg-3"></div>
                        <div class="col-xl-6 col-lg-6" *ngFor="let experto of jsonExpertos$ | async; let index = index">
                            <a *ngIf="index === 0" 
                            (click)="getRouterLink(tipoFiltro, experto.id)"
                                class="btn btn-block btn-primary">
                                <span class="text">¿Deseas hacer una Pregunta a todos?</span>
                            </a>
                        </div>
                        <div class="col-xl-3 col-lg-3"></div>
                    </div>


                </header>


                <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                    <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                </div>
                <div *ngIf="total > 0 && isNormal">
                    <article class="card card-product-list" style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertos$ | async | sortBy: 'asc':'posicion'">
                        <div class="row no-gutters">
                            <aside class="col-md-3">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1.5rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                    <span
                                        style="font-size: 1.5rem; top: unset; right: 10px; bottom: 10px; left: unset; position: absolute;"
                                        class="badge badge-danger">
                                        {{ experto.nombrenivel }} </span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                                <div class="m-2">
                                    <div class="rating-wrap text-center mb-2" *ngIf="experto.calificaciones === 0">
                                        <ul class="rating-stars">
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                </div>
                            </aside>
                            <div class="col-md-6">
                                <div class="info-main">
                                    <a [routerLink]="['/experto', experto.id]" class="h5 title"
                                        style="line-height: 1.6rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </a>
                                    <h3 style="margin: 0;">Años de experiencia: {{experto.anosexperiencia}}
                                        año(s)</h3>
                                    <h3 style="margin: 0;">Tarifa: {{experto.tarifa | currency:'$':'symbol':'1.0' }}
                                    </h3>
                                    <p *ngIf="isEspecialidad === 0">
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad11">
                                            {{ getNombreEspecialidad(experto.fkespecialidad11)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad12">
                                            {{ getNombreEspecialidad(experto.fkespecialidad12)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad13">
                                            {{ getNombreEspecialidad(experto.fkespecialidad13)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad14">
                                            {{ getNombreEspecialidad(experto.fkespecialidad14)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad15">
                                            {{ getNombreEspecialidad(experto.fkespecialidad15)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad21">
                                            {{ getNombreEspecialidad(experto.fkespecialidad21)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad22">
                                            {{ getNombreEspecialidad(experto.fkespecialidad22)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad23">
                                            {{ getNombreEspecialidad(experto.fkespecialidad23)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad24">
                                            {{ getNombreEspecialidad(experto.fkespecialidad24)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad25">
                                            {{ getNombreEspecialidad(experto.fkespecialidad25)[0].nombre }}
                                        </span>
                                    </p>

                                    <p class="mt-3" [innerHtml]="experto.curriculum | slice:0:200"></p>
                                    <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]">
                                        <span class="text">Ver más</span>
                                    </a>

                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]">
                                        <span class="text">Ver más</span>
                                    </a>
                                </div>
                            </div>
                            <aside class="col-sm-3">
                                <div class="info-aside">
                                    <div class="d-flex align-items-end flex-column" style="min-height: 100%;">
                                        <div class="p-2 w-100">
                                            <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]"
                                                class="btn btn-block btn-primary">
                                                <i class="fas fa-user"></i> <span class="text">Ver {{_experto}}</span>
                                            </a>

                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                        </div>

                                        <div class="p-2 w-100 text-center">
                                            <button (click)="share('/experto/' + experto.id)" class="btn btn-sm m-1"
                                                style="background-color: #3b5998; border-color: #3b5998; color: #fff;">
                                                <i class="fab fa-facebook-square"></i> Compartir
                                            </button>
                                            <button (click)="copy('experto/' + experto.id)"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Copiar URL
                                            </button>
                                            <a *ngIf="fk" [routerLink]="['/recomendar-experto', fk, experto.id]"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Correo electrónico
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 0">
                                            <a [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 1">
                                            <a *ngIf="experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </article>
                    <div class="card" style="background-color: #ffffff60;">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TARIFA (Menor a Mayor) -->
                <div *ngIf="total > 0 && isTarifaMenorMayor">
                    <article class="card card-product-list" style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertos$ | async | sortBy: 'asc':'tarifa'">
                        <div class="row no-gutters">
                            <aside class="col-md-3">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1.5rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                    <span
                                        style="font-size: 1.5rem; top: unset; right: 10px; bottom: 10px; left: unset; position: absolute;"
                                        class="badge badge-danger">
                                        {{ experto.nombrenivel }} </span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                                <div class="m-2">
                                    <div class="rating-wrap text-center mb-2" *ngIf="experto.calificaciones === 0">
                                        <ul class="rating-stars">
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                </div>
                            </aside>
                            <div class="col-md-6">
                                <div class="info-main">
                                    <a [routerLink]="['/experto', experto.id]" class="h5 title"
                                        style="line-height: 1.6rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </a>
                                    <h3 style="margin: 0;">Años de experiencia: {{experto.anosexperiencia}}
                                        año(s)</h3>
                                    <h3 style="margin: 0;">Tarifa: {{experto.tarifa | currency:'$':'symbol':'1.0' }}
                                    </h3>
                                    <p *ngIf="isEspecialidad === 0">
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad11">
                                            {{ getNombreEspecialidad(experto.fkespecialidad11)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad12">
                                            {{ getNombreEspecialidad(experto.fkespecialidad12)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad13">
                                            {{ getNombreEspecialidad(experto.fkespecialidad13)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad14">
                                            {{ getNombreEspecialidad(experto.fkespecialidad14)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad15">
                                            {{ getNombreEspecialidad(experto.fkespecialidad15)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad21">
                                            {{ getNombreEspecialidad(experto.fkespecialidad21)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad22">
                                            {{ getNombreEspecialidad(experto.fkespecialidad22)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad23">
                                            {{ getNombreEspecialidad(experto.fkespecialidad23)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad24">
                                            {{ getNombreEspecialidad(experto.fkespecialidad24)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad25">
                                            {{ getNombreEspecialidad(experto.fkespecialidad25)[0].nombre }}
                                        </span>
                                    </p>

                                    <p class="mt-3" [innerHtml]="experto.curriculum | slice:0:200"></p>
                                    <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]">
                                        <span class="text">Ver más</span>
                                    </a>

                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]">
                                        <span class="text">Ver más</span>
                                    </a>

                                </div>
                            </div>
                            <aside class="col-sm-3">
                                <div class="info-aside">
                                    <div class="d-flex align-items-end flex-column" style="min-height: 100%;">
                                        <div class="p-2 w-100">
                                            <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]"
                                                class="btn btn-block btn-primary">
                                                <i class="fas fa-user"></i> <span class="text">Ver {{_experto}}</span>
                                            </a>

                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                        </div>

                                        <div class="p-2 w-100 text-center">
                                            <button (click)="share('/experto/' + experto.id)" class="btn btn-sm m-1"
                                                style="background-color: #3b5998; border-color: #3b5998; color: #fff;">
                                                <i class="fab fa-facebook-square"></i> Compartir
                                            </button>
                                            <button (click)="copy('experto/' + experto.id)"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Copiar URL
                                            </button>
                                            <a *ngIf="fk" [routerLink]="['/recomendar-experto', fk, experto.id]"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Correo electrónico
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 0">
                                            <a [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 1">
                                            <a *ngIf="experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </article>
                    <div class="card" style="background-color: #ffffff60;">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TARIFA (Mayor a Menor) -->
                <div *ngIf="total > 0 && isTarifaMayorMenor">
                    <article class="card card-product-list" style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertos$ | async | sortBy: 'desc':'tarifa'">
                        <div class="row no-gutters">
                            <aside class="col-md-3">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1.5rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                    <span
                                        style="font-size: 1.5rem; top: unset; right: 10px; bottom: 10px; left: unset; position: absolute;"
                                        class="badge badge-danger">
                                        {{ experto.nombrenivel }} </span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                                <div class="m-2">
                                    <div class="rating-wrap text-center mb-2" *ngIf="experto.calificaciones === 0">
                                        <ul class="rating-stars">
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                </div>
                            </aside>
                            <div class="col-md-6">
                                <div class="info-main">
                                    <a [routerLink]="['/experto', experto.id]" class="h5 title"
                                        style="line-height: 1.6rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </a>
                                    <h3 style="margin: 0;">Años de experiencia: {{experto.anosexperiencia}}
                                        año(s)</h3>
                                    <h3 style="margin: 0;">Tarifa: {{experto.tarifa | currency:'$':'symbol':'1.0' }}
                                    </h3>
                                    <p *ngIf="isEspecialidad === 0">
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad11">
                                            {{ getNombreEspecialidad(experto.fkespecialidad11)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad12">
                                            {{ getNombreEspecialidad(experto.fkespecialidad12)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad13">
                                            {{ getNombreEspecialidad(experto.fkespecialidad13)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad14">
                                            {{ getNombreEspecialidad(experto.fkespecialidad14)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad15">
                                            {{ getNombreEspecialidad(experto.fkespecialidad15)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad21">
                                            {{ getNombreEspecialidad(experto.fkespecialidad21)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad22">
                                            {{ getNombreEspecialidad(experto.fkespecialidad22)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad23">
                                            {{ getNombreEspecialidad(experto.fkespecialidad23)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad24">
                                            {{ getNombreEspecialidad(experto.fkespecialidad24)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad25">
                                            {{ getNombreEspecialidad(experto.fkespecialidad25)[0].nombre }}
                                        </span>
                                    </p>

                                    <p class="mt-3" [innerHtml]="experto.curriculum | slice:0:200"></p>
                                    <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]">
                                        <span class="text">Ver más</span>
                                    </a>

                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]">
                                        <span class="text">Ver más</span>
                                    </a>

                                </div>
                            </div>
                            <aside class="col-sm-3">
                                <div class="info-aside">
                                    <div class="d-flex align-items-end flex-column" style="min-height: 100%;">
                                        <div class="p-2 w-100">
                                            <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]"
                                                class="btn btn-block btn-primary">
                                                <i class="fas fa-user"></i> <span class="text">Ver {{_experto}}</span>
                                            </a>

                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                        </div>

                                        <div class="p-2 w-100 text-center">
                                            <button (click)="share('/experto/' + experto.id)" class="btn btn-sm m-1"
                                                style="background-color: #3b5998; border-color: #3b5998; color: #fff;">
                                                <i class="fab fa-facebook-square"></i> Compartir
                                            </button>
                                            <button (click)="copy('experto/' + experto.id)"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Copiar URL
                                            </button>
                                            <a *ngIf="fk" [routerLink]="['/recomendar-experto', fk, experto.id]"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Correo electrónico
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 0">
                                            <a [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 1">
                                            <a *ngIf="experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </article>
                    <div class="card" style="background-color: #ffffff60;">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- AÑOS DE EXPERIENCIA (Menor a Mayor) -->
                <div *ngIf="total > 0 && isExperienciaMenorMayor">
                    <article class="card card-product-list" style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertos$ | async | sortBy: 'asc':'anosexperiencia'">
                        <div class="row no-gutters">
                            <aside class="col-md-3">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1.5rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                    <span
                                        style="font-size: 1.5rem; top: unset; right: 10px; bottom: 10px; left: unset; position: absolute;"
                                        class="badge badge-danger">
                                        {{ experto.nombrenivel }} </span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                                <div class="m-2">
                                    <div class="rating-wrap text-center mb-2" *ngIf="experto.calificaciones === 0">
                                        <ul class="rating-stars">
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                </div>
                            </aside>
                            <div class="col-md-6">
                                <div class="info-main">
                                    <a [routerLink]="['/experto', experto.id]" class="h5 title"
                                        style="line-height: 1.6rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </a>
                                    <h3 style="margin: 0;">Años de experiencia: {{experto.anosexperiencia}}
                                        año(s)</h3>
                                    <h3 style="margin: 0;">Tarifa: {{experto.tarifa | currency:'$':'symbol':'1.0' }}
                                    </h3>
                                    <p *ngIf="isEspecialidad === 0">
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad11">
                                            {{ getNombreEspecialidad(experto.fkespecialidad11)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad12">
                                            {{ getNombreEspecialidad(experto.fkespecialidad12)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad13">
                                            {{ getNombreEspecialidad(experto.fkespecialidad13)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad14">
                                            {{ getNombreEspecialidad(experto.fkespecialidad14)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad15">
                                            {{ getNombreEspecialidad(experto.fkespecialidad15)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad21">
                                            {{ getNombreEspecialidad(experto.fkespecialidad21)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad22">
                                            {{ getNombreEspecialidad(experto.fkespecialidad22)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad23">
                                            {{ getNombreEspecialidad(experto.fkespecialidad23)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad24">
                                            {{ getNombreEspecialidad(experto.fkespecialidad24)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad25">
                                            {{ getNombreEspecialidad(experto.fkespecialidad25)[0].nombre }}
                                        </span>
                                    </p>

                                    <p class="mt-3" [innerHtml]="experto.curriculum | slice:0:200"></p>
                                    <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]">
                                        <span class="text">Ver más</span>
                                    </a>

                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]">
                                        <span class="text">Ver más</span>
                                    </a>

                                </div>
                            </div>
                            <aside class="col-sm-3">
                                <div class="info-aside">
                                    <div class="d-flex align-items-end flex-column" style="min-height: 100%;">
                                        <div class="p-2 w-100">
                                            <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]"
                                                class="btn btn-block btn-primary">
                                                <i class="fas fa-user"></i> <span class="text">Ver {{_experto}}</span>
                                            </a>

                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                        </div>

                                        <div class="p-2 w-100 text-center">
                                            <button (click)="share('/experto/' + experto.id)" class="btn btn-sm m-1"
                                                style="background-color: #3b5998; border-color: #3b5998; color: #fff;">
                                                <i class="fab fa-facebook-square"></i> Compartir
                                            </button>
                                            <button (click)="copy('experto/' + experto.id)"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Copiar URL
                                            </button>
                                            <a *ngIf="fk" [routerLink]="['/recomendar-experto', fk, experto.id]"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Correo electrónico
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 0">
                                            <a [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 1">
                                            <a *ngIf="experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </article>
                    <div class="card" style="background-color: #ffffff60;">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- AÑOS DE EXPERIENCIA (Mayor a Menor) -->
                <div *ngIf="total > 0 && isExperienciaMayorMenor">
                    <article class="card card-product-list" style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertos$ | async | sortBy: 'desc':'anosexperiencia'">
                        <div class="row no-gutters">
                            <aside class="col-md-3">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1.5rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                    <span
                                        style="font-size: 1.5rem; top: unset; right: 10px; bottom: 10px; left: unset; position: absolute;"
                                        class="badge badge-danger">
                                        {{ experto.nombrenivel }} </span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                                <div class="m-2">
                                    <div class="rating-wrap text-center mb-2" *ngIf="experto.calificaciones === 0">
                                        <ul class="rating-stars">
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                </div>
                            </aside>
                            <div class="col-md-6">
                                <div class="info-main">
                                    <a [routerLink]="['/experto', experto.id]" class="h5 title"
                                        style="line-height: 1.6rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </a>
                                    <h3 style="margin: 0;">Años de experiencia: {{experto.anosexperiencia}}
                                        año(s)</h3>
                                    <h3 style="margin: 0;">Tarifa: {{experto.tarifa | currency:'$':'symbol':'1.0' }}
                                    </h3>
                                    <p *ngIf="isEspecialidad === 0">
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad11">
                                            {{ getNombreEspecialidad(experto.fkespecialidad11)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad12">
                                            {{ getNombreEspecialidad(experto.fkespecialidad12)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad13">
                                            {{ getNombreEspecialidad(experto.fkespecialidad13)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad14">
                                            {{ getNombreEspecialidad(experto.fkespecialidad14)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad15">
                                            {{ getNombreEspecialidad(experto.fkespecialidad15)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad21">
                                            {{ getNombreEspecialidad(experto.fkespecialidad21)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad22">
                                            {{ getNombreEspecialidad(experto.fkespecialidad22)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad23">
                                            {{ getNombreEspecialidad(experto.fkespecialidad23)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad24">
                                            {{ getNombreEspecialidad(experto.fkespecialidad24)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad25">
                                            {{ getNombreEspecialidad(experto.fkespecialidad25)[0].nombre }}
                                        </span>
                                    </p>

                                    <p class="mt-3" [innerHtml]="experto.curriculum | slice:0:200"></p>
                                    <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]">
                                        <span class="text">Ver más</span>
                                    </a>

                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]">
                                        <span class="text">Ver más</span>
                                    </a>

                                </div>
                            </div>
                            <aside class="col-sm-3">
                                <div class="info-aside">
                                    <div class="d-flex align-items-end flex-column" style="min-height: 100%;">
                                        <div class="p-2 w-100">
                                            <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]"
                                                class="btn btn-block btn-primary">
                                                <i class="fas fa-user"></i> <span class="text">Ver {{_experto}}</span>
                                            </a>

                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                        </div>

                                        <div class="p-2 w-100 text-center">
                                            <button (click)="share('/experto/' + experto.id)" class="btn btn-sm m-1"
                                                style="background-color: #3b5998; border-color: #3b5998; color: #fff;">
                                                <i class="fab fa-facebook-square"></i> Compartir
                                            </button>
                                            <button (click)="copy('experto/' + experto.id)"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Copiar URL
                                            </button>
                                            <a *ngIf="fk" [routerLink]="['/recomendar-experto', fk, experto.id]"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Correo electrónico
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 0">
                                            <a [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 1">
                                            <a *ngIf="experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </article>
                    <div class="card" style="background-color: #ffffff60;">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CALIFICACIÓN (Menor a Mayor) -->
                <div *ngIf="total > 0 && isCalificacionMenorMayor">
                    <article class="card card-product-list" style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertos$ | async | sortBy: 'asc':'calificaciones'">
                        <div class="row no-gutters">
                            <aside class="col-md-3">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1.5rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                    <span
                                        style="font-size: 1.5rem; top: unset; right: 10px; bottom: 10px; left: unset; position: absolute;"
                                        class="badge badge-danger">
                                        {{ experto.nombrenivel }} </span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                                <div class="m-2">
                                    <div class="rating-wrap text-center mb-2" *ngIf="experto.calificaciones === 0">
                                        <ul class="rating-stars">
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                </div>
                            </aside>
                            <div class="col-md-6">
                                <div class="info-main">
                                    <a [routerLink]="['/experto', experto.id]" class="h5 title"
                                        style="line-height: 1.6rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </a>
                                    <h3 style="margin: 0;">Años de experiencia: {{experto.anosexperiencia}}
                                        año(s)</h3>
                                    <h3 style="margin: 0;">Tarifa: {{experto.tarifa | currency:'$':'symbol':'1.0' }}
                                    </h3>
                                    <p *ngIf="isEspecialidad === 0">
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad11">
                                            {{ getNombreEspecialidad(experto.fkespecialidad11)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad12">
                                            {{ getNombreEspecialidad(experto.fkespecialidad12)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad13">
                                            {{ getNombreEspecialidad(experto.fkespecialidad13)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad14">
                                            {{ getNombreEspecialidad(experto.fkespecialidad14)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad15">
                                            {{ getNombreEspecialidad(experto.fkespecialidad15)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad21">
                                            {{ getNombreEspecialidad(experto.fkespecialidad21)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad22">
                                            {{ getNombreEspecialidad(experto.fkespecialidad22)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad23">
                                            {{ getNombreEspecialidad(experto.fkespecialidad23)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad24">
                                            {{ getNombreEspecialidad(experto.fkespecialidad24)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad25">
                                            {{ getNombreEspecialidad(experto.fkespecialidad25)[0].nombre }}
                                        </span>
                                    </p>

                                    <p class="mt-3" [innerHtml]="experto.curriculum | slice:0:200"></p>
                                    <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]">
                                        <span class="text">Ver más</span>
                                    </a>

                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]">
                                        <span class="text">Ver más</span>
                                    </a>

                                </div>
                            </div>
                            <aside class="col-sm-3">
                                <div class="info-aside">
                                    <div class="d-flex align-items-end flex-column" style="min-height: 100%;">
                                        <div class="p-2 w-100">
                                            <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]"
                                                class="btn btn-block btn-primary">
                                                <i class="fas fa-user"></i> <span class="text">Ver {{_experto}}</span>
                                            </a>

                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                        </div>

                                        <div class="p-2 w-100 text-center">
                                            <button (click)="share('/experto/' + experto.id)" class="btn btn-sm m-1"
                                                style="background-color: #3b5998; border-color: #3b5998; color: #fff;">
                                                <i class="fab fa-facebook-square"></i> Compartir
                                            </button>
                                            <button (click)="copy('experto/' + experto.id)"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Copiar URL
                                            </button>
                                            <a *ngIf="fk" [routerLink]="['/recomendar-experto', fk, experto.id]"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Correo electrónico
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 0">
                                            <a [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 1">
                                            <a *ngIf="experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </article>
                    <div class="card" style="background-color: #ffffff60;">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CALIFICACIÓN (Mayor a Menor) -->
                <div *ngIf="total > 0 && isCalificacionMayorMenor">
                    <article class="card card-product-list" style="border: 1px solid #e5e7ea !important;"
                        *ngFor="let experto of jsonExpertos$ | async | sortBy: 'desc':'calificaciones'">
                        <div class="row no-gutters">
                            <aside class="col-md-3">
                                <a [routerLink]="['/experto', experto.id]" class="img-wrap">
                                    <span style="font-size: 1.5rem;" class="badge" *ngIf="experto.destacado === 'SI'">
                                        <i class="fas fa-certificate" style="color: #FD6C3B;"></i></span>
                                    <span
                                        style="font-size: 1.5rem; top: unset; right: 10px; bottom: 10px; left: unset; position: absolute;"
                                        class="badge badge-danger">
                                        {{ experto.nombrenivel }} </span>
                                    <img [src]="experto.urlfotografia">
                                    {{experto.urlfotografia}}
                                </a>
                                <div class="m-2">
                                    <div class="rating-wrap text-center mb-2" *ngIf="experto.calificaciones === 0">
                                        <ul class="rating-stars">
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0 && experto.calificaciones <= 0.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 0.5 && experto.calificaciones <= 1">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1 && experto.calificaciones <= 1.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 1.5 && experto.calificaciones <= 2">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2 && experto.calificaciones <= 2.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 2.5 && experto.calificaciones <= 3">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3 && experto.calificaciones <= 3.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 3.5 && experto.calificaciones <= 4">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4 && experto.calificaciones <= 4.5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                    <div class="rating-wrap text-center mb-2"
                                        *ngIf="experto.calificaciones > 4.5 && experto.calificaciones <= 5">
                                        <ul class="rating-stars">
                                            <li style="width:100%" class="stars-active text-left">
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                            <li>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                            </li>
                                        </ul>
                                        <div class="label-rating">{{experto.calificaciones}} / 5</div>
                                    </div>
                                </div>
                            </aside>
                            <div class="col-md-6">
                                <div class="info-main">
                                    <a [routerLink]="['/experto', experto.id]" class="h5 title"
                                        style="line-height: 1.6rem!important; color: #390796;">
                                        <b>{{ experto.nickname | uppercase }}</b>
                                    </a>
                                    <h3 style="margin: 0;">Años de experiencia: {{experto.anosexperiencia}}
                                        año(s)</h3>
                                    <h3 style="margin: 0;">Tarifa: {{experto.tarifa | currency:'$':'symbol':'1.0' }}
                                    </h3>
                                    <p *ngIf="isEspecialidad === 0">
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad11">
                                            {{ getNombreEspecialidad(experto.fkespecialidad11)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad12">
                                            {{ getNombreEspecialidad(experto.fkespecialidad12)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad13">
                                            {{ getNombreEspecialidad(experto.fkespecialidad13)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad14">
                                            {{ getNombreEspecialidad(experto.fkespecialidad14)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad15">
                                            {{ getNombreEspecialidad(experto.fkespecialidad15)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad21">
                                            {{ getNombreEspecialidad(experto.fkespecialidad21)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad22">
                                            {{ getNombreEspecialidad(experto.fkespecialidad22)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad23">
                                            {{ getNombreEspecialidad(experto.fkespecialidad23)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad24">
                                            {{ getNombreEspecialidad(experto.fkespecialidad24)[0].nombre }}
                                        </span>
                                        <span class="badge badge-primary mr-1" *ngIf="experto.fkespecialidad25">
                                            {{ getNombreEspecialidad(experto.fkespecialidad25)[0].nombre }}
                                        </span>
                                    </p>

                                    <p class="mt-3" [innerHtml]="experto.curriculum | slice:0:200"></p>
                                    <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]">
                                        <span class="text">Ver más</span>
                                    </a>

                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]">
                                        <span class="text">Ver más</span>
                                    </a>
                                    <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                        [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]">
                                        <span class="text">Ver más</span>
                                    </a>

                                </div>
                            </div>
                            <aside class="col-sm-3">
                                <div class="info-aside">
                                    <div class="d-flex align-items-end flex-column" style="min-height: 100%;">
                                        <div class="p-2 w-100">
                                            <a *ngIf="isEspecialidad === 0" [routerLink]="['/experto', experto.id]"
                                                class="btn btn-block btn-primary">
                                                <i class="fas fa-user"></i> <span class="text">Ver {{_experto}}</span>
                                            </a>

                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                            <a *ngIf="isEspecialidad === 1 && experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/experto', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">Ver {{_experto}}</span>

                                            </a>
                                        </div>

                                        <div class="p-2 w-100 text-center">
                                            <button (click)="share('/experto/' + experto.id)" class="btn btn-sm m-1"
                                                style="background-color: #3b5998; border-color: #3b5998; color: #fff;">
                                                <i class="fab fa-facebook-square"></i> Compartir
                                            </button>
                                            <button (click)="copy('experto/' + experto.id)"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Copiar URL
                                            </button>
                                            <a *ngIf="fk" [routerLink]="['/recomendar-experto', fk, experto.id]"
                                                class="btn btn-sm btn-secondary m-1">
                                                <i class="far fa-envelope"></i> Correo electrónico
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 0">
                                            <a [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>

                                        <div class="mt-auto p-2 w-100" *ngIf="isEspecialidad === 1">
                                            <a *ngIf="experto.fkespecialidad11 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad11]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad12 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad12]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad13 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad13]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad14 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad14]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad15 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea1, experto.fkrama1, experto.fkespecialidad15]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad21 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad21]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad22 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad22]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad23 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad23]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad24 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad24]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                            <a *ngIf="experto.fkespecialidad25 === especialidad"
                                                [routerLink]="['/pregunta', experto.id, experto.fkarea2, experto.fkrama2, experto.fkespecialidad25]"
                                                class="btn btn-block btn-primary">
                                                <span class="text">¿Deseas hacer una Pregunta?</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </article>
                    <div class="card" style="background-color: #ffffff60;">
                        <div class="card-body">
                            <div class="text-center">
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>