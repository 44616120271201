import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Encuesta } from './../interface/encuesta';

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {

  constructor(
    private http: HttpClient
  ) { }

  public post(object: Encuesta): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/Encuesta', object);
  }

  public postAuth(object: Encuesta): Observable<object> {/**Create */
    return this.http.post(environment.urlApi + '/EncuestaAuth', object);
  }
}

