import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from './../../environments/environment';
import { QueryService } from './../shared/service/-query.service';
import { AuthService } from './../shared/service/-auth.service';
import { Experto } from './../shared/interface/experto';
import { Area } from './../shared/interface/area';
import { AreaService } from './../shared/service/area.service';
import { Rama } from './../shared/interface/rama';
import { Especialidad } from './../shared/interface/especialidad';
import { PreguntaService } from './../shared/service/pregunta.service';

@Component({
  selector: 'app-nueva-pregunta',
  templateUrl: './nueva-pregunta.component.html',
  styleUrls: ['./nueva-pregunta.component.css']
})
export class NuevaPreguntaComponent implements OnInit {

  // public jsonAreas: Area[];
  // public jsonRamas: Rama[];
  public fk: number;
  public fkexperto: number;
  public fkarea: number;
  public fkrama: number;
  public fkespecialidad: number;
  public tipof: string;
  public totalCaracteres = 0;
  private valor: number;
  private valorcomision: number;
  private contenido: string;
  public isMailContenido = false;
  public isContenidoIncorrecto = false;
  public isPendientePago: number;

  public jsonAreas$: Observable<Area[]>;
  public jsonRamas$: Observable<Rama[]>;
  public jsonEspecialidads$: Observable<Especialidad[]>;
  public jsonExpertos$: Observable<Experto[]>;
  public isRama = false;
  public isEspecialidad = false;
  public isExperto = false;
  public isSeleccionar = false;
  public isBuscandoExperto = false;

  private _urlApi: string = environment.urlApi;
  private _urlApi2: string = environment.urlApi2;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlSlim: string = environment.urlSlim;
  private _urlSlim2: string = environment.urlSlim2;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _whatsApp: string = environment.whatsApp;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  /** OBLIGATORIO */
  public form: FormGroup;
  public isAccion = -1;
  public isEstado = -1;
  public estado$: Observable<any>;

  constructor(
    /** OBLIGATORIO */
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queryService: QueryService,
    private auth: AuthService,
    private areaService: AreaService,
    private preguntaService: PreguntaService,
  ) {
    this.getAreas();
    // this.getRamas();

    /** OBLIGATORIO */
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (this.auth.validar()) {
        if (this.auth.validarUsuario()) {
          if (this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/mi-experto/' + params.fk + '/mensaje1';
          } else if (!this.auth.validarURL(params.fk, 'usuario')) {
            window.location.href = '/inicio';
          }
        } else if (this.auth.validarExperto()) {
          if (!this.auth.validarURL(params.fk, 'experto')) {
            window.location.href = '/inicio';
          }
        }

        /*** GET(params.id) */
      }

      if (params.fk) {
        // this.fk = parseInt(params.fk);
        this.fk = parseInt(this.auth.obtener('fk'));
      }
      if (params.tipof) {
        // this.tipo = parseInt(params.tipo);
        this.tipof = params.tipof;
      }

      if (params.especialidad) {
        this.isSeleccionar = true;

        if (params.istodos) {
          this.fkexperto = parseInt('0');
        } else {
          this.fkexperto = parseInt(params.experto);
        }

        this.fkarea = parseInt(params.area);
        this.fkrama = parseInt(params.rama);
        this.fkespecialidad = parseInt(params.especialidad);

        if (params.istodos) {
          this.isEstado = 1;
        } else {
          this.getEstadoExperto(this.fkexperto, this.fkarea, this.fkrama, this.fkespecialidad);
        }

        this.getTarifasExperto(this.fkexperto);
      } else {
        this.isEstado = 1;
        this.form.get('fkarea').setValidators(Validators.required);
        this.form.get('fkrama').setValidators(Validators.required);
        this.form.get('fkespecialidad').setValidators(Validators.required);
        this.form.get('fkexperto').setValidators(Validators.required);
      }
    });

    this.queryService.query_69(this.fk).subscribe(
      (response: any) => {
        try {
          this.isPendientePago = response[0].id;
        } catch (error) {
          this.isPendientePago = 0;
        }
      },
      error => {
        this.isPendientePago = 0;
      }
    );
  }

  /** OBLIGATORIO */
  private buildForm(): void {
    this.form = this.formBuilder.group({
      /** Pregunta */
      fecha: [''],
      tipo: [null], //ABIERTA ó DIRECTA
      titulo: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      tipof: [null], //area, rama, especialidad, all 
      fkarea: [null], //this.fkarea
      fkrama: [null], //this.fkrama 
      fkespecialidad: [null], //this.fkespecialidad
      fkusuario: [null], //this.fk
      estado1: [null], //PENDIENTE
      estado2: [null], //*PENDIENTE
      correoelectronicotemp: [this.auth.obtener('correoelectronico'), [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      /** Hilo */
      // fecha: [''],
      fecharespuesta: [null],
      fkexperto: [null], //this.fkexperto ó 0(cero)
      valor: [null],
      valorcomision: [null],
      fkpregunta: [null],
      contenido: [null, Validators.required],
      respuesta: [null],
      estado3: [null], //NUEVA
      estado4: [null], //PENDIENTE
      estado5: [null], //PENDIENTE
      estado6: [null], //*PENDIENTE
      fechamaxotranotificacion: [null],
      fkrecibo: [null],
    });
  }

  public validator(control: string) {
    const validator = this.form.get(control).validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  public refresh(): void {
    this.form.get('fkarea').setValue(null);
    this.form.get('fkrama').setValue(null);
    this.form.get('fkespecialidad').setValue(null);

    this.fkarea = null;
    this.fkrama = null;
    this.fkespecialidad = null;
    this.isRama = false;
    this.isEspecialidad = false;
    this.isExperto = false;
    this.isBuscandoExperto = false;

    this.jsonRamas$ = null;
    this.jsonEspecialidads$ = null;
    this.jsonExpertos$ = null;
  }

  /** OBLIGATORIO */
  public save(event: Event): void {
    event.preventDefault();

    if (this.form.valid) {
      const object = this.form.value;
      /** Pregunta */
      if (!this.isSeleccionar) {
        object.fkarea = this.form.get('fkarea').value.split(':')[0];
        object.fkrama = this.form.get('fkrama').value.split(':')[0];
        object.fkespecialidad = this.form.get('fkespecialidad').value.split(':')[0];
      } else {
        object.fkarea = this.fkarea;
        object.fkrama = this.fkrama;
        object.fkespecialidad = this.fkespecialidad;
      }
      object.fkusuario = this.getFkusuario();
      object.estado1 = 'PENDIENTE';
      object.estado2 = 'PENDIENTE';
      /** Hilo */
      if (!this.isSeleccionar) {
        object.fkexperto = this.form.get('fkexperto').value.split(':')[0];
      } else {
        object.fkexperto = this.fkexperto;
      }
      if(this.tipof){
        object.tipof= this.tipof;
      }
      /** Pregunta */
      if (parseInt(object.fkexperto) === 0) {
        object.tipo = 'ABIERTA';        
      } else {
        object.tipo = 'DIRECTA';
      }
      /** Hilo */
      object.valor = this.valor;
      object.valorcomision = this.valorcomision;
      object.contenido = this.contenido;
      object.estado3 = 'NUEVA';
      object.estado4 = 'PENDIENTE';
      object.estado5 = 'PENDIENTE';
      object.estado6 = 'PENDIENTE';
      this.isAccion = 0;

      this.preguntaService.postAuth(object).subscribe(
        (response: any) => {
          // response 200
          this.isAccion = 1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success'
            },
            buttonsStyling: false
          });

          swal.fire({
            imageUrl: this._urlTest + '/logo_128.png',
            imageHeight: 64,
            icon: 'success',
            title: 'Hemos enviado tu ' + this._pregunta + '...',
            showConfirmButton: false,
            timer: 4000
          });

          setTimeout(function () {
            window.location.href = '/mi-usuario/' + object.fkusuario;
          }, 4000);
        },
        error => {
          // response 500
          this.isAccion = -1;
          const swal = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-error'
            },
            buttonsStyling: false
          });

          swal.fire({
            // imageUrl: this._urlTest + '/logo_128.png',
            // imageHeight: 64,
            icon: 'error',
            title: 'Encontramos un problema con tu conexión a internet, intenta nuevamente en unos minutos',
            showConfirmButton: false,
            timer: 5000
          });
        }
      );

    }
  }

  public getFkusuario(): number {
    return this.fk;
  }

  public getTarifasExperto(id: any): any {
    id = id.toString().trim();
    this.queryService.query_19(id).subscribe(
      (response: any) => {
        try {
          this.valor = response[0].valor;
          this.valorcomision = response[0].valorcomision;
        } catch (error) {
          this.valor = null;
          this.valorcomision = null;
        }
      },
      error => {
        this.valor = null;
        this.valorcomision = null;
      }
    );
  }

  private getEstadoExperto(id: any, fkarea: any, fkrama: any, fkespecialidad: any): void {
    this.queryService.query_21(id, fkarea, fkrama, fkespecialidad).subscribe(
      (experto: any[]) => {
        try {
          this.estado$ = new Observable((observer) => {
            try {
              observer.next(experto[0].estado);
              observer.complete();
            } catch (error) {
              this.isEstado = 0;
            }
          });

          this.estado$.subscribe(response => {
            if (response === 'ACTIVO') {
              this.isEstado = 1;
            } else {
              this.isEstado = 0;
            }
          });
        } catch (error) {
          this.isEstado = 0;
        }

      }, error => {
        this.isEstado = 0;
      }
    );
  }

  // public getAreas(): void {
  //   this.areaService.getAll().subscribe(
  //     (areas: Area[]) => {
  //       this.jsonAreas = areas;
  //     }
  //   );
  // }

  // public getRamas(): void {
  //   this.ramaService.getAll().subscribe(
  //     (ramas: Rama[]) => {
  //       this.jsonRamas = ramas;
  //     }
  //   );
  // }

  // public getNombreArea(id: number): Area[] {
  //   return this.jsonAreas.filter(area => area.id === id);
  // }

  // public getNombreRama(id: number): Rama[] {
  //   return this.jsonRamas.filter(rama => rama.id === id);
  // }

  public onChangue(event: any): void {
    var re = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/;
    if (re.test(event.editor.getText())) {
      this.isMailContenido = true;
    } else {
      this.isMailContenido = false;
    }

    if (event.editor.getText().toLowerCase().includes('llámame') ||
      event.editor.getText().toLowerCase().includes('llamame') ||
      event.editor.getText().toLowerCase().includes('contáctame') ||
      event.editor.getText().toLowerCase().includes('contacteme') ||
      event.editor.getText().toLowerCase().includes('mi número de contacto es') ||
      event.editor.getText().toLowerCase().includes('mi numero de contacto es') ||
      event.editor.getText().toLowerCase().includes('mi correo es') ||
      event.editor.getText().toLowerCase().includes('escríbeme al correo') ||
      event.editor.getText().toLowerCase().includes('escribeme al correo') ||
      event.editor.getText().toLowerCase().includes('mándeme un correo') ||
      event.editor.getText().toLowerCase().includes('mandeme un correo') ||
      event.editor.getText().toLowerCase().includes('escríbame al correo') ||
      event.editor.getText().toLowerCase().includes('escribame al correo') ||
      event.editor.getText().toLowerCase().includes('escríbame a') ||
      event.editor.getText().toLowerCase().includes('escribame a') ||
      event.editor.getText().toLowerCase().includes('mándeme a') ||
      event.editor.getText().toLowerCase().includes('mandame a') ||
      event.editor.getText().toLowerCase().includes('mándeme un correo') ||
      event.editor.getText().toLowerCase().includes('mandame un correo') ||
      event.editor.getText().toLowerCase().includes('mi número de teléfono') ||
      event.editor.getText().toLowerCase().includes('mi numero de teléfono') ||
      event.editor.getText().toLowerCase().includes('mi número de celular') ||
      event.editor.getText().toLowerCase().includes('mi numero de celular') ||
      event.editor.getText().toLowerCase().includes('mi celular es')) {
      this.isContenidoIncorrecto = true;
    } else {
      this.isContenidoIncorrecto = false;
    }

    this.totalCaracteres = event.editor.getLength();
    if (this.totalCaracteres > 499) {
      event.editor.deleteText(499, event.editor.getLength());
    }
    this.contenido = event.editor.getText();
  }

  private getAreas(): void {
    this.areaService.getAll().subscribe(
      (areas: Area[]) => {
        this.jsonAreas$ = new Observable((observer) => {
          observer.next(areas);
          observer.complete();
        });
      }
    );
  }

  public getRamas(area: any): void {
    this.isRama = true;
    const id = area.target.value.split(':')[1];
    const nombre = area.target.value.split(':')[2];
    this.fkarea = nombre;

    this.queryService.query_1(id).subscribe(
      (ramas: Rama[]) => {
        this.jsonRamas$ = new Observable((observer) => {
          observer.next(ramas);
          observer.complete();
        });
      }
    );
  }

  public getEspecialidads(rama: any): void {
    this.isEspecialidad = true;
    const id = rama.target.value.split(':')[1];
    const nombre = rama.target.value.split(':')[2];
    this.fkrama = nombre;

    this.queryService.query_2(id).subscribe(
      (especialidads: Especialidad[]) => {
        this.jsonEspecialidads$ = new Observable((observer) => {
          observer.next(especialidads);
          observer.complete();
        });
      }
    );
  }

  public getExpertos(especialidad: any): void {
    this.isBuscandoExperto = true;
    this.isExperto = true;
    const id = especialidad.target.value.split(':')[1];
    const nombre = especialidad.target.value.split(':')[2];
    this.fkespecialidad = nombre;

    this.queryService.query_30(id).subscribe(
      (expertos: Experto[]) => {
        this.isBuscandoExperto = false;
        this.jsonExpertos$ = new Observable((observer) => {
          observer.next(expertos);
          observer.complete();
        });
      }
    );
  }

  public reload(): void {
    window.location.href = '/nueva-pregunta/' + this.fk;
  }

  public changedExperto(experto: any): void {
    const fkexperto = experto.target.value.split(':')[1];
    this.getTarifasExperto(fkexperto);
  }

  public setExperto(): void {
    this.form.get('fkexperto').setValue('0:0');
  }

}
