<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-usuario></app-menu-usuario>
    </div>
    <div class="container div-titulo">
        <h2 class="titulo">{{_pregunta.toUpperCase()}}</h2>
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_hilo}}, intenta
                                nuevamente.</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                    </div>
                    <form [formGroup]="form" (ngSubmit)="save($event)" enctype="multipart/form-data"
                        class="form-horizontal">
                        <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">FECHA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="fecha" disabled>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">TÍTULO</label>
                                    <div class="col-md-9">
                                        <div class="p-3" [innerHtml]="'<b>' + form.get('titulo').value + '</b>'"
                                            style="border: 1px solid #ced4da; border-radius: .25rem;">
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-9">
                                        <input type="text" class="form-control" formControlName="titulo" disabled>
                                    </div> -->
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">CONTENIDO</label>
                                    <div class="col-md-9">
                                        <div class="p-3" [innerHtml]="form.get('contenido').value"
                                            style="border: 1px solid #ced4da; border-radius: .25rem; text-align: justify;">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">{{_experto}}</label>
                                    <div class="col-md-9" *ngIf="!isExperto">
                                        <input type="text" class="form-control" value="{{fkexperto}}" disabled>
                                    </div>
                                    <div class="col-md-9 text-center" *ngIf="isBuscandoExperto && isExperto">
                                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 2.5rem;">
                                    </div>
                                    <div class="col-md-9 text-center"
                                        *ngIf="(jsonExpertos$ | async)?.length === 0 && isExperto">
                                        <span class="mr-md-auto mb-5">No hemos encontrado tu {{_experto}}, intenta
                                            comunicarte con nosotros.</span>
                                    </div>
                                    <div class="col-md-9" *ngIf="(jsonExpertos$ | async)?.length > 0">
                                        <select class="form-control" formControlName="fkexperto"
                                            (change)="changedExperto($event)">
                                            <option value="0:0">... {{_pregunta}} para todos</option>
                                            <option
                                                *ngFor="let experto of jsonExpertos$ | async | sortBy: 'asc':'nombre'"
                                                [ngValue]="experto.id + ':' + experto.nickname">
                                                {{ experto.nickname }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ffffff60;"
                            *ngIf="isBuscando === 1 && (jsonExpertos$ | async)?.length > 0">
                            <div class="card-body">
                                <div class="text-center" *ngFor="let item of jsonEstados$ | async; let index = index">
                                    <!-- ({{total}}) <b>{{item.tipo}}</b> - {{item.estado1}} -
                                    <b>{{item.estado2}}</b> -
                                    {{item.estado3}} - {{item.estado4}} - {{item.estado5}} -
                                    <b>{{item.estado6}}</b> -
                                    Experto {{item.fkexperto}} - {{item.horas}} horas -
                                    {{item.horasRespuesta}} horas *Respuesta<br>
                                    Hilo {{item.id}}<br>{{parseInt(item.estrella)}}
                                    estrellas<br> -->
                                    
                                    {{setEstados(item.estado1, item.estado4, item.estado5)}}
                                    <button type="button" class="btn btn-secondary rounded-pill m-1" *ngIf="form.invalid &&
                                        isAccion === -1 && 
                                        item.estado1 === 'RECHAZADA' &&
                                        item.estado4 === 'RECHAZADA' &&
                                        item.estado5 === 'RECHAZADA' && 
                                        parseInt(item.fkexperto) > 0 &&
                                        parseInt(item.fkexperto) === parseInt(fkexperto)">
                                        <i class="fas fa-angle-double-right"></i> Cambiar {{_experto}}
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill m-1" *ngIf="form.valid &&
                                        isAccion === -1 && 
                                        item.estado1 === 'RECHAZADA' &&
                                        item.estado4 === 'RECHAZADA' &&
                                        item.estado5 === 'RECHAZADA' && 
                                        parseInt(item.fkexperto) > 0 &&
                                        parseInt(item.fkexperto) === parseInt(fkexperto)">
                                        <i class="fas fa-angle-double-right"></i> Cambiar {{_experto}}
                                    </button>
                                    <button type="button" class="btn btn-secondary rounded-pill m-1" *ngIf="form.invalid &&
                                        isAccion === -1 && 
                                        item.estado1 === 'PENDIENTE' &&
                                        item.estado4 === 'PENDIENTE' &&
                                        item.estado5 === 'PENDIENTE' && 
                                        parseInt(item.fkexperto) > 0 &&
                                        parseInt(item.horas) >= 24 &&
                                        parseInt(item.fkexperto) === parseInt(fkexperto)">
                                        <i class="fas fa-angle-double-right"></i> Cambiar {{_experto}}
                                    </button>
                                    <button type="submit" class="btn btn-outline-primary rounded-pill m-1" *ngIf="form.valid &&
                                        isAccion === -1 && 
                                        item.estado1 === 'PENDIENTE' &&
                                        item.estado4 === 'PENDIENTE' &&
                                        item.estado5 === 'PENDIENTE' && 
                                        parseInt(item.fkexperto) > 0 &&
                                        parseInt(item.horas) >= 24 &&
                                        parseInt(item.fkexperto) === parseInt(fkexperto)">
                                        <i class="fas fa-angle-double-right"></i> Cambiar {{_experto}}
                                    </button>
                                    <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>