<section class="banner-all">
    <div class="container div-titulo">
        <h2 class="titulo" style="text-align: center; color: #390796!important;">Regístrate como</h2>
    </div>
    <div class="container">
        <p class="text-center mt-4"><span style="color: #665C61 !important;">Elige tú perfil y Regístrate.<br>Si ya
                tienes una cuenta ingresa </span> <a [routerLink]="['/login-usuario']">aquí</a>
        </p>
        <div class="row">
            <div class="col-md-4 p-3 text-center">
                <a [routerLink]="['/registro-usuario/usuario']"><img src="assets/consultante_persona_1.png" alt=""
                        class="img-fluid" class="button"></a>
                <!-- <h3 class="text-center" style="color: #390796!important;">Como funciona {{_usuario}} <i class="fas fa-arrow-down"></i></h3> -->
            </div>
            <div class="col-md-4 p-3 text-center">
                <a [routerLink]="['/registro-usuario/empresa']"><img src="assets/consultante_empresa_2.png" alt=""
                        class="img-fluid" class=" button"></a>
                <!-- <h3 class="text-center" style="color: #390796!important;">Como funciona Empresa <i class="fas fa-arrow-down"></i></h3> -->
            </div>
            <div class="col-md-4 p-3 text-center">
                <a [routerLink]="['/registro-experto']"><img src="assets/experto_1.png" alt="" class="img-fluid"
                        class="button"></a>
                <!-- <h3 class="text-center" style="color: #390796!important;">Como funciona {{_experto}} <i class="fas fa-arrow-down"></i></h3> -->
            </div>
        </div>
    </div>
</section>