<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Activa tu acceso de {{_experto}}</h2> -->
    </div>
    <div class="container">
        <div class="animated fadeIn">
            <div class="row">
                <div class="col-md-12">
                    <div class="card mx-auto" style="background-color: #ffffff60; max-width: 650px;">
                        <span class="span-titulo-confirmacion">
                            <mark class="titulo-confirmacion">ACTIVA TU ACCESO DE {{_experto}}</mark>
                        </span>
                        <form [formGroup]="form" (ngSubmit)="update($event)" enctype="multipart/form-data"
                            autocomplete="off">
                            <div class="card-body card-confirmacion">
                                <input id="username" style="opacity: 0;position: absolute;" type="text"
                                    name="fakeusernameremembered">
                                <input id="password" style="opacity: 0;position: absolute;" class="cp-password_stub"
                                    type="password" name="fakepasswordremembered">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Correo electrónico"
                                        formControlName="correoelectronico" required autocomplete="off" 
                                        oninput="this.value = this.value.toLowerCase()">
                                    <div *ngIf="form.get('correoelectronico').errors?.pattern">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['correoelectronico'].hasError('pattern')">
                                            + Ingresa un correo valido
                                        </span>
                                    </div>
                                    <div
                                        *ngIf="form.get('correoelectronico').errors && form.get('correoelectronico').dirty">
                                        <small class="form-text text-danger"
                                            *ngIf="form.controls['correoelectronico'].hasError('required')">
                                            + Campo requerido
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Código de confirmación"
                                        formControlName="tokenrecupera" required autocomplete="off">
                                    <div *ngIf="form.get('tokenrecupera').errors && form.get('tokenrecupera').dirty">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['tokenrecupera'].hasError('required')">
                                            + Campo requerido
                                        </span>
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['tokenrecupera'].hasError('minlength')">
                                            + Debes ingresar más caracteres
                                        </span>
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['tokenrecupera'].hasError('maxlength')">
                                            + Excediste el valor de caracteres
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Contraseña"
                                        formControlName="contrasena" required autocomplete="off">
                                    <div *ngIf="form.get('contrasena').errors?.pattern">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['contrasena'].hasError('pattern')">
                                            + Ingresa una contraseña con el siguiente formato
                                            <ul>
                                                <li>Mínimo 6 caracteres</li>
                                                <li>Máximo 10</li>
                                                <li>No espacios en blanco</li>
                                                <li>Al menos una letra mayúscula</li>
                                                <li>Al menos una letra minúscula</li>
                                                <li>Al menos un dígito</li>
                                                <li>Al menos 1 caracter especial</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div *ngIf="form.get('contrasena').errors && form.get('contrasena').dirty">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['contrasena'].hasError('required')">
                                            + Campo requerido
                                        </span>
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['contrasena'].hasError('minlength')">
                                            + Debes ingresar más caracteres
                                        </span>
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['contrasena'].hasError('maxlength')">
                                            + Excediste el valor de caracteres
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Confirmar contraseña"
                                        formControlName="confirmacontrasena" required autocomplete="off">
                                    <div
                                        *ngIf="form.get('confirmacontrasena').errors && form.get('confirmacontrasena').dirty">
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['confirmacontrasena'].hasError('required')">
                                            + Campo requerido
                                        </span>
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['confirmacontrasena'].hasError('minlength')">
                                            + Debes ingresar más caracteres
                                        </span>
                                        <span class="help-block text-danger"
                                            *ngIf="form.controls['confirmacontrasena'].hasError('maxlength')">
                                            + Excediste el valor de caracteres
                                        </span>
                                    </div>
                                    <div *ngIf="form.get('confirmacontrasena').value">
                                        <div
                                            *ngIf="(!form.controls['confirmacontrasena'].hasError('minlength') 
                                            && !form.controls['confirmacontrasena'].hasError('maxlength')) 
                                            && (form.get('contrasena').value !== form.get('confirmacontrasena').value)">
                                            <span class="help-block text-danger">
                                                + Las contraseñas no coinciden
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group text-center" style="margin-top: -1rem;">
                                <button type="button" class="btn btn-secondary rounded-pill"
                                    style="border: 2px solid #fff !important;"
                                    *ngIf="form.invalid && isAccion === -1"><i class="fas fa-angle-double-right"></i>
                                    Recuperar</button>
                                <button type="submit" class="btn btn-outline-primary rounded-pill"
                                    *ngIf="form.valid && isAccion === -1"><i class="fas fa-angle-double-right"></i>
                                    Recuperar</button>
                                <img src="assets/template/images/loading_16.gif" alt="" *ngIf="isAccion === 0">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>