<section class="banner-inter">
    <div class="container" style="margin-top: -50px !important;
    margin-bottom: 50px !important;">
        <app-menu-experto></app-menu-experto>
    </div>
    <!-- <div class="container div-titulo">
        <h2 class="titulo">{{_pregunta}}</h2>
    </div> -->
    <div class="container">
        <div class="animated fadeIn">
            <h2 class="titulo">{{_pregunta.toUpperCase()}}</h2>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <header class="mb-3">
                        <div class="form-inline" *ngIf="isBuscando === -1">
                            <strong class="mr-md-auto">Buscando ...</strong>
                        </div>
                        <div class="form-inline" *ngIf="(isBuscando === 0 || isBuscando === 1) && total <= 0">
                            <strong class="mr-md-auto mb-5">No hemos encontrado tu {{_hilo}}, intenta
                                nuevamente.</strong>
                        </div>
                    </header>
                    <div class="col-12 text-center" *ngIf="isBuscando === -1 && total <= 0">
                        <img src="assets/spinner.gif" alt="" style="width: auto; height: 8rem;">
                    </div>
                    <div class="card" style="background-color: #ffffff60;" *ngIf="isBuscando === 1">
                        <div class="card-body">
                            <div
                                *ngFor="let item of jsonPreguntaHilo$ | async | sortBy: 'asc':'idHilo'; let index = index">

                                <div class="form-group row" *ngIf="index === 0">
                                    <label class="col-md-3 col-form-label">FECHA</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" [value]="item.fechaHilo" disabled>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="index === 0">
                                    <label class="col-md-3 col-form-label">TÍTULO</label>
                                    <div class="col-md-9">
                                        <div class="p-3" [innerHtml]="'<b>' + item.titulo + '</b>'"
                                            style="border: 1px solid #ced4da; border-radius: .25rem;">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <div class="card" style="background-color: #ffffff60;">
                                            <div class="card-header" *ngIf="index === 0"><b>{{_pregunta}}</b></div>
                                            <div class="card-header" *ngIf="index > 0"><b>{{_hilo}}</b></div>
                                            <div class="card-body">
                                                <div [innerHtml]="item.contenido" style="text-align: justify;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3" *ngIf="item.respuesta && item.estado4 === 'NO_PAGO' && item.estado3 != 'HILO'">
                                    <div class="offset-md-3 col-md-9">
                                        <div class="card" style="background-color: #ffffff60;">
                                            <div class="card-header" style="background-color: #b44ad2!important;">
                                                <b>[{{_experto}}] {{item.nickname}}</b> dice :
                                            </div>
                                            <div class="card-body">
                                                <div [innerHtml]="item.respuesta" style="text-align: justify;"></div>
                                                <div [innerHtml]="item.estado4" style="text-align: justify;"></div>
                                                <div [innerHtml]="item.estado3" style="text-align: justify;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3" *ngIf="!item.respuesta &&
                                item.estado1 === 'PENDIENTE' &&
                                item.estado4 === 'PENDIENTE' &&
                                item.estado5 === 'PENDIENTE' ">
                                    <div class="offset-md-3 col-md-9">
                                        <div class="card" style="background-color: #ffffff60;">
                                            <div class="card-header" style="background-color: #b44ad2!important;">
                                                <b>[{{_experto}}] {{item.nickname}}</b> responde :
                                            </div>
                                            <div class="card-body">
                                                <form [formGroup]="form" (ngSubmit)="save($event)"
                                                    enctype="multipart/form-data" class="form-horizontal">
                                                    <div class="card" style="background-color: #ffffff60;">
                                                        <div class="card-body">
                                                            <div class="form-group row">
                                                                <div class="col-md-12">
                                                                    {{form.get('id').setValue(item.idHilo)}}
                                                                    <input type="hidden" formControlName="id">
                                                                    <quill-editor [styles]="{height: '250px'}" (onContentChanged)="onChangue($event)"
                                                                        formControlName="respuesta">
                                                                    </quill-editor>
                                                                    <span class="help-block text-danger"
                                                                        *ngIf="isMailContenido">
                                                                        + No puedes incluir correos electronicos en el texto
                                                                    </span>
                                                                    <span class="help-block text-danger" *ngIf="isContenidoIncorrecto">
                                                                        + No puedes incluir esta palabra en el texto
                                                                    </span>
                                                                    <div
                                                                        *ngIf="form.get('respuesta').errors && form.get('respuesta').dirty">
                                                                        <span class="help-block text-danger"
                                                                            *ngIf="form.controls['respuesta'].hasError('required')">
                                                                            + Campo requerido
                                                                        </span>
                                                                        <span class="help-block text-danger"
                                                                            *ngIf="form.controls['respuesta'].hasError('minlength')">
                                                                            + Debes ingresar más caracteres
                                                                        </span>
                                                                        <span class="help-block text-danger"
                                                                            *ngIf="form.controls['respuesta'].hasError('maxlength')">
                                                                            + Excediste el valor de caracteres
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card" style="background-color: #ffffff60;"
                                                        *ngIf="isBuscando === 1 && (jsonPreguntaHilo$ | async)?.length > 0">
                                                        <div class="card-body">
                                                            <div class="text-center"
                                                                *ngFor="let item of jsonEstados$ | async; let index = index">
                                                                <!-- ({{total}}) <b>{{item.tipo}}</b> - {{item.estado1}} -
                                                                <b>{{item.estado2}}</b> -
                                                                {{item.estado3}} - {{item.estado4}} - {{item.estado5}} -
                                                                <b>{{item.estado6}}</b> -
                                                                Experto {{item.fkexperto}} - {{item.horas}} horas -
                                                                {{item.horasRespuesta}} horas *Respuesta<br>
                                                                Hilo {{item.id}}<br> *** {{fk}} *** {{item.id}} *** {{id}} ***  -->
                                                                <button type="button"
                                                                    class="btn btn-secondary rounded-pill m-1" *ngIf="(isMailContenido || isContenidoIncorrecto || form.invalid) && 
                                                                        isAccion === -1 && 
                                                                        item.estado1 === 'PENDIENTE' &&
                                                                        item.estado4 === 'PENDIENTE' &&
                                                                        item.estado5 === 'PENDIENTE' && 
                                                                        parseInt(item.fkexperto) > 0
                                                                        && (jsonPreguntaHilo$ | async)?.length === 1">
                                                                    <i class="fas fa-angle-double-right"></i> Responder
                                                                    {{_pregunta}}
                                                                </button>
                                                                <button type="submit"
                                                                    class="btn btn-outline-primary rounded-pill m-1"
                                                                    *ngIf="form.valid &&
                                                                        isAccion === -1 &&
                                                                        item.estado1 === 'PENDIENTE' &&
                                                                        item.estado4 === 'PENDIENTE' &&
                                                                        item.estado5 === 'PENDIENTE' && 
                                                                        !isMailContenido && !isContenidoIncorrecto && 
                                                                        parseInt(item.fkexperto) > 0
                                                                        && (jsonPreguntaHilo$ | async)?.length === 1">
                                                                    <i class="fas fa-angle-double-right"></i>
                                                                    Responder {{_pregunta}}
                                                                </button>
                                                                <a (click)="update(item.id)"
                                                                    class="btn btn-dark rounded-pill m-1" *ngIf="isAccion === -1 &&
                                                                        item.estado1 === 'PENDIENTE' &&
                                                                        item.estado4 === 'PENDIENTE' &&
                                                                        item.estado5 === 'PENDIENTE' && 
                                                                        parseInt(item.fkexperto) > 0
                                                                        && (jsonPreguntaHilo$ | async)?.length === 1">
                                                                    <i class="fas fa-angle-double-right"></i>
                                                                    Rechazar {{_pregunta}}
                                                                </a>
                                                                <button type="button"
                                                                    class="btn btn-secondary rounded-pill m-1" *ngIf="(isMailContenido || isContenidoIncorrecto || form.invalid) && 
                                                                        isAccion === -1 && 
                                                                        item.estado1 === 'PENDIENTE' &&
                                                                        item.estado4 === 'PENDIENTE' &&
                                                                        item.estado5 === 'PENDIENTE' && 
                                                                        parseInt(item.fkexperto) > 0
                                                                        && (jsonPreguntaHilo$ | async)?.length > 1">
                                                                    <i class="fas fa-angle-double-right"></i> Responder
                                                                    {{_hilo}}
                                                                </button>
                                                                <button type="submit"
                                                                    class="btn btn-outline-primary rounded-pill m-1"
                                                                    *ngIf="form.valid &&
                                                                        isAccion === -1 &&
                                                                        item.estado1 === 'PENDIENTE' &&
                                                                        item.estado4 === 'PENDIENTE' &&
                                                                        item.estado5 === 'PENDIENTE' && 
                                                                        !isMailContenido && !isContenidoIncorrecto && 
                                                                        parseInt(item.fkexperto) > 0
                                                                        && (jsonPreguntaHilo$ | async)?.length > 1">
                                                                    <i class="fas fa-angle-double-right"></i>
                                                                    Responder {{_hilo}}
                                                                </button>
                                                                <a (click)="update(item.id)"
                                                                    class="btn btn-dark rounded-pill m-1" *ngIf="isAccion === -1 &&
                                                                        item.estado1 === 'PENDIENTE' &&
                                                                        item.estado4 === 'PENDIENTE' &&
                                                                        item.estado5 === 'PENDIENTE' && 
                                                                        parseInt(item.fkexperto) > 0
                                                                        && (jsonPreguntaHilo$ | async)?.length > 1">
                                                                    <i class="fas fa-angle-double-right"></i>
                                                                    Rechazar {{_hilo}}
                                                                </a>
                                                                <img src="assets/template/images/loading_16.gif" alt=""
                                                                    *ngIf="isAccion === 0">
                                                            </div>
                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>