import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageService } from './-local-storage.service';
import { QueryService } from './-query.service';
import Swal from 'sweetalert2';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private isLoginObservable = new BehaviorSubject<boolean>(true);
  // public isLogin$ = this.isLoginObservable.asObservable();

  private _urlApi: string = environment.urlApi;
  private _urlImage: string = environment.urlImage;
  private _urlFile: string = environment.urlFile;
  private _urlNoreply: string = environment.urlNoreply;
  private _urlPay: string = environment.urlPay;
  private _urlTest: string = environment.urlTest;
  private _urlWeb: string = environment.urlWeb;
  public _experto: string = environment.experto;
  public _usuario: string = environment.usuario;
  public _pregunta: string = environment.pregunta;
  public _hilo: string = environment.hilo;
  public _encuesta: string = environment.encuesta;
  public _tipo1: string = environment.tipo1;
  public _tipo2: string = environment.tipo2;
  public _estado1: string = environment.estado1;
  public _estado2: string = environment.estado2;
  public _estado3: string = environment.estado3;
  public _area: string = environment.area;
  public _rama: string = environment.rama;
  public _especialidad: string = environment.especialidad;
  public _destacado: string = environment.destacado;

  constructor(
    private route: Router,
    private localstorageService: LocalStorageService,
    private queryService: QueryService,
  ) {
  }

  public validarUsuario() {
    const route = this.route.url;
    if (route.includes('/mi-usuario/')
      || route.includes('/nueva-encuesta/')
      // || route.includes('/confirmacion-pago-encuesta/')
      || route.includes('/informe-encuesta-xls/')
      || route.includes('/nueva-pregunta/')
      // || route.includes('/confirmacion-pago-hilo/')
      || route.includes('/pregunta-pendiente/')
      || route.includes('/pregunta-cambio-experto/')
      || route.includes('/pregunta-resuelta/')
      || route.includes('/valoracion-respuesta/')
      || route.includes('/pregunta-hilo/')
      || route.includes('/perfil-usuario/')
      || route.includes('/recibo/')
      || route.includes('/recomendar-amigo/')
      || route.includes('/recomendar-experto/')
      || route.includes('/notificacion-usuario/')) {
      // console.log('validarUsuario(): true');
      return true;
    } else {
      // console.log('validarUsuario(): false');
      return false;
    }
  }

  public validarExperto() {
    const route = this.route.url;
    if (route.includes('/mi-experto/')
      || route.includes('/respuesta-abierta/')
      || route.includes('/respuesta-pendiente/')
      || route.includes('/responder/')
      || route.includes('/respuesta-resuelta/')
      || route.includes('/opinion-abierta/')
      // || route.includes('/opinion-pendiente/')
      || route.includes('/opinar/')
      || route.includes('/perfil-experto/')
      || route.includes('/tarifa/')
      || route.includes('/beneficio/')
      || route.includes('/ser-destacado/')
      || route.includes('/ser-prime/')
      || route.includes('/notificacion-experto/')) {
      // console.log('validarExperto(): true');
      return true;
    } else {
      // console.log('validarExperto(): false');
      return false;
    }
  }

  public validarMenu() {
    const route = this.route.url;
    if (route.includes('/mi-experto/')
      || route.includes('/respuesta-abierta/')
      || route.includes('/respuesta-pendiente/')
      // || route.includes('/responder/')
      || route.includes('/respuesta-resuelta/')
      || route.includes('/opinion-abierta/')
      // || route.includes('/opinion-pendiente/')
      // || route.includes('/opinar/')
      || route.includes('/perfil-experto/')
      || route.includes('/tarifa/')
      || route.includes('/beneficio/')
      || route.includes('/ser-destacado/')
      || route.includes('/ser-prime/')
      || route.includes('/notificacion-experto/')
      || route.includes('/mi-usuario/')
      || route.includes('/nueva-encuesta/')
      || route.includes('/confirmacion-pago-encuesta/')
      || route.includes('/informe-encuesta-xls/')
      || route.includes('/nueva-pregunta/')
      || route.includes('/confirmacion-pago-hilo/')
      || route.includes('/pregunta-pendiente/')
      || route.includes('/pregunta-cambio-experto/')
      || route.includes('/pregunta-resuelta/')
      || route.includes('/valoracion-respuesta/')
      || route.includes('/pregunta-hilo/')
      || route.includes('/perfil-usuario/')
      || route.includes('/recibo/')
      || route.includes('/recomendar-amigo/')
      || route.includes('/recomendar-experto/')
      || route.includes('/notificacion-usuario/')
    ) {
      // console.log('validarMenu(): false');
      return false;
    } else {
      // console.log('validarMenu(): true');
      return true;
    }
  }

  public validarEnlaces() {
    const route = this.route.url;
    if (route.includes('/responder/')
      || route.includes('/opinar/')
    ) {
      return false;
    } else {
      return true;
    }
  }

  public validarFooter() {
    const route = this.route.url;
    if (route.includes('/activacion-experto/')
      || route.includes('/activacion-recuperar-experto/')
      || route.includes('/activacion-recuperar-usuario/')
      || route.includes('/activacion-referencia-experto/')
      || route.includes('/activacion-usuario/')
      || route.includes('/confirmacion-recuperar-experto/')
      || route.includes('/confirmacion-recuperar-usuario/')
      || route.includes('/confirmacion-registro-experto/')
      || route.includes('/confirmacion-registro-usuario/')
      || route.includes('/recuperar-experto/')
      || route.includes('/recuperar-usuario/')
      || route.includes('/registro')
      || route.includes('/registro-experto')
      || route.includes('/registro-usuario')
      || route.includes('/login-experto')
      || route.includes('/login-usuario')) {
      // console.log('validarFooter(): false');
      return false;
    } else {
      // console.log('validarFooter(): true');
      return true;
    }
  }

  public isLogin() {
    if (this.localstorageService.get('fddkZXqdwyKudhcVSEMY')) {
      // this.isLoginObservable.next(true);
      return true;
    } else {
      // this.isLoginObservable.next(false);
      return false;
    }
  }

  public validar() {
    if (this.localstorageService.get('fddkZXqdwyKudhcVSEMY')) {
      var time: any;
      var date: Date = new Date();
      time = date.getTime().toString().substring(0, 10);

      this.queryService.query_13().subscribe(
        (response: any) => {
          // console.log('response--->>>', response);
          if (parseInt(time) - parseInt(response) > 3600) {
            // console.log('parseInt(time) - parseInt(response)--->>>', parseInt(time) - parseInt(response));
            this.salirMensaje();
          } else if (parseInt(response) - parseInt(time) > 3600) {
            // console.log('parseInt(response) - parseInt(time)--->>>', parseInt(response) - parseInt(time));
            this.salirMensaje();
          }
        }, error => {
        }
      );
      // console.log('time--->>>', time);
      // console.log('localstorageService...ini--->>>', this.localstorageService.get('wxxSIlluFQDHVSkSnqtr'));
      // console.log('localstorageService...exp--->>>', this.localstorageService.get('fddkZXqdwyKudhcVSEMY'));

      if (parseInt(time) > parseInt(this.localstorageService.get('fddkZXqdwyKudhcVSEMY'))) {
        // console.log('parseInt(time) > parseInt(this.localstorageService.get(\'fddkZXqdwyKudhcVSEMY\'))--->>>', false);
        // this.isLoginObservable.next(false);
        return false;
      } else {
        // console.log(true);
        // this.isLoginObservable.next(true);
        return true;
      }
    } else {
      // console.log('else--->>>', false);
      // this.isLoginObservable.next(false);
      return false;
    }
  }

  public validarURL(fk: any, type: any) {
    if (this.localstorageService.get('eVBEvatmKiVlokbfjfwJ') && this.localstorageService.get('qFUNseVbOFIMuWyQSdxC')) {
      try {
        if (('' + this.localstorageService.get('eVBEvatmKiVlokbfjfwJ')).toString().match(fk.toString()) &&
          ('' + this.localstorageService.get('qFUNseVbOFIMuWyQSdxC')).toString().match(type.toString())) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  public crear(value: any, correoelectronico: any): void {
    this.localstorageService.set('QYfRuxeadphBcSuOAczF', value.message.Authorization);
    this.localstorageService.set('eVBEvatmKiVlokbfjfwJ', value.message.fk);
    this.localstorageService.set('WTDmCLTytWRVIjnSyHqP', value.message.nickname);
    this.localstorageService.set('qdwyKudhTytWRVIjUHqP', value.message.url);
    this.localstorageService.set('wxxSIlluFQDHVSkSnqtr', value.message.ini);
    this.localstorageService.set('fddkZXqdwyKudhcVSEMY', value.message.exp);
    this.localstorageService.set('qFUNseVbOFIMuWyQSdxC', value.message.typ);
    this.localstorageService.set('CBIfXgisPiSqolrJrNFd', correoelectronico);
    // this.isLoginObservable.next(true);
  }

  public obtener(tipo: any): any {
    try {
      if (tipo === 'fk') {
        return this.localstorageService.get('eVBEvatmKiVlokbfjfwJ').toString();
      } else if (tipo === 'nickname') {
        return this.localstorageService.get('WTDmCLTytWRVIjnSyHqP').toString();
      } else if (tipo === 'url') {
        return this.localstorageService.get('qdwyKudhTytWRVIjUHqP').toString();
      } else if (tipo === 'typ') {
        return this.localstorageService.get('qFUNseVbOFIMuWyQSdxC').toString();
      } else if (tipo === 'correoelectronico') {
        return this.localstorageService.get('CBIfXgisPiSqolrJrNFd').toString();
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public salir() {
    this.localstorageService.clear();
    // this.route.navigate(['/inicio']);
    window.location.href = '/inicio';
    // this.isLoginObservable.next(false);
  }

  public salirMensaje() {
    const swal = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-error'
      },
      buttonsStyling: false
    });

    swal.fire({
      imageUrl: this._urlTest + '/logo_128.png',
      imageHeight: 64,
      // icon: 'info',
      title: 'Sesión finalizada',
      showConfirmButton: false,
      timer: 4000
    });

    this.localstorageService.clear();
    // this.route.navigate(['/inicio']);
    setTimeout(function () {
      window.location.href = '/inicio';
    }, 4000);
    // this.isLoginObservable.next(false);
  }

}
