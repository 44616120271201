<section class="banner-all">
    <div class="container div-titulo">
        <!-- <h2 class="titulo">Confirmamos tu registro de {{_usuario}}</h2> -->
    </div>
    <div class="container">
        <div class="row">
            <!-- *** -->
            <div class="card mx-auto" style="background-color: #ffffff60; max-width: 650px;">
                <!-- *** -->
                <span class="span-titulo-confirmacion">
                    <mark class="titulo-confirmacion">CONFIRMACIÓN DE REGISTRO</mark>
                </span>
                <!-- *** -->
                <div class="card-body card-confirmacion text-center">
                    <!-- *** -->
                    <p style="color: #665C61 !important;">Confirma tu registro ingresando el código que te llegará a tu
                        correo electrónico. Recuerda revisar en la bandeja de entrada y/o correo no deseado.
                    </p>
                </div>
                <!-- *** -->
                <div class="form-group text-center" style="margin-top: -1rem;">
                    <a class="btn btn-outline-primary rounded-pill"
                        [routerLink]="['/activacion-usuario', correoelectronico]">
                        Ingresar el código de confirmación <i class="fas fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>